import React from "react";
import p_nav_previous from "../../../Assets/Images/p_nav_previous.svg";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { LuPlusCircle } from "react-icons/lu";
import { FiMinusCircle } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useState } from "react";
import { SlArrowDown } from "react-icons/sl";
import { SlArrowUp } from "react-icons/sl";
import { IoIosArrowForward } from "react-icons/io";
import { MdWork } from "react-icons/md";

const ReviewPayDoorstep = () => {
  const navigate = useNavigate();
  const [showDetails, setShowDetails] = useState(false);
  const [showDetails1, setShowDetails1] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [count, setCount] = useState(1); // Initial value is set to 0

  const handleIncrement = () => {
    if (count < 10000000) {
      // Change the value as per your requirement
      setCount((prevCount) => prevCount + 1);
    }
  };

  const handleDecrement = () => {
    if (count > 0) {
      // Change the value as per your requirement
      setCount((prevCount) => prevCount - 1);
    }
  };

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  const [divStates, setDivStates] = useState({
    service: { backgroundColor: "#FFFFFF", borderColor: "#00000099" },
    product: { backgroundColor: "#FFFFFF", borderColor: "#00000099" },
    gst: { backgroundColor: "#FFFFFF", borderColor: "#00000099" },
  });

  const [divStates2, setDivStates2] = useState({
    service: { backgroundColor: "#FFFFFF", borderColor: "#00000099" },
    product: { backgroundColor: "#FFFFFF", borderColor: "#00000099" },
    gst: { backgroundColor: "#FFFFFF", borderColor: "#00000099" },
  });
  
  const [divStates3, setDivStates3] = useState({
    service: { backgroundColor: "#FFFFFF", borderColor: "#00000099" },
    product: { backgroundColor: "#FFFFFF", borderColor: "#00000099" },
    gst: { backgroundColor: "#FFFFFF", borderColor: "#00000099" },
  });

  const handleCheck = (divKey) => {
    setDivStates((prevStates) => {
      const newStates = { ...prevStates };
      newStates[divKey] = {
        backgroundColor:
          newStates[divKey].backgroundColor === "#FFFFFF"
            ? "#58A901"
            : "#FFFFFF",
        borderColor:
          newStates[divKey].borderColor === "#00000099"
            ? "#58A901"
            : "#00000099",
      };
      return newStates;
    });
  };

  const handleCheck2 = (divKey) => {
    setDivStates2((prevStates) => {
      const newStates = { ...prevStates };
      newStates[divKey] = {
        backgroundColor:
          newStates[divKey].backgroundColor === "#FFFFFF"
            ? "#58A901"
            : "#FFFFFF",
        borderColor:
          newStates[divKey].borderColor === "#00000099"
            ? "#58A901"
            : "#00000099",
      };
      return newStates;
    });
  };

  const handleCheck3 = (divKey) => {
    setDivStates3((prevStates) => {
      const newStates = { ...prevStates };
      newStates[divKey] = {
        backgroundColor:
          newStates[divKey].backgroundColor === "#FFFFFF"
            ? "#58A901"
            : "#FFFFFF",
        borderColor:
          newStates[divKey].borderColor === "#00000099"
            ? "#58A901"
            : "#00000099",
      };
      return newStates;
    });
  };

  return (
    <>
      <div className="scroller-style-y">
        <div className="p-2 border secondary-bg-1">
          <div className="p-3 bg-white mt-2 rounded-4">

            <div className="border border-black border-opacity-10 w-100 rounded p-2">
              <div className="primary-text ff-balooPaaji2-regular">
                Before making a purchase, please log in.
              </div>
              <div>
                <button className="w-100 border-0 primary-bg text-white ff-balooPaaji2-semiBold py-1 rounded mt-2">
                  Login
                </button>
              </div>
            </div>

            <div className="mb-4 mt-4">
              <div className="ff-balooPaaji2-bold mb-2">Delivery Address</div>
              <div>
                <button
                  className="border border-black border-opacity-10 w-100 ff-balooPaaji2-semiBold rounded p-2 bg-white"
                  data-bs-toggle="modal" data-bs-target="#exampleModal1">
                  Add Address
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal for adding address  */}
        <div
          class="modal fade bottom"
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog mainModal">
            <div className="forgetRadiusDivRP">
              <div className="downContentDivHeadRP">
                <div className="d-flex justify-content-between">
                  <p className="ff-poppins-bold">Choose Address</p>
                  <button
                    type="button"
                    class="btn-close fs-12 border p-2 rounded-circle text-black"
                    aria-label="Close"
                  ></button>
                </div>
                <div>
                  <div className="d-flex align-items-center mt-4">
                    <div className="col-2">
                      <div className="border w-100 border-black border-opacity-10 rounded d-flex justify-content-center fs-24 primary-text py-2">
                        <MdWork className="primary-text" />
                      </div>
                    </div>
                    <div className="col-9">
                      <p className="m-0 ps-3 ff-poppins-bold">Work</p>
                      <p className="m-0 ps-3 ff-poppins-regular fs-12">
                        203, Vinayagar street, laxmi mills, Coimbatore.
                      </p>
                    </div>
                    <div className="col-1">
                      <IoIosArrowForward />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center mt-4">
                    <div className="col-2">
                      <div className="border w-100 border-black border-opacity-10 rounded d-flex justify-content-center fs-24 primary-text">
                        +
                      </div>
                    </div>
                    <div className="col-9">
                      <p className="m-0 ps-3 ff-poppins-bold primary-text">
                        Add new Address
                      </p>
                    </div>
                    <div className="col-1">
                      <IoIosArrowForward />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal for confirmation  */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered mainModal">
            <div class="modal-content p-3" >
              <div class="modal-body text-center ff-balooPaaji2-bold mb-3">
                Are you sure want to delete the product?
              </div>
              <div class="modalFooter">
                <button
                  type="button"
                  class="btn btn-secondary ff-balooPaaji2-bold"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-danger ff-balooPaaji2-bold"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default ReviewPayDoorstep;
