import React, { useState, useRef, useEffect } from 'react';
import { isAndroid, isMobile, browserName, isIOS } from 'react-device-detect';
import chrome from "../Assets/Images/chrome.png";
import safari from "../Assets/Images/safari.png";
import edge from "../Assets/Images/edge.png";
import pingle from "../Assets/Images/pingle_icon.webp";
import browser from "../Assets/Images/browser.png";


const InstallPrompt = () => {
    const [showModal, setShowModal] = useState(false);
    const [browserIcon, setBrowserIcon] = useState(false);
    const installPromtRef = useRef(null);

    useEffect(() => {
        console.log(`browserName`, browserName);
        if (browserName == 'Chrome') {
            setBrowserIcon(chrome);
        } else if (browserName == 'Safari' || browserName == 'Mobile Safari') {
            setBrowserIcon(safari);
        } else if (browserName == 'Edge') {
            setBrowserIcon(edge);
        } else {
            setBrowserIcon(browser);
        }
        const isModalShown = sessionStorage.getItem('installPromptShown');
        if ((!isModalShown && isAndroid && isMobile) || (!isModalShown && isIOS && isMobile)) {
            console.log(`isAndroid`, isAndroid);
            console.log(`isIOS`, isIOS);

            setShowModal(true);
        }
    }, []);

    useEffect(() => {
        if (showModal) {
            installPromtRef.current.classList.add("show");
            installPromtRef.current.style.display = "block";
        } else {
            installPromtRef.current.classList.remove("show");
            installPromtRef.current.style.display = "none";
        }
    }, [showModal]);

    const handleInstall = () => {
        if (isAndroid && isMobile) {
            // Check if the app is installed
            const appPackage = 'com.pingle_user'; // Replace with your app's package name
            const appUrl = `intent://${appPackage}/#Intent;scheme=http;package=${appPackage};end`;
            window.location.href = "https://pingle.page.link/KUdh"; // Open the app
            // fetch(appUrl)
            //     .then(() => {
            //         alert('something')
            //         console.log('App is installed. Opening...');
            //         window.location.href = appUrl; // Open the app

            //     })
            //     .catch(() => {
            //         alert('something catch')
            //         console.log('App is not installed. Redirecting to Play Store...');
            //         const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.pingle_user'; // Replace with your app's Play Store URL
            //         window.location.href = playStoreUrl; // Redirect to Play Store
            //     });
        } else if (isIOS) {
            const appUrl = 'iOSIntentIntegrationPingleUser://'; // Replace 'yourapp' with your iOS app's custom URL scheme
            const appStoreUrl = 'https://apps.apple.com/in/app/pingle-user/id6443915736'; // Replace 'your-app' and '123456789' with your actual App Store URL

            // Attempt to open the app
            window.location.href = appUrl;

            // Wait for a short duration before redirecting to App Store to ensure the app opens if installed
            setTimeout(() => {
                window.location.href = appStoreUrl;
            }, 500);
        } else {
            console.log('Not on Android or not on mobile.');
        }
        setShowModal(false);
        sessionStorage.setItem('installPromptShown', 'true');
    };

    const handleContinueWithBrowser = () => {
        sessionStorage.setItem('installPromptShown', 'true'); // Store in session storage
        setShowModal(false); // Hide the modal without reloading
    };

    return (
        <>

            <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" ref={installPromtRef} style={{ backgroundColor: '#0005', botton: 50, }}>
                <div className="modal-dialog m-0 modal-dialog-centered" style={{ alignItems: 'flex-end' }}>
                    <div className="modal-content" style={{ width: '100%' }}>
                        <div className="modal-body">
                            <p className='fs-14 ff-poppins-regular'>Open using:</p>
                            <div className='d-flex justify-content-between align-items-center py-2' >
                                <div className='d-flex align-items-center'>
                                    <div className='p-2'>
                                        <img src={pingle} height={'50px'} width={'50px'} style={{ borderRadius: 25 }} />
                                    </div>
                                    <div>
                                        <p className='fs-14 ff-poppins-semiBold m-0'>Pingle User App</p>
                                        <p className='fs-12 ff-poppins-regular m-0'>Recommended</p>
                                    </div>
                                </div>
                                <div>
                                    <button type="button" className="btn primary-bg text-white fs-12 ff-poppins-semiBold" style={{ width: 100 }} onClick={handleInstall}>Open</button>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center py-2'>
                                <div className='d-flex align-items-center'>
                                    <div className='p-2'>
                                        <img src={browserIcon} height={'50px'} width={'50px'} style={{ borderRadius: 10 }} />
                                    </div>
                                    <div>
                                        <p className='fs-14 ff-poppins-semiBold m-0'>Browser</p>
                                        {/* <p>Recommended</p> */}
                                    </div>
                                </div>
                                <div>
                                    <button type="button" className="btn primary-bg text-white fs-12 ff-poppins-semiBold" style={{ width: 100 }} onClick={handleContinueWithBrowser}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};

export default InstallPrompt;