import React, { useState, useCallback } from "react";
import QrReader from "react-qr-scanner";
import axiosInstance from "../Services/axios";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Scanner = () => {
  const [result, setResult] = useState("No result");
  const [extractedValue, setExtractedValue] = useState("");
  const [scanning, setScanning] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    vendorDetails();
  }, [extractedValue]);

  const vendorDetails = () => {
    try {
      const reqData = {
        vendor_qr_id: extractedValue,
      };
      axiosInstance
        .post("/user/vendor_qr_search", reqData)
        .then((res) => {
          if (res.status === 200) {
            navigate(`/vendor/${res.data.vendor_id}`)
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 400 || err.response.status === 500) {
              console.error("Error response from server:", err.response.data);
              // Handle specific error cases here
            } else {
              console.error("Error occurred in request:", err.message);
              // Handle other types of errors
            }
          } else if (err.request) {
            // The request was made but no response was received
            console.error("No response received:", err.request);
          } else {
            // Something happened in setting up the request that triggered an error
            console.error("Request setup error:", err.message);
          }
        });
    } catch (error) {
      // This catch block won't catch asynchronous errors from Axios
      alert("Try again later");
    }
  };

  const handleScan = useCallback(
    (data) => {
      if (data) {
        const url = data.text;
        const baseUrl = "https://pingle.page.link/";
        const extractedValue = url.startsWith(baseUrl)
          ? url.slice(baseUrl.length)
          : url;

        setResult(url);
        setExtractedValue(extractedValue);
        setScanning(false);
        stopCamera();
        vendorDetails();
      }
    },
    [vendorDetails]
  );

  const handleError = useCallback((err) => {
    console.error(err);
  }, []);

  const stopCamera = useCallback(() => {
    const video = document.querySelector("video");
    if (video && video.srcObject) {
      const stream = video.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      video.srcObject = null;
    }
  }, []);

  const previewStyle = {
    height: "100%",
    width: "100%",
  };

  const videoConstraints = {
    facingMode: "environment", // This requests the rear camera
  };

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      {scanning && (
        <QrReader
          delay={300}
          style={previewStyle}
          onError={handleError}
          onScan={handleScan}
          constraints={{ video: videoConstraints }}
          showViewFinder={false} // Remove the viewfinder if it helps in removing the pause/play button
        />
      )}
      {!scanning && <p>Vendor_Qr_ID: {extractedValue}</p>}
    </div>
  );
};

export default Scanner;
