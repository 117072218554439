import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import "../Membership/membershipList.css";
import MembershipCard from "../../Components/MembershipCard/MembershipCard";
import axiosInstance from "../../Services/axios";
import { toast } from 'react-toastify';
import ReactLoading from "react-loading";
const MemberShipDetails = () => {
  const location = useLocation();
  const [membershipDetails, setMembershipDetails] = useState([]);
  const [VendorDetails, setVendorDetails] = useState([]);
  const { vendor_Id, membershipiD } = location.state || {};
  const [offertime, setOffertime] = useState([]);
  const [profile, setProfile] = useState();
  const [membershipID, setMembershipID] = useState();
  const [showModal, setShowModal] = useState(false);
  const token = localStorage.getItem("token");
  let { vendorId, membership_ID } = useParams();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [purchased_status, setPurchased_status] = useState('')
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const sym = Symbol('key');
  const obj = {
    [sym]: 'value'
  };

  // Incorrect usage (will throw "Cannot convert a Symbol value to a string")
  console.log(obj['key']);  // Trying to access Symbol key with a string

  // Correct usage
  console.log( 'Correct usage',obj[sym]); // Accessing Symbol key with Symbol

  const navigate = useNavigate();

  const [isTouch, setIsTouch] = useState(false);

  const handleTouch = () => {
    setIsTouch(!isTouch);
  };

  useEffect(() => {
    if (token) {
      membershipStatusCheck()
      membershipdetail();
    } else {
      membershipdetail();
    }
  }, []);

  const membershipStatusCheck = () => {
    try {
      let reqData = {
        vendor_id: vendorId,
        membership_id: membership_ID,
      };
      axiosInstance
        .post("/user/membership/get_vendor_membership_dateil", reqData)
        .then((res) => {

          const data = res.data
          console.log(data);
          setPurchased_status(data.purchased_status)
          setPageLoaded(true);
        }).catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {
            // setLoadingStatus(false);
            toast(err.response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "text-danger toast-style-1",
            });
          }
        })
    } catch (error) {
      console.log("error --->", error);
    }
  };

  const confirmPaymentBuyNow = () => {
    if (token) {
      if (purchased_status === true) {
        toast.error("Membership Already Purchased By You...", { position: toast.POSITION.BOTTOM_CENTER, });
      } else {
        try {
          BuyNow();
          console.error("workedddd BuyNow:.....");
        } catch (error) {
          console.error("Error in BuyNow:.....", error);
        }
      }
    } else {
      handleShow();
    }

  };

  const BuyNow = () => {
    navigate("/BuyNow", {
      state: {
        vendorId: profile.vendor_id,
        membership_ID: membershipID,
        flowParent: "buy"
      },
    });
  };


  const membershipdetail = () => {
    try {
      let reqData = {
        vendor_id: vendorId,
        membership_id: membership_ID,
      };
      console.log('reqData', reqData);
      axiosInstance
        .post("/user/membership/get_vendor_membership_dateil_without_login", reqData)
        .then((res) => {
          console.error("res in BuyNow:....", res);
          setMembershipDetails(res.data.membership_detail);
          setVendorDetails(res.data.vendor_details);
          setOffertime(res.data.membership_detail.membership_availability);
          setProfile(res.data);
          setMembershipID(res.data.membership_detail.membership_id);
          setPageLoaded(true);
        }).catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {
            // setLoadingStatus(false);
            toast(err.response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "text-danger toast-style-1",
            });
          }
        })

      // .catch((err) => {
      //   if (err.response.status == 400) {
      //     alert(`Error:${err.response.state}-${err.response.message}`);
      //   }
      //   if (err.response.status == 500) {
      //     alert(`Error:${err.response.status}-${err.response.message}`);
      //   }
      // });
    } catch (error) {
      console.log("error --->", error);
    }
  };


  const handleLoginNavigate = () => {
    // Navigate to a different route and pass parameters
    localStorage.setItem('vendorUrl', location.pathname);
    navigate('/login');
  };


  return (
    <>
      <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white ">
        <div className="d-flex justify-content-between align-items-center ms-2 me-2">
          <img
            src={p_nav_previous}
            width={"24px"}
            height={"16px"}
            alt="Previous Button"
            role="button"
            onClick={() => navigate(-1)}
          />
          <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">Membership</div>
        </div>
      </div>
      {
        pageLoaded ?
          <div className="p-2 scroller-style-y">
            <div>
              {/* <div className="pb-3">
            {membershipDetails ? 
              <MembershipCard
                cardColor={membershipDetails.card_color}
                data={membershipDetails}
                // data={{ membershipDetails, VendorDetails }}
                buttonName="Subscription"
              /> : <></>
            }
          </div> */}
              <div className="pb-3">
                {

                  membershipDetails && VendorDetails ? (
                    <MembershipCard
                      cardColor={membershipDetails.card_color}
                      data={{ membershipDetails, VendorDetails }}
                      buttonName="Subscription"
                    />
                  ) : (
                    <></>
                  )
                }
              </div>
              <hr />
              <p className="m-0 ff-poppins-semiBold fs-17 p-2">
                Membership Description
              </p>
              <p className="m-0 ff-poppins-regular fs-14 p-2">
                {membershipDetails?.membership_description}
              </p>
            </div>
            <div className="d-flex mt-3">
              <div className="d-flex justify-content-center col-6">
                <div className="membershipListBox">
                  <p className="ff-poppins-semiBold m-0 fs-17">Price</p>
                  <p className="ff-poppins-semiBold m-0 fs-24 primary-text">
                    {membershipDetails?.price}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center col-6">
                <div className="membershipListBox">
                  <p className="ff-poppins-semiBold m-0 fs-17">Validity</p>
                  <div className="d-flex align-items-center">
                    <p className="ff-poppins-semiBold m-0 fs-30 primary-text">
                      {membershipDetails?.price_duration < 10
                        ? `0${membershipDetails?.price_duration}`
                        : membershipDetails?.price_duration}{" "}
                    </p>
                    <p className="ff-poppins-regular m-0 fs-9 ps-1">
                      Months from the date of purchase
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="d-flex justify-content-center col-6">
                <div className="membershipListBox">
                  <p className="ff-poppins-semiBold m-0 fs-17">Services</p>
                  <div className="d-flex align-items-center">
                    <p className="ff-poppins-semiBold m-0 fs-30 primary-text">
                      {membershipDetails?.services_count < 10
                        ? `0${membershipDetails?.services_count}`
                        : membershipDetails?.services_count}
                    </p>
                    <p className="ff-poppins-regular m-0 fs-9 ps-1">Services</p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center col-6">
                <div className="membershipListBox">
                  <p className="ff-poppins-semiBold m-0 fs-17">Products</p>
                  <div className="d-flex align-items-center">
                    <p className="ff-poppins-semiBold m-0 fs-30 primary-text">
                      {membershipDetails?.products_count < 10
                        ? `0${membershipDetails?.products_count}`
                        : membershipDetails?.products_count}
                    </p>
                    <p className="ff-poppins-regular m-0 fs-9 ps-1">products</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="d-flex justify-content-center col-6">
                <div className="membershipListBox">
                  <p className="ff-poppins-semiBold m-0 fs-17">Discount</p>
                  <div className="d-flex align-items-center">
                    <p className="ff-poppins-semiBold m-0 fs-30 primary-text">
                      {membershipDetails?.discount}%
                    </p>
                    <p className="ff-poppins-regular m-0 fs-9 ps-1">
                      Discount on eligible services & products
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="m-1 p-2 border rounded my-4">
              <p className="ff-poppins-semiBold fs-15 m-0">Available Hours</p>
              {offertime.map((time) => {
                return (
                  <div className="d-flex justify-content-between p-2 fs-15">
                    <p className="primary-text m-0">{time.day} </p>
                    <p className="m-0">{time.timing} </p>
                  </div>
                );
              })}
            </div>

            <div>
              <p className="ff-poppins-regular fs-12 m-0 p-2">
                Membership{" "}
                <span className="pointer primary-text " onClick={handleTouch}>
                  Terms & Condition
                </span>
              </p>
              <p
                id="terms_and_conditions"
                className={`p-2 ${isTouch ? "" : "hidden"}`}
              >
                {membershipDetails?.terms_and_conditions}
              </p>
            </div>

            <div className="">
              <button
                onClick={() => confirmPaymentBuyNow()}

                className="ff-poppins-regular primary-bg text-white w-100 butNowBtnMembership"
              >
                Buy Now
              </button>
            </div>
          </div> :
          (
            <div className=" ReactLoading">
              <ReactLoading type={"spinningBubbles"} color={"#4E26A3"} />
            </div>
          )
      }

      {
        showModal && (
          <div className="modal-backdrop show"></div>
        )
      }
      {
        showModal && (
          <div className="modal justify-content-center" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog-centered loginWarningDialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className='ff-poppins-semiBold fs-1 primary-text text-center'>
                    Attention!
                  </div>
                  <div className='ff-poppins-regular fs-14 text-center mt-2'>
                    To proceed further you need to login/register and get access to all the features.
                  </div>
                  <div className='d-flex justify-content-center align-items-center mt-1 mb-1' role='button' onClick={() => navigate('/login')}>
                    <div className='loginBtn primary-bg text-center text-white p-1 ff-poppins-medium fs-16'>Login</div>
                  </div>
                  <div className='d-flex justify-content-center align-items-center' role='button' onClick={handleClose}>
                    <div className='loginBtn text-center text-secondary p-1 ff-poppins-medium fs-16 secondary-bg-1 mt-2'>Close</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};
export default MemberShipDetails;
