import React, { useEffect, useCallback, useRef } from "react";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { LuPlusCircle } from "react-icons/lu";
import { FiMinusCircle } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useState } from "react";
import { SlArrowDown } from "react-icons/sl";
import { SlArrowUp } from "react-icons/sl";
import axiosInstance from "../../Services/axios";
import { IoIosArrowForward } from "react-icons/io";
import { MdWork } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { MdOutlineCheckCircle } from "react-icons/md";
import { MdOutlineRadioButtonUnchecked } from "react-icons/md";
import defaultimg from "../../Assets/Images/default-placeholder.png";
import useRazorpay from "react-razorpay";
import { useUser } from "../../Components/UserContext";
import { getDatabase, ref, onValue } from "firebase/database";
import Lottie from "lottie-react";
import SuccessLotie from "./../../Assets/lottieFiles/success.json";
import defaultproductimg from "../../Assets/Images/default-placeholder.png";
import { CgCloseO } from "react-icons/cg";
import { toast } from "react-toastify";
import { FaRegSquarePlus } from "react-icons/fa6";
import ReactLoading from "react-loading";
import ProductCard from "../../Components/product card/ProductCard";
const ReviewPay = () => {
  const [Razorpay] = useRazorpay();
  const currentUser = useUser(); // Access user context
  const paymentSuccessModalRef = useRef(null);
  const orderSuccessModalRef = useRef(null);
  const locationModalRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { merchantId, newcartId, flow, offerCartId, inCartId, fromPage, offerLoyaltyPoints } = location.state || {};
  const [showContent, setShowContent] = useState(false);
  const [productDetail, setProductDetail] = useState([]);
  const [productlist, setProductlist] = useState([]);
  const [shipingtype, setShipingtype] = useState("");
  const [userLocation, setUserLocation] = useState([]);
  const [confirmlocation, setConfirmlocation] = useState();
  const [userlatlong, setUserlatlong] = useState([]);
  const [paymenttype, setPaymenttype] = useState([]);
  const [selectpaytype, setSelectPaytype] = useState(1);
  const [gststatus, setGststatus] = useState(true);
  const [deliverylocation, setDeliverylocation] = useState([]);
  const [consulation, setConsulation] = useState("");
  const [deleteitem, setDeleteitem] = useState();
  const [animationSrc, setAnimationSrc] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cartId, setCartId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [pageLoaded, setPageLoaded] = useState(false);
  const [buyproduct, setBuyproduct] = useState([]);
  const [getproduct, setGetproduct] = useState([]);
  const [offerDetails, setOfferDetails] = useState();
  const [offertype, setOffertype] = useState();
  const [userId, setUserId] = useState();
  const [paymentOrderId, setPaymentOrderId] = useState();
  const token = localStorage.getItem("token");
  const [initiateTime, setInitiateTime] = useState("");
  const [loyaltyOfferid, setLoyaltyOfferid] = useState('')
  // useEffect(() => {
  //     const localProductDetailCard = localStorage.getItem("cartid")
  //         ? JSON.parse(localStorage.getItem("cartid"))
  //         : "";
  //     setCartId(localProductDetailCard?.cart_id ? localProductDetailCard.cart_id : "");

  // }, []);

  // Offer or Membership
  const [cardStatus, setCardStatus] = useState();
  const [cardType, setcardType] = useState();
  const [cardId, setcardId] = useState();

  useEffect(() => {

    const fetchData = async () => {
      let productCart = localStorage.getItem("productCart") ? JSON.parse(localStorage.getItem("productCart")) : "";
      setCartId(productCart?.cartId ? productCart?.cartId : "");
      setVendorId(productCart?.vendorId ? productCart?.vendorId : "");
      setShipingtype(productCart?.shipping_type ? productCart?.shipping_type : "");
      setLoyaltyOfferid(productCart?.loyaltyDetail?.offer_id ? productCart?.loyaltyDetail?.offer_id : "")

      let appliedCard = JSON.parse(localStorage.getItem("appliedCard"));
      setCardStatus(appliedCard?.cardStatus ? appliedCard?.cardStatus : "");
      setcardType(appliedCard?.cardType ? appliedCard?.cardType : "");
      setcardId(appliedCard?.cardId ? appliedCard?.cardId : "");
    };
    fetchData();

  }, []);

  useEffect(() => {

    const fetchData = async () => {
      if (cartId != "" && String(cartId).length > 0) {
        getselectProductDetail();

      }
    };
    fetchData();
    let initiateTimes = formatDateToCustomFormatWithTimeZone(
      new Date(),
      "Asia/Kolkata"
    );
    setInitiateTime(initiateTimes);

  }, [cartId, flow, offerCartId]);

  useEffect(() => {
    if (!currentUser) return; // Check if user is not logged in
    const database = getDatabase();
    const orderRef = ref(database, `/payments/product_order/${userId}`);
    // const orderRef = ref(database, `/payments/product_order/23U00002`);
    const unsubscribe = onValue(orderRef, (snapshot) => {
      const data = snapshot.val();

      if (data.status === 2 && data.order_id === paymentOrderId) {
        openPaymentSuccessModal();
      }
      // if (data.status === 3 && data.order_id === paymentOrderId) {

      // }
    });
    return () => unsubscribe(); // Detach listener on cleanup
  }, [currentUser, paymentOrderId]);

  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await fetch(
          "https://lottie.host/24705db4-5a9c-4d93-9c1b-217adf1bf628/z8KgTNPh81.json"
        );
        const animationData = await response.json();
        setAnimationSrc(animationData);
      } catch (error) {
        console.error("Error fetching animation data:", error);
      }
    };

    fetchAnimationData();
  }, []);


  // useEffect(() => {
  //   if (fromPage == 'addAddress') {
  //     getselectProductDetail()
  //   }
  // }, [fromPage]);


  const toggleContent = () => {
    setShowContent(!showContent);
  };

  const handleOkClick = () => {
    setShowModal(false);
    // navigate("/");
    navigate("/", { replace: true });
    localStorage.removeItem("productCart");
    localStorage.removeItem("appliedCard");
  };

  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  const formatDateToCustomFormatWithTimeZone = (isoDate, timeZone) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZone: timeZone,
    };

    const date = new Date(isoDate);
    const formattedDate = date.toLocaleString("en-US", options);
    const day = date.getDate();
    const daySuffix = getDaySuffix(day);
    const modifiedFormattedDate = formattedDate.replace(
      day,
      `${day}${daySuffix}`
    );
    return modifiedFormattedDate;
  };

  const getselectProductDetail = () => {
    try {
      if (cartId != "") {
        ;
        let reqData = {
          vendor_id: vendorId,
          cart_id: cartId,
        };

        let usertoken = localStorage.getItem("token");
        let reqUrl = usertoken
          ? "user/products/product_checkout_details"
          : "user/products/product_checkout_details_without_login";
        axiosInstance
          .post(reqUrl, reqData)
          .then((res) => {
            let data = res.data;
            console.log('data===>>>', data)
            setProductDetail(data);
            setPaymenttype(data.payment_options);
            setProductlist(data.cart_products);
            setGststatus(data.gst_status);
            setOfferDetails(data.offer_detail);
            setBuyproduct(data.offer_detail.buy_products);
            setGetproduct(data.offer_detail.get_products);
            setOffertype(data.applied_card_detail.offer_type);
            setPageLoaded(true);
            const localProductDetail = localStorage.getItem("shipping_type")
              ? JSON.parse(localStorage.getItem("shipping_type"))
              : "";
            setShipingtype(
              localProductDetail?.shipping_type
                ? localProductDetail?.shipping_type
                : ""
            );
            if (res?.data?.delivery_location_status) {
              setDeliverylocation(res?.data?.delivery_location_address);
            }

            if (fromPage == 'addAddress' && !res?.data?.delivery_location_status) {
              getuserlocationlist()
            }
          })
          .catch((err) => {
            if (err.response.status == 400) {
              alert(`Error:${err.response.state}-${err.response.message}`);
            }
            if (err.response.status == 500) {
              alert(`Error:${err.response.status}-${err.response.message}`);
            }
          });
      }
    } catch {
      toast("Try again later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: 'text-danger toast-style-1'
      })
    }
  };

  const getuserlocationlist = () => {

    try {
      let reqData = {
        vendor_id: vendorId,
        cart_id: cartId,
      };
      axiosInstance
        .post("user/products/user_locations_list", reqData)
        .then((res) => {
          setUserLocation(res.data.user_locations);
          setUserlatlong(res.data.latlng);
          openBookForModal();
        })
        .catch((err) => {
          if (err.response.status == 400) {
            alert(`Error:${err.response.state}-${err.response.message}`);
          }
          if (err.response.status == 500) {
            alert(`Error:${err.response.status}-${err.response.message}`);
          }
        });
    } catch { }
  };

  const select_Location = (latlng) => {
    try {
      let reqData = {
        cart_id: cartId,
        location: latlng,
      };
      axiosInstance
        .post("user/products/select_delivery_location", reqData)
        .then((res) => {
          closeLocationModal();
          setConfirmlocation(1);
          getselectProductDetail();
        })
        .catch((err) => {
          if (err.response.status == 400) {
            alert(`Error:${err.response.state}-${err.response.message}`);
          }
          if (err.response.status == 500) {
            alert(`Error:${err.response.status}-${err.response.message}`);
          }
        });
    } catch (error) {
      console.error("An error occurred:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const confirmticket = () => {
    try {
      let reqData = {
        cart_id: cartId,
        payment_method: selectpaytype,
        note_desc: consulation,
      };
      axiosInstance
        .post("/user/products/confirm_order", reqData)
        .then((res) => {
          openOrderSuccessModal();
          // localStorage.removeItem("cartid");
          // alert(res.data.message);
        })
        .catch((err) => {
          if (err.response) {
            // Check if the response object exists
            if (err.response.status === 400) {
              alert(
                `Error: ${err.response.data.state}-${err.response.data.message}`
              );
            }
            if (err.response.status === 500) {
              alert(
                `Error: ${err.response.status}-${err.response.data.message}`
              );
            }
          } else {
            // Handle cases where err.response is undefined
            console.log(err);
          }
        });
    } catch { }
  };

  function openBookForModal() {
    document
      .getElementById("selectCustomerModalProductRP")
      .classList.add("show");
  }

  function closeBookForModal() {
    var modal = document.getElementById("selectCustomerModalProductRP");
    modal.classList.remove("show");
  }

  const confirmPayment = () => {
    try {
      let reqData = {
        cart_id: cartId,
        payment_method: flow == 'LoyaltyProduct' ? 4 : selectpaytype,
        note_desc: consulation,
        loyalty_applied: flow == 'LoyaltyProduct' ? 1 : 0
      };

      axiosInstance
        .post("/user/products/payment_checkout_razorpay", reqData)
        .then((res) => {
          let data = res.data;
          let userDetatil = {
            name: "",
            phone: "",
            email: "",
          };
          if (flow == 'LoyaltyProduct') {
            redeemProduct_loyalty()
          } else {

            if (selectpaytype == 1 || selectpaytype == 2) {
              setPaymentOrderId(data?.payment_recipt?.id);
              setUserId(data?.user_id);
              handlePayment(userDetatil, data?.payment_recipt);
            } else {
              confirmticket();
              // navigate("/");
            }
          }
        });
    } catch (error) {
      alert("Try again later");
    }
  };

  const Add_Remove_productcount = (productId, Operation) => {
    try {
      let reqData = {
        vendor_id: vendorId,
        product_id: productId,
        shipping_type: shipingtype,
        quantity: 1,
        operation: Operation,
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
      };

      axiosInstance
        .post("user/products/add_or_remove_product_cart_without_login", reqData)
        .then((res) => {
          if (res.status == 200) {
            getselectProductDetail();
          }
        })
        .catch((err) => {
          if (err.response) {
            // Check if the response object exists
            if (err.response.status === 400) {
              alert(
                `Error: ${err.response.data.state}-${err.response.data.message}`
              );
            }
            if (err.response.status === 500) {
              alert(
                `Error: ${err.response.status}-${err.response.data.message}`
              );
            }
          } else {
            // Handle cases where err.response is undefined
            console.log(err);
          }
        });
    } catch (error) {
      alert("Tryagain later");
    }
  };

  const handleConfirmPurchase = () => {
    setIsLoading(true);

    // Simulate asynchronous action with setTimeout
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    // Call your Razorpay function here if needed
    // Razorpay();

    confirmPayment();


  };

  const deleteProduct = () => {
    try {
      let reqData = {
        vendor_id: vendorId,
        product_id: deleteitem.product_id,
        shipping_type: shipingtype,
        quantity: deleteitem.added_count,
        operation: "remove",
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
      };
      axiosInstance
        .post("user/products/add_or_remove_product_cart_without_login", reqData)
        .then((res) => {
          getselectProductDetail();
        });
    } catch (error) {
      alert("Try Again later");
    }
  };
  const redeemProduct_loyalty = () => {
    try {

      let reqData = {
        "cart_id": cartId,
        "payment_method": '4',
        "offer_id": loyaltyOfferid,
        "loyalty_applied": '1'
      }
      const Loyaltyoffer_id = localStorage.getItem("token");
      console.log('reqdataaaaaa', reqData);
      axiosInstance.post('/user/products/redeem_vendor_loyalty', reqData)
        .then((res) => {
          openOrderSuccessModal();
        })
    }
    catch {

    }
  }
  // rzp_live_1wESdx2BEv08Ox
  const handlePayment = useCallback(
    (userData, orderData) => {
      const createOrderAndInitializePayment = async () => {
        const options = {
          key: "rzp_live_1wESdx2BEv08Ox",
          amount: orderData.amount,
          currency: orderData.currency,
          name: "PINGLE",
          description: "Payment",
          image: "https://pingle.in/user/assets/images/LogoUser.png",
          order_id: orderData.id,
          handler: (res) => {

            // if (onPayment) {
            //     onPayment(res); // Passing the order ID to the parent component
            // }
          },
          prefill: {
            name: userData.name,
            email: userData.email,
            contact: userData.phone,
          },
          theme: {
            color: "#4E26A3",
          },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
      };

      createOrderAndInitializePayment();
    },
    [Razorpay]
  );

  const openPaymentSuccessModal = () => {
    paymentSuccessModalRef.current.classList.add("show");
    paymentSuccessModalRef.current.style.display = "block";
  };

  const openOrderSuccessModal = () => {
    orderSuccessModalRef.current.classList.add("show");
    orderSuccessModalRef.current.style.display = "block";
  };

  const closeLocationModal = () => {

    locationModalRef.current.classList.remove("show");
  };

  const handleLoginNavigate = () => {
    // Navigate to a different route and pass parameters
    localStorage.setItem("vendorUrl", location.pathname);
    navigate("/login");
  };

  return (
    <>

      {
        pageLoaded ?
          <div>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
              <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                <img
                  src={p_nav_previous}
                  width={"24px"}
                  height={"16px"}
                  alt="Previous Button"
                  role="button"
                  onClick={() => navigate(-1)}
                />
                <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
                  Review & Pay
                </div>
              </div>
            </div>

            <div className="scroller-style-y pisition-relative">
              <div className="p-2 secondary-bg-1">
                {!token ? (
                  <div className="border border-black border-opacity-10 w-100 rounded-4 p-3 bg-white">
                    <div className="primary-text ff-balooPaaji2-regular">
                      Before making a purchase, please log in.
                    </div>
                    <div>
                      <button
                        className="w-100 btn border-0 primary-bg text-white ff-balooPaaji2-semiBold py-2 rounded mt-2"
                        onClick={handleLoginNavigate}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className="p-2 secondary-bg-1">
                {/* // Doorstep Add address // */}
                <div className="p-3 bg-white rounded-4">
                  {shipingtype == 2 ? (
                    <div className="mb-4 mt-4">
                      <div className="ff-balooPaaji2-bold mb-2">
                        Delivery Address
                      </div>
                      {deliverylocation.length === 0 ? ( // Corrected condition for an object
                        <div>
                          { }
                          <button
                            disabled={token ? false : true}
                            className={
                              "border border-black border-opacity-10 w-100 ff-balooPaaji2-semiBold rounded p-2 bg-white"
                            }
                            onClick={() => getuserlocationlist()}
                          >
                            Add Address
                          </button>
                        </div>
                      ) : (
                        <div onClick={() => getuserlocationlist()}>
                          <div className="border rounded d-flex p-2 ">
                            <div className="col-2 ">
                              <div className="border w-100 border-black border-opacity-10 rounded d-flex justify-content-center mt-2 fs-24 primary-text py-2">
                                {deliverylocation.location_type_id == 1 ? (
                                  <IoMdHome className="primary-text" />
                                ) : deliverylocation.location_type_id == 2 ? (
                                  <MdWork className="primary-text" />
                                ) : (
                                  <IoLocationSharp />
                                )}
                              </div>
                            </div>
                            <div className="col-9">
                              <p className="m-0 ps-3 ff-poppins-bold">
                                {deliverylocation.location_type}{" "}
                              </p>
                              <p className="m-0 ps-3 ff-poppins-regular singleline-text fs-12">
                                {deliverylocation.address}
                              </p>
                              <p className="m-0 ps-3 ff-poppins-regular fs-12">
                                {deliverylocation?.distance.text} - {deliverylocation?.duration.text}
                              </p>
                            </div>
                            <div className="col-1 ms-2 mt-3">
                              <IoIosArrowForward />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <p className="ff-poppins-bold m-0">{initiateTime}</p>
                  )}
                  {productDetail &&
                    productDetail?.card_applied_status == 1 &&
                    productDetail?.applied_card_detail?.card_type == "offer" &&
                    productDetail?.applied_card_detail?.offer_type == 3 ? (
                    <div className="offcanvas-parentType3 position-relative">
                      <h6 className="ff-poppins-bold">Buy products</h6>
                      {productDetail &&
                        productDetail?.offer_detail?.buy_products &&
                        productDetail?.offer_detail?.buy_products?.length > 0 ? (
                        productDetail?.offer_detail?.buy_products.map(
                          (buy, index) => {
                            return (
                              <div className="productCardHead">
                                <div>
                                  <div className="productRPDiv">
                                    <div className="col-5 productImageRPDiv">
                                      <img
                                        src={
                                          buy.cover_image.image == ""
                                            ? defaultproductimg
                                            : buy.cover_image.image_url
                                        }
                                        className="productImageBasket"
                                      />
                                    </div>
                                    <div className="col-5">
                                      <p className="productNameBasket ff-balooPaaji2">
                                        {buy.product_name}
                                      </p>
                                      <p className="productNameBasket ff-balooPaaji2">
                                        ₹{buy.price}{" "}
                                      </p>
                                      <p className="productWeightBasket primary-text ff-balooPaaji2">
                                        {buy.unit_type} {buy.unit_type_name}
                                      </p>
                                      <div className="moreDetailsDiv">
                                        <p
                                          className="productWeightBasket primary-text ff-balooPaaji2"
                                          onClick={() =>
                                            navigate(`/singleProductDetails/${vendorId}/${buy.product_id}`, {
                                              state: {
                                                productId: buy.product_id,
                                                merchantId,
                                              },
                                            })
                                          }
                                        >
                                          More details
                                        </p>
                                        <IoIosArrowDown className="fw-bold productWeightBasket primary-text" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <></>
                      )}
                      <h6 className="ff-poppins-bold">
                        Get products<span className="primary-text">(Free)</span>{" "}
                      </h6>
                      {productDetail &&
                        productDetail?.offer_detail?.get_products &&
                        productDetail?.offer_detail?.get_products?.length > 0 ? (
                        productDetail?.offer_detail?.get_products.map(
                          (get, index) => {
                            return (
                              <div className="productCardHead">
                                <div>
                                  <div className="productRPDiv">
                                    <div className="col-5 productImageRPDiv">
                                      <img
                                        src={
                                          get.cover_image.image == ""
                                            ? defaultimg
                                            : get.cover_image.image_url
                                        }
                                        className="productImage"
                                      />
                                    </div>
                                    <div className="col-5">
                                      <p className="productNameBasket ff-balooPaaji2">
                                        {get.product_name}
                                      </p>
                                      <p className="productNameBasket ff-balooPaaji2">
                                        ₹{get.price}{" "}
                                      </p>
                                      <p className="productWeightBasket primary-text ff-balooPaaji2">
                                        {get.unit_type} {get.unit_type_name}
                                      </p>
                                      <div className="moreDetailsDiv">
                                        <p
                                          className="productWeightBasket primary-text ff-balooPaaji2"
                                          onClick={() =>
                                            navigate(`/singleProductDetails/${vendorId}/${get.product_id}`, {
                                              state: {
                                                productId: get.product_id,
                                                merchantId,
                                              },
                                            })
                                          }
                                        >
                                          More details
                                        </p>
                                        <IoIosArrowDown className="fw-bold productWeightBasket primary-text" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <div>
                      <h6 className="ff-poppins-bold m-0 py-2">Products</h6>
                      {productlist.map((item, index) => {
                        return (
                          <div className="offcanvas-parentType3 position-relative">
                            <div className="productCardHead">
                              <div>
                                <div className="productRPDiv">
                                  <div className="col-5 productImageRPDiv">
                                    <img
                                      src={
                                        item.cover_image.image == ""
                                          ? defaultproductimg
                                          : item.cover_image.image_url
                                      }
                                      className="productImageBasket"
                                    />
                                  </div>

                                  <div className="col-5">
                                    <p className="productNameBasket ff-poppins-semiBold">
                                      {item.product_name}
                                    </p>
                                    <p className="productNameBasket ff-balooPaaji2">
                                      ₹{item.price}{" "}
                                    </p>
                                    <p className="productWeightBasket primary-text ff-balooPaaji2">
                                      {item.unit_quantity} {item.unit_type_name}
                                    </p>
                                    <div className="moreDetailsDiv">
                                      <p
                                        className="productWeightBasket primary-text ff-balooPaaji2"
                                        onClick={() => navigate(`/singleProductDetails/${vendorId}/${item.product_id}`, { state: { productId: item.product_id, merchantId, }, })}  >
                                        More details
                                      </p>
                                      <IoIosArrowDown className="fw-bold productWeightBasket primary-text" />
                                    </div>
                                    {productDetail &&
                                      productDetail?.card_applied_status == 1 &&
                                      productDetail?.applied_card_detail?.card_type ==
                                      "offer" &&
                                      productDetail?.applied_card_detail
                                        ?.offer_type == 2 ? (
                                      <></>
                                    ) : (
                                      flow != 'LoyaltyProduct' ?
                                        <div className="row align-items-center w-50 gx-0">
                                          <div className="row align-items-center w-100 gx-0">
                                            <div className="col-4">
                                              <FiMinusCircle className="w-100 fs-24 primary-text" onClick={() => Add_Remove_productcount(item.product_id, "remove")} />
                                            </div>
                                            <div className="col-4 d-flex justify-content-center fs-5 primary-text">
                                              {item.added_count}
                                            </div>
                                            <div className="col-4">
                                              <LuPlusCircle className="w-100 fs-24 primary-text" onClick={() => Add_Remove_productcount(item.product_id, "add")} />
                                            </div>
                                          </div>
                                        </div>
                                        : <></>
                                    )}
                                  </div>

                                  {productDetail && productDetail?.card_applied_status == 1 &&
                                    productDetail?.applied_card_detail?.card_type == "offer" && productDetail?.applied_card_detail?.offer_type == 2 ? (
                                    <></>
                                  ) : (
                                    flow != 'LoyaltyProduct' ?
                                      <div className="col-2 deleteIconProductBasket">
                                        <MdOutlineDeleteOutline
                                          className="ms-2"
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModaldelete"
                                          onClick={() => setDeleteitem(item)} />
                                      </div>
                                      : <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {/* Payment method  */}
                  {
                    flow != 'LoyaltyProduct' ?
                      <div>
                        <p className="ff-poppins-bold fs-18">Payment method</p>
                        {productDetail &&
                          productDetail.payment_options &&
                          (productDetail.shipping_type == 1 ||
                            productDetail.shipping_type == 2) ? (
                          <>
                            <div className="d-flex justify-content-between mb-0" onClick={() => { setSelectPaytype(1); }} >
                              <div>
                                <p className="mb-0 ff-poppins-semiBold"> Pay Full </p>
                                <p className="fs-11">pay total amount now</p>
                              </div>
                              {selectpaytype == 1 ? (
                                <MdOutlineCheckCircle className="selecticon" />
                              ) : (
                                <MdOutlineRadioButtonUnchecked />
                              )}
                            </div>

                            {productDetail.payment_options.advance_payment == '1' ? (
                              <div className="d-flex justify-content-between mb-0" onClick={() => { setSelectPaytype(2); }}  >
                                <div>
                                  <p className="mb-0 ff-poppins-semiBold">
                                    Pay Advance
                                  </p>
                                  <p className="fs-11">
                                    Pay a merchant-determined amount upfront now.
                                  </p>
                                </div>
                                {selectpaytype == 2 ? (
                                  <MdOutlineCheckCircle className="selecticon" />
                                ) : (
                                  <MdOutlineRadioButtonUnchecked />
                                )}
                              </div>
                            ) : (
                              <></>
                            )}

                            {productDetail?.payment_options?.cash_after_delivery == '1' ? (
                              <div className="d-flex justify-content-between mb-0" onClick={() => { setSelectPaytype(3); }}  >
                                <div>
                                  <p className="mb-0 ff-poppins-semiBold">
                                    Cash after product
                                  </p>
                                  <p className="fs-11 secondary-text">
                                    pay total amount now
                                  </p>
                                </div>
                                <div className="d-flex gap-2">
                                  {selectpaytype == 3 ? (
                                    <MdOutlineCheckCircle className="selecticon" />
                                  ) : (
                                    <MdOutlineRadioButtonUnchecked />
                                  )}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}

                            {productDetail.payment_options.cash_after_service == 1 ? (
                              <div className="d-flex justify-content-between mb-0" onClick={() => { setSelectPaytype(4); }}  >
                                <div>
                                  <p className="mb-0 ff-poppins-semiBold">
                                    Cash after service
                                  </p>
                                  <p className="fs-11 secondary-text">
                                    Pay in cash at the store after service completion.
                                  </p>
                                </div>
                                <div className="d-flex gap-2">
                                  {selectpaytype == 4 ? (
                                    <MdOutlineCheckCircle className="selecticon" />
                                  ) : (
                                    <MdOutlineRadioButtonUnchecked />
                                  )}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div> : <></>
                  }
                  <div>
                    <div className="d-flex justify-content-between" onClick={toggleContent}   >
                      <div>
                        <h6 className="bookInfoTextRP ff-poppins-bold">
                          <b>Billing Information</b>
                        </h6>
                      </div>
                      <div>
                        {showContent ? (
                          <SlArrowUp className="me-2" />
                        ) : (
                          <SlArrowDown className="me-2" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    {showContent && (
                      <div>
                        <div>
                          <p>Booking Initiated at {initiateTime}</p>
                        </div>
                      </div>
                    )}

                    <hr className="my-2" />
                    {productDetail &&
                      productDetail?.card_applied_status == 1 &&
                      productDetail?.applied_card_detail?.card_type == "offer" &&
                      productDetail?.applied_card_detail?.offer_type == 3 ? (
                      <div className="servicePriceHeadDiv">
                        <h6 className="serviceTextRP">
                          <b>Buy Products</b>
                        </h6>
                        {buyproduct.map((item, index) => {
                          return (
                            <div className="d-flex justify-content-between">
                              <p className="m-0"> {item.product_name}</p>
                              <p className="m-0">
                                {item.added_count} x {item.price}.00
                              </p>
                            </div>
                          );
                        })}
                        <div className="totalBar fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                          <p className="m-0 text-success">Total</p>
                          <p className="m-0 text-success">
                            Rs {offerDetails.buy_products_total}
                          </p>
                        </div>
                        <h6 className="serviceTextRP">
                          <b>Get Products</b>
                        </h6>
                        {getproduct.map((item, index) => {
                          return (
                            <div className="d-flex justify-content-between">
                              <p className="m-0"> {item.product_name}</p>
                              <p className="m-0">
                                {item.added_count} x {item.price}.00
                              </p>
                            </div>
                          );
                        })}
                        <div className="totalBar text-success fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                          <p className="m-0">Total</p>
                          <p className="m-0">
                            Rs {offerDetails.get_products_total}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="servicePriceHeadDiv" style={{ paddingBottom: -10 }}   >
                        <h6 className="serviceTextRP">
                          <b>Products</b>
                        </h6>
                        {productlist.map((item, index) => {
                          return (
                            <div className="d-flex justify-content-between">
                              <p className="m-0"> {item.product_name}</p>
                              <p className="m-0">
                                {item.added_count} x {item.price}
                              </p>
                            </div>
                          );
                        })}
                        {productDetail && productDetail?.discount_status != "" ? (
                          <>
                            <div style={{ borderBottomColor: "gray", borderBottomWidth: 2, borderBottomStyle: "dashed", marginTop: 10, }} ></div>
                            <div className=" text-success fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                              <p className="m-0">
                                {productDetail?.discount_detail?.lable}
                              </p>
                              <p className="m-0">
                                Rs {productDetail?.discount_detail?.discount_amount}
                              </p>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}

                    {/* <div className="dashBorderRP"></div> */}
                    <div className="servicePriceHeadDiv">
                      {
                        flow == 'LoyaltyProduct' ?
                          <div>
                            <div className="d-flex justify-content-between">
                              <p className="m-0">Subtotal</p>
                              <p className="m-0">{productDetail.sub_total} </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="m-0">Pingle Points ({offerLoyaltyPoints})</p>
                              <p className="m-0"> - {productDetail.sub_total} </p>
                            </div>
                          </div>
                          :
                          <div>
                            <div className="d-flex justify-content-between">
                              <p className="m-0">Subtotal</p>
                              <p className="m-0">{productDetail.sub_total} </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="m-0">Packing cost</p>
                              <p className="m-0">{productDetail.total_packing_cost} </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="m-0">Platform Charges</p>
                              <p className="m-0">{productDetail.user_platform_charges}</p>
                            </div>
                          </div>
                      }
                      {flow != 'LoyaltyProduct' && gststatus == true ? (
                        <div>
                          <div className="d-flex justify-content-between">
                            <p className="m-0">SGST</p>
                            <p className="m-0">{productDetail.total_sgst_amount}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="m-0">CGST</p>
                            <p className="m-0">{productDetail.total_cgst_amount}</p>
                          </div>
                        </div>
                      ) :
                        <></>
                      }
                      {flow != 'LoyaltyProduct' && productDetail.round_off != 0 ? (
                        <div className="d-flex justify-content-between">
                          <p className="m-0">Round Off</p>
                          <p className="m-0">{productDetail.round_off}</p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="dashBorderRP"></div>
                    <div className="totalDivRP ">
                      <h6 className="fs-15 mt-2 ff-poppins-bold">
                        <b>
                          Total - Rs <span>{productDetail.total_amount}</span>
                        </b>
                      </h6>
                    </div>
                    {selectpaytype == 2 ? (

                      <div className="fs-16 prima primary-text fw-bold">
                        <p className="d-flex justify-content-end m-0 ">
                          Advance amount - Rs {productDetail?.payment_options?.advance_amount}
                        </p>
                        <p className="d-flex justify-content-end m-0">
                          Balance amount - Rs {productDetail?.payment_options?.balence_amount}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              <div className="offcanvas offcanvas-bottomm Chooseaddres main"
                tabIndex="-1"
                id="selectCustomerModalProductRP"
                aria-labelledby="offcanvasBottomLabel"
                data-bs-backdrop="false"
                ref={locationModalRef}

                style={{ maxHeight: '70vh', position: 'absolute' }}>
                <div className="offcanvas-body small bg-light position-relative">
                  <div className="d-flex justify-content-between">
                    <div className="ff-poppins-semiBold">
                      <h5>Choose address</h5>
                    </div>
                    <div onClick={() => closeBookForModal()}>
                      {" "}
                      <CgCloseO />
                    </div>
                  </div>
                  <div style={{}}>
                    {userLocation.map((location, index) => {
                      return (
                        <div
                          onClick={() =>
                            location.select_status ? select_Location(location.latlng) : ""
                          }
                        >
                          <div
                            className="d-flex align-items-center mt-4"
                            style={{ opacity: location.select_status == 0 ? 0.4 : 1 }}
                          >
                            <div className="col-2">
                              <div className="border w-100 border-black border-opacity-10 rounded d-flex justify-content-center fs-24 primary-text py-2">
                                {location.location_type_id == 1 ? (
                                  <IoMdHome className="primary-text" />
                                ) : location.location_type_id == 2 ? (
                                  <MdWork className="primary-text" />
                                ) : (
                                  <IoLocationSharp />
                                )}
                              </div>
                            </div>
                            <div className="col-9">
                              <p className="m-0 ps-3 ff-poppins-bold">
                                {location.location_type}{" "}
                              </p>
                              <p className="m-0 ps-3 ff-poppins-regular fs-12">
                                {location.address}
                              </p>
                            </div>
                            <div className="col-1">
                              <IoIosArrowForward />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* <div className="offcanvas-footer" style={{ position: 'absolute', left: 0, bottom: 0, width: 390, backgroundColor:'#F8F9FA',padding:15,}}>
                <button
                  className="sendPayLinkBtnRP ff-poppins-semiBold primary-bg"
                  onClick={() => {
                    navigate("/AddDeliveryAddress");
                  }}>
                  Add New Address
                </button>
              </div> */}
                  <div onClick={() => { navigate("/AddDeliveryAddress", { state: { fromPage: 'products' } }); }} >
                    <div className="d-flex align-items-center mt-4">
                      <div className="col-2">
                        <div className="w-100  d-flex justify-content-center fs-24 primary-text py-2">
                          <FaRegSquarePlus />
                        </div>

                      </div>
                      <div className="col-9">
                        <p className="m-0 ps-3 ff-poppins-bold primary-text">Add New Address</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {(shipingtype == "2" && productDetail.delivery_location_status == true) || shipingtype == "1" || flow == 'LoyaltyProduct' ? (
                <div className="forgetRadiusDivRP" style={{ position: 'sticky', bottom: 0 }}>
                  <div className="downContentDivHeadRP">
                    <textarea
                      className="textAreaRP mb-1"
                      placeholder="Reason for consultation"
                      value={consulation}
                      onChange={(e) => setConsulation(e.target.value)}
                    ></textarea>
                    {flow != 'LoyaltyProduct' ?
                      <></>
                      : selectpaytype == 1 ? (
                        <div className="toPayTextRP" style={{}}>
                          <span className="ff-poppins-semiBold">To Pay</span>
                          <b className="ms-2 fw-bold text-dark">

                            Rs <span>{productDetail.total_amount} </span>
                          </b>
                        </div>
                      ) : selectpaytype == 2 ? (
                        <div className="toPayTextRP">
                          <span className="ff-poppins-semiBold">To Pay</span>
                          <b className="ms-2 fw-bold text-dark">
                            Rs   <span>{productDetail?.payment_options?.advance_amount}</span>
                          </b>
                        </div>
                      ) : (
                        <></>
                      )}

                    <button
                      style={{ borderRadius: "6px", margin: "15px 0px" }}
                      className={
                        token
                          ? "sendPayLinkBtnRP primary-bg ff-poppins-semiBold"
                          : "border sendPayLinkBtnRP rimary-bg-1 ff-poppins-semiBold secondary-text-2"
                      }
                      onClick={() => { handleConfirmPurchase() }
                      }
                      disabled={token && !isLoading ? false : true}
                    >
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Loading...</span>
                        </>
                      ) : (
                        "Confirm purchase"
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div >
          :
          <div className=" ReactLoading">
            <ReactLoading type={"spinningBubbles"} color={"#4E26A3"} />
          </div>
      }


      <div
        class="modal fade"
        id="exampleModaldelete"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered mainModal ">
          <div class="modal-content p-3">
            <div class="modal-body text-center ff-balooPaaji2-bold mb-3">
              <h5>Delete Product</h5>
              Are you sure want to delete the product?
              <div className="d-flex justify-content-around mt-2">
                <button
                  type="button"
                  class="btn btn-secondary ff-balooPaaji2-bold"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-danger ff-balooPaaji2-bold"
                  data-bs-dismiss="modal"
                  onClick={() => deleteProduct()}
                >
                  Delete
                </button>
              </div>
            </div>
            <div class="modal Footer"></div>
          </div>
        </div>
      </div>
      {/* Lottie Modal */}

      <div
        className={`modal fade`}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={paymentSuccessModalRef}
        data-bs-backdrop="false"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="modal-dialog m-0 modal-dialog-centered mainModal">
          <div
            className="modal-content modal-success"
            style={{ width: "80%" }}
          >
            <div className="modal-header">
              <div>
                <div>
                  <div>
                    <Lottie animationData={SuccessLotie} loop={false} />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body modal-bodyRP">
              <div className="text-center fw-bold primary-text">
                Payment Success.
              </div>
              <div className="text-center fw-bold">
                Redirecting you to your ticket.
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn okBtnRP"
                onClick={handleOkClick}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade`}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={orderSuccessModalRef}
        data-bs-backdrop="false"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="modal-dialog m-0 modal-dialog-centered mainModal">
          <div
            className="modal-content modal-success"
            style={{ width: "80%" }}
          >
            <div className="modal-header">
              <div>
                <div>
                  <div>
                    <Lottie animationData={SuccessLotie} loop={false} />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body modal-bodyRP">
              <div className="text-center fw-bold primary-text">
                Order Successful.
              </div>
              <div className="text-center fw-bold">
                Redirecting you to your ticket.
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn okBtnRP"
                onClick={handleOkClick}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ReviewPay;
