import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";

const Stopwatch = forwardRef((props, ref) => {
    // state to store time
    // let startTime = new Date(props.startTime);
    const startTime = new Date(props.startTime).getTime(); // Target start time
    const currentTime = new Date().getTime(); // Current time
    const initialTime = currentTime - startTime; 
    // console.log(`startTime`, startTime, currentTime);


    const [time, setTime] = useState(initialTime > 0 ? initialTime : 0);
    // state to check stopwatch running or not
    const [isRunning, setIsRunning] = useState(true); // Set to true initially
    
    useEffect(() => {
        let intervalId;
        if (isRunning) {
            // setting time from 0 to 1 every 1 millisecond using setInterval method
            intervalId = setInterval(() => setTime(time + 1), 8);
        }
        return () => clearInterval(intervalId);
    }, [isRunning, time]);

    // Hours calculation
    const hours = Math.floor(time / 3600000);

    // Minutes calculation
    const minutes = Math.floor((time % 360000) / 6000);

    // Seconds calculation
    const seconds = Math.floor((time % 6000) / 100);

    // Milliseconds calculation
    const milliseconds = time % 100;

    // Method to start and stop timer
    const startAndStop = () => {
        setIsRunning(!isRunning);
    };

    // Method to reset timer back to 0
    const reset = () => {
        setTime(0);
    };

    // Call from parent
    useImperativeHandle(ref, () => ({
        startTimer() {
            setIsRunning(true);
        },
        stopTimer() {
            setIsRunning(false);
            reset();
        },
        resetTimer() {
            reset();
        }
    }));

    return (
        <div className="stopwatch-container">
            <div className="stopwatch-time fs-16 fw-600 primary-text">
                {hours.toString().padStart(2, "0")}:{minutes.toString().padStart(2, "0")}:
                {seconds.toString().padStart(2, "0")}
                {/* {milliseconds.toString().padStart(2, "0")} */}
            </div>
        </div>
    );
});

export default Stopwatch;
