import { useNavigate } from 'react-router-dom';
import register_success from '../../Assets/Images/Pingle-Merchant-icons-09-edited.png';

const RegistrationAlert = () => {

    const navigate = useNavigate();

    return (
        <div className='d-flex justify-content-center align-items-center h-100'>
            <div className="registrationSuccessAlert text-center">
                <img src={register_success} className='w-100' />
                <div className='ff-poppins-bold fs-16 mt-2'>Thank You For Registering!</div>
                <div className='ff-poppins-regular fs-13 mt-3'>Please verify your mail id from the user profile setting screen</div>
                <div className='ff-poppins-regular fs-13 mt-3'>Let make it easy... Its time to Pingle!</div>
                <div className='d-flex justify-content-center align-items-center mt-4 mb-4'>
                    <div className='okBtn d-flex justify-content-center align-items-center ff-poppins-semiBold fs-12 primary-bg text-white' role='button' onClick={() => navigate('/')}>OK</div>
                </div>
            </div>
        </div>
    )
};

export default RegistrationAlert