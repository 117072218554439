import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { userLogin } from "../../Services/api";
import { toast } from 'react-toastify';

const Login = () => {
    const [isLoading, setLoadingStatus] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

    useEffect(() => {
        // if (token) {
        //     navigate('/');
        //     return
        // }
    }, []);

    const loginWithPhoneNumber = () => {
        setPhoneNumberError('');

        if (phoneNumber == '' || phoneNumber.length < 10 || phoneNumber.length > 10) {
            toast("Please Enter a Valid Number", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'text-danger toast-style-1'
            });
            return
        }
        else {
            setLoadingStatus(true);
            let param = {
                user_mbl: phoneNumber
            };
            userLogin(param).then((res) => {

                if (res.data.status == "1") {
                    navigate('/otpVerification', { state: { mobileNumber: phoneNumber } });
                    setLoadingStatus(false);
                }
            }, error => {
                setLoadingStatus(false);
                toast(error?.response?.data?.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'text-danger toast-style-1'
                });
                return
            });
        }
    }

    return (
        <>
            <div className="loginPage bg-white vh-100 d-flex align-items-center">
                <div>
                    <div className="ff-balooPaaji-regular fs-24 secondary-text mb-4">Enter Your Phone <br />
                        Number</div>
                    <div className="input-group ff-poppins-regular">
                        <span className="input-group-text bg-white rounded-0 border-0 py-3 fs-24 secondary-text" id="loginByNumber-addon1">+91</span>
                        <input
                            type="number"
                            value={phoneNumber}
                            onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                            onChange={ev => setPhoneNumber(ev.target.value)}
                            className="form-control rounded-0 border-0 py-3 fs-24 secondary-text no-spinners"
                            placeholder="Mobile Number"
                            aria-label="Mobile Number"
                            aria-describedby="loginByNumber-addon1" />
                    </div>

                    <div className="d-flex justify-content-center">
                        <button type="submit" onClick={loginWithPhoneNumber} className={(isLoading ? "loading" : "") + " " + "w-100 border-0 primary-bg rounded-pill ff-poppins-semiBold fs-20 text-white mt-5 d-flex justify-content-center align-items-center"}>
                            {
                                isLoading
                                    ?
                                    <span className="loader-style-1"></span>
                                    :
                                    <span>Continue</span>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Login;