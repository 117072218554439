import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import p_search_location from "../../Assets/Images/p_search_location.svg";
import p_store_dropdown_arrow from "../../Assets/Images/p_store_dropdown_arrow.svg";
import React, { useState, useEffect, useRef } from "react";
import { BiSolidOffer } from "react-icons/bi";
import { MdCardMembership } from "react-icons/md";
import "./searchStore.css";
import { IoSearch } from "react-icons/io5";
import defaultimg from "../../Assets/Images/default-placeholder.png";
import {
  addSearchHistory,
  clearSearchHistory,
  getLocationTypes,
  getSearchFilter,
  getSearchHistory,
  getSearchVendor,
} from "../../Services/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../Services/axios";
import OfferSearch from "../../Components/offCanvas/offerSearch";

import membership_icon from '../../Assets/Images/icons/membership_card.png';
import offer_icon from '../../Assets/Images/icons/offer_icon.png';
import MembershipSearch from "../../Components/offCanvas/membershipSearch";


const SearchStore = () => {
  const membershipSearchModalRef = useRef(null)
  const offerSearchModalRef = useRef(null)
  const [value, setValue] = useState("");
  const [showLocations, setShowLocations] = useState(false);
  const [vendorTypes, setVendorTypes] = useState([]);
  const [searchHistoryList, setSearchHistoryList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [vendorFilteredList, setVendorFilteredList] = useState([]);
  const navigate = useNavigate();

  // Filters
  const [fil_location, set_fil_location] = useState();
  const [fil_name, set_fil_name] = useState("");
  const [fil_type, set_fil_type] = useState(-1);
  const [fil_category, set_fil_category] = useState(0);
  const [vendor_category_list, set_vendor_category_list] = useState([]);
  const [showMenus, setShowMenus] = useState(false);
  const [searchitem, setSearchitem] = useState("");
  const [offersDetailData, setOffersDetailData] = useState([]);
  const [serviceTypeFilterData, setServiceTypeFilterData] = useState("");
  const [latLong, setLatLong] = useState([]);
  const [citySelectedVal, setCitySelectedVal] = useState("");

  const valueParam = useParams();
  const merchantId = valueParam;
  const [filteredItems, setFilteredItems] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [inputSearchValue, setInputSearchValue] = useState('');

  useEffect(() => {
    getSearchFilter().then((res) => {
      res.data.vendor_type.map((item) => {
        item.isSelected = false;
      });
      setVendorTypes(res.data.vendor_type);
      set_fil_location(res.data.selected_location);
    });
    getRecentSearchHistory();
  }, [value]);

  useEffect(() => {
    if (fil_type != -1) {
      getVendor();
    }
  }, [fil_location, fil_name, fil_type, fil_category]);

  function getRecentSearchHistory() {
    getSearchHistory().then((res) => {
      if (res.data.search_count > 0) {
        // setSearchHistoryList(res.data.search_results);
        const newSearches = res.data.search_results.filter((item) =>
          item.search_name.toLowerCase().includes(fil_name.toLowerCase())
        );
        setSearchHistoryList(newSearches);
      }
    });
  }

  // function searchVendorByName(e) {

  //   if (fil_type == -1) {
  //     set_fil_type(0);
  //   }
  //   set_fil_name(e.target.value);
  //   getRecentSearchHistory();
  // }

  const handleInputSearchChange = (event) => {
    setInputSearchValue(event.target.value);
    // searchVendorByName(event);
  };

  function searchVendorByName(e) {
    const searchText = e.target ? e.target.value : e;
    setInputSearchValue(searchText);

    if (fil_type === -1) {
      set_fil_type(0);
    }

    set_fil_name(searchText);

    // Update searchHistoryList with the new search text
    if (
      searchText !== "" &&
      !searchHistoryList.some((item) => item.search_name === searchText)
    ) {
      const newSearchHistoryList = [...searchHistoryList];
      newSearchHistoryList.unshift({ id: Date.now(), search_name: searchText });
      // setSearchHistoryList(newSearchHistoryList);
    }
    if (searchText == '') {
      setInputSearchValue('');
    }
  }

  function getVendor() {
    let param = {
      user_location: fil_location?.latlng,
      search_name: fil_name,
      search_type: fil_type,
      search_category: fil_category,
    };
    getSearchVendor(param).then((res) => {
      setShowLocations(true);
      setVendorList(res.data.search_results);

      if (fil_type == 1) {
        setVendorFilteredList(res.data.search_results);
      } else {
        setVendorFilteredList([]);
      }
    });
  }

  function selectCategory(item) {
    vendorTypes.map((obj) => {
      obj.isSelected = false;
    });

    let obj = vendorTypes.find((x) => x.vendor_type_id == item.vendor_type_id);

    if (obj) {
      let index = vendorTypes.indexOf(obj);
      vendorTypes.fill((obj.isSelected = !obj.isSelected), index, index++);

      forceUpdate();
    }
  }

  const clearAllSearches = () => {

    clearSearchHistory().then((res) => {

      if (res.data?.status == '1') {
        setSearchHistoryList([]);
      }
    });
  };

  const handleSubmit = (event) => {
    // event.preventDefault();
    // const checkedValues = checkboxes.filter(checkbox => checkbox.checked);
    // console.log('Checked checkboxes:', checkedValues);
    // You can perform further actions here, such as sending data to a server
  };

  const handleCheckboxChange = (index) => {
    set_vendor_category_list(prevCheckboxes => {
      const updatedCheckboxes = [...prevCheckboxes];
      updatedCheckboxes[index] = { ...updatedCheckboxes[index], checked: !updatedCheckboxes[index].checked };
      filterVendorsByCategory(updatedCheckboxes);
      return updatedCheckboxes;
    });
  };

  const filterVendorsByCategory = (category) => {
    const filterTypeIds = category
      .filter(obj => obj.checked == true)
      .map(obj => obj.vendor_category_id);

    const filteredObjects = vendorFilteredList.filter(obj => filterTypeIds.includes(obj.vendor_cat_id));

    if (filteredObjects.length > 0) {
      setVendorList(filteredObjects);
    } else {
      setVendorList(vendorFilteredList);
    }
  }

  useEffect(() => {
   
  }, [vendor_category_list]);

  useEffect(() => {
    if (vendorTypes.length > 0) {
      vendorTypes.map(x => {

        if (x?.vendor_categories?.length > 0) {
          x?.vendor_categories?.map(y => {
            y.checked = false;
          });
          set_vendor_category_list(x?.vendor_categories)
        }
      })

    }
  }, [vendorTypes]);

  function openVendorProfile(vendorId) {
    let param = {
      "search_vendor_id": vendorId
    }
    addSearchHistory(param).then((res) => {

      if (res.data?.status == '1') {
        navigate(`/vendor/${vendorId}`);
      }
    });
  }

  const openOfferSearchModal = () => {
    offerSearchModalRef.current.classList.add("show");
    offerSearchModalRef.current.style.display = "block";
  };

  const closeOfferSearchModal = () => {
    offerSearchModalRef.current.classList.remove("show");
  };

  const openMembershipSearchModal = () => {
    membershipSearchModalRef.current.classList.add("show");
    membershipSearchModalRef.current.style.display = "block";
  };

  const closeMembershipSearchModal = () => {
    membershipSearchModalRef.current.classList.remove("show");
  };
console.log('fil_location?.address==============================',fil_location?.address);
  return (
    <>
      <div className="searchStore offcanvas-parentType2">
        <div className="d-flex justify-content-between align-items-center p-3">
          <div className="d-flex justify-content-between align-items-center ms-2 me-2">
            <img
              src={p_nav_previous}
              width={"24px"}
              height={"16px"}
              alt="Previous Button"
              role="button"
              onClick={() => navigate(-1)}
            />
            <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">Search</div>
          </div>
          <Link className="text-decoration-none" to={`/find-location`}>
            <div className="location-label d-flex align-items-center justify-content-center bg-white">
              <img
                src={p_search_location}
                width={"14px"}
                height={"14px"}
                alt="Location"
              />
              <div className="ff-poppins-bold fs-13 ms-1  text-truncate secondary-text-2">
                {fil_location?.address}
              </div>
            </div>
          </Link>
        </div>

        <div className="p-3">
          <input
            type="search"
            className="w-100 ff-poppins-semiBold fs-16"
            placeholder="Search store by name"
            onChange={searchVendorByName}
            value={inputSearchValue}
          />

          <div className="d-flex justify-content-around align-items-center p-3">
            <div className=" align-items-center text-center" onClick={() => { openOfferSearchModal() }}>
              <img
                src={offer_icon}
                width={"40px"}
                height={"40px"}
              />
              <div className="ff-balooPaaji2-bold fs-16 mt-1">OFFER</div>
            </div>
            <div className=" align-items-center text-center" onClick={() => { openMembershipSearchModal() }}>
              <img
                src={membership_icon}
                width={"40px"}
                height={"40px"}
              />
              <div className="ff-balooPaaji2-bold fs-16 mt-1">MEMBERSHIP</div>
            </div>
          </div>

          <div className="searchFilter scroller-style-x mt-4 mb-4">
            {vendorTypes.map((item) => {

              if (item.vendor_categories.length > 1) {
                return (
                  <div
                    className={
                      (item.isSelected ? "active " : " ") +
                      "d-flex align-items-center"
                    }
                    role="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasBottom"
                    aria-controls="offcanvasBottom"
                    onClick={() => {
                      set_fil_type(item.vendor_type_id);

                      if (fil_type != item.vendor_type_id) {
                        set_vendor_category_list(item.vendor_categories);
                      }
                      selectCategory(item);
                    }}
                  >
                    {item.vendor_type_name}
                    {item.vendor_categories.length > 1 && (
                      <img
                        src={p_store_dropdown_arrow}
                        width={"10px"}
                        height={"10px"}
                        alt="Location"
                        className="ms-2"
                      />
                    )}
                  </div>
                );
              } else {
                return (
                  <div
                    className={
                      (item.isSelected ? "active " : " ") +
                      "d-flex align-items-center"
                    }
                    role="button"
                    onClick={() => {
                      set_fil_type(item.vendor_type_id);
                      selectCategory(item);
                    }}
                  >
                    {item.vendor_type_name}
                  </div>
                );
              }
            })}
          </div>

          {showLocations ? (
            <div className="recentSearch">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="ff-poppins-semiBold fs-16">Location</div>
                  <div className="ff-poppins-thin fs-18 secondary-text-2">
                    result
                  </div>
                </div>
                <button>
                  <div
                    className="ff-poppins-light fs-11"
                    onClick={() => setShowLocations(false)}
                  >
                    Recent Search
                  </div>
                </button>
              </div>

              <div className="storeList scroller-style-y">
                {vendorList.length > 0 ? (
                  vendorList.map((item) => {
                    return (
                      <div className="storeCard bg-white d-flex mb-3" role="button" onClick={() => openVendorProfile(item.vendor_id)}>
                        <img
                          src={item.vendor_logo}
                          width={"81px"}
                          height={"81px"}
                          className="userImg"
                        />
                        <div>
                          <div className="ff-balooPaaji2-medium fs-17">
                            {item.vendor_company_name}
                          </div>
                          <div className="d-flex">
                            <div className="ff-poppins-regular fs-9 d-flex align-items-center">
                              <div className="bullets me-2"></div>
                              MID {item.vendor_id}
                            </div>
                            <div className="ff-poppins-regular fs-9 d-flex align-items-center">
                              <div className="bullets me-2"></div>
                              {item.vendor_cat_name}
                            </div>
                          </div>
                          <div className="ff-poppins-regular fs-9 d-flex align-items-center">
                            <div className="bullets me-2"></div>
                            {item.vendor_address}
                          </div>
                          <div className="ff-poppins-regular fs-9">
                            {item.distance} -
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="ff-poppins-light fs-13">No Data Found</div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="recentSearch">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="ff-poppins-semiBold fs-16">Recent</div>
                  <div className="ff-poppins-thin fs-18 secondary-text-2">
                    searches
                  </div>
                </div>
                <button onClick={clearAllSearches}>
                  <div className="ff-poppins-light fs-11">Clear all</div>
                </button>
              </div>

              {searchHistoryList.length > 0 ? (
                <div className="list scroller-style-y">
                  <ul className="ps-0 mb-0">
                    {searchHistoryList.map((item) => {
                      return (
                        <li role="button" className="ff-poppins-regular fs-15 p-3 border-bottom" onClick={() => {
                          setInputSearchValue(item.search_name); searchVendorByName(item.search_name);
                        }}>
                          {item.search_name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <div className="list scroller-style-y d-flex justify-content-center align-items-center">
                  <div className="ff-poppins-light fs-13">No Recent Result</div>
                </div>
              )}
            </div>
          )}

          <div
            className="offcanvas serachFilter "
            style={{
              width: "-webkit-fill-available",
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              marginLeft: -15,
            }}
            tabIndex="-1"
            id="offcanvasBottom"
            aria-labelledby="offcanvasBottomLabel"
          >
            {" "}
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasBottomLabel">
                Filter
              </h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body small">
              {/* {vendor_category_list.map((item) => {
                return (
                  <div className="d-flex justify-content-between mt-1 mb-1">
                    <div className="ff-poppins-medium fs-14">
                      {item.vendor_category_name}
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                      />
                    </div>
                  </div>
                );
              })} */}

              <form onSubmit={handleSubmit}>
                {vendor_category_list.map((checkbox, index) => (
                  <div className="d-flex justify-content-between mt-1 mb-1" key={checkbox?.vendor_category_id} role="button">
                    <label className="ff-poppins-medium fs-14" role="button"
                      htmlFor={`checkbox-${checkbox?.vendor_category_id}`}>
                      {checkbox?.vendor_category_name}
                    </label>
                    <div className="form-check">
                      <input
                        role="button"
                        type="checkbox"
                        id={`checkbox-${checkbox?.vendor_category_id}`}
                        checked={checkbox?.checked}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </div>

                  </div>
                ))}
                {/* <button type="submit">Submit</button> */}
              </form>

              <hr />

              <div className="container-fluid">
                <div className="row">
                  <div className="col-6">
                    <div
                      role="button"
                      data-bs-dismiss="offcanvas"
                      onClick={() => {
                        vendor_category_list?.map(y => {
                          y.checked = false;
                        });
                        set_vendor_category_list(vendor_category_list);
                        setVendorList(vendorFilteredList);
                      }}
                      className="ff-poppins-medium fs-14 text-danger d-flex justify-content-center align-items-center btn-type-1">
                      Clear All
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      role="button"
                      data-bs-dismiss="offcanvas"
                      className="ff-poppins-medium fs-16 d-flex justify-content-center align-items-center primary-bg text-white btn-type-1">
                      Apply
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="offcanvas offcanvas-bottom"
          style={{
            width: "-webkit-fill-available",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            height: '100dvh',
            bottom: 0,
            marginBottom: 0,
            backgroundColor: '#fff'
          }}
          ref={offerSearchModalRef}
          tabIndex="-1">
          <div className="offcanvas-header">
            <h5 className="ff-balooPaaji2-bold fs-20 ms-2 ps-1" id="offcanvasBottomLabel">
              Search Offer
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={() => { closeOfferSearchModal() }}
            ></button>
          </div>
          <div className="offcanvas-body small">
            <OfferSearch></OfferSearch>
          </div>
        </div>

        <div className="offcanvas offcanvas-bottom"
          style={{
            width: "-webkit-fill-available",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            height: '100dvh',
            bottom: 0,
            marginBottom: 0,
            backgroundColor: '#fff'
          }}
          ref={membershipSearchModalRef}
          tabIndex="-1">
          <div className="offcanvas-header">
            <h5 className="ff-balooPaaji2-bold fs-20 ms-2 ps-1`" id="offcanvasBottomLabel">
              Search Membership
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => { closeMembershipSearchModal() }}
            ></button>
          </div>
          <div className="offcanvas-body small">
            <MembershipSearch></MembershipSearch>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchStore;
