import React, { useState } from "react";

function RazorPay() {
  const [amount, setAmount] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (amount === "") {
      alert("Please enter the amount!");
    } else {
      var options = {
        key: "rzp_live_1wESdx2BEv08Ox",
        key_secret: "HqJRGiuPgH5N7Kh4H0I3uLpL",
        amount: amount * 100,
        currency: "INR",
        name: "PINGLE USER",
        description: "The description for this is testing the purpose",
        handler: function (response) {
          console.log(response.razorpay_payment_id);
          alert(`Response and Payment ID--- :${response.razorpay_payment_id}`);
        },
        prefill: {
          name: "Gaby",
          email: "amudhavan.episode@gmail.com",
          contact: "9360017853",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "primary-bg",
        },
      };

      var pay = new window.Razorpay(options);
      pay.open();
    }
  };
  return (
    <div>
      <h1>RazorPay</h1>
      <input
        type="number"
        placeholder="paying amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <button onClick={handleSubmit}>Pay Now</button>
    </div>
  );
}

export default RazorPay;
