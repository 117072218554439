import { useRef, useState } from 'react';
import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import message_read from '../../Assets/Images/message_read.svg';
import message_unread from '../../Assets/Images/message_unread.svg';
import messenger_attachment from '../../Assets/Images/messenger_attachment.svg';
import messenger_send from '../../Assets/Images/messenger_send.svg';
import { createNewUserVendorChat, getChatList, sendAttachment, sendChatMessage, userBookingChatDetails, userOrderChatDetails, userSingleChatDetailsNew } from '../../Services/api';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import '../../Assets/Styles/messenger.css';
import { IoSendSharp } from "react-icons/io5";
import ImageCropperModal from '../../Components/modal/imageCropperModal';
import ChatParticipantOffCanvas from '../../Components/offCanvas/chatParticipantOffCanvas.js';
import { FaCamera } from "react-icons/fa";
import Webcam from 'react-webcam';
import { TbCapture } from "react-icons/tb";
import { MdCameraswitch } from "react-icons/md";
import { RiArrowGoBackFill } from "react-icons/ri";
import { useCallback } from 'react';

const BookingChat = () => {

    const navigate = useNavigate();
    const valueParam = useParams();
    const location = useLocation()
    const fileRef = useRef(null);
    const webcamRef = useRef(null);
    const showcamRef = useRef(null);
    const { chatFlow } = location.state || {}
    const [chatDetails, setChatDetails] = useState(null);
    const [bookingChatDetail, setBookingChatDetail] = useState(null);
    const [messageText, setMessageText] = useState(null);
    const [newText, setNewText] = useState('')
    const [isImageCropperModaOpen, setIsImageCropperModaOpen] = useState(false);
    const [imageToCrop, setImageToCrop] = useState('');
    const [facingMode, setFacingMode] = useState('user');
    const [isMessageSending, setMessageSending] = useState(false);
    const [isChatParticipantOffCanvasOpen, setChatParticipantOffCanvasOpen] = useState(false);
    const [chatParticipants, setChatParticipants] = useState([]);
    const [showWebcam, setShowWebcam] = useState(false);
    const [chatId, setChatId] = useState()
    const [imageSrc, setImageSrc] = useState(null);

    const [singleChatHistoryDetail, setSingleChatHistoryDetail] = useState(null);
    useEffect(() => {
        getBookingChatDetails()
    }, [])
    const openwebCamModal = () => {
        showcamRef.current.classList.add("show");
        showcamRef.current.style.display = "block";
        setShowWebcam(true);
        setImageSrc(null)
    };
    const closewebCamModal = () => {

        showcamRef.current.classList.remove("show");
        showcamRef.current.style.display = "none";
        setShowWebcam(false);
    };
    const onImageCropped = (e) => {

        if (e) {
            setMessageSending(true);
            const data = new FormData();
            data.append('file', e);
            data.append('chat_id', chatId);

            sendAttachment(data)
                .then((res) => {

                    if (res.data.status === "1") {

                        setIsImageCropperModaOpen(false);
                    }

                    setMessageSending(false);
                    getBookingChatDetails()
                })
                .catch((error) => {
                    // setLoadingStatus(false)
                    // toast(error.message, {
                    //     position: "bottom-center",
                    //     autoClose: 5000,
                    //     hideProgressBar: true,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    //     theme: "light",
                    //     className: 'text-danger toast-style-1'
                    // });
                    setMessageSending(false);
                });
            setIsImageCropperModaOpen(false);
            clearFileInput();
        }
    }
    const switchCamera = () => {
        setFacingMode((prevState) => (prevState === 'user' ? 'environment' : 'user'));
    };
    // const capture = useCallback(() => {
    //     const imageSrc = webcamRef.current.getScreenshot(); 
    //     setImageToCrop(imageSrc);
    //     setIsImageCropperModaOpen(true);
    //     closewebCamModal() 
    // }, [webcamRef]);
    // useEffect(() => {

    //     if (chatDetails && chatDetails.status == '1') {
    //         getSingleChatDetails()
    //     }
    // }, [chatDetails])
    const toggleChatParticipantOffCanvas = () => {
        setChatParticipantOffCanvasOpen(!isChatParticipantOffCanvasOpen);
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];

        const month = monthNames[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${month}, ${day < 10 ? '0' + day : day} ${year}`;
    };
    function getBookingChatDetails() {

        let param = {
            "chat_id": valueParam.chat_id
        };
        chatFlow == 'order' ?
            userOrderChatDetails(param).then((res) => {
                const groupedMessages = res.data?.chat_history?.reduce((acc, message) => {
                    const date = message.msg_date;
                    if (!acc[date]) {
                        acc[date] = [];
                    }
                    acc[date].push(message);
                    return acc;
                }, {});
                setSingleChatHistoryDetail(groupedMessages);
                console.log('userOrderChatDetails===>>>', groupedMessages);
                setBookingChatDetail(res.data);
                setChatId(res.data.chat_id)
            }) :
            userBookingChatDetails(param).then((res) => {
                const groupedMessages = res.data?.chat_history?.reduce((acc, message) => {
                    const date = message.msg_date;
                    if (!acc[date]) {
                        acc[date] = [];
                    }
                    acc[date].push(message);
                    return acc;
                }, {});
                setSingleChatHistoryDetail(groupedMessages);
                console.log('userBookingChatDetails=====', res.data);
                setBookingChatDetail(res.data);
                setChatId(res.data.chat_id)
            });
    }

    function sendMessage() {

        let param = {
            "chat_id": valueParam.chat_id,
            "message": newText
        }
        sendChatMessage(param).then((res) => {

            if (res.data?.status == '1') {
                setNewText('');
                getBookingChatDetails();
            }
        });
    }
    const clearFileInput = () => {
        if (fileRef.current) {
            fileRef.current.value = null;
        }
    };
    const handleFileRead = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file);

        if (base64) {
            setImageToCrop(base64);
            setIsImageCropperModaOpen(true);
        }
    }
    const convertBase64 = (file) => {

        if (file) {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                    resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                    reject('custom error', error);
                }
            })
        }
    }
    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        // setShowWebcam(false)
        // setImageToCrop(imageSrc);
        // setIsImageCropperModaOpen(true);
        // closewebCamModal() 
        setImageSrc(imageSrc);
    }, [webcamRef]);
    const handelnavigate = () => {
        setShowWebcam(false);
        closewebCamModal()
    };

    const opponentProfileShowStatus = (section, index, item) => {
        let currentChat = section[index] ? section[index].msg_align == 'left' : section[index]
        let preChat = section[index + 1] ? section[index + 1].msg_align == 'left' : section[index + 1]
        let nextChat = section[index + 1] ? section[index + 1].msg_align == 'left' : section[index + 1]
        let nextChatsender_id = section[index - 1] ? section[index - 1].msg_writer_id : section[index - 1]
        let currentChatsender_id = section[index] ? section[index].msg_writer_id : section[index]
        let previousChatsender_id = section[index + 1] ? section[index + 1].msg_writer_id : section[index + 1]
        let imageshowStatus = true
        if (item.msg_align == 'left') {
            if (preChat == true && currentChat == true) {
                imageshowStatus = false
                if (nextChatsender_id == currentChatsender_id && currentChatsender_id != previousChatsender_id) {
                    imageshowStatus = true
                }
                if (nextChatsender_id != currentChatsender_id && currentChatsender_id != previousChatsender_id) {
                    imageshowStatus = true
                }
                if (nextChatsender_id == undefined && nextChatsender_id != currentChatsender_id) {
                    imageshowStatus = true
                    if (nextChatsender_id == undefined && nextChatsender_id != currentChatsender_id && currentChatsender_id == previousChatsender_id) {
                        imageshowStatus = false
                    }
                }
            }
        }
        return imageshowStatus;
    }

    const retake = () => {
        setImageSrc(null);
    };

    const handleOK = () => {
        // Handle the OK action, e.g., save the image or proceed to the next step
        if (imageSrc) {
            // onImageCropped(imageSrc);
            setShowWebcam(false)
            setImageToCrop(imageSrc);
            setIsImageCropperModaOpen(true);
            closewebCamModal()
        }
    };
    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>
                        Booking Comments
                    </div>
                </div>
            </div>

            <div className='messengerList scroller-style-y'>

                <div className='d-flex justify-content-center'>
                    {
                        chatFlow == 'order' ?
                            <div className='bookingCommentsDetails d-flex mt-2 align-items-center rounded-2 p-2 mb-2'>
                                <img src={bookingChatDetail?.order_detail?.user_detail?.user_img} className='rounded-2' width={'70px'} height={'70px'} alt='Previous Button' role='button' />
                                <div className='lh-sm ms-2'>
                                    <div className='ff-poppins-semiBold fs-14'>{bookingChatDetail?.order_detail?.user_detail?.user_name}</div>
                                    <div className='ff-balooPaaji2-regular fs-13'>{bookingChatDetail?.order_detail?.user_detail?.user_id}</div>
                                    <div className='ff-poppins-semiBold fs-13'>{bookingChatDetail?.order_detail?.order_date_string}</div>
                                    <div className='ff-balooPaaji2-regular fs-13'>{bookingChatDetail?.order_detail?.order_products[0]?.product_name} </div>
                                </div>
                            </div>
                            :
                            <div className='bookingCommentsDetails d-flex mt-2 align-items-center rounded-2 p-2 mb-2'>
                                <img src={bookingChatDetail?.booking_detail?.user_img} className='rounded-2' width={'70px'} height={'70px'} alt='Previous Button' role='button' />
                                <div className='lh-sm ms-2'>
                                    <div className='ff-poppins-semiBold fs-14'>{bookingChatDetail?.booking_detail?.book_user_name}</div>
                                    <div className='ff-balooPaaji2-regular fs-13'>{bookingChatDetail?.booking_detail?.book_user_id}</div>
                                    <div className='ff-poppins-semiBold fs-13'>{bookingChatDetail?.booking_detail?.book_date_time}</div>
                                    <div className='ff-balooPaaji2-regular fs-13'>{bookingChatDetail?.booking_detail?.service_gender} - {bookingChatDetail?.booking_detail?.book_service_id}</div>
                                </div>
                            </div>
                    }

                </div>

                {
                    bookingChatDetail?.chat_count > 0 ?
                        <div className='p-2'>
                            {
                                Object.entries(singleChatHistoryDetail).map(([date, messages]) => {
                                    const isToday = new Date().setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0);
                                    return (
                                        <div className='mb-5' key={date}>
                                            <div className='d-flex justify-content-center'>
                                                <div className='chatDayBadge'>{isToday ? 'Today' : formatDate(date)}</div>
                                            </div>
                                            {messages.map((item, index) => {
                                                let imageshow = opponentProfileShowStatus(messages, index, item);
                                                if (item.msg_type === "chat") {
                                                    return (
                                                        <div className={`d-flex ${item.msg_align === 'right' ? 'justify-content-end' : 'justify-content-start'}`} key={item.msg_sno}>
                                                            {item.msg_align === 'right' ? (
                                                                <div className={`msgTypeChat rightAlign`}>
                                                                    <div className='ff-poppins-regular fs-13'>{item.message}</div>
                                                                    <div className='d-flex justify-content-end'>
                                                                        <div className='ff-poppins-regular fs-10 me-1'>{item.msg_time}</div>
                                                                        <img src={item.read_status === '1' ? message_read : message_unread} width={'15px'} height={'15px'} />
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className='d-flex'>
                                                                    {imageshow ? <img src={item.msg_writer_image} width={'40px'} height={'40px'} className='rounded-circle me-1' /> : <div style={{ height: 40, width: 40 }}></div>}
                                                                    <div className={`msgTypeChat leftAlign`}>
                                                                        <div className='ff-poppins-regular fs-13'>{item.message}</div>
                                                                        <div className='d-flex justify-content-end'>
                                                                            <div className='ff-poppins-regular fs-10 me-1'>{item.msg_time}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                } else if (item.msg_type === "attachment") {
                                                    let widthRatio = Number(item.message.width_ratio);
                                                    let heightRatio = Number(item.message.height_ratio);
                                                    let size = 0;
                                                    let imageHeight = 0;
                                                    let imageWidth = 0;
                                                    if (widthRatio > heightRatio) {
                                                        imageWidth = 250;
                                                        size = 250 / widthRatio;
                                                        imageHeight = heightRatio * size;
                                                    } else {
                                                        imageHeight = 250;
                                                        size = 250 / heightRatio;
                                                        imageWidth = widthRatio * size;
                                                        if (imageWidth < 100) {
                                                            imageWidth = 100;
                                                            size = 100 / widthRatio;
                                                            imageHeight = heightRatio * size;
                                                        }
                                                    }
                                                    return (
                                                        <div className={`d-flex ${item.msg_align === 'right' ? 'justify-content-end' : 'justify-content-start'}`} key={item.msg_sno}>
                                                            <div className='msgTypeAttachment'>
                                                                <div>
                                                                    <img src={item.message.file_url} width={imageWidth} height={imageHeight} />
                                                                </div>
                                                                <div className='d-flex justify-content-end p-1'>
                                                                    <div className='ff-poppins-regular fs-10 me-1 text-white'>{item.msg_time}</div>
                                                                    <img src={item.read_status === '1' ? message_read : message_unread} width={'15px'} height={'15px'} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                    );
                                })
                            }
                        </div>

                        :
                        <div className='d-flex justify-content-center align-items-center ff-balooPaaji2-regular fs-14' style={{ height: 'calc(100vh - 210px)' }}>
                            No messages yet
                        </div>
                }
            </div>

            <div class="messageInputSection input-group">
                <span class="input-group-text attachment">
                    <input className='d-none' type='file' accept="image/*" onChange={handleFileRead} ref={fileRef} />
                    <img src={messenger_attachment} width={'24px'} height={'24px'} alt='Attachment' role='button' onClick={() => { fileRef.current?.click() }} />
                </span>
                <input type="text" class="form-control ff-poppins-regular fs-18 border-0" placeholder='Write your message...' value={newText} onChange={ev => setNewText(ev.target.value)} />
                <FaCamera className='primary-text fs-24 mt-2 bg-white' onClick={() => openwebCamModal()} />
                <span class="input-group-text border-0" style={{ borderBottom: 'none' }} role='button' onClick={() => {
                    if (newText === '') { }
                    else {
                        sendMessage()
                    }
                }}>
                    {newText === '' ?
                        <IoSendSharp className='secondary-text-1 fs-24 border-0' /> :
                        <IoSendSharp className='primary-text fs-24 border-0' />}
                </span>
            </div>
            <ImageCropperModal
                isOpen={isImageCropperModaOpen}
                inputImage={imageToCrop}
                onSelect={onImageCropped}
                onCancel={() => {
                    setIsImageCropperModaOpen(false);
                    clearFileInput();
                }}
                enableRatio={true}
                isBlolbOutput={true}
            />

            <ChatParticipantOffCanvas
                isOpen={isChatParticipantOffCanvasOpen}
                toggleOffCanvas={toggleChatParticipantOffCanvas}
                particpants={chatParticipants}
            />


            <div class="modal fade" ref={showcamRef} data-bs-keyboard="false" data-bs-backdrop="false"
                style={{ backgroundColor: "#fff" }}
                tabindex="-1" aria-hidden="true" aria-labelledby="exampleModalLabel">
                <div class="modal-dialog " style={{width:390}}>
                    <div class="modal-content">
                        <div class="modal-body">
                            {showWebcam && (

                                imageSrc ? (
                                    <div>
                                        <img src={imageSrc} alt="Captured" style={{ height: '80vh', width: '100%', backgroundColor: '#e8e9ff' }} />
                                        <div className='d-flex justify-content-between mt-1 primary-text'>
                                            <span onClick={handelnavigate} className='primary-text ff-poppins-bold mt-2'>Cancel</span>
                                            <RiArrowGoBackFill className='fs-30 mt-2' onClick={retake} />
                                            <span className='primary-text ff-poppins-bold mt-2' onClick={handleOK}>OK</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <Webcam
                                            audio={false}
                                            ref={webcamRef}
                                            screenshotFormat="image/jpeg"
                                            style={{ height: '80vh', width: '100%', backgroundColor: '#e8e9ff' }}
                                            videoConstraints={{ facingMode }}
                                        />
                                        {/* <button onClick={capture}>Capture</button> */}
                                        <div className='d-flex justify-content-between mt-1 primary-text'>
                                            <span onClick={handelnavigate} className='primary-text ff-poppins-bold mt-2'>Cancel</span>
                                            <TbCapture onClick={capture} className='fs-48' data-bs-dismiss="modal" />
                                            <MdCameraswitch onClick={switchCamera} className='fs-34 mt-2' />
                                        </div>
                                    </div>
                                )
                            )}
                            <div  >
                                {/* <RiArrowGoBackFill  className='fs-30 mt-2' /> */}



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BookingChat