import { useEffect, useState } from 'react';
import p_nav_previous from '../../../Assets/Images/p_nav_previous.svg';
import p_verify_icon from '../../../Assets/Images/p_verify_icon.svg';
import { useNavigate } from 'react-router-dom';
import { getMyProfileDetails, verifyEmailAddress } from '../../../Services/api';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

const EditProfileEmailVerify = () => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [isEmailVerified, setEmailVerified] = useState('');
    const [formData, setFormData] = useState({
        "user_email": ""
    });

    useEffect(() => {
        getMyProfile();
    }, []);

    const getMyProfile = () => {
        getMyProfileDetails().then((res) => {
            const userEmail = res.data?.user_details?.user_email;
            const userEmailVerified = res.data?.user_details?.user_email_status;
            setEmailVerified(userEmailVerified);
            setFormData({ user_email: userEmail });
        });
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (formData.user_email === '') {
            dispErrorMsg('Email field required');
            return;
        }

        setLoading(true);
        verifyEmailAddress(formData)
            .then((res) => {
                setLoading(false);
                getMyProfile();
                if (res.data?.status === '1') {
                    toast(res.data?.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'text-dark toast-style-1'
                    });
                    navigate('/edit-profile/email-otp-verify', { state: { userEmail: formData.user_email } });
                }
            })
            .catch((error) => {
                setLoading(false);
                const errorMessage = error.response?.data?.message || 'An error occurred while verifying email';
                toast(errorMessage, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'text-danger toast-style-1'
                });
            });
    }

    const dispErrorMsg = (msg) => {
        toast(msg, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: 'text-danger toast-style-1'
        });
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>Email Verify</div>
                </div>
            </div>

            <div className='profile-edit-user scroller-style-y'>
                <div className='m-2 p-1'>
                    <div className='mx-3 px-2 my-3 py-2'>
                        <div className='ff-poppins-semiBold fs-20'>Verify</div>
                        <div className='ff-poppins-regular fs-20'>Email ID</div>
                    </div>
                </div>

                <form className='ms-5 me-5 p-1' onSubmit={handleSubmit}>
                    {/* Email  */}
                    <label className='ff-poppins-regular fs-12'>Email</label>
                    <div className="input-group">
                        <input type="email" className="form-control ff-poppins-regular fs-16 text-dark bg-transparent p-1 rounded-0" placeholder='Enter Email' name="user_email" value={formData.user_email} onChange={handleChange} />
                        <span className="input-group-text bg-transparent p-1 rounded-0" id="basic-addon2">
                            {isEmailVerified !== '0' && <img src={p_verify_icon} width={'27px'} height={'27px'} alt='Verified icon' />}
                        </span>
                    </div>

                    <div className='d-flex justify-content-center'>
                        <button className='verifyBtn d-flex justify-content-center align-items-center primary-bg ff-poppins-regular fs-16 fw-600 text-white' disabled={isLoading}>
                            {isLoading ? <ReactLoading type="spinningBubbles" color="#fff" height={20} width={20} /> : 'Verify'}
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default EditProfileEmailVerify;
