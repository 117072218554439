import React, { useEffect, useState } from 'react'
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import { useLocation, useNavigate } from 'react-router';
import axiosInstance from '../../Services/axios';

const OfferBooking = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { vendorId, applyid, offerid, offertype, categoryStatus } = location.state || {};
    const [vendorStatus, setVendorstatus] = useState()
    const [offerCartId, setOfferCartId] = useState()

    useEffect(() => {
        getvendorStatus()
        // getcartid()
    }, [])

    const getvendorStatus = () => {
        try {
            let reqData = {
                "vendor_id": vendorId
            }

            axiosInstance.post('/user/vendor_profile_details_without_login', reqData)
                .then((res) => {
                    console.log(res.data);
                    setVendorstatus(res.data.vendor_profile.vendor_details)
                })
        }
        catch {

        }
    }

    const setProductDetails = (shipping_type, vendorId, offerid) => {


        if (offertype == '1') {
            let productDetail = {
                'shipping_type': shipping_type,
                'Offerid': offerid,
            }

            let cardDetail = {
                cardStatus: '1',
                cardType: 'offer',
                cardId: offerid,
            }

            let productCart = {
                shipping_type: shipping_type,
                cartId: "",
                vendorId: vendorId
            }

            localStorage.setItem('productCart', JSON.stringify(productCart));
            localStorage.setItem('appliedCard', JSON.stringify(cardDetail));
            localStorage.setItem('shipping_type', JSON.stringify(productDetail));
            navigate(`/products/${vendorId}`, { state: { offer: "offer", cardStatus: '1', cardType: 'offer', cardId: offerid } })
        } else {
            redeemOfferProduct(shipping_type, vendorId, offerid);
            // navigate(`/ReviewPay/${vendorId}`, { state: { flow: "offer", merchantId: vendorId, cardStatus: '1', cardType: 'offer', cardId: offerid, inCartId: offerCartId } })
        }


    }

    const selectOfferService = async (serviceType) => {
        if (offertype == 1) {
            let serviceDetail = {
                serviceType: serviceType,
                vendorId: vendorId
            }

            let cardDetail = {
                cardStatus: '1',
                cardType: 'offer',
                cardId: offerid,
            }

            localStorage.setItem('serviceDetail', JSON.stringify(serviceDetail));
            localStorage.setItem('appliedCard', JSON.stringify(cardDetail));
            localStorage.setItem('selectedServiceOption', 'service');
            navigate(`/door-step-service/${vendorId}`, { state: { fromPage: 'offerPage' } })
        } else {
            try {

                let params = {
                    "vendor_id": vendorId,
                    "offer_id": offerid,
                    "service_type": serviceType
                }

                const response = await axiosInstance.post('/user/redeem_offer', params)
                const data = await response?.data;
                console.log(`data   ----------->`, data);
                if (response?.status === 200) {
                    let serviceDetail = {
                        serviceType: serviceType,
                        vendorId: vendorId,
                        cart_app_id: data.cart_app_id
                    }

                    let cardDetail = {
                        cardStatus: '1',
                        cardType: 'offer',
                        cardId: offerid,
                    }

                    localStorage.setItem('serviceDetail', JSON.stringify(serviceDetail));
                    localStorage.setItem('appliedCard', JSON.stringify(cardDetail));
                    localStorage.setItem('selectedServiceOption', 'service');
                    navigate(`/door-step-service/${vendorId}`, { state: { fromPage: 'offerPage' } })
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const redeemOfferProduct = (shipping_type, vendorId, offerid) => {
        try {
            let reqData = {
                "vendor_id": vendorId,
                "offer_id": offerid,
                "shipping_type": shipping_type
            };

            axiosInstance.post('user_products/redeem_offer_without_login', reqData).then((res) => {

                let productDetail = {
                    'shipping_type': shipping_type,
                    'Offerid': offerid,
                }

                let cardDetail = {
                    cardStatus: '1',
                    cardType: 'offer',
                    cardId: offerid,
                }

                let productCart = {
                    shipping_type: shipping_type,
                    cartId: res.data.cart_id,
                    vendorId: vendorId
                }

                localStorage.setItem('productCart', JSON.stringify(productCart));
                localStorage.setItem('appliedCard', JSON.stringify(cardDetail));
                localStorage.setItem('shipping_type', JSON.stringify(productDetail));
                navigate(`/ReviewPay/${vendorId}`, { state: { flow: "offer", merchantId: vendorId, cardStatus: '1', cardType: 'offer', cardId: offerid, inCartId: res.data.cart_id } })
            }).catch((error) => {
                console.error("Error: ", error);
            });
        } catch (error) {
            console.error("Catch Error: ", error);
        }
    }



    // const getcartid = (shipType) => {
    //     try {
    //         let reqData = {
    //             "vendor_id": vendorId,
    //             "offer_id": offerid,
    //             "shipping_type": shipType
    //         };
    //         console.log('reqData', reqData);
    //         axiosInstance.post('user_products/redeem_offer', reqData).then((res) => {
    //             setOfferCartId(res.data.cart_id)
    //         })
    //             .catch((error) => {
    //                 console.error("Error: ", error);
    //             });
    //     } catch (error) {
    //         console.error("Catch Error: ", error);
    //     }
    // }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img
                        src={p_nav_previous}
                        width={"24px"}
                        height={"16px"}
                        alt="Previous Button"
                        role="button"
                        onClick={() => navigate(-1)}
                    />
                    <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
                        {vendorStatus?.vendor_company_name}
                    </div>
                </div>
            </div>
            <div className='scroller-style-y'>
                <div>
                    {
                        categoryStatus?.service_status == 1 && vendorStatus?.service_status == '1' ?
                            <div className='px-3 py-2'>
                                <div className='p-3 BookingCard rounded-2'>
                                    <h6 className='primary-text ff-poppins-semiBold'>Book a service </h6>
                                    <p className='fs-14'>Browsen through the service
                                        offered on this Offer and book a service </p>
                                    <div className='d-flex justify-content-end'>
                                        <button className='primary-bg border-0 text-white col-5 align-self-center rounded fs-14 btnoutline p-1 ff-poppins-semiBold'
                                            onClick={() => {
                                                selectOfferService(1)
                                            }}>
                                            select
                                        </button>
                                    </div>
                                </div>
                            </div> : <></>
                    }
                    {
                        categoryStatus?.doorstep_status == 1 && vendorStatus?.doorstep_status == '1' ?
                            <div className='px-3 py-2'>
                                <div className='p-3 BookingCard rounded-2'>
                                    <h6 className='primary-text ff-poppins-semiBold'>Door step Service </h6>
                                    <p className='fs-14'>Browsen through the service in this
                                        offered  and book a service </p>
                                    <div className='d-flex justify-content-end'>
                                        <button className='primary-bg border-0 text-white col-5 align-self-center rounded fs-14 btnoutline p-1 ff-poppins-semiBold'
                                            onClick={() => {
                                                selectOfferService(2)
                                            }}>
                                            select
                                        </button>
                                    </div>
                                </div>
                            </div> : <></>
                    }
                    {
                        categoryStatus?.product_status == 1 && vendorStatus?.product_status == '1' ?
                            <div className='px-3 py-2'>
                                <div className='p-3 BookingCard rounded-2'>
                                    <h6 className='primary-text ff-poppins-semiBold'>Book a store pickup Product</h6>
                                    <p className='fs-14'>Browsen through the store store pickup Products
                                        offered on this Offer and book a service </p>
                                    <div className='d-flex justify-content-end'>
                                        <button className='primary-bg border-0 text-white col-5 align-self-center rounded fs-14 btnoutline p-1 ff-poppins-semiBold'
                                            onClick={() => {
                                                setProductDetails(1, vendorId, offerid);
                                                // Assuming 1 is a meaningful argument in your context
                                            }}>
                                            select
                                        </button>
                                    </div>
                                </div>
                            </div> : <></>
                    }
                    {
                        categoryStatus?.product_status == 1 && vendorStatus?.product_status == '1' && vendorStatus?.product_delivery_status == '1' ?
                            <div className='px-3 py-2'>
                                <div className='p-3 BookingCard rounded-2'>
                                    <h6 className='primary-text ff-poppins-semiBold'>Book a Doorstep Product </h6>
                                    <p className='fs-14'>Browsen through the store store pickup Products
                                        offered on this Offer and book a service </p>
                                    <div className='d-flex justify-content-end'>
                                        <button className='primary-bg border-0 text-white col-5 align-self-center rounded fs-14 btnoutline p-1 ff-poppins-semiBold'
                                            onClick={() => {
                                                setProductDetails(2, vendorId, offerid);
                                                // Assuming 1 is a meaningful argument in your context
                                            }}>
                                            select
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                    }

                </div>
            </div>
        </div >
    )
}
export default OfferBooking