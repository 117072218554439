import React, { useState } from "react";
import inStore from "../../Assets/Images/In-Store.png";
import p_appointment_right_arrow from "../../Assets/Images/p_appointment_right_arrow.svg";
import doorstep from "../../Assets/Images/doorstepIcons.png"
import { FaStar } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
const AppointmentProductCard = (product) => {
    const [data, setData] = useState(product?.data)
    return (
        <>
            <div className="appointmentCard position-relative p-0" >
                <div className="appointmentCardInner">
                    {data?.shipping_type == 1 ? (
                        <div className="service position-absolute">
                            <img
                                src={inStore}
                                width="14px"
                                height="14px"
                            />
                            <div className="ff-poppins-regular fs-12">
                                In-Store
                            </div>
                        </div>
                    ) : (
                        <div className="doorstep position-absolute">
                            <img
                                src={doorstep}
                                width="12px"
                                height="12px"

                            />
                            <div className="ff-poppins-regular fs-12">
                                Doorstep
                            </div>
                        </div>
                    )}
                    <div className="d-flex">
                        <div className="position-relative">
                            <img src={data?.vendor_detail?.vendor_logo} width="79px" height="86px" />
                            {
                                data?.order_status == "1" ?
                                    <div className="status  d-flex justify-content-center align-items-center text-white ff-poppins-semiBold fs-10 position-absolute " style={{ backgroundColor: "#68a801" }}>YET TO START</div>
                                    : <></>
                            }
                            {/* Completed  Status*/}
                            {data?.order_status == "2" ?
                                <div className="status  d-flex justify-content-center align-items-center text-white ff-poppins-semiBold fs-10 position-absolute" style={{ backgroundColor: '#727374' }}>COMPLETED</div>
                                : <></>
                            }
                            {/* Cancelled  Status*/}
                            {data?.order_status == "3" ?
                                <div className="status  d-flex justify-content-center align-items-center text-white ff-poppins-semiBold fs-10 position-absolute" style={{ backgroundColor: '#f43b51' }}>CANCELLED</div>
                                : <></>

                            }
                            {data?.order_status == "" ?
                                <div className="status  d-flex justify-content-center align-items-center text-white ff-poppins-semiBold fs-10 position-absolute" style={{ backgroundColor: '#FFA500' }}>YET TO PAY</div>
                                : <></>

                            }
                            {/* <>
                                <div className="status  d-flex justify-content-center align-items-center text-white ff-poppins-semiBold fs-10 position-absolute" style={{ backgroundColor: '#FFA500' }}>YET TO PAY</div>
                            </> */}

                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 ms-3 ps-2">
                            <div>
                                <div className="ff-poppins-semiBold singleline-text fs-16">
                                    {data?.vendor_detail?.vendor_company_name}
                                </div>
                                <div className="ff-poppins-regular fs-10">
                                    MID-{data?.vendor_detail?.vendor_id}
                                </div>
                                <div className="ff-poppins-regular fs-10 ff-poppins-semiBold">
                                    {data?.order_date_string}
                                </div>
                                <div className="ff-poppins-regular fs-10 singleline-text">
                                    {data?.order_products
                                        .map((obj) => obj.product_name)
                                        .join(", ")}
                                </div>
                                {
                                    data?.delivery_status == "5" && data?.rating_status == false ?
                                        <div className="primary-text fs-11 d-flex">
                                            <div > Feedback Pending </div> <div className="ms-1"><FaArrowRightLong /></div>
                                        </div> : <></>
                                }
                            </div>

                            <div className="d-flex h-100 flex-column justify-content-evenly">
                                <div className="d-flex h-100 flex-column justify-content-evenly mt-4">
                                    <div className="d-flex justify-content-end">
                                        <img src={p_appointment_right_arrow} width="16px" height="16px" />
                                    </div>
                                    <div >
                                        {
                                            data?.rating_status == true ?
                                                <div className=' d-flex justify-content-end'>
                                                    <div className='rating '>
                                                        <div><span style={{ fontSize: '13px' }} className="d-flex flex-column align-items-center justify-content-center"> <FaStar className='star' /> </span></div>
                                                        <div className="ff-poppins-semiBold"><span style={{ fontSize: '11px' }}> {data?.rating_detail?.rate_count}</span></div>
                                                    </div>
                                                </div>
                                                : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="primary-text ff-poppins-semiBold fs-9 px-2 py-1 mt-2 w-100 secondary-bg-1 productStatus d-flex justify-content-between"  >

                    <div>
                        {
                            data?.delivery_status == "0" ?
                                <span>Order status - Unconfirmed</span>
                                : data?.delivery_status == "1" ?
                                    <span>Order status - Not Ready</span>
                                    : data?.delivery_status == "2" ?
                                        <span>Order status - Ready</span>
                                        : data?.delivery_status == "3" ?
                                            <span>Order status - pickup</span>
                                            : data?.delivery_status == "4" ?
                                                <span>Order status - Delivery Location Reached</span>
                                                : data?.delivery_status == "5" ?
                                                    <span>Order status - Product Delivered</span>
                                                    : <></>

                        }
                    </div>
                    <div>
                        {
                            data?.delivery_staff_status == true && data?.shipping_type == 2 ?
                                <span className="">Delivery staff : Assigned</span>
                                :
                                data?.delivery_staff_status == false && data?.shipping_type == 2 && data?.delivery_status != "0" ?
                                    <span className="text-dark">Delivery staff : Not Assigned</span>
                                    : <></>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppointmentProductCard