import { useState } from 'react';
import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import { getBulletinDetails } from '../../Services/api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BulletinBoard = () => {

    const navigate = useNavigate();
    const [bulletinDetails, setBulletinDetails] = useState([]);

    useEffect(() => {
        getBulletinDetails().then((res) => {
            setBulletinDetails(res.data?.bulletin_details);
        });
    }, []);

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>User Bulletin Board</div>
                </div>
            </div>

            <div className='bulletinBoardPage scroller-style-y'>
                <div className='ff-poppins-semiBold fs-20'>Bulletin</div>
                <div className='ff-poppins-light fs-20'>board</div>

                {bulletinDetails.map((item, index) => (
                    <div className='bg-white mt-4 mb-4 p-4 rounded-4'>

                        <div className='ff-poppins-light fs-10'>
                            {item.broadcastdate}
                        </div>

                        <div className='ff-poppins-semiBold fs-15'>
                            {item.broadcast_title}
                        </div>

                        <div className='mt-4'>
                            {item.broadcast_msg}
                        </div>
                    </div>
                ))}

            </div>
        </>
    )
}

export default BulletinBoard