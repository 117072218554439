import React, { useState } from "react";
import "../MembershipCard/Membership.css";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import pingleLogo from "../../Assets/Images/pingle.png";
function MembershipCard({
  buttonName,
  icon,
  threeDot,
  Status,
  data,
  cardColor,
}) {
  const { expiryDate, membershipDetails, VendorDetails } = data;
  console.log("data====Membership", VendorDetails, membershipDetails);
  const navigate = useNavigate();
  const [showContainer1, setShowContainer1] = useState(true);
  const [showContainer2, setShowContainer2] = useState(false);

  const handleMouseOver = () => {
    setShowContainer1(false);
    setShowContainer2(true);
  };

  const handleMouseLeave = () => {
    setShowContainer1(true);
    setShowContainer2(false);
  };
  const getStatusBackgroundColor = () => {
    return Status === "Active" ? "#98FB98" : "red";
  };
  console.log('VendorDetails.vendor_company_name', membershipDetails.card_color);

  return (
    <div>
      <div className="body">
        <div className="container">
          {/* container 1*/}
          {showContainer1 && (
            <div>
              <div className="main-div" >
                <div
                  className="myMembershipStatus"
                >
                  {Status}
                </div>
                <div className="">

                  {/* {console.log();} */}
                  <div
                    className="box-container"

                    style={{
                      ...(membershipDetails.card_pattern_status === true
                        ? { backgroundImage: `url(${membershipDetails.card_pattern_image_url})` }
                        : membershipDetails.card_color_status === true ? { backgroundColor: membershipDetails.card_color } : <></>
                      )
                    }}
                    id="onMouseOver"
                    onMouseOver={handleMouseOver}
                  >
                    {
                      console.log(cardColor)
                    }
                    <div className="conatiner-left">
                      <img
                        className="image"
                        src={membershipDetails.membership_image}
                        width={"100px"}
                        height={"212"}
                        style={{ borderRadius: "15px 0px 0px 15px" }}
                      />
                    </div>
                    <div className="conatiner-right">
                      <div className="content-top d-flex justify-content-between align-items-center">
                        <text className="store-name fs-14 ff-poppins-semiBold text-white">
                          {VendorDetails.vendor_company_name}
                        </text>
                        <text className="">{threeDot}</text>
                      </div>
                      <div className="content-bottom">
                        <text className="heading fs-14 ff-poppins-semiBold text-uppercase text-white">
                          {membershipDetails.membership_name}
                        </text>
                        <p className="content ff-poppins-regular fs-10 text-white">
                          {" "}
                          {membershipDetails.membership_description}
                        </p>
                        <text className="price fs-14 ff-poppins-semiBold text-white">
                          {membershipDetails.price_lable}
                        </text>
                      </div>
                      <div className="button">
                        {expiryDate && (
                          <p className="End date text-white m-0 fs-10 ff-poppins-semiBold">

                            {new Date(expiryDate.substring(0, 10)).toDateString().substring(4, 20)}
                          </p>
                        )}</div>
                      <div className="footer py-2">
                        <text className="bottom-left fs-12 ff-poppins-regular text-white">
                          {" "}
                        </text>
                        <button className="buyNow fs-12 ff-poppins-regular">
                          {buttonName}{" "}
                          {icon && <FiArrowRight className="ms-1" />}
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* container 2 */}
          {showContainer2 && (
            <div>
              <div className="">
                <div className="myMembershipStatus">{Status}</div>

                <div
                  className="box-container"
                  id="onMouseLeave"

                  style={{
                    ...(membershipDetails.card_pattern_status === true
                      ? { backgroundImage: `url(${membershipDetails.card_pattern_image_url})` }
                      : membershipDetails.card_color_status === true ? { backgroundColor: membershipDetails.card_color } : <></>
                    )
                  }}

                  onMouseLeave={handleMouseLeave}
                >
                  <div className="conatiner-center">
                    <div className="content-top-center">
                      <div className="bLL"></div>
                      <div className="bL">
                        <text className="store-name fs-14 ff-poppins-semiBold text-white">
                          {VendorDetails.vendor_company_name}
                        </text>
                      </div>
                      <div className="bR">
                        <text className="text-white">{threeDot}</text>
                      </div>
                    </div>
                    <div className="left">
                      <text className="px-4 fs-14 ff-poppins-semiBold text-uppercase text-white text-left">
                        {membershipDetails.membership_name}
                      </text>
                    </div>
                    <div className="content-center">
                      <div>
                        <text className="content-right ff-poppins-bold fs-19 text-white">
                          {membershipDetails.membership_id && membershipDetails.membership_id.match(/.{1,4}/g).join(" ")}
                        </text>
                        <text className="content-bottom-right text-white">
                          Membership Card ID
                        </text>
                      </div>
                    </div>
                    <div className="bottom-container">
                      <div>
                        <text className="bottom-left fs-12 ff-poppins-regular text-white">
                          {membershipDetails.membership_type_lable} Membership
                        </text>
                      </div>
                      <div className="logo d-flex">
                        <img
                          className="bottom-right"
                          src={pingleLogo}
                          width={"100%"}
                          height={"100%"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )}
        </div>
      </div>
    </div >
  );
}

export default MembershipCard;
