import axios, { AxiosError, AxiosHeaders } from "axios";

// Dev

//  const axiosInstance = axios.create({
//     baseURL: 'https://dev.pingle.in/dev/api_v3/',
//     headers: {
//         "Access-Control-Allow-Origin": "*"
//     },
// });

// Test

// const axiosInstance = axios.create({
//     baseURL: 'https://dev.pingle.in/test/api_v3/',
//     headers: {
//         "Access-Control-Allow-Origin": "*"
//     },
// });

// Pre Production API 

// const axiosInstance = axios.create({
//     baseURL: 'https://pingle.in/preproduction/', 
//     headers: {
//         "Access-Control-Allow-Origin": "*"
//     },
// });

// Production API 

const axiosInstance = axios.create({
    baseURL: 'https://pingle.in/api/',
    headers: {
        "Access-Control-Allow-Origin": "*"
    },
});

// Razer pay live key
// key:rzp_live_1wESdx2BEv08Ox
// Razer pay test and pre-preprodution Key
// key:rzp_test_OgleFD0j6keHpA
// Razer pay dev Key 
// key:rzp_test_fGhaglbOrBdNAc

axiosInstance.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            if (config.headers)
                (config.headers).set("Authorization-Token", `${token}`);
        }
        return config;
    },
    (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error instanceof AxiosError && error.response?.status === 401) {
            // useAuthStore.setState({ signedInAs: undefined });
        }
        return Promise.reject(error);
    },
);

export default axiosInstance;
