import { useParams } from "react-router-dom";
import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import Loading from 'react-loading';
//images
import p_left_arrow from '../../Assets/Images/booking/p_left_arrow.svg';
import p_left_arrow_circle from '../../Assets/Images/booking/p_left_arrow_circle.svg';
import p_right_arrow_circle from '../../Assets/Images/booking/p_right_arrow_circle.svg';
import p_down_arrow_circle from '../../Assets/Images/booking/p_down_arrow_circle.svg';
import p_up_arrow_circle from '../../Assets/Images/booking/p_up_arrow_circle.svg';
import p_history_icon from '../../Assets/Images/booking/p_history_icon.svg';
import p_nofill_star from '../../Assets/Images/booking/p_nofill_star.svg';
import p_home from '../../Assets/Images/booking/p_home.svg';
import p_navigation from '../../Assets/Images/booking/p_navigation.svg';
import p_call from '../../Assets/Images/booking/p_call.svg';
import p_text_message from '../../Assets/Images/booking/p_text_message.svg';
import p_share from '../../Assets/Images/booking/p_share.svg';
import p_acc_down_arrow from '../../Assets/Images/booking/p_acc_down_arrow.svg';
import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import { FaExclamation } from "react-icons/fa";

import VerticalStepper from '../../Components/stepper';

import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";

import { GoChevronRight } from "react-icons/go";
//firebase
import { getDatabase, ref, set, onValue } from "firebase/database";
import database from '../../Services/firebase-service/firebase';
//Stopwatch
import { getBookingDetails } from "../../Services/api";
import Stopwatch from "../../Components/Stopwatch";
import axiosInstance from "../../Services/axios";
import { useUser } from '../../Components/UserContext'; // Adjust the path according to your file structure
import AppointmentCancelled from '../../Assets/Images/Appointment.jpg';
import { IoIosStarOutline } from "react-icons/io";
import { IoMdStar } from "react-icons/io";
import { toast } from 'react-toastify';
import Lottie from "lottie-react";
import SuccessLotie from "./../../Assets/lottieFiles/success.json";
import useRazorpay from "react-razorpay";
import { IoMdHome } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { MdWork } from "react-icons/md";
const UserBookingDetails = () => {
    const ref1 = useRef();
    const rescheduleModalRef = useRef(null);
    const cancelrefundModalRef = useRef(null);
    const cancelConfirmModalRef = useRef(null);
    const locationModalRef = useRef(null);
    const cancelBalanceRequestModalRef = useRef(null);
    const paymentSuccessModalRef = useRef(null);
    const canceldisputeModalRef = useRef(null);
    const [Razorpay] = useRazorpay();
    const currentUser = useUser();
    const params = useParams();
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    let [record, setRecord] = useState([]);
    const [paymenttype, setPaymenttype] = useState()
    const [balanceamt, setBalanceamt] = useState()
    const [paymentdetail, setPaymentdetail] = useState()
    const [showMoreInforUser, setShowMoreInforUser] = useState(false);
    const [showMoreInforStaff, setShowMoreInforStaff] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deliverystep, setDeliverystep] = useState([]);
    const [vendor_id, setVendorId] = useState()
    const [refund, setRefund] = useState([])
    const [dispute, setDispute] = useState()
    const [bookInformation, setBookInformation] = useState('')
    const token = localStorage.getItem("token");
    const [appointmentRescheduled, setAppointmentRescheduled] = useState(false);
    const [vendor_subscription_status, setVendor_subscription_status] = useState(false);
    const [bookingstatus, setBookingstatus] = useState(0);
    const [storeActiveStep, setStoreActiveStep] = useState('');
    const [doorActiveStep, setDoorActiveStep] = useState('');
    const [activeTab, setActiveTab] = useState("Ticket");
    const [ratings, setRatings] = useState([false, false, false, false, false]);
    const [feedback, setFeedback] = useState()
    const [ratecount, setRatecount] = useState()
    const [userId, setUserId] = useState();
    const [paymentOrderId, setPaymentOrderId] = useState();
    const [payment, setPayment] = useState([]);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (!currentUser) return; // Check if user is not logged in
        const database = getDatabase();
        const orderRef = ref(database, `/bookings/${params.id}`);
        const unsubscribe = onValue(orderRef, snapshot => {
            const data = snapshot.val();
            if (data) {
                // getproductDetails();
                loadBookingDetails()

            }
        });

        return () => unsubscribe(); // Detach listener on cleanup
    }, [currentUser, params.id]);

    useEffect(() => {
        if (paymentdetail?.balence_request?.request_status == 1 && paymentdetail?.payment_status == "0") {
            openBalancerequestModal()
        } else {
            closeBalancerequestModal()
        }
    }, [paymentdetail]);

    useEffect(() => {

        loadBookingDetails();
        try {
            const starCountRef = ref(database, `bookings/${params.id}`);
            onValue(starCountRef, (snapshot) => {
                const data = snapshot.val();
                if (!!data) {

                    loadBookingDetails();
                    if (data == 4) {
                        setTimeout(() => {
                            ref1.current.resetTimer();
                            ref1.current.startTimer();
                        }, 3000);
                    }

                } else {
                    console.log('Data not found');
                }
            })
        } catch (error) {
            console.log('error,', error);

        }


    }, [value]);


    const loadBookingDetails = () => {
        let body = {
            booking_id: params.id
        };
        getBookingDetails(body).then((res) => {

            let data = res.data;
            setBookInformation(res.data)
            setRecord(res.data);

            setPaymenttype(res.data.booking_details.payment_method)
            setVendorId(res.data.book_staff.vendor_id)


            setPaymentdetail(res.data.booking_details.payment_details)
            if (Array.isArray(res.data.doorstep_que_detail)) {
                setDeliverystep(res.data.doorstep_que_detail);
            }

            if (Number(data?.reschedule_status) == 1 && Number(data?.doorstep_que_status) == 6 && data?.vendor_subscription_status == 1) {
                setAppointmentRescheduled(true);
            } else {
                setAppointmentRescheduled(false);
            }
            setVendor_subscription_status(data?.vendor_subscription_status);
            setBookingstatus(Number(data?.booking_details.book_status));

            if (Number(data?.book_service_type) == 1) {
                setStoreActiveStep(Number(data?.booking_details?.book_que_status));
                //  Doorstep Booking Que status
            } else if (Number(data?.book_service_type) == 2) {

                if (Number(data?.doorstep_que_status) == 1) {
                    setDoorActiveStep(1);
                    setActiveTab('Schedule')
                } else if (Number(data?.doorstep_que_status) == 2) {
                    setActiveTab('Schedule')
                    setDoorActiveStep(2);
                } else if (Number(data?.doorstep_que_status) == 3) {
                    setDoorActiveStep(3);


                } else if (Number(data?.doorstep_que_status) == 4) {
                    setDoorActiveStep(4);
                    // setBookingStartDateTime(data?.booking_details.bookstartdatetime);
                } else if (Number(data?.doorstep_que_status) == 5) {
                    setDoorActiveStep(5);
                } else if (Number(data?.doorstep_que_status) == 6) {
                    setDoorActiveStep(6);
                    setActiveTab('Schedule')
                }
            }

            if (data?.rate_status == '1') {
                handleStarClick(data?.rate_details?.rate_count ? Number(data?.rate_details?.rate_count) - 1 : 0);
                setFeedback(data?.rate_details?.rate_desc);
            }
        });
    }

    //Calling the child component functions


    const getRefundInfo = async () => {
        try {
            const reqData = {
                booking_id: params.id,
            };

            const response = await axiosInstance.post('user/get_refund_info', reqData);
            if (response.status == 200) {
                const data = response?.data


                cancelrefundModalRef.current.classList.add('show');
                cancelrefundModalRef.current.style.display = 'block';
                setRefund(data)
                // setCancelBalanceRequestModal(false)

            }
        } catch (err) {
            if (err.response) {
                if (err.response.status == 400) {
                    console.log("err.response.status", err.response.data)

                }
            } else {
                console.log("Non-API error:", err);
            }
            // setIsLoading(false)

        }
    }

    const cancelBooking = async () => {
        try {
            const reqData = {
                booking_id: params.id,
            };

            setLoading(true);
            setButtonClicked(true);
            const response = await axiosInstance.post('user/confirm_cancel_booking', reqData);
            if (response.status == 200) {
                const data = response?.data
                cancelrefundModalRef.current.classList.remove('show');
                cancelConfirmModalRef.current.classList.add('show');
                cancelConfirmModalRef.current.style.display = 'block';
                setLoading(false);
                setButtonClicked(false);
            }
        } catch (err) {
            if (err.response) {
                if (err.response.status == 400) {
                    console.log("err.response.status", err.response.data)
                }
            } else {
                console.log("Non-API error:", err);
            }

        }
    }

    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        const addressElement = document.getElementById("Address");
        if (addressElement) {
            const addressText = addressElement.textContent;
            if (addressText.length > 17) {
                addressElement.textContent = addressText.slice(0, 17) + "...";
            }
        }
    }, []);

    const confirmReschedule = () => {
        let serviceDetail = {
            serviceType: record?.book_service_type,
            vendorId: record?.vendor_id,
            cart_app_id: record?.book_id,
            reschedule: true
        }

        let cardDetail = {
            cardStatus: "",
            cardType: "",
            cardId: "",
        }

        localStorage.setItem('serviceDetail', JSON.stringify(serviceDetail));
        localStorage.setItem('appliedCard', JSON.stringify(cardDetail));
        localStorage.setItem('selectedServiceOption', 'service');
        closerescheduleModal()
        navigate(`/select-staff/${record.vendor_id}`)
    }

    const openrescheduleModal = () => {
        rescheduleModalRef.current.classList.add("show");
        rescheduleModalRef.current.style.display = "block";
    };

    const closerescheduleModal = () => {
        rescheduleModalRef.current.classList.remove("show");
        rescheduleModalRef.current.style.display = "none";

    };

    const handleOkClick = () => {
        cancelConfirmModalRef.current.classList.remove("show");
        cancelConfirmModalRef.current.style.display = "none";
    };

    const openBalancerequestModal = () => {
        locationModalRef.current.classList.add("show");
        locationModalRef.current.style.display = "block";
    }


    const closeBalancerequestModal = () => {
        locationModalRef.current.classList.remove("show");
        locationModalRef.current.style.display = "none";
    }

    //
    const handleStarClick = (index) => {
        const newRatings = [...ratings];
        for (let i = 0; i < newRatings.length; i++) {
            if (i <= index) {
                newRatings[i] = true;

            } else {
                newRatings[i] = false;
            }
        }
        setRatings(newRatings);
        setRatecount(index + 1)
    };

    const starRating = () => {
        try {

            if (record.rate_status) {
                const reqData = {
                    user_id: record.user_id,
                    rate_id: record.rate_details.rate_id,
                    rate_count: ratecount,
                    rate_desc: feedback,
                };

                axiosInstance.post('user/update_rate_details', reqData)
                    .then((res) => {

                        toast('Rating has been updated.', {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            className: 'text-success toast-style-1'
                        });
                    })

            } else {
                const reqData = {
                    user_id: record.user_id,
                    booking_id: params.id,
                    vendor_id: record.vendor_id,
                    rate_count: ratecount == 0 ? 1 : ratecount,
                    rate_desc: feedback,
                };

                axiosInstance.post('user/add_rate_details', reqData)
                    .then((res) => {

                        toast('Rating has been submited.', {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            className: 'text-success toast-style-1'
                        });
                    })
            }
        } catch {

        }
    }


    const payBalance = () => {

        let userDetatil = {
            name: "",
            phone: "",
            email: "",
        };

        setPaymentOrderId(paymentdetail.balence_request?.order_id);
        setUserId(record?.user_id);
        handlePayment(userDetatil, paymentdetail.balence_request.order);
    }


    const handlePayment = useCallback(
        (userData, orderData) => {
            const createOrderAndInitializePayment = async () => {
                const options = {
                    key: "rzp_live_1wESdx2BEv08Ox",
                    amount: orderData.amount,
                    currency: orderData.currency,
                    name: "PINGLE",
                    description: "Payment",
                    image: "https://pingle.in/user/assets/images/LogoUser.png",
                    order_id: orderData.id,
                    handler: (res) => {


                        if (res) {
                            openPaymentSuccessModal()
                            // onPayment(res); // Passing the order ID to the parent component
                            //  
                        }
                    },
                    prefill: {
                        name: userData.name,
                        email: userData.email,
                        contact: userData.phone,
                    },
                    theme: {
                        color: "#4E26A3",
                    },
                };

                const rzpay = new Razorpay(options);
                rzpay.open();
            };

            createOrderAndInitializePayment();
        },
        [Razorpay]
    );

    const cancelBalanceRequest = () => {
        try {
            const reqData = {
                booking_id: record.book_id,
            };

            axiosInstance.post('user/reject_balance_payment_request', reqData)
                .then((res) => {
                    closeCancelBalanceRequestModal()

                })

        } catch (err) {
            if (err.response) {
                if (err.response.status == 400) {
                    console.log("err.response.status", err.response.data)
                }

            } else {
                console.log("Non-API error:", err);
            }

        }
    }

    const openPaymentSuccessModal = () => {
        paymentSuccessModalRef.current.classList.add('show');
        paymentSuccessModalRef.current.style.display = 'block';
    };

    const handleSuccessOkClick = () => {
        paymentSuccessModalRef.current.classList.remove('show');
        paymentSuccessModalRef.current.style.display = 'none';
        // navigate('/');
    };

    const openCancelBalanceRequestModal = () => {
        cancelBalanceRequestModalRef.current.classList.add('show');
        cancelBalanceRequestModalRef.current.style.display = 'block';
    }

    const closeCancelBalanceRequestModal = () => {
        cancelBalanceRequestModalRef.current.classList.remove('show');
        cancelBalanceRequestModalRef.current.style.display = 'none';
    }
    const copyToClipboard = () => {
        const textToCopy = record.profile_merchant_share_url;

        // Create a textarea element and set its value to the text to be copied
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
        document.body.appendChild(textarea);

        // Select the text in the textarea
        textarea.select();
        textarea.setSelectionRange(0, 99999); // For mobile devices

        // Copy the selected text to the clipboard
        document.execCommand('copy');

        // Remove the textarea from the DOM
        document.body.removeChild(textarea);

        // Update state to indicate that text has been copied
        setCopied(true);

        toast('Link copied!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: 'text-danger toast-style-1'
        });

        // Reset the copied state after 2 seconds
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    const raisedispute = () => {
        try {
            let reqData = {
                booking_id: params.id,
                reason: dispute
            }
            axiosInstance.post('/user/raise_dispute', reqData)
                .then((res) => {

                    openDisputeModal()
                })
        }
        catch {

        }
    }

    const openDisputeModal = () => {
        canceldisputeModalRef.current.classList.add('show');
        canceldisputeModalRef.current.style.display = 'block';
    }

    const closeDisputeModal = () => {
        canceldisputeModalRef.current.classList.remove('show');
        canceldisputeModalRef.current.style.display = 'none';
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>Service Pass</div>
                </div>
            </div>
            <div className="scroller-style-y">
                <div className="container-fluid ps-4 pe-4 mt-3">
                    <div className="row">
                        <div className="col bg-white">
                            <div className="ticketAndSchedule ms-2 me-2 mt-4 mb-4">
                                {
                                    record?.booking_details?.book_status !== '4' ?
                                        record.book_service_type == "2" &&
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button className="nav-link active ff-poppins-semiBold w-50 fs-16 py-2 rounded-0" id="nav-ticket-tab" data-bs-toggle="tab" data-bs-target="#nav-ticket" type="button" role="tab" aria-controls="nav-ticket" aria-selected="true">Ticket</button>
                                                <button className="nav-link w-50 ff-poppins-semiBold fs-16 py-2 rounded-0" id="nav-schedule-tab" data-bs-toggle="tab" data-bs-target="#nav-schedule" type="button" role="tab" aria-controls="nav-schedule" aria-selected="false">Schedule</button>
                                            </div>
                                        </nav>
                                        : <></>
                                }

                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-ticket" role="tabpanel" aria-labelledby="nav-ticket-tab">
                                        {(record.booking_details?.book_que_status == "0") &&
                                            <div className="text-center">
                                                <img src={record.booking_details?.book_qrcode} width={'80%'} height={'80%'} />
                                            </div>
                                        }

                                        {(record.booking_details?.book_que_status == "1") &&
                                            // Stopwatch start
                                            <div className="d-flex justify-content-center mt-2 mb-3">
                                                <div className="d-flex justify-content-center align-items-center servicePassTimer rounded-circle">
                                                    <div className="text-center">
                                                        <div className="ff-balooPaaji2-regular fs-12">Service Started at {record.booking_details?.book_start_time}</div>
                                                        <Stopwatch ref={ref1} startTime={record?.booking_details?.book_start_date_time} />
                                                    </div>
                                                </div>
                                            </div>
                                            // Stopwatch Ends 
                                        }

                                        {record.booking_details?.book_que_status == "2" &&
                                            // Ratings Start  
                                            <div className="mb-4">
                                                <div className="text-center  lh-lg">
                                                    <div className="fs-16 fw-600 ff-poppins-bold">Duration {record.booking_details?.time_diff_view}</div>
                                                    <div className="fs-12 m-0 p-0">Service Started at {record.booking_details?.book_start_time}</div>
                                                    <div className="fs-12 m-0 p-0">Completed at {record.booking_details?.book_end_time}</div>
                                                </div>

                                                {/* <div className="secondary-bg-3 text-center p-3 ms-2 me-2 rounded-1">
                                                    <div className="fs-12 fw-600 ff-medium fw-bold">Service Completed, Rate Service</div>
                                                    <div className="d-flex justify-content-center mt-3 mb-3">
                                                        {ratings.map((filled, index) => (
                                                        <div key={index} onClick={() => handleStarClick(index)}>
                                                            {filled ? (
                                                            <IoMdStar color="gold" size={30} />
                                                            ) : (
                                                            <IoIosStarOutline color="gold" size={30} />
                                                            )}
                                                        </div>
                                                        ))} 
                                                        </div>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control border-0 fs-12 fw-600" placeholder="Enter Feedback" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                        <span className="input-group-text border-0 bg-white fs-12 fw-600 primary-text" id="basic-addon2">POST</span>
                                                    </div>
                                                </div> */}

                                                <div className="ratingContainer">
                                                    <div className="ff-poppins-semiBold text-center">
                                                        Service Completed, Rate Service
                                                    </div>
                                                    <div className="feedback">
                                                        {ratings.map((filled, index) => (
                                                            <div key={index} onClick={() => handleStarClick(index)}>
                                                                {filled ? (
                                                                    <IoMdStar color="gold" size={30} />
                                                                ) : (
                                                                    <IoIosStarOutline color="gold" size={30} />
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <fieldset className="feedbackInput">
                                                        <input className="feedbackEnter bg-white" value={feedback} onChange={(e) => setFeedback(e.target.value)} type="text" />
                                                        <button type="submit" className="form-submit ff-poppins-semiBold" onClick={() => starRating()}>POST</button>
                                                    </fieldset>
                                                </div>
                                            </div>



                                            // Ratings Ends 
                                        }
                                    </div>

                                    <div className="tab-pane fade" id="nav-schedule" role="tabpanel" aria-labelledby="nav-schedule-tab">
                                        <div className="timeline p-4">
                                            {/* <ul>
                                                {record.doorstep_que_detail?.map(item => {
                                                    return (
                                                        <li className={`${item.date != '' ? "active" : ""}`}>

                                                            <div className={`fs-10 fw-500 ${item.date != '' ? "ff-bold" : "secondary-text-2"}`}>{item.lable}</div>
                                                            {item.date != '' &&
                                                                <div className="fs-10 fw-500">{item.date}</div>
                                                            }
                                                        </li>
                                                    )
                                                })}
                                            </ul> */}

                                            <div className="service-stepper">
                                                {/* Check if deliverystep is not undefined before rendering VerticalStepper */}
                                                {deliverystep && deliverystep.length > 0 && (
                                                    <VerticalStepper steps={deliverystep} />
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="container-fluid">
                                <div className="row">
                                    <div className='d-flex justify-content-between col-12'>
                                        <div className={``}>
                                            <div className="ff-poppins-bold fs-15">{record.book_date_time}</div>
                                            <div className="ff-poppins-semiBold fs-15">Booking ID - {record.book_id}</div>
                                        </div>
                                        {
                                            record?.booking_details?.book_status == '3' ?
                                                <div className="">
                                                    <p className="px-2 py-1 unpaidbtn ff-poppins-bold">Canceled</p>
                                                </div>
                                                :
                                                record?.booking_details?.book_status == '4' ?
                                                    <div className=" ">
                                                        <p className="px-2 py-1 unpaidbtn ff-poppins-bold ">Missed</p>
                                                    </div>
                                                    :
                                                    record.booking_details?.book_status != 4 &&
                                                    <div className={`text-center otp-sec ff-bold px-1 py-0`}>
                                                        <div className="ff-poppins-bold fs-15">OTP</div>
                                                        <div className="ff-poppins-bold fs-15">{record.booking_details?.book_code}</div>
                                                    </div>

                                        }
                                    </div>

                                    {
                                        record.book_service_type == "2" &&
                                        <div className="col-12 mt-4">

                                            <div className="fs-17 fw-600 ff-medium mb-2 fw-bold">Service Address</div>

                                            <div className="container-fluid deleiveryAddress rounded pt-1 pb-1">
                                                <div className="row align-items-center">
                                                    <div className="col-2">
                                                        {record.service_location.location_type_id == '1' ? (
                                                            <IoMdHome className="homeIconPP" />
                                                        ) : record.service_location.location_type_id == '2' ? (
                                                            <MdWork className="homeIconPP" />
                                                        ) : record.service_location.location_type_id == '3' ? (
                                                            <IoLocationSharp className="homeIconPP" />
                                                        ) :
                                                            <></>
                                                        }
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="fs-12 fw-500 ff-medium fw-bold">{record.service_location.location_type}</div>

                                                        <div className="fs-10 fw-400 clamped-text" id="Address">{record.service_location.address}</div>
                                                        <div className="fs-10 fw-400">
                                                            <p> {
                                                                record.service_location ?
                                                                    record.service_location.distance.text + ' - ' + record.service_location.duration.text
                                                                    : ''
                                                            }</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        <GoChevronRight />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="col-12 ps-1 pe-1 mt-4">
                                        <div className="ff-poppins-semiBold fs-17 mb-2 ms-2">Booking For</div>
                                        <div className="profile-card-1 d-flex">
                                            <img src={record.book_for?.image} className="rounded-3" width={'70px'} height={'70px'} />
                                            <div className="ms-3 w-100">
                                                <div className="ff-poppins-semiBold fs-18">
                                                    {record.book_for?.name}
                                                </div>
                                                <div className="ff-poppins-regular fs-10 mb-1">
                                                    {record.book_for?.category_name}
                                                </div>
                                                {
                                                    record.vendor_type_id == '2' ?
                                                        <div className='ff-poppins-regular fs-10 mb-1'>
                                                            {record.book_for?.type} - {record.book_for?.breed}
                                                        </div> : <></>
                                                }
                                                <div className="ff-poppins-regular fs-14 text-secondary">{record.book_for?.relationship}</div>
                                                {
                                                    showMoreInforUser
                                                        ?
                                                        <div>

                                                            {
                                                                record.vendor_type_id == '1' ?
                                                                    <div className='ff-poppins-regular fs-14 mb-1'>
                                                                        {record.book_for?.gender}  </div> :
                                                                    record.vendor_type_id == '2' ?
                                                                        <div className='ff-poppins-regular fs-14 mb-1'>
                                                                            {record.book_for?.type} - {record.book_for?.age} Years - {record.book_for?.weight} Kg
                                                                        </div> : <></>
                                                            }
                                                            <div className='ff-poppins-regular fs-14 mb-1'>{record.book_for?.register_number}</div>
                                                            <hr className='mt-1 mb-1 text-secondary' />
                                                            {
                                                                record.vendor_type_id == '2' ?
                                                                    <div className='ff-poppins-regular text-center col-4 rounded-1 ff-poppins-regular temperamentBox fs-10 '>
                                                                        {record.book_for?.temperament.toUpperCase()}
                                                                    </div> : <></>
                                                            }

                                                            <div className='ff-poppins-regular fs-14 '>
                                                                {record.book_for?.about}
                                                            </div>
                                                            <div className='ff-poppins-semiBold fs-16 d-flex align-items-center primary-text' role='button' onClick={() => { setShowMoreInforUser(!showMoreInforUser) }}>
                                                                Less info
                                                                <img
                                                                    src={p_up_arrow_circle}
                                                                    width={'15px'}
                                                                    height={'15px'}
                                                                    alt='Show Less Info'
                                                                    role='button'
                                                                    className='ms-1'
                                                                />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='ff-poppins-semiBold fs-16 d-flex align-items-center primary-text' role='button' onClick={() => { setShowMoreInforUser(!showMoreInforUser) }}>
                                                            More info
                                                            <img src={p_down_arrow_circle} className="ms-1" width={'15px'} height={'15px'} />
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        record?.staff_app_status == 1 && record.book_staff?.staff_id != "1" ?
                                            <div className="col-12 ps-1 pe-1 mt-4">
                                                <div className="ff-poppins-semiBold fs-17 mb-2 ms-2">Appointments with</div>
                                                <div className="profile-card-1 d-flex">
                                                    <img src={record.book_staff?.staff_image} onError={(event) => { event.target.onerror = null; event.target.src = 'https://pingle.s3.ap-south-1.amazonaws.com/uploads/vendor/profile/user.jpg'; }} className="rounded-3" width={'70px'} height={'70px'} />
                                                    <div className="ms-3 w-100">
                                                        <div className="ff-poppins-semiBold fs-18">{record.book_staff?.staff_name}</div>
                                                        <div className="ff-poppins-regular fs-14 text-secondary">{record.book_staff?.staff_designation}</div>
                                                        {
                                                            showMoreInforStaff
                                                                ?
                                                                <div>
                                                                    <div className='ff-poppins-regular fs-14 mb-1'>
                                                                        {record.book_staff?.staff_specialist}
                                                                    </div>
                                                                    <hr className='mt-1 mb-1 text-secondary' />
                                                                    <div className='ff-poppins-regular fs-14 '>
                                                                        {record.book_staff?.staff_about}
                                                                    </div>
                                                                    <div className='ff-poppins-semiBold fs-16 d-flex align-items-center primary-text' role='button' onClick={() => { setShowMoreInforStaff(!showMoreInforStaff) }}>
                                                                        Less info
                                                                        <img
                                                                            src={p_up_arrow_circle}
                                                                            width={'15px'}
                                                                            height={'15px'}
                                                                            alt='Show Less Info'
                                                                            role='button'
                                                                            className='ms-1'
                                                                        />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='ff-poppins-semiBold fs-16 d-flex align-items-center primary-text' role='button' onClick={() => { setShowMoreInforStaff(!showMoreInforStaff) }}>
                                                                    More info
                                                                    <img src={p_down_arrow_circle} className="ms-1" width={'15px'} height={'15px'} />
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            : <></>
                                    }



                                    <div className="col-12 ps-1 pe-1 mt-4">
                                        <div className="store-card-1 d-flex">
                                            <div onClick={() => navigate(`/vendor/${record.vendor_id}`)} className="d-flex justify-content-between">
                                                <img className="rounded-3" src={record.vendor_logo} width={'70px'} height={'70px'} />
                                                <div className="ms-2">
                                                    <div className="ff-poppins-semiBold fs-16">{record.book_staff?.vendor_name}</div>
                                                    <div className="ff-poppins-semiBold fs-12 text-secondary">&#x2022; MID {record.book_staff?.vendor_id} &#x2022; {record.vendor_type_name} </div>
                                                    <div className="ff-poppins-semiBold fs-12 singleline-text text-secondary">&#x2022; {record.vendor_address} </div>
                                                </div>
                                            </div>
                                            <div className="ms-auto d-flex justify-content-center align-items-center history-btn">
                                                <Link to={`/history/${vendor_id}`} className="text-decoration-none">
                                                    <div className="text-center history">
                                                        <img src={p_history_icon} width={'36px'} height={'36px'} alt="History Icon" />
                                                        <div className="ff-poppins-semiBold fs-13 primary-text">History</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container-fluid mt-4 primary-bg rounded-3 p-3">
                                        <div className="row">

                                            <div className="col-4 fs-12 fw-600 text-white p-2 d-flex align-items-center justify-content-center">
                                                <a href={`tel:${record.vendor_mbl}`} className='text-decoration-none d-flex'>
                                                    <img src={p_call} width={'20px'} height={'20px'} />
                                                    <div className="ff-poppins-semiBold text-white fs-16 ms-2">Call</div>
                                                </a>
                                            </div>
                                            <div className="col-4 fs-12 fw-600 text-white p-2 d-flex align-items-center justify-content-center text-sec">
                                                <img src={p_text_message} width={'20px'} height={'20px'} />
                                                <div className="ff-poppins-semiBold fs-16 ms-2">Text</div>
                                            </div>
                                            <div className="col-4 fs-12 fw-600 text-white p-2 d-flex align-items-center justify-content-center" role="button" onClick={() => copyToClipboard()}>
                                                <img src={p_share} width={'20px'} height={'20px'} />
                                                <div className="ff-poppins-semiBold fs-16 ms-2">Share</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 mt-4 primary-text d-flex justify-content-between billing-info pb-1" onClick={toggleExpanded}>
                                        <div className="ff-poppins-semiBold fs-15">BOOKING INFORMATION</div>
                                        <div className="fs-14 fw-600 text-dark">
                                            {expanded ? <FaAngleUp className="fs-4" /> : <FaAngleDown className="fs-4" />}
                                        </div>
                                    </div>
                                    <div>
                                        {expanded && (
                                            <div className="expanded-content fs-12 ff-poppins-regular mb-2">
                                                Appointment Initiated at {record.book_initiate_time} by {record.user_name} for {record.book_for.name}.
                                            </div>
                                        )}
                                    </div>
                                    <hr></hr>
                                    <div className="col-12 mt-2">
                                        {/* Buy get offer */}
                                        {
                                            record?.booking_details?.applied_card_detail?.offer_type == "3" ?
                                                <div>
                                                    <div>
                                                        <div className='ff-poppins-semiBold primary-text fs-14 mt-2 pt-1 pb-1'>
                                                            Buy Services
                                                        </div>
                                                        {
                                                            record?.booking_details?.offer_services?.buy_services.map(list => {

                                                                return (
                                                                    <div className="d-flex justify-content-between ff-balooPaaji2-regular fs-16 mb-2">
                                                                        <p className="m-0">{list.service_name} </p>
                                                                        <p className="m-0">
                                                                            {list.service_price}
                                                                        </p>
                                                                    </div>
                                                                )
                                                            })

                                                        }


                                                        <div className='d-flex justify-content-between ff-balooPaaji2-regular fs-16 totalBar'>
                                                            <p className='m-0 mt-1 text-success mb-1'>Total(Offer price)</p>
                                                            <p className='m-0 mt-1 text-success mb-1'>Rs. {record?.booking_details?.offer_services?.buy_services_total} </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='ff-poppins-semiBold primary-text fs-14 mt-2 pt-1 pb-1'>
                                                            Get Services(Free)
                                                        </div>
                                                        {
                                                            record?.booking_details?.offer_services?.get_services.map(list => {
                                                                return (
                                                                    <div className="d-flex justify-content-between ff-balooPaaji2-regular fs-16 mb-2">
                                                                        <p className="m-0">{list.service_name} </p>
                                                                        <p className="m-0"> {list.service_price}.00  </p>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                        <div className='d-flex justify-content-between ff-balooPaaji2-regular fs-16 borderTop '>
                                                            <p className='m-0 mt-1 text-success mb-1'>Total(Offer Value)</p>
                                                            <p className='m-0 mt-1 text-success mb-1'>RS {record?.booking_details?.offer_services?.get_services_total}</p>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div>
                                                    <div className="ff-poppins-semiBold fs-15 primary-text mb-2">Services</div>
                                                    {record.booking_details?.book_service_details?.map(item => (
                                                        <div className="d-flex justify-content-between mb-2">
                                                            <div className="ff-balooPaaji2-regular fs-16">{item.service_name}</div>
                                                            <div className="ff-balooPaaji2-regular fs-16">{item.service_price}</div>
                                                        </div>
                                                    ))}
                                                    {
                                                        record?.booking_details?.discount_status == 1 ?
                                                            <div className='d-flex justify-content-between ff-balooPaaji2-regular fs-16  borderTop'>
                                                                <p className='m-0 mt-1 text-success mb-1'>{record?.booking_details?.discount_detail?.lable} </p>
                                                                <p className='m-0 mt-1 text-success mb-1'>RS {record?.booking_details?.discount_detail?.discount_amount}</p>
                                                            </div>
                                                            : <></>
                                                    }
                                                </div>
                                        }

                                        {/* Billing details */}
                                        <div className="service-section pt-2 pb-2 mb-2 ">
                                            <div className="d-flex justify-content-between">
                                                <div className="ff-balooPaaji2-regular fs-16">Subtotal </div>
                                                <div className="ff-balooPaaji2-regular fs-16">{record.booking_details?.net_amount}</div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="ff-balooPaaji2-regular fs-16">Platform charges</div>
                                                <div className="ff-balooPaaji2-regular fs-16">{record.booking_details?.user_platform_charges}</div>
                                            </div>
                                            {
                                                record.payment_method == "loyalty" ?
                                                    <div className="d-flex justify-content-between">
                                                        <div className="ff-balooPaaji2-regular fs-16">Pingle points redeemed ({record.redeemed_offer?.offer_points})</div>
                                                        <div className="ff-balooPaaji2-regular fs-16">-{record.booking_details?.net_amount}</div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="ff-balooPaaji2-regular fs-16">SGST {record.booking_details?.SGST_percentage}</div>
                                                            <div className="ff-balooPaaji2-regular fs-16">{record.booking_details?.SGST_amount}</div>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="ff-balooPaaji2-regular fs-16">CGST {record.booking_details?.CGST_percentage}</div>
                                                            <div className="ff-balooPaaji2-regular fs-16">{record.booking_details?.CGST_amount}</div>
                                                        </div>
                                                    </div>

                                            }

                                            {
                                                record.booking_details?.round_off != 0 ?
                                                    <div className="d-flex justify-content-between">
                                                        <div className="ff-balooPaaji2-regular fs-16">Round Off</div>
                                                        <div className="ff-balooPaaji2-regular fs-16">{record.booking_details?.round_off}</div>
                                                    </div>
                                                    : <></>
                                            }

                                        </div>

                                        <div className=" mt-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <div className="ff-poppins-semiBold fs-18">Total</div>
                                                <div className="ff-poppins-semiBold fs-18">
                                                    {
                                                        paymenttype !== '3' ?
                                                            record.booking_details?.payment_details?.payment_status == '1' ?
                                                                <span className="paidBtnSP text-center  p-1 ff-poppins-semiBold me-1"> PAID  </span>
                                                                :
                                                                <span className="unpaidbtn text-center  p-1 ff-poppins-semiBold me-1"> UNPAID </span>
                                                            : <></>
                                                    }
                                                    {record.booking_details?.payment_details?.total_amount}
                                                </div>
                                            </div>

                                            {paymenttype == '3' ?
                                                <div className="ff-poppins-bold fs-16 mt-3">
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <div className="">Advance amount</div>
                                                        <div className="d-flex justify-content-between me-2 ">
                                                            <span className="paidBtnSP text-center p-1  ff-poppins-semiBold  me-2"> PAID  </span>
                                                            {paymentdetail.advance_amount}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="">Balance amount</div>
                                                        <div className="d-flex me-2 fs-14">
                                                            {paymentdetail.balance_status == 0 ? (
                                                                <button className="unpaidbtn  ff-poppins-semiBold me-1">
                                                                    UNPAID
                                                                </button>
                                                            ) : (
                                                                <button className="paidBtnSP  ff-poppins-semiBold me-1">
                                                                    PAID
                                                                </button>
                                                            )}
                                                            {paymentdetail.balance_amount}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>
                                        {
                                            record.cancel_and_refund_detail?.cancel_status == "1" ?
                                                <div>
                                                    <div>
                                                        <h5 className="fs-15 ff-poppins-bold m-0">Cancel Information</h5>
                                                        <p className="secondary-text-1 fs-13 ">{record?.cancel_and_refund_detail?.cancel_description} </p>
                                                    </div>
                                                    <div>
                                                        <h5 className="fs-15 ff-poppins-bold m-0">Refund Information</h5>
                                                        <p className="secondary-text-1 fs-13">{record?.cancel_and_refund_detail?.refund_description} </p>
                                                    </div>

                                                    <div>
                                                        {
                                                            record?.cancel_and_refund_detail?.refund_status == "1" ?
                                                                <div>
                                                                    <h5 className="fs-15 ff-poppins-bold m-0">Refund Status</h5>

                                                                    <p className="secondary-text-1 fs-13">
                                                                        {record?.cancel_and_refund_detail?.refund_process_status == "1" ? (
                                                                            "Refund Processing"
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                        {record?.cancel_and_refund_detail?.refund_process_status == "2" ? (
                                                                            "Refund Complete"
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                        {record?.cancel_and_refund_detail?.refund_process_status == "3" ? (
                                                                            "Refund Faild"
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                : <></>
                                                        }</div>
                                                </div>
                                                : <></>
                                        }

                                        {record.dispute == true ?
                                            <div>
                                                <div>
                                                    <h5 className="fs-15 ff-poppins-bold m-0">Dispute Status</h5>
                                                    <p className="secondary-text-1 fs-13 ">{record?.dispute_detail?.dispute_status_name} </p>
                                                </div>
                                                <div>
                                                    <h5 className="fs-15 ff-poppins-bold m-0">Dispute description</h5>
                                                    <p className="secondary-text-1 fs-13">{record?.dispute_detail?.dispute_description} </p>
                                                </div>
                                                <div>
                                                    <h5 className="fs-15 ff-poppins-bold m-0">Dispute reason</h5>
                                                    <p className="secondary-text-1 fs-13">{record?.dispute_detail?.dispute_reason} </p>
                                                </div>
                                            </div> : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-4 ps-4 pe-4">
                    <div className="row">
                        {
                            (record?.booking_details?.book_que_status == 0 || record?.booking_details?.book_status == 4 || record?.doorstep_que_status == 1) && !appointmentRescheduled && vendor_subscription_status == 0 && bookingstatus != 3 && bookingstatus != 2 ?
                                <>
                                    <div className="col-12 fs-16 primary-bg rounded fw-600 text-white ff-poppins-bold text-center p-4" onClick={() => openrescheduleModal()}>Reschedule Appointment</div>
                                </> :
                                <></>
                        }

                        {(storeActiveStep == 0 || bookingstatus == 4 || doorActiveStep == 1 || doorActiveStep == 2 || doorActiveStep == 3) && !appointmentRescheduled && vendor_subscription_status == 1 && bookingstatus != 3 && bookingstatus != 2 ?
                            <> <div className="col-12 d-flex ps-0 pe-0">
                                <div className="w-50 primary-bg rounded-3 text-white text-center p-3 me-1 ff-poppins-semiBold fs-18"
                                    onClick={() => openrescheduleModal()}>Reschedule</div>

                                <button aria-controls="offcanvasBottom"
                                    className="w-50 primary-bg rounded-3 text-white text-center p-3 me-1 ff-poppins-semiBold fs-18 border-0" onClick={() => getRefundInfo()} >
                                    Cancel</button>
                            </div></> : <></>
                        }

                        <a href="https://pingle.in/user/refund-policy.php" className="col-12 ff-poppins-regular fs-13 text-center pt-4 pb-4 text-decoration-none text-dark text-center" target="_blank" rel="noopener noreferrer">
                            <div className="text-center">
                                Read our <span className="primary-text">Cancellation Policy</span> and <span className="primary-text">Refund Policy</span>
                            </div>
                        </a>
                        {/* <div className="col-12  ff-poppins-semiBold fs-13 text-center pt-4 pb-4">Read our <span className="primary-text">Cancellation policy</span> and <span className="primary-text">Refund Policy</span></div> */}


                    </div>
                </div>
                {
                    record.dispute == false ?
                        <div>
                            <p className="primary-text text-center ff-poppins-semiBold" onClick={openDisputeModal}>Raise Dispute</p>
                        </div> : <></>
                }



                <div class="offcanvas" tabindex="-1" style={{ width: 390, borderTopLeftRadius: 15, borderTopRightRadius: 15 }} ref={canceldisputeModalRef} >
                    <div class="offcanvas-header">
                        <h5 class="offcanvas-title ff-poppins-semiBold fs-16" id="offcanvasBottomLabel">Dispute reason</h5>
                        <button type="button" class="btn-close" onClick={closeDisputeModal}></button>
                    </div>
                    <div class="offcanvas-body small">
                        <textarea className="w-100 rounded-2 p-2 mb-3" placeholder="please provide details of your dispute reason."
                            value={dispute}
                            onChange={(e) => setDispute(e.target.value)}>
                        </textarea>
                        <button className="w-100 primary-bg border-0 text-white ff-poppins-semiBold py-2 rounded" onClick={() => raisedispute()}>Raise Dispute</button>
                    </div>
                </div>

                <div class="modal fade " id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1" ref={rescheduleModalRef}>
                    <div class="modal-dialog modal-dialog-centered mainModal " >
                        <div class="modal-content mx-4 text-center bg-white">

                            <div class="modal-body">
                                <FaExclamation className="exclamatoryIcon" />
                            </div>
                            <div className="p-5">
                                <h5 className="primary-text ff-poppins-bold">Are you sure you want to reschedule</h5>
                                <p className="secondary-text ff-poppins-semiBold">
                                    Please note that refund will not be available for future cancellations.
                                </p>
                                <button class="primary-bg ff-poppins-bold w-50 rounded-pill border border-0 text-light p-1"
                                    onClick={() => confirmReschedule()} >Ok</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="offcanvas mainModal rounded-4 CancelProduct cancelBtnModalProductPass" style={{ position: 'sticky !important', minHeight: '55px !important' }} ref={cancelrefundModalRef}>
                    <div className="refundContentModal mb-2 refundModal" style={{ marginTop: 10 }}>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="text-center w-100 py-3">
                                <p className="m-0 ff-poppins-bold">Cancel Booking</p>
                            </div>
                            <div onClick={() => { cancelrefundModalRef.current.classList.remove("show"); }}  >
                                <p className="ff-balooPaaji2-semiBold p-2 m-0" style={{ color: "gray", cursor: "pointer" }} > </p>
                            </div>
                        </div>
                        <div className="position-relative"></div>
                        <p className="m-0 ff-poppins-regular fs-14" >
                            Are you sure you want to cancel the Booking and get refund? Please read the read the refund policy before clicking the 'confirm' button.
                        </p>
                        <p className="mt-3 ff-poppins-bold" >Refund amount</p>
                        {
                            refund?.refund_detail?.refund_status == 1 && refund?.refund_detail?.refund_charges_type == "2" ?
                                refund?.refund_detail?.refund_charges.map((data) => {
                                    return (
                                        <div className="d-flex justify-content-between">
                                            <p className="primary-text m-0">{data.percentage == 0 ? "  No" : data.percentage + "%"} Refund</p>
                                            <p className="m-0">Cancel {data.time} hrs within booking</p>
                                        </div>
                                    )
                                })
                                : <></>}
                        {
                            refund?.refund_detail?.refund_status == 0 ?
                                <p>{refund?.refund_message[0]} </p>
                                : <></>
                        }
                        <p className="m-0 ff-poppins-regular fs-12 text-center" >
                            Read our <span className="primary-text">cancellation policy</span> and <span className="primary-text">Refund Policy</span>.
                        </p>
                    </div>
                    <button
                        className="text-center text-white rescheduleCancelBtnPP ff-poppins-semiBold"
                        style={{
                            marginTop: 15,
                            position: "relative",
                            padding: "10px 20px",
                            backgroundColor: "#primary-bg",
                            color: "white",
                            border: "0px",
                            borderRadius: "5px",
                            height: "40px",
                            lineHeight: "20px",
                            verticalAlign: "middle",
                        }}
                        onClick={() => {
                            if (!buttonClicked) {
                                cancelBooking();
                            }
                        }}
                        disabled={buttonClicked}
                    >
                        {loading && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                }}
                            >
                                <Loading type="spinningBubbles" color="#fff" height={20} width={20} />
                            </div>
                        )}
                        {!loading && 'Confirm'}
                    </button>
                </div>

                <div class="modal fade" id="exampleModalConfirm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={cancelConfirmModalRef}>
                    <div class="modal-dialog m-0 modal-dialog-centered mainModal px-5">
                        <div class="modal-content px-4 py-2">
                            <div class="modal-body d-flex justify-content-center ff-poppins-bold">
                                Appointment Cancelled
                            </div>

                            <img src={AppointmentCancelled} alt="My Image" />

                            <div class="mb-4 d-flex text-center justify-content-center ff-poppins-regular">
                                The appointment has been cancelled successfully.
                            </div>
                            <button class="text-center text-white rescheduleCancelBtnPP ff-poppins-semiBold" onClick={handleOkClick}>
                                OK </button>
                        </div>
                    </div>
                </div>

                <div className="offcanvas offcanvas-bottomm offcanvass main " style={{ height: 190 }} data-bs-scroll="true" data-bs-target="#staticBackdrop" tabIndex="-1"
                    aria-labelledby="" ref={locationModalRef} >
                    <div className=" ">
                        <div className="d-flex justify-content-center">
                            <div class="modal-content p-3 bg-white">
                                <div class="">
                                    <h1 class="d-flex ff-poppins-semiBold justify-content-center fs-16 mb-3" id="staticBackdropLabel" >
                                        Pay Balance
                                    </h1>
                                </div>
                                <div class="">
                                    <p className="fs-13 text-center">
                                        <span> {record.vendor_name}</span> Has requested you to pay the balance
                                        amount
                                    </p>
                                    <p className="m-0 ff-poppins-regular text-primary text-center mb-3">
                                        (Rs.{record?.booking_details?.payment_details?.balance_amount})
                                    </p>
                                </div>
                                <div class="d-flex justify-content-center gap-3">
                                    <button class="PayBtn px-5 py-1 rounded-pill" onClick={payBalance}> Pay </button>
                                    <button type="button" class="rejectBtn px-5 py-1 rounded-pill" onClick={openCancelBalanceRequestModal}>
                                        Reject
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`modal fade`}
                    id='successMoadl'
                    tabIndex="-1"
                    aria-labelledby="successMoadl"
                    aria-hidden="true"
                    ref={paymentSuccessModalRef}
                    data-bs-backdrop="false"
                    style={{ backgroundColor: "#fff3" }}
                >
                    <div className="modal-dialog modal-dialog-centered mainModal">
                        <div className="modal-content modal-success" style={{ width: "80%" }} >
                            <div className="modal-header">
                                <div>
                                    <div>
                                        <div>
                                            <Lottie animationData={SuccessLotie} loop={false} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body modal-bodyRP">
                                <div className="text-center fw-bold primary-text">
                                    Payment Success.
                                </div>
                                <div className="text-center fw-bold">
                                    Redirecting you to your ticket.
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn okBtnRP"
                                    onClick={handleSuccessOkClick} >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade p-3" aria-hidden="true" tabindex="-1" ref={cancelBalanceRequestModalRef}>
                    <div class="modal-dialog m-0 modal-dialog-centered mainModal p-4">
                        <div class="modal-content p-3">
                            <div class="">
                                <h1 class="d-flex ff-poppins-semiBold justify-content-center fs-16 mb-3" id="staticBackdropLabel" >
                                    Cancel Request
                                </h1>
                            </div>
                            <div class="">
                                <p className="m-0 fs-15 ff-poppins-regular text-center mb-3">
                                    Are you sure want to cancel the payment request?
                                </p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button class="btn primary-bg px-5 rounded-pill text-white"
                                    onClick={() => cancelBalanceRequest()} >
                                    Confirm
                                </button>
                                <button type="button" class="btn secondary-bg-1 px-5 rounded-pill" onClick={() => { closeCancelBalanceRequestModal() }}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        // <h1>BookingDetails {params.id}</h1>
    );
};

export default UserBookingDetails;