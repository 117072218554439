import { useState } from 'react';
import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import p_history_icon from '../../Assets/Images/booking/p_history_icon.svg';
import { getChatList, getMyFollowingList } from '../../Services/api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FollowingCards from '../../Components/followingCards';
import axiosInstance from '../../Services/axios';

const UserFollowing = () => {


    const [followersData, setFollowersData] = useState([]);

    const navigate = useNavigate();
    const [followingList, setFollowingList] = useState([]);

    useEffect(() => {
        getMyFollowingList().then((res) => {
            setFollowingList(res.data?.following_history);
        });
    }, []);

    useEffect(() => {
        getFollowersList();
     
      }, []);
    const getFollowersList = () => {
        try {
          axiosInstance.post('user/my_following_list')
            .then((res) => {
              let data = res.data;
              console.log("followers Data----------------->",data);
              setFollowersData(data.following_history)
            
            });
        } catch (error) {
          alert("Try again Later");
        }
      };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>Following</div>
                </div>
            </div>
            <div className='d-flex flex-column p-2 scroller-style-y'>
                {followersData.map((follower,index)=>{
                    console.log("follower//////////",follower);
                    return (
                        <FollowingCards 
                        key={index} 
                        vendor_id={follower.vendor_id}
                        vendor_name={follower.vendor_name}
                        vendor_logo={follower.vendor_logo}
                        vendor_address={follower.vendor_address}

                        />
                    )
                    })}
              
            </div>

            {followersData.length === 0 && (
                <div className='messengerList scroller-style-y'>
                    <div className='ff-poppins-semiBold fs-14 w-100 h-100 d-flex align-items-center justify-content-center'>
                        No data found
                    </div>
                </div>
            )}
        </>
    )
}

export default UserFollowing