import React, { useEffect, useState } from "react";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import { useLocation, useNavigate } from "react-router-dom";
import p_call from '../../Assets/Images/booking/p_call.svg';
import p_text_message from '../../Assets/Images/booking/p_text_message.svg';
import axiosInstance from "../../Services/axios";
import default_img from "../../Assets/Images/default-placeholder.png"
const RedeemedDetails = () => {
    const navigate = useNavigate()

    const location = useLocation()
    const { purchaseid } = location.state || {}
    const [data, setData] = useState()
    useEffect(() => {
        getLoyaltyDetails()
    }, [])
    const getLoyaltyDetails = () => {
        try {
            let reqData = {
                purchase_id: purchaseid
            }
            axiosInstance.post('/user/get_redeemed_single_loyalty_offer', reqData)
                .then((res) => {
                    console.log("res", res);
                    setData(res.data)
                })
        }
        catch {

        }
    }
    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img
                        src={p_nav_previous}
                        width={"24px"}
                        height={"16px"}
                        alt="Previous Button"
                        role="button"
                        onClick={() => navigate(-1)}
                    />
                    <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
                        Redeemed item
                    </div>
                </div>
            </div>
            <div className="p-3 scroller-style-y">
                <div className=" ">
                    <div className="p-2 bg-white rounded-2">
                        <div className="d-flex justify-content-between">
                            <div className="col-8">
                                <h5 className="ff-poppins-bold fs-18" >{data?.purchase_detail?.offer_detail?.offer_name} </h5>
                                <p className="m-0 fs-12">Purchase id : {data?.purchase_detail?.offer_detail?.offer_id}</p>
                                <p className="m-0 fs-12">Pingle Coins : {data?.purchase_detail?.offer_detail?.offer_price} </p>
                            </div>
                            <div className="col-4" style={{ position: 'relative', width: 120, height: 100 }}>
                                <img src={data?.purchase_detail?.offer_detail?.offer_images === '' ? default_img : data?.purchase_detail?.offer_detail?.offer_images[0]}
                                    className="" style={{ borderRadius: 10, width: '100%', height: '100%' }} />
                                {data?.purchase_detail?.delivery_status === '2' ?
                                    <p className="fs-16 offerStatus text-center text-white ff-poppins-regular " style={{ backgroundColor: '#f7b924' }}>
                                        Shipped
                                    </p> :
                                    <p className="fs-16 offerStatus  text-center text-white ff-poppins-regular" style={{ backgroundColor: '#16aaff' }}>
                                        Processing
                                    </p>
                                }
                            </div>

                        </div>
                        <div className="d-flex justify-content-between mt-5">
                            <div>
                                <h5 className="fs-16 ff-poppins-bold">Purchased on</h5>
                                <p className="fs-13 m-0">{new Date(data?.purchase_detail?.purchase_date.substring(0, 10)).toDateString().substring(4, 20)}</p>
                            </div>
                            <div>
                                <h5 className="fs-16 ff-poppins-bold">Delivery on</h5>
                                <p className="fs-13 m-0">{new Date(data?.purchase_detail?.delivery_date.substring(0, 10)).toDateString().substring(4, 20)}</p>
                            </div>
                        </div>
                        <div className="mt-3">
                            <h5 className="ff-poppins-bold fs-16">Offer Description</h5>
                            <p className="fs-13">{data?.purchase_detail?.offer_detail?.offfer_description} </p>
                        </div>
                    </div>
                    <div className="bg-white mt-2 rounded-2">
                        <div className="p-2">
                            <h5 className="fs-16 ff-poppins-bold">Delivery AddAddress :</h5>
                            <p className="fs-13 m-0">{data?.purchase_detail?.delivery_address} </p>
                        </div>
                    </div>
                    <div className="bg-white mt-2 rounded-2">
                        <div className="p-2">
                            <h5 className="fs-16 ff-poppins-bold">Pingle customer care :</h5>
                            <div className=" d-flex justify-content-between">
                                <div className="col-5 fs-12 primary-bg fw-600 text-white rounded-2 p-1 d-flex align-items-center justify-content-center">
                                    <a href={`tel:${9790059991}`} className='text-decoration-none d-flex'>
                                        <img src={p_call} width={'20px'} height={'20px'} />
                                        <div className="ff-poppins-semiBold text-white fs-16 ms-2">Call</div>
                                    </a>
                                </div>
                                <div className="col-5 fs-12 fw-600 primary-bg text-white p-1 rounded-2 d-flex align-items-center justify-content-center text-sec"
                                onClick={()=>navigate('/messenger-list')}>
                                    <img src={p_text_message} width={'20px'} height={'20px'} />
                                    <div className="ff-poppins-semiBold fs-16 ms-2">Message</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default RedeemedDetails