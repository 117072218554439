import React from "react";
import { useCallback, useEffect, useState } from "react";
import "../Products/product.css";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import ion_cart_outline from "../../Assets/Images//ion_cart-outline.svg";
import { Await, useLocation, useNavigate, useParams } from "react-router-dom";
import { vendorProductList } from "../../Services/api";
import axiosInstance from "../../Services/axios";
// import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { LuPlusCircle } from "react-icons/lu";
import { FiMinusCircle } from "react-icons/fi";
import hamburgerMenu from "../../Assets/Images/p_navbars.svg";
import { RxHamburgerMenu } from "react-icons/rx";
import "bootstrap/dist/css/bootstrap.min.css";
import { IoSearch } from "react-icons/io5";
import defaultimg from "../../Assets/Images/default-placeholder.png";
import { toast } from "react-toastify";
import { BsChevronDown } from "react-icons/bs";
import { IoIosArrowUp } from "react-icons/io";
import { IoChevronDown } from "react-icons/io5";
const Products = ({ productItems, selectedProductId }) => {
  const navigate = useNavigate();
  const [productlist, setProductlist] = useState([]);
  const [cartId, setCartId] = useState("");
  const [products, setProducts] = useState(false);
  const [moreDetails, setMoreDetails] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(true);
  const [count, setCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [cardCount, setCardCount] = useState([]);
  const [shipingtype, setShipingtype] = useState("");
  const [dataid, setDataid] = useState();
  const valueParam = useParams();
  const merchantId = valueParam;
  const [isLoading, setLoadingStatus] = useState(false);
  const [matchingProduct, setMatchingProduct] = useState(null);
  const [showMenus, setShowMenus] = useState(false);
  const [searchitem, setSearchitem] = useState("");
  const [listitem, setListitem] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loadingButtonId, setLoadingButtonId] = useState(null);
  // Offer or Membership
  const [cardStatus, setCardStatus] = useState();
  const [cardType, setcardType] = useState();
  const [cardId, setcardId] = useState();
  const dropUpMenuPress = () => {
    setShowMenus((prevShowMenus) => {
      const newState = !prevShowMenus;
      return newState;
    });
  };

  const location = useLocation();
  const { offer } = location.state || {};
  const closeMenuList = (event) => {
    const menuButton = document.getElementById("menuButton");
    if (menuButton && !menuButton.contains(event.target)) {
      setShowMenus(false);
    }
  };

  useEffect(() => {
    const updatedList = listitem.filter((item) => {
      const hasValidProductName = typeof item.product_name === "string";
      const hasValidProductCategory = typeof item.category_name === "string";

      const productNameMatches =
        hasValidProductName &&
        item.product_name.toLowerCase().includes(searchitem.toLowerCase());
      const productCategoryMatches =
        hasValidProductCategory &&
        item.category_name.toLowerCase().includes(searchitem.toLowerCase());
      const updatedList = listitem.filter((item) => {
        const hasValidProductName = typeof item.product_name === "string";
        const hasValidProductCategory = typeof item.category_name === "string";
        const productNameMatches =
          hasValidProductName &&
          item.product_name.toLowerCase().includes(searchitem.toLowerCase());
        const productCategoryMatches =
          hasValidProductCategory &&
          item.category_name.toLowerCase().includes(searchitem.toLowerCase());
        if (productNameMatches || productCategoryMatches) {
          return true;
        }
        if (searchitem && (!hasValidProductName || !hasValidProductCategory)) {
          console.warn(
            "Item with non-string or missing name/category encountered:",
            item
          );
        }

        return false;
      });
      setFilteredItems(updatedList);
    });
  }, [searchitem, listitem]);

  useEffect(() => {
    setOpenIndices(productlist.map((_, index) => index));
  }, [productlist]);

  useEffect(() => {
    let productCart = localStorage.getItem("productCart")
      ? JSON.parse(localStorage.getItem("productCart"))
      : "";
    setCartId(productCart?.cartId ? productCart?.cartId : "");
    setShipingtype(
      productCart?.shipping_type ? productCart?.shipping_type : ""
    );
    let appliedCard = JSON.parse(localStorage.getItem("appliedCard"));
    setCardStatus(appliedCard?.cardStatus ? appliedCard?.cardStatus : "");
    setcardType(appliedCard?.cardType ? appliedCard?.cardType : "");
    setcardId(appliedCard?.cardId ? appliedCard?.cardId : "");
  }, []);

  useEffect(() => {
    if (shipingtype != "") {
      getproductlist();
    }
  }, [shipingtype]);

  useEffect(() => {
    if (cartId != "") {
      getproductlist();
    }
  }, [cartId]);

  const getproductlist = () => {
    try {
      let reqData = {
        vendor_id: valueParam.vendor_id,
        shipping_type: shipingtype,
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
      };

      axiosInstance
        .post("/user/products/vendor_products_list_without_login", reqData)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            setProductlist(data.result);
            setCardCount(res.data.cart_count);
            setListitem(data.all_products);
          }
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {
            setLoadingStatus(false);
            toast(err.response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "text-danger toast-style-1",
            });
          }
        });
    } catch (error) {
      alert("Tryagin Later");
    }
  };

  const Add_Remove_productcount = async (productId, Operation) => {
    try {
      let reqData = {
        vendor_id: valueParam.vendor_id,
        product_id: productId,
        shipping_type: shipingtype,
        quantity: 1,
        operation: Operation,
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
      };

      axiosInstance
        .post("user/products/add_or_remove_product_cart_without_login", reqData)
        .then((res) => {
          if (res.status == 200) {
            // getproductlist();
            const localcartid = res.data.cart_id;
            let productCart = {
              shipping_type: shipingtype,
              cartId: res.data.cart_id,
              vendorId: valueParam.vendor_id,
            };

            localStorage.setItem("productCart", JSON.stringify(productCart));
            localStorage.setItem("cartid", JSON.stringify(res.data.cart_id));
            setCartId(res.data.cart_id);
            getproductlist();
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            alert(
              `Error: ${err.response.status} - ${err.response.data.message}`
            );
          }
          if (err.response.status == 500) {
            alert(`Error:${err.response.status}-${err.response.message}`);
          }
        });
    } catch (error) {
      alert("try again later");
    }
  };

  const handleClick = (buttonId) => {
    setLoadingButtonId(buttonId);
    setModelOpen(true);
    // setLoadingStatus(true);
    setTimeout(() => {
      setLoadingButtonId(null);
      Add_Remove_productcount(buttonId, "add");
    }, 1000);
    setButtonVisible(false);
    setModalVisible(true); // Show the modal
  };

  function openBookForModal() {
    document.getElementById("selectCustomerModalProduct").classList.add("show");
    setMenuOpen(false)
  }

  function closeBookForModal() {
    var modal = document.getElementById("selectCustomerModalProduct");
    modal.classList.remove("show");
    setMenuOpen(true)
  }

  const check = () => {
    // const localProductDetail = localStorage.getItem("shipping_type") ? JSON.parse(localStorage.getItem("shipping_type")) : "";
    // setShipingtype( localProductDetail?.shipping_type ? localProductDetail?.shipping_type : "" );
  };
  const [openIndices, setOpenIndices] = useState([]);

  const toggleAccordion = (index) => {
    if (openIndices.includes(index)) {
      // If the index is already in openIndices, remove it
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      // Otherwise, add it to openIndices
      setOpenIndices([...openIndices, index]);
    }
  };
  const isItemOpen = (index) => openIndices.includes(index);

  const handleCategoryClick = (index) => {
    const accordionItem = document.getElementById(`collapseOne${index}`);
    if (accordionItem) {
      accordionItem.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const [isModelOpen, setModelOpen] = useState(true);
  const [isMenuOpen, setMenuOpen] = useState(true);
  const handleModelClose = () => {
    // Function to close the model
    setModelOpen(false);
  };

  return (
    <>
      <div className="main-top d-flex flex-column" style={{ overflowY: "auto", maxHeight: "300px" }}>
        <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
          <div className="d-flex justify-content-between align-items-center ms-2 me-2">
            <img
              src={p_nav_previous}
              width={"24px"}
              height={"16px"}
              alt="Previous Button"
              role="button"
              onClick={() => {
                navigate(-1);
                localStorage.removeItem("shipping_type");
                localStorage.removeItem("cartid");
              }}
            />
            <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">Products</div>
          </div>

          {cardCount > 0 ? (
            <div
              onClick={() =>
                navigate("/productBasket", { state: { merchantId, cartId } })
              }
              style={{ cursor: "pointer" }}
            >
              <img src={ion_cart_outline} />
              <div className="basketCount">
                {cardCount > 0 ? (
                  <span
                    style={{
                      height: "20px",
                      width: "20px",
                      backgroundColor: "var(--primary-bg)",
                      color: "white",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {cardCount}
                  </span>
                ) : (
                  cardCount > 0 && <span>{cardCount}</span>
                )}
              </div>
            </div>
          ) : (
            <div>
              <img src={ion_cart_outline} />
            </div>
          )}
        </div>

        <div className="mb-3 p-4" >
          <input
            type="search"
            className="searchInput rounded-pill border-0 ps-3 py-2 pe-3 w-100"
            placeholder="Search"
            onClick={openBookForModal}
          />
        </div>

      </div>

      <div
        className="offcanvas-parentType3 position-relative scroller-style-y"
        id="scroll"
        style={{ overflowY: "auto", maxHeight: "600px" }}
        onClick={closeMenuList}
      >
        <div className="doorStepService p-0">

          <div className="accordion px-3  scroller-style-y" id="accordionExample">

            {productlist?.length > 0 ? (
              productlist?.map((product, index) => {
                return (
                  <div className="accordion-item " key={index}>
                    <h2 className="accordion-header " id={"heading" + index}>
                      <button
                        className={`serviceMenu-but accordion-button ff-poppins-bold fs-16 text-white ${isItemOpen(index) ? "" : "collapsed"
                          }`}
                        type="button"
                        onClick={() => toggleAccordion(index)}
                        aria-expanded={isItemOpen(index) ? "true" : "false"}
                      >
                        <p>
                          {product.category_name}
                        </p>
                        <p>
                          {isItemOpen(index) ? (
                            <IoIosArrowUp style={{ fontSize: 20 }} />
                          ) : (
                            <IoChevronDown style={{ fontSize: 20 }} />
                          )}
                        </p>
                        <style>
                          {""}
                          {`button::after {
                                display: none;
                                position: absolute;
                                content: ''; 
                            }`}
                        </style>
                      </button>
                    </h2>
                    <div
                      id={"collapseOne" + index}
                      className={`accordion-collapse collapse ${isItemOpen(index) ? "show" : ""
                        }`}
                      aria-labelledby={"heading" + index}
                    >
                      <div className="accordion-body">
                        {product?.products?.length > 0 ? (
                          product?.products?.map((item, itemIndex) => (
                            <div key={itemIndex}>
                              <div>
                                <div className="productDiv">
                                  <div className="col-3 ">
                                    <div className="imgBox position-relative productImageDiv">
                                      <img
                                        src={
                                          item.cover_image.image == ""
                                            ? defaultimg
                                            : item.cover_image.image_url
                                        }
                                        className="productImage"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-6 ps-2">
                                    <div className="ff-poppins-semiBold fs-12">
                                      {item.product_name}
                                    </div>
                                    <div>
                                      {item.applied_card_status == "1" ? (
                                        <div className="d-flex ">
                                          <span className="ff-poppins-semiBold fs-12 me-1 ">
                                            Rs.{item.price}{" "}
                                          </span>
                                          <span className="primary-text fs-10">
                                            ({item.benefit_lable}){" "}
                                          </span>
                                        </div>
                                      ) : (
                                        <span className="ff-poppins-semiBold fs-12 me-1 ">
                                          Rs.{item.price}
                                        </span>
                                      )}
                                    </div>
                                    <div className="ff-poppins-regular primary-text fs-9">
                                      {item.unit_quantity} {item.unit_type_name}
                                    </div>
                                    <div className="moreDetails">
                                      <div className="accordion-item">
                                        <h2
                                          className="moreInfo fs-14"
                                          id={"headingSubOne"}
                                        >
                                          <button
                                            className="moreInfobtn ff-poppins-regular fs-10 px-0"
                                            type="button"
                                            onClick={() =>
                                              navigate(
                                                `/singleProductDetails/${merchantId.vendor_id}/${item.product_id}`,
                                                {
                                                  state: {
                                                    productId: item.product_id,
                                                    merchantId,
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            More Details
                                            <BsChevronDown />
                                          </button>
                                        </h2>
                                      </div>
                                    </div>

                                    {item.stock_status == "1" ? (
                                      <>
                                        <p className="ff-poppins-regular primary-text fs-9 m-0">
                                          {item.stock_quantity -
                                            item.added_count}{" "}
                                          left
                                        </p>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div className="col-3 d-flex justify-content-center align-items-end primary-text">
                                    <div className="ff-poppins-regular fs-9 primary-text ">
                                      {item.added_count > 0 ? (
                                        <div>
                                          <div className="row align-items-center w-100 gx-0">
                                            <div className="col-4">
                                              <FiMinusCircle className="w-100 primary-text fontSizeCountIcon" onClick={() => Add_Remove_productcount(item.product_id, "remove")} />
                                            </div>
                                            <div className="col-4 d-flex justify-content-center align-items-end fs-20 primary-text">
                                              {item.added_count}
                                            </div>
                                            <div className="col-4">
                                              <LuPlusCircle className="w-100 primary-text fontSizeCountIcon" onClick={() => Add_Remove_productcount(item.product_id, "add")} />
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          <div className="col-4 selectBtnProductDiv">
                                            <button
                                              className={`selectBtnProduct primary-text fs-12 ff-poppins-semiBold ${loadingButtonId ===
                                                item.product_id ? "loading" : ""}`}
                                              onClick={() => handleClick(item.product_id)}   >
                                              {loadingButtonId ===
                                                item.product_id ? (
                                                // Display a loading spinner while the button is loading
                                                <span className="loader-style-2 fs-12"></span>
                                              ) : (
                                                // Display "Select" when the button is not loading
                                                <span>Select</span>
                                              )}
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                        <></>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
            <></>
          </div>

          <div className="productsHeroDiv  App" style={{ width: "-webkit-fill-available", }}  >
            {showMenus && (
              <ul className="dropdown-menu " id="menuList">
                {productlist.map((cate, index) => (
                  <li key={index}>
                    <a className="dropdown-item d-flex justify-content-between" href="#"  >
                      {cate.category_name}
                      <span>{cate.products.length}</span>
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Review and Pay Modal for selected product  */}
          <div className="">
            {isModelOpen && (
              <div className="productsHeroDiv">
                {cardCount > 0 && (
                  <div className="reviewPayDivs" >
                    <div className="linetop-container" onClick={handleModelClose}>
                      <div
                        className="lineTop text-center primary-text"
                        style={{ borderTop: "3px solid" }}
                      >

                      </div>
                    </div>
                    <div className="d-flex ff-poppins-bold">
                      <p className="primary-text">
                        {cardCount > 1 ? cardCount + " Products" : cardCount + " Product"}
                        <span className="text-dark">added</span>{" "}
                      </p>
                    </div>
                    <button className="reviewAndPayBtnProduct ff-balooPaaji2-bold"
                      onClick={() => navigate("/ReviewPay", { state: { merchantId, cartId, shipingtype }, })} >
                      Review and Pay
                    </button>
                  </div>
                )}
              </div>
            )}

            {isMenuOpen && (
              <div className="menu-div">
                <div className="left-div"></div>
                <div className="right-div">
                  <div className="dropup-center dropup" style={{ zIndex: 9999 }}>
                    <button id="menuButton" className="btn btn-secondary dropdown-toggle menuBtn" type="button" onClick={dropUpMenuPress} style={{ display: showMenus ? "none" : " ", zIndex: 9999 }} >
                      <RxHamburgerMenu className="menuIcon" />
                      Menu
                    </button>
                  </div>
                </div>
                {showMenus && (
                  <div className="menu-list-container scroller-style-y" style={{ zIndex: 9998, maxHeight: "300px", overflowY: "hidden" }}  >
                    <ul className="dropdown-menu show " style={{ maxHeight: "300px", }} id="menuList">
                      {productlist.map((cate, index) => (
                        <li key={index}>
                          <button
                            className="dropdown-item d-flex collapsed justify-content-between"
                            onClick={() => {
                              handleCategoryClick(index);
                            }}
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapseOne" + index}
                            aria-controls={"collapseOne" + index}
                          >
                            {cate.category_name}
                            <span>{cate.products.length}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>)}
          </div>

        </div>

        {/* Select product search modal  */}
        <div
          className="offcanvas serachProduct"
          tabindex="-1"
          id="selectCustomerModalProduct"
          aria-labelledby="selectCustomerModalProductLabel"
          style={{ height: '80vh', position: 'sticky' }}
        >
          <div className="offcanvas-body small">
            <div class="modal-header modal-headerSearch d-flex justify-content-between">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Search
              </h1>
              <button type="button" className="btn-close d-flex justify-content-end" aria-label="Close" onClick={() => { closeBookForModal(); setSearchitem(""); }}></button>
            </div>

            <div className="mb-3">
              <div class="text-white col text-center">
                <form class="search">
                  <button type="button" class="search__button">
                    <i class="fa-solid fa-magnifying-glass primary-text">
                      <IoSearch />
                    </i>
                  </button>
                  <input
                    type="text"
                    placeholder="Search With the product"
                    value={searchitem}
                    onChange={(e) => setSearchitem(e.target.value)}
                  />
                </form>
              </div>
            </div>
            <div>
              {filteredItems.map((item, itemIndex) => (
                // <div>
                //   {/* <li key={item.product_name}>{item.product_name}</li> */}
                //   <div className="productDiv">
                //     <div className="col-3 ">
                //       <div className="imgBox position-relative productImageDiv">
                //         <img
                //           src={
                //             item.cover_image.image == ""
                //               ? defaultimg
                //               : item.cover_image.image_url
                //           }
                //           className="productImage"
                //         />
                //       </div>
                //     </div>

                //     <div className="col-5 ps-0">
                //       <div className="ff-poppins-semiBold fs-14">
                //         {item.product_name}
                //       </div>
                //       <div>
                //         <span className="ff-poppins-semiBold fs-12 me-1">
                //           Rs.{item.price}
                //         </span>
                //         {/* <span className='ff-poppins-medium fs-10 primary-text'>(20% Discount applied)</span> */}
                //       </div>
                //       <div className="ff-poppins-regular primary-text fs-9">
                //         {item.unit_quantity}
                //         {item.unit_type_name} (s)
                //       </div>
                //       <div className="moreDetails">
                //         <div className="accordion" id={"accordionExample1Sub"}>
                //           <div className="accordion-item border-0">
                //             <h2
                //               className="accordion-header"
                //               id={"headingSubOne"}
                //             >
                //               <div className="accordion-item border-0">
                //                 <h2
                //                   className="accordion-header"
                //                   id={"headingSubOne"}
                //                 >
                //                   <button
                //                     className="accordion-button collapsed ff-poppins-regular fs-12"
                //                     type="button"
                //                     onClick={() =>
                //                       navigate("/singleProductDetails", {
                //                         state: {
                //           </div>
                //         </div>
                //       </div>
                //     </div>
                //   </div>
                // </div>                       productId: item.product_id,
                //                           merchantId,
                //                         },
                //                       })
                //                     }
                //                   >
                //                     More Details
                //                   </button>
                //                 </h2>
                //               </div>
                //             </h2>
                //
                <div key={itemIndex}>
                  <div>
                    <div className="productDiv">
                      <div className="col-3 ">
                        <div className="imgBox position-relative productImageDiv">
                          <img
                            src={
                              item.cover_image.image == ""
                                ? defaultimg
                                : item.cover_image.image_url
                            }
                            className="productImage"
                          />
                        </div>
                      </div>

                      <div className="col-6 ps-2">
                        <div className="ff-poppins-semiBold fs-12">
                          {item.product_name}
                        </div>
                        <div>
                          {item.applied_card_status == "1" ? (
                            <div className="d-flex ">
                              <span className="ff-poppins-semiBold fs-12 me-1 ">
                                Rs.{item.price}{" "}
                              </span>
                              <span className="primary-text fs-10">
                                ({item.benefit_lable}){" "}
                              </span>
                            </div>
                          ) : (
                            <span className="ff-poppins-semiBold fs-12 me-1 ">
                              Rs.{item.price}
                            </span>
                          )}
                        </div>
                        <div className="ff-poppins-regular primary-text fs-9">
                          {item.unit_quantity} {item.unit_type_name}
                        </div>
                        <div className="moreDetails">
                          <div className="accordion-item ">
                            <h2 className="moreInfo fs-14" id={"headingSubOne"}>
                              <button
                                className="moreInfobtn ff-poppins-regular fs-10 px-0"
                                type="button"
                                onClick={() =>
                                  navigate(`/singleProductDetails/${merchantId.vendor_id}/${item.product_id}`, {
                                    state: {
                                      productId: item.product_id,
                                      merchantId,
                                    },
                                  })
                                }
                              >
                                More Details
                                <BsChevronDown />
                              </button>
                            </h2>
                          </div>
                        </div>

                        {item.stock_status == "1" ? (
                          <>
                            <p className="ff-poppins-regular primary-text fs-9 m-0">
                              {item.stock_quantity - item.added_count} left
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-3 d-flex justify-content-center align-items-end primary-text">
                        <div className="ff-poppins-regular fs-9 primary-text ">
                          {item.added_count > 0 ? (
                            <div>
                              <div className="row align-items-center w-100 gx-0">
                                <div className="col-4">
                                  <FiMinusCircle
                                    className="w-100 fs-24 primary-text"
                                    onClick={() =>
                                      Add_Remove_productcount(
                                        item.product_id,
                                        "remove"
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-4 d-flex justify-content-center align-items-end fs-20 primary-text">
                                  {item.added_count}
                                </div>
                                <div className="col-4">
                                  <LuPlusCircle
                                    className="w-100 fs-24 primary-text"
                                    onClick={() =>
                                      Add_Remove_productcount(
                                        item.product_id,
                                        "add"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="col-4 selectBtnProductDiv">

                                <button
                                  className={`selectBtnProduct primary-text fs-12 ff-poppins-semiBold ${loadingButtonId ===
                                    item.product_id
                                    ? "loading"
                                    : ""
                                    }`}

                                  onClick={() => {
                                    handleClick(item.product_id)
                                    Add_Remove_productcount(
                                      item.product_id,
                                      "add"
                                    );
                                  }}
                                >
                                  {loadingButtonId ===
                                    item.product_id ? (
                                    <span className="loader-style-2  fs-12"></span>
                                  ) : (
                                    // <span>Select</span>
                                    <span className="fs-12">Select</span>
                                  )}

                                </button>

                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* <div className="dropup-center dropup">
              <button
                className="btn btn-secondary dropdown-toggle menuBtn"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <RxHamburgerMenu className="menuIcon" />
                Menu
              </button>
              <ul className="dropdown-menu">
                {productlist.map((cate, index) => {
                  return (
                    <li>
                      <a
                        className="dropdown-item d-flex justify-content-between"
                        href="#"
                      >
                        {cate.category_name}
                        <span> {cate.products.length}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div> */}

      </div>
    </>
  );
};

export default Products;
