import { useState } from 'react';
import p_nav_previous from '../../../Assets/Images/p_nav_previous.svg';
import p_right_arrow from '../../../Assets/Images/p_right_arrow.svg';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMyProfileDetails } from '../../../Services/api';

const EditProfileMenu = () => {

    const navigate = useNavigate();
    const [userName, setUserName] = useState('');

    useEffect(() => {
        getMyProfileDetails().then((res) => {
            setUserName(res.data?.user_details?.user_name);
        });
    }, []);

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>Profile Edit</div>
                </div>
            </div>

            <div className='profileEdit scroller-style-y'>
                <div className='m-2 p-1'>
                    <div className='mx-3 px-2 my-3 py-2'>
                        <div className='ff-poppins-semiBold fs-20'>Welcome</div>
                        <div className='ff-poppins-regular fs-20'>{userName}</div>
                    </div>

                    <button className='border-0 w-100 primary-bg d-flex justify-content-between' onClick={() => navigate('/edit-profile/user')}>
                        <div className='text-start text-white'>
                            <div className='ff-poppins-regular fs-12'>PROFILE</div>
                            <div className='ff-poppins-semiBold fs-18'>EDIT PHOTO/ INFO</div>
                        </div>
                        <img src={p_right_arrow} width={'28px'} height={'40px'} alt='Next Button' role='button' />
                    </button>

                    <button className='border-0 w-100 primary-bg d-flex justify-content-between' onClick={() => navigate('/edit-profile/email-verify')}>
                        <div className='text-start text-white'>
                            <div className='ff-poppins-regular fs-12'>VERIFY</div>
                            <div className='ff-poppins-semiBold fs-18'>EMAIL ADDRESS</div>
                        </div>
                        <img src={p_right_arrow} width={'28px'} height={'40px'} alt='Next Button' role='button' />
                    </button>

                    <button className='border-0 w-100 primary-bg d-flex justify-content-between' onClick={() => navigate('/edit-profile/aadhar')}>
                        <div className='text-start text-white'>
                            <div className='ff-poppins-regular fs-12'>AADHAR CARD</div>
                            <div className='ff-poppins-semiBold fs-18'>ADD COPY</div>
                        </div>
                        <img src={p_right_arrow} width={'28px'} height={'40px'} alt='Next Button' role='button' />
                    </button>
                </div>
            </div>
        </>
    )
}

export default EditProfileMenu