import { useNavigate } from 'react-router-dom';
import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import p_map_location_arrow from '../../Assets/Images/p_map_location_arrow.svg';
import p_search_location_arrow_secondary from '../../Assets/Images/p_search_location_arrow_secondary.svg';
import p_address_home from '../../Assets/Images/p_address_home.svg';
import p_address_others from '../../Assets/Images/p_address_others.svg';
import p_address_work from '../../Assets/Images/p_address_work.svg';
import p_address_delete from '../../Assets/Images/p_address_delete.svg';
import p_address_time from '../../Assets/Images/p_address_time.svg';
import { useEffect, useState } from 'react';
import { addRecentLocation, clearRecentLocations, getMyLocations, removeMySavedLocation } from '../../Services/api';

const FindLocation = () => {
  const navigate = useNavigate();
  const [savedLocations, setSavedLocations] = useState([]);
  const [recentLocations, setRecentLocations] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const service = new window.google.maps.places.AutocompleteService();
  const geocoder = new window.google.maps.Geocoder();

  useEffect(() => {
    getLocations();
  }, []);

  function getLocations() {
    getMyLocations().then((res) => {
      setSavedLocations(res.data?.saved_locations);
      setRecentLocations(res.data?.recent_locations);
    });
  }

  const displaySuggestions = function (predictions, status) {

    if (predictions) {
      setSearchResults(predictions)
    }
    if (
      status != window.google.maps.places.PlacesServiceStatus.OK ||
      !predictions
    ) {
      alert(status);
      return;
    }
  }

  function deleteSavedLocation(locationId) {
    let param = {
      "location_id": locationId
    }
    removeMySavedLocation(param).then((res) => {
      
      if(res.data?.status == '1') {
        getLocations();
      }
    });
  }


  const handleChange = (event) => {
    const { name, value } = event.target;

    if (event.target.value != "") {
      service.getQueryPredictions(
        { input: event.target.value },
        displaySuggestions
      );
    } else {
      setSearchResults([]);
    }
  };

  const handlePlaceSelect = async (place) => {
    if (!place.place_id) {
      return;
    }

    geocoder
      .geocode({ placeId: place.place_id })
      .then(({ results }) => {
        console.log(results[0].geometry.location);
        let location = results[0].geometry.location;
        navigate("/map-view", {
          state: { mapLat: location.lat(), mapLng: location.lng(), isFromFindLocation: true },
        });
      })
      .catch((e) => window.alert("Geocoder failed due to: " + e));
  };
  const handleDeleteAddress = (idToDelete) => {
    const updatedSavedLocations = savedLocations.filter(item => item.id !== idToDelete);
    setSavedLocations(updatedSavedLocations);
  };

  function selectLocation(item) {
    let param = {
      "latlng": item.latlng,
      "select_address": item.address,
      "select_locality": item.locality
    }
    addRecentLocation(param).then((res => {

      if (res.data?.status == '1') {
        navigate(-1);
      }
    }))
  }

  function clearMyRecentLocation() {
    clearRecentLocations().then((res) => {
      
      if (res.data?.status == '1') {
        setRecentLocations([]);
      }
    })
  }

  return (
    <>
      <div className="findLocation p-3">
        <div className="d-flex align-items-center ms-2 me-2">
          <img
            src={p_nav_previous}
            width={"24px"}
            height={"16px"}
            alt="Previous Button"
            role="button"
            onClick={() => navigate(-1)}
          />
          <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
            Find Location
          </div>
        </div>

        <div className="pt-3 pb-3 serachArea">
          <input
            type="search"
            className="w-100 ff-poppins-semiBold fs-16"
            placeholder="Try PN Palaiyam, Coimbatore"
            onChange={handleChange}
          />

          <div className="d-flex align-items-center mt-3 pt-1 pb-1">
            <img
              src={p_map_location_arrow}
              width={"16px"}
              height={"18px"}
              alt="Location Button"
              role="button"
            />
            <div
              className="ff-poppins-semiBold fs-13 primary-text ms-2 pe-1"
              role="button"
              onClick={() =>
                navigate("/map-view", { state: { mapLat: null, mapLng: null, isFromFindLocation: true } })
              }
            >
              Use my current location
            </div>
          </div>
        </div>

        <div className='bodyContent scroller-style-y'>
          {searchResults.length > 0 && (
            <div className="searchResults">
              <div className="ff-poppins-light fs-13 addressHeading">SEARCH RESULT</div>

              <ul className="m-0 p-0">
                {searchResults?.map((item) => (
                  <li className="mb-3" role='button' onClick={() => handlePlaceSelect(item)}>
                    <div className="d-flex">
                      <img
                        src={p_search_location_arrow_secondary}
                        width={"14px"}
                        height={"14px"}
                        alt="Location Button"
                        role="button"
                      />
                      <div className="ff-poppins-semiBold fs-12 title ms-2 ps-1">
                        {item?.structured_formatting.main_text}
                      </div>
                    </div>
                    <div className="ff-poppins-light fs-11 ">
                      {item?.description}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {
            /* {
                        (savedLocations.length > 0 && searchResults.length == 0)
                        &&
                        <div className='savedAddress'>
                            <div className='ff-poppins-light fs-13 heading'>SAVED ADDRESSES</div>
    
                            <ul className='m-0 p-0'>
                                {
                                    savedLocations.map(item => (
                                        <li>
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex'>
                                                    <img src={p_address_home} width={'18px'} height={'18px'} alt='Address type' role='button' />
                                                    <div className='ff-poppins-semiBold fs-12 ms-2 ps-1'>{item.location_type}</div>
                                                </div>
                                                <img src={p_address_delete} width={'18px'} height={'18px'} alt='Address type' role='button'
                                                onClick={() => deleteSavedLocation(item.id)}  />
    
                                            </div>
                                            <div className='ff-poppins-light fs-11 '>
                                                {item.address}
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    } */
          }

          {savedLocations.length > 0 && searchResults.length === 0 && (
            <div className="savedAddress">
              <div className="ff-poppins-light fs-13 addressHeading">
                SAVED ADDRESSES
              </div>

              <ul className="m-0 p-0">
                {savedLocations.map((item, index) => (
                  <li key={index} role='button'>
                    <div className="d-flex justify-content-between align-items-center py-2">
                      <div className="d-flex align-items-center" onClick={() => selectLocation(item)}>
                        {
                          item.location_type_id == '1' &&
                          <img
                            src={p_address_home}
                            width={"18px"}
                            height={"18px"}
                            alt="Address type"
                            role="button"
                          />
                        }
                        {
                          item.location_type_id == '2' &&
                          <img
                            src={p_address_work}
                            width={"18px"}
                            height={"18px"}
                            alt="Address type"
                            role="button"
                          />
                        }

                        {
                          item.location_type_id == '3' &&
                          <img
                            src={p_address_others}
                            width={"18px"}
                            height={"18px"}
                            alt="Address type"
                            role="button"
                          />
                        }
                        <div className="ff-poppins-semiBold fs-12 ms-2 ps-1">
                          {item.location_type}
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <img
                          src={p_address_delete}
                          width={"18px"}
                          height={"18px"}
                          alt="Delete address"
                          role="button"
                          onClick={() => deleteSavedLocation(item.id)}
                        />
                      </div>
                    </div>
                    <div className="ff-poppins-light fs-11 " onClick={() => selectLocation(item)}>{item.address}</div>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {recentLocations.length > 0 && searchResults.length == 0 && (
            <div className="recentLocation">
              <div className="d-flex justify-content-between">
                <div className="ff-poppins-light fs-13 addressHeading">
                  RECENT LOCATION
                </div>
                <div className="ff-poppins-light fs-13 addressHeading" role='button' onClick={clearMyRecentLocation}>CLEAR ALL</div>
              </div>

              <ul className="m-0 p-0">
                {recentLocations.map((item) => (
                  <li className="mb-3" role='button' onClick={() => selectLocation(item)}>
                    <div className="d-flex">
                      <img
                        src={p_address_time}
                        width={"16px"}
                        height={"16px"}
                        alt="Address type"
                        role="button"
                      />
                      <div className="ff-poppins-semiBold fs-12 ms-2 ps-1">
                        {item.locality}
                      </div>
                    </div>
                    <div className="ff-poppins-light fs-11 ">{item.address}</div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
//   }}
export default FindLocation;
