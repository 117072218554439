import React, { useCallback, useEffect, useState } from 'react';
import '../../Assets/Styles/offCanvas.css';
import { cartAddServiceTimeslot, checkValidCart, confirmBooking, getMyProfileDetails, serviceTimeSlotDetailsNew } from '../../Services/api';
import { useNavigate, useParams } from 'react-router-dom';
//firebase
import { getDatabase, ref, set, onValue } from "firebase/database";
import database from '../../Services/firebase-service/firebase';
import ReactLoading from 'react-loading';
import useRazorpay from "react-razorpay";
import axiosInstance from '../../Services/axios';
import OfferCard from '../../Pages/Offers/offercard';
import { toast } from 'react-toastify';
import MembershipCard from '../MembershipCard/MembershipCard';

const MembershipSearch = ({ }) => {

    const navigate = useNavigate();
    const [searchData, setSearchData] = useState("");
    const [membershipDetailData, setMembershipDetailData] = useState([]);
    const [merchantServiceId, setMerchantServiceId] = useState(0);
    const [latlong, setLatLong] = useState("");
    const [serviceTypeFilterData, setServiceTypeFilterData] = useState([])
    const [citySelectedVal, setCitySelectedVal] = useState("");
    const [apiSearchingDataLoader, setApiSearchingDataLoader] = useState(true);
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        getFilterData()
    }, []);

    useEffect(() => {
        getMembershipDetails()
    }, [latlong, merchantServiceId, searchData])



    const getFilterData = async () => {
        try {
            const response = await axiosInstance.post('/user/get_search_fillter');
            if (response.status == 200) {
                const data = response?.data
                console.log(`data`, data);
                setServiceTypeFilterData(data.vendor_type)
                setLatLong(data.selected_location.latlng)
                setCitySelectedVal(data.selected_location.address);
                setPageLoaded(true)
                setApiSearchingDataLoader(true)
                // getOfferDetails()
                // setRecentSecarchOnOFF(false)

            }
        } catch (err) {
            console.log(`err`, err);
        }
    }

    const getMembershipDetails = async () => {
        try {
            console.log(searchData);

            let params = {
                user_location: latlong,
                search_name: searchData, // search offer name
                search_type: merchantServiceId, // search vendor type
                search_category: '' // search vendor category
            }
            const response = await axiosInstance.post('/user/search_vendor_membership', params)
            const data = await response
            setMembershipDetailData(response.data.search_results)
            setPageLoaded(true)
        } catch (error) {
            console.log(error);
        }
    }

    const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };

    // let renderOfferCardItem = ({ item, index }) => {
    //     return (<>
    //         <Pressable onPress={() => {
    //             navigation.navigate("ViewOfferDetails",
    //                 {
    //                     applied_to_status: item?.applied_to_status,
    //                     vendor_id: item?.vendor_id,
    //                     offer_id: item?.offer_id
    //                 })
    //         }}>
    //             <View style={{ marginTop: 10, paddingVertical: 10, marginHorizontal: 10 }}>
    //                 <View style={[styles.firstCard, { borderRadius: 10, flex: 1, flexDirection: 'row', zIndex: 111, backgroundColor: "#fff", }]}>
    //                     <View style={{ width: 105, height: 148 }}>
    //                         <Image source={{ uri: item?.offers_image }} style={{ height: "100%", width: "100%", resizeMode: "contain", borderRadius: 10 }}></Image>
    //                     </View>
    //                     <View style={{ width: screenWidth - 150, paddingHorizontal: 10 }}>
    //                         <Text style={{ fontSize: 16, fontFamily: FontFamily.poppinsSemibold, color: "#000a", lineHeight: 20, marginRight: 30 }}>{item?.offer_name}</Text>
    //                         {
    //                             item.offer_type == 3 ? <Text style={{ fontSize: 12, fontFamily: FontFamily.poppinsMedium, color: PRIMARY_BACKGROUND }}>
    //                                 Buy Get Offer
    //                             </Text> : <Text style={{ fontSize: 12, fontFamily: FontFamily.poppinsMedium, color: PRIMARY_BACKGROUND }}>
    //                                 Get {item?.discount_amount}
    //                                 <Text style={{ fontFamily: "arial" }}>{Number(item?.discount_type) == 1 ? "₹ " : Number(item?.discount_type) == 2 ? "% " : ''}</Text>
    //                                 Offer
    //                             </Text>
    //                         }
    //                         <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }}>
    //                             <Text style={{ fontSize: 16, fontFamily: FontFamily.poppinsSemibold, color: PRIMARY_BACKGROUND, lineHeight: 20 }}>{item?.vendor_company_name}</Text>
    //                             <View style={{ flexDirection: 'row', }}>
    //                                 <Ionicons name="location-sharp" size={11} color={PRIMARY_BACKGROUND} style={{ marginLeft: 5, marginTop: -2 }} />
    //                                 <Text style={{ fontSize: 10, fontFamily: FontFamily.poppinsRegular, color: PRIMARY_BACKGROUND, lineHeight: 12 }}>{item?.distance} Km</Text>
    //                             </View>
    //                         </View>
    //                         <View style={{ width: "100%", height: 1, backgroundColor: "#0002", marginVertical: 2 }}></View>
    //                         <Text style={{ fontSize: 10, fontFamily: FontFamily.poppinsRegular, color: "#000", marginTop: 5 }}>{item?.offer_description ? (item?.offer_description?.length > 100 ? item?.offer_description?.substring(0, 100) + "..." : item?.offer_description) : "Your offer discription will be here. "}</Text>
    //                         {/* {
    //                             item.offer_type == 2 ?
    //                                 <>
    //                                     <Text style={{ fontSize: 16, fontFamily: FontFamily.poppinsSemibold, color: PRIMARY_BACKGROUND, lineHeight: 18 }}>Rs.{item?.applied_to.includes('1') ? serviceCost.totalPrice.toFixed(2) : productsCost.totalPrice.toFixed(2)}</Text>
    //                                     <Text style={{ fontSize: 10, fontFamily: FontFamily.poppinsRegular, color: "#000", lineHeight: 12 }}>Actual Cost : <Text style={{ textDecorationLine: 'line-through', textDecorationColor: '#f00' }}>{item?.applied_to.includes('1') ? serviceCost.subTotal.toFixed(2) : productsCost.subTotal.toFixed(2)}</Text>/-</Text>
    //                                 </> : <></>
    //                         } */}
    //                         <View style={{ flexDirection: 'row', justifyContent: 'space-between', position: 'absolute', bottom: 0, left: 10, width: '100%' }}>
    //                             <Text style={{ fontSize: 10, fontFamily: FontFamily.poppinsRegular, color: PRIMARY_BACKGROUND, lineHeight: 12 }}>Expire Date : {new Date(item?.duration).toLocaleDateString('en-US', dateOptions)}</Text>
    //                         </View>
    //                     </View>
    //                 </View>
    //             </View>
    //         </Pressable>
    //     </>)
    // }

    return (
        <div className='searchModal'>
            <input type="search"
                className="ff-poppins-semiBold fs-16 w-100"
                placeholder="Search Membership"
                onChange={(event) => { console.log(`event`, event.target.value);; setSearchData(event.target.value) }}
            // value={searchData}
            />

            <div className="searchFilter scroller-style-x mt-4 mb-4">
                {serviceTypeFilterData && serviceTypeFilterData.map((item) => {
                    return (
                        <div className={(merchantServiceId == item.vendor_type_id ? "active " : " ") + "d-flex align-items-center"}
                            role="button"
                            onClick={() => {
                                setMerchantServiceId(item.vendor_type_id);
                            }}>
                            {item.vendor_type_name}
                        </div>);
                })}
            </div>
            <div className='scroller-style-y'>

                {membershipDetailData && membershipDetailData.length > 0 ? membershipDetailData.map((membership, index) => {

                    return (
                        <div key={index} className="" >
                            <button
                                className="MembershipDetailButton"
                                onClick={() => {
                                    // if (!token) {
                                    //     handleShow();
                                    //     return;
                                    // }
                                    navigate(
                                        `/MemberShipDetails/${membership.vendor_id}/${membership.membership_id}`,
                                        {
                                            state: {
                                                vendorId: membership.vendor_id,
                                                membership_ID: membership.membership_id,
                                            },
                                        }
                                    );
                                }} 
                                style={{ backgroundColor: '#fff' }}
                            >
                                {/* {console.log(vendorId, "gaby!!!!!!", membershipID)} */}
                                <MembershipCard 
                                
                                    buttonName="Buy Now"
                                    icon
                                    cardColor={membership.card_color}
                                    data={{
                                        membershipDetails: membership,
                                        VendorDetails: { vendor_company_name: membership.vendor_company_name },
                                    }}
                                />
                            </button>
                        </div>
                    )
                }) :
                    <p className="ff-poppins-regular fs-15 p-3 text-center">
                        No membership cards
                    </p>
                } 
                <div style={{height:180}}></div>
            </div>
        </div>
    )
};

export default MembershipSearch;