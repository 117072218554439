import { Link, useNavigate } from 'react-router-dom';
import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import { FaArrowRight } from "react-icons/fa6";
import { IoQrCodeOutline } from "react-icons/io5";
import { useEffect, useRef, useState } from 'react';
import Scanner from '../../Components/Scanner';

const QRCodeScan = () => {

    const navigate = useNavigate();

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>QR Code Scan check</div>
                </div>
            </div>

            <div className='d-flex flex-column justify-content-between scroller-style-y'>
                <div className='ff-poppins-regular d-flex flex-column justify-content-center align-items-center h-25 px-3'>
                    Scan the QR Code to find the merchant
                </div>
                <div className='h-50 secondary-bg'>
                    <Scanner style={{ width: '100%', height: '100vh' }}/>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center mx-3 h-25'>
                    <div className='ff-poppins-regular d-flex '>
                        <span>
                            If code can not be scanned, please enter the Merchant ID to proceed.
                        </span>
                        <span className='mx-3 d-flex flex-column justify-content-center align-items-center'>
                            <Link to={'/SearchMerchant'} className='text-black'> <FaArrowRight className='fs-2' /> </Link>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QRCodeScan;