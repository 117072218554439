import React, { useEffect, useRef, useState } from "react";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Pingle_coin from "../../Assets/Images/Pingle _UserCoin.png"
import { FaArrowRightLong } from "react-icons/fa6";
import axiosInstance from "../../Services/axios";
import ImageGallery from "react-image-gallery";
import 'react-image-gallery/styles/css/image-gallery.css';
import ReactLoading from "react-loading";
import { getMyLocations } from "../../Services/api";
import { MdWork } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { CgCloseO } from "react-icons/cg";
import { IoIosArrowForward } from "react-icons/io";
import { FaRegSquarePlus } from "react-icons/fa6";
import defaultimg from "../../Assets/Images/default-placeholder.png"
const SingleRefrelPointsdetail = () => {
    const valueParam = useParams();
    const navigate = useNavigate()
    const [data, setData] = useState()
    const [coverimg, setCoverimg] = useState([])
    const [vendor, setVendor] = useState()
    const [pageLoaded, setPageLoaded] = useState(false);
    const [cartid, setcartid] = useState()
    const [service, setService] = useState()
    const [loyaltyid, setLoyaltyid] = useState()
    const [datastatus, setDatastatus] = useState()
    const [locationsList, setLocationsList] = useState([]);
    const [btnLoader, setBtnLoader] = useState(false)
    const [vendorId, setVendorId] = useState()
    const locationModalRef = useRef(null);
    const [loadstatus, setLoadstatusl] = useState(false);
    const orderSuccessModalRef = useRef(null);
    const place = useLocation()
    const { flowparent, fromPage, latitude } = place.state || {}

    useEffect(() => {

        let localloyaltyDetail = localStorage.getItem('loyaltyDetail') ? JSON.parse(localStorage.getItem('loyaltyDetail')) : ''
        setLoyaltyid(localloyaltyDetail?.offerId)
        setVendorId(localloyaltyDetail?.vendorid)

    }, [flowparent, fromPage])
    // useEffect(() => {
    //     confirmRedeem()
    // }, [fromPage])
    useEffect(() => {
        getloyaltydetails()
    }, [loyaltyid])

    const openOrderSuccessModal = () => {
        orderSuccessModalRef.current.classList.add("show");
        orderSuccessModalRef.current.style.display = "block";
    };

    // datastatus == 1  referral_loyalty ,datastatus == 2 vendor_loyalty
    const getloyaltydetails = () => {
        try {
            let reqData = {
                vendor_id: valueParam.vendorId,
                offer_id: loyaltyid
            }
            axiosInstance.post('user/my_referral_loyalty_single_offer_detail', reqData)
                .then((res) => {
                    setPageLoaded(true);
                    console.log('ressss', res);
                    if (res.data && res.data.offer_detail) {
                        setData(res.data.offer_detail);
                    }
                }).catch((err) => {
                    if (err.response.status == 400) {
                        setPageLoaded(false)
                    }
                    if (err.response.status == 500) {
                        setPageLoaded(false)
                    }
                });
        }
        catch {

        }
    }
    const handelLocation = () => {

        getMyLocations().then((res) => {
            setLocationsList(res.data?.saved_locations)
            openBookForModal()
        });
    }
    function closeBookForModal() {
        var modal = document.getElementById("selectCustomerModalProductRP");
        modal.classList.remove("show");
    }

    function openBookForModal() {
        document
            .getElementById("selectCustomerModalProductRP")
            .classList.add("show");
    }
  
    const confirmRedeem = (latlng) => {
        try {
            let reqData = {
                "delivery_adderss_latlng": latlng,
                "offer_id": loyaltyid
            }

            axiosInstance.post('/user/redeem_referral_loyalty_offer', reqData)
                .then((res) => {
                    setLoadstatusl(false)
                    openOrderSuccessModal();
                })
        }
        catch {

        }
    }


    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img
                        src={p_nav_previous}
                        width={"24px"}
                        height={"16px"}
                        alt="Previous Button"
                        role="button"
                        onClick={() => navigate(-1)}
                    />

                    <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">Offer</div>
                </div>
            </div>
            <div>
                {
                    pageLoaded ?
                        <div>
                            <div className="scroller-style-y position-relative">
                                <div className="p-3">
                                    {
                                        datastatus == '1' ?
                                            <h3 className="ff-poppins-semiBold">Pingle </h3> :
                                            <h3 className="ff-poppins-semiBold">{vendor?.vendor_name} </h3>
                                    }
                                    <h6>Loyalty Offer</h6>
                                    <div className="p-2">
                                        <div className="bg-white p-1 rounded">
                                            <div id="myCarousel" class="carousel slide custom-carousel" data-bs-ride="carousel">
                                                <div class="carousel-inner">
                                                    {
                                                        coverimg.length > 0 ?
                                                            coverimg.map((item, index) => (
                                                                <div key={index} className={(index == '0' ? 'active' : '') + ' carousel-item'}>
                                                                    <img src={item} className="d-block w-100" alt="item.img_id" />
                                                                </div>
                                                            )) :
                                                            // <div key={index} className={(index == '0' ? 'active' : '') + ' carousel-item'}>
                                                            <img src={defaultimg} className="d-block w-100" alt="item.img_id" />


                                                    }
                                                </div>
                                                <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span class="visually-hidden">Previous</span>
                                                </button>
                                                <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span class="visually-hidden">Next</span>
                                                </button>
                                            </div>

                                            <div className="p-2">
                                                <h2 className="ff-poppins-semiBold fs-20">{data?.offer_name}</h2>
                                                <p className="fs-13">LID :{data?.offer_id} </p>
                                                <p className="fs-12">
                                                    {data?.offfer_description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="position-sticky bottom-0 secondary-bg">
                                    <div className="d-flex justify-content-center container-fluid ClaimBtnBox p-3 align-items-center "
                                        onClick={() => handelLocation()}>
                                        <div className="primary-bg rounded text-light col-12  ps-0 pe-0 ">
                                            <div className="d-flex ">
                                                <div className="d-flex  justify-content-evenly borderline align-items-center col-3">
                                                    <img src={Pingle_coin} style={{ width: 30, height: 30, borderColor: '#fff', borderRadius: 15, borderWidth: 1 / 2 }} />
                                                    {<p className="ff-poppins-bold mt-3 ">{data?.offer_price} </p>}

                                                </div>
                                                <div className="col-9  ">
                                                    {
                                                        loadstatus == false ?
                                                            <div className="d-flex mt-3 justify-content-around">
                                                                <div className="">Claim This item</div>
                                                                <div className="ff-poppins-bold"><FaArrowRightLong /></div>
                                                            </div>
                                                            :
                                                            <div class="spinner-border text-light d-flex justify-content-center" role="status">
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className=" d-flex justify-content-center align-items-center">
                            <ReactLoading type={"spinningBubbles"} color={"#4E26A3"} />
                        </div>
                }
            </div>
            <div className="offcanvas offcanvas-bottomm Chooseaddres main"
                tabIndex="-1"
                id="selectCustomerModalProductRP"
                aria-labelledby="offcanvasBottomLabel"
                data-bs-backdrop="false"
                ref={locationModalRef}

                style={{ maxHeight: '70vh', position: 'absolute' }}>
                <div className="offcanvas-body small bg-light position-relative">
                    <div className="d-flex justify-content-between">
                        <div className="ff-poppins-semiBold">
                            <h5>Choose a delivery address</h5>
                        </div>
                        <div onClick={() => closeBookForModal()}>

                            <CgCloseO />
                        </div>
                    </div>
                    <div style={{}}>
                        {locationsList.map((location, index) => {
                            return (
                                <div >
                                    <div className="d-flex align-items-center mt-4" onClick={() => { confirmRedeem(location.latlng); closeBookForModal(); setLoadstatusl(true) }}
                                        style={{ opacity: location.select_status == 0 ? 0.4 : 1 }} >
                                        <div className="col-2">
                                            <div className="border w-100 border-black border-opacity-10 rounded d-flex justify-content-center fs-24 primary-text py-2">
                                                {location.location_type_id == 1 ? (
                                                    <IoMdHome className="primary-text" />
                                                ) : location.location_type_id == 2 ? (
                                                    <MdWork className="primary-text" />
                                                ) : (
                                                    <IoLocationSharp />
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-9">
                                            <p className="m-0 ps-3 ff-poppins-bold">
                                                {location.location_type}{" "}
                                            </p>
                                            <p className="m-0 ps-3 ff-poppins-regular fs-12 addersClamped-text">
                                                {location.address}
                                            </p>
                                        </div>
                                        <div className="col-1">
                                            <IoIosArrowForward />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div onClick={() => { navigate("/AddDeliveryAddress", { state: { fromPage: 'loyalty', } }); }} >
                        <div className="d-flex align-items-center mt-4">
                            <div className="col-2">
                                <div className="w-100  d-flex justify-content-center fs-24 primary-text py-2">
                                    <FaRegSquarePlus />
                                </div>

                            </div>
                            <div className="col-9">
                                <p className="m-0 ps-3 ff-poppins-bold primary-text">Add New Address</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* claim Modal  */}

            <div className={`modal fade`}
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                ref={orderSuccessModalRef}
                data-bs-backdrop="false"
                style={{ backgroundColor: "#fff" }}>
                <div class="modal-dialog modal-dialog-centered m-0 bg-white">
                    <div class="modal-content">
                        <div class="modal-body p-2">
                            <div className="py-3">
                                <div className='text-center ff-poppins-semiBold fs-1 mb-3 primary-text'>Yayyy!!!</div>
                                <div className='text-center ff-poppins-semiBold fs-1 mb-3 primary-text'>Congratulation</div>
                            </div>
                            <div className="d-flex p-navbar rounded my-3">
                                <div className="col-5">
                                    <img src={coverimg[0]} className="slofferImg rounded-start" />
                                </div>
                                <div className="col-7 d-flex flex-column align-items-center justify-content-center px-1">
                                    <h4 className="elipseLoyalty ff-poppins-bold m-0">{data?.offer_name}</h4>
                                    <div className='text-center ff-poppins-regular fs-12 elipseLoyalty'>   {
                                        datastatus == 1 ?
                                            data?.offfer_description
                                            :
                                            data?.offer_discription
                                    }</div>
                                </div>
                            </div>
                            <div className='text-center ff-poppins-regular fs-12 py-3'>It will be delivered within the next three working days in your place</div>

                            <div className='d-flex justify-content-center mt-3'>
                                <button type="button" class="btn primary-bg text-white ff-poppins-semiBold" data-bs-dismiss="modal" onClick={() => navigate('/PinglePoints')}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SingleRefrelPointsdetail;