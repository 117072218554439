import { useState } from 'react';
import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import { followUnfollowDetails, getMyProfileDetails, getVendorProfile, notificationCounts, getVendorProfileWithoutLogin, getVendorLoyalty } from '../../Services/api';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import p_storeprofile_history from '../../Assets/Images/p_storeprofile_history.svg';
import p_storeprofile_follow from '../../Assets/Images/p_storeprofile_follow.svg';
import p_storeprofile_share from '../../Assets/Images/p_storeprofile_share.svg';
import p_dash_call from '../../Assets/Images/p_dash_call.svg';
import p_dash_text from '../../Assets/Images/p_dash_text.svg';
import p_right_arrow from '../../Assets/Images/p_right_arrow.svg';
import p_vendor_map from '../../Assets/Images/p_vendor_map.svg';
import p_storeprofile_logo_background from '../../Assets/Images/p_storeprofile_logo_background.svg';
import p_storeprofile_logo from '../../Assets/Images/p_storeprofile_logo.svg';
import p_navbars from '../../Assets/Images/p_navbars.svg';
import p_nav_search from '../../Assets/Images/p_nav_search.svg';
import p_search_location from '../../Assets/Images/p_search_location.svg';
import p_nav_location from '../../Assets/Images/p_nav_location.svg';
import p_nav_next_outline from '../../Assets/Images/p_nav_next_outline.svg';
import p_nav_book_outline from '../../Assets/Images/p_nav_book_outline.svg';
import p_nav_message_outline from '../../Assets/Images/p_nav_message_outline.svg';
import p_nav_notifications_outline from '../../Assets/Images/p_nav_notifications_outline.svg';
import p_nav_about_outline from '../../Assets/Images/p_nav_about_outline.svg';
import p_nav_setting_outline from '../../Assets/Images/p_nav_setting_outline.svg';
import { toast } from 'react-toastify';
import axiosInstance from "../../Services/axios";

const VendorProfile = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const valueParam = useParams();
    const [profile, setProfile] = useState();
    const [profileData, setProfileData] = useState('');
    const [merchantId, setMerchantId] = useState();
    const [followStatus, setFollowStatus] = useState(0);
    const [followShowMessage, setFollowShowMessage] = useState('');
    const token = localStorage.getItem("token");
    const [notificationcount, setNotificationcount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(false);
    const [copied, setCopied] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [loyalty, setLoyalty] = useState()
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    useEffect(() => {
        if (token) {
            getMyProfileDetails().then((res) => {
                setProfileData(res.data?.user_details);

            });

            getVendorProfile(valueParam).then((res) => {
                setProfile(res.data);
                setMerchantId(res.data.vendor_id);
                setFollowStatus(res.data.follow_status);
                setFollowShowMessage(res.data.follow_show_message);
                console.log('res data====>>>>', res.data);
            }).catch((error) => {


            });
            setPageLoaded(true)
            getNotificationsCount()
            getVendorLoyalty(valueParam).then((res) => {
                setLoyalty(res.data)

            })
        } else {
            getVendorProfileWithoutLogin(valueParam).then((res) => {
                setProfile(res.data);
                setMerchantId(res.data.vendor_id);
                setFollowStatus(res.data.follow_status);
                setFollowShowMessage(res.data.follow_show_message);
            }).catch((error) => {

            });;

            setPageLoaded(true)
        }

        localStorage.setItem('vendorUrl', location.pathname);
        localStorage.removeItem('serviceDetail');
        localStorage.removeItem('shipping_type');
    }, []);

    useEffect(() => {
        if (token) {
            try {

                if (localStorage.getItem("selectedLocation")) {
                    let location = JSON.parse(localStorage.getItem("selectedLocation"));
                    setSelectedLocation(location);
                } else {
                    axiosInstance
                        .post("user/get_search_fillter")
                        .then((res) => {
                            let selected = res.data?.selected_location ? res.data?.selected_location.address : 'Coimbatore'
                            setSelectedLocation(selected)
                        }).catch((err) => {
                            console.log(`err`, err);
                        });
                }
            } catch {

            }
        } else {
            if (localStorage.getItem("selectedLocation")) {
                let location = JSON.parse(localStorage.getItem("selectedLocation"));
                setSelectedLocation(location);
            }
        }
    }, [])

    function removeExtraFadeFromOffcanvas() {
        const myOffcanvas = document.getElementById('offcanvasTop');
        myOffcanvas.addEventListener('shown.bs.offcanvas', event => {
            let fade = document.getElementsByClassName('offcanvas-backdrop fade show')
            for (let i = 0; i < fade.length; i++) {
                while (fade.length > 1) {
                    fade[i].remove()
                }
            }
        });
    }

    function truncateString(str, maxLength) {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + '...';
        } else {
            return str;
        }
    }

    useEffect(() => { }, [followStatus, followShowMessage]);
    function openMap(lat, lng) {
        const mapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
        window.open(mapsUrl, '_blank');
    }

    const setServiceDetails = (serviceType, vendorId, isStaff) => {
        let serviceDetail = {
            'serviceType': serviceType,
            'vendorId': vendorId
        }
        localStorage.setItem('serviceDetail', JSON.stringify(serviceDetail));

        if (isStaff) {
            localStorage.setItem('selectedServiceOption', 'staff');
            navigate(`/select-staff/${vendorId}`)
        } else {
            localStorage.setItem('selectedServiceOption', 'service');
            navigate(`/door-step-service/${vendorId}`
            )
        }
    }

    const selectLocation = () => {
        navigate("/AddAddress", { state: { fromPage: 'profile' } })
    }

    const setProductDetails = (shipping_type, vendorId) => {
        // let productDetail = {
        //   'shipping_type': shipping_type
        // }
        // localStorage.setItem('shipping_type', JSON.stringify(productDetail));
        // navigate(`/products/${vendorId}`)


        let productDetail = {
            'shipping_type': shipping_type
        }

        let cardDetail = {
            cardStatus: "",
            cardType: "",
            cardId: "",
        }

        let productCart = {
            shipping_type: shipping_type,
            cartId: "",
            vendorId: vendorId
        }

        localStorage.setItem('productCart', JSON.stringify(productCart));

        localStorage.setItem('appliedCard', JSON.stringify(cardDetail));
        localStorage.setItem('shipping_type', JSON.stringify(productDetail));
        navigate(`/products/${vendorId}`)
    }

    const handleFollow = () => {

        if (!token) {
            handleShow();
            return
        }

        let param = {
            "follow_status": followStatus ? (followStatus == '1' ? '0' : '1') : '1',
            ...valueParam
        }

        followUnfollowDetails(param).then((res) => {
            setFollowStatus(res.data.follow_status)
            setFollowShowMessage(res.data.follow_show_message)
        });
    }

    const copyToClipboard = () => {
        const textToCopy = profile.profile_merchant_share_url;
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        textarea.setSelectionRange(0, 99999);
        document.execCommand('copy');
        document.body.removeChild(textarea);
        setCopied(true);

        toast('Link copied!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: 'text-danger toast-style-1'
        });

        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    const getNotificationsCount = () => {
        notificationCounts().then((res) => {
            setNotificationcount(res.data);
        });
    }
    return (
        <>
            {
                pageLoaded ?
                    <>
                        <div className="d-flex justify-content-between align-items-center px-1 py-3 p-navbar bg-white offcanvas-parentType1">

                            {
                                token
                                    ?
                                    <div className="d-flex justify-content-between align-items-center ms-2 me-2 ">

                                        <div className="d-flex justify-content-between align-items-center" role='button' data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop">
                                            <img src={p_navbars} width={'24px'} height={'16px'} role='button' data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop" onClick={removeExtraFadeFromOffcanvas} />

                                            <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1 text-dark'>
                                                {profileData.user_name}
                                            </div>
                                        </div>

                                        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
                                            <div className="offcanvas-body p-0">
                                                <Link to={`/dashboard`} className="text-decoration-none">
                                                    <div className="profileDetails primary-bg p-3 text-white d-flex justify-content-between align-items-center">
                                                        <div className="d-flex">
                                                            <img src={profileData.user_img} width={'84px'} height={'81px'} />
                                                            <div className="ms-2">
                                                                <div className='ff-poppins-semiBold fs-16'>{profileData.user_name}</div>
                                                                <div className='ff-balooPaaji2-regular fs-12'>UID:{profileData.user_id}</div>
                                                                <div className='ff-balooPaaji2-regular fs-12'>
                                                                    <img src={p_nav_location} width={'12px'} height={'12px'} className="me-1" />
                                                                    {profileData.user_city}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <img src={p_nav_next_outline} width={'24px'} height={'24px'} />
                                                    </div>
                                                </Link>

                                                <div>
                                                    <Link to={`/`} className="text-decoration-none text-dark">
                                                        <div className="d-flex p-3" role="button" data-bs-dismiss="offcanvas" aria-label="Close">
                                                            <img src={p_nav_book_outline} width={'24px'} height={'24px'} />
                                                            <div className="ff-poppins-regular fs-16 ms-3">Bookings Manager</div>
                                                        </div>
                                                    </Link>
                                                    <Link to={`/messenger-list`} className="text-decoration-none text-dark"  >
                                                        <div className="d-flex p-3" role="button">
                                                            <img
                                                                src={p_nav_message_outline}
                                                                width={"24px"}
                                                                height={"24px"}
                                                            />
                                                            <div className="d-flex justify-content-between w-100">
                                                                <div className="ff-poppins-regular fs-16 ms-3">Messenger</div>
                                                                <p className="">

                                                                    {notificationcount.unread_message_count > 0 ?
                                                                        <span className="notification_count">
                                                                            {notificationcount.unread_message_count >= 9 ? "9+" : notificationcount.unread_message_count}
                                                                        </span> : <></>
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <Link to={`/notifications`} className="text-decoration-none text-dark" >
                                                        <div className="d-flex sidebar p-3 " role="button">
                                                            <img src={p_nav_notifications_outline} width={"24px"} height={"24px"} />
                                                            <div className="d-flex justify-content-between w-100">
                                                                <div className="ff-poppins-regular fs-16 ms-3">
                                                                    Notification
                                                                </div>
                                                                <p className="">
                                                                    {notificationcount.unread_count > 0 ?
                                                                        <span className="notification_count">
                                                                            {notificationcount.unread_count >= 9 ? "9+" : notificationcount.unread_count}
                                                                        </span> : <></>
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <Link to={`/aboutPingle`} className="text-decoration-none text-dark">
                                                        <div className="d-flex p-3" role="button">
                                                            <img src={p_nav_about_outline} width={'24px'} height={'24px'} />
                                                            <div className="ff-poppins-regular fs-16 ms-3">About Pingle</div>
                                                        </div>
                                                    </Link>
                                                    <Link to={`/settings`} className="text-decoration-none text-dark">
                                                        <div className="d-flex p-3" role="button">
                                                            <img src={p_nav_setting_outline} width={'24px'} height={'24px'} />
                                                            <div className="ff-poppins-regular fs-16 ms-3">Settings</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <Link to={`/login`} className="text-decoration-none">
                                        <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                                            <img src={p_navbars} width={'24px'} height={'16px'} role='button' />

                                            <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1 text-dark'>
                                                Login
                                            </div>
                                        </div>
                                    </Link>
                            }

                            <div className='d-flex align-items-center'>
                                <div className='locationDetailsBadge fs-12 ff-poppins-regular rounded-pill p-2 me-2 text-truncate' onClick={() => { selectLocation() }}>
                                    <img src={p_search_location} width={'14px'} height={'14px'} alt='Location' className='me-1' />
                                    {selectedLocation ? truncateString(selectedLocation, 20) : "Select Location"}
                                </div>
                                {/* <Link to={`/search-store`}>
                        <img src={p_nav_search} width={'20px'} height={'20px'} />
                    </Link> */}
                            </div>
                        </div>

                        <div className='vendorProfile scroller-style-y'>
                            {/* Image slider  */}
                            {/* {
                    profile?.vendor_profile?.gallery_count_details != 0
                    &&
                    <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            {
                                profile?.vendor_profile?.gallery_details?.map((item, index) => (
                                    <div key={index} className={(index == '0' ? 'active' : '') + ' carousel-item'} data-bs-interval="2000">
                                        <img src={item.img_name} className="d-block w-100" alt="item.img_id" />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                } */}

                            {
                                profile?.vendor_profile?.gallery_count_details != 0
                                &&
                                <div id="myCarousel" class="carousel slide custom-carousel" data-bs-ride="carousel">
                                    <div class="carousel-inner">
                                        {
                                            profile?.vendor_profile?.gallery_details?.map((item, index) => (
                                                <div key={index} className={(index == '0' ? 'active' : '') + ' carousel-item'}>
                                                    <img src={item.img_name} className="d-block w-100" alt="item.img_id" />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            }

                            <div className='info-card-1 bg-white'>
                                <div className='ff-poppins-semiBold fs-24'>{profile?.vendor_profile?.vendor_details?.vendor_company_name}</div>
                                <div className='ff-poppins-semiBold fs-14'>MID :  {profile?.vendor_profile?.vendor_details?.vendor_id}</div>
                                <div className='ff-poppins-medium fs-15'>{profile?.vendor_profile?.vendor_details?.vendor_type_name}</div>

                                <div className='d-flex justify-content-between mt-3 pt-2'>
                                    <div>
                                        <div className='ff-poppins-medium fs-15'>Address</div>
                                        <div className='ff-poppins-medium fs-15'>{profile?.vendor_profile?.vendor_details?.vendor_address}</div>
                                    </div>

                                    <div className='text-center border-start ps-4' role='button' onClick={() => openMap(profile?.vendor_profile?.vendor_details?.vendor_lat, profile?.vendor_profile?.vendor_details?.vendor_long)}>
                                        <img src={p_vendor_map} className="d-block" alt="map" width={'29px'} height={'37px'} />
                                        <div className='ff-poppins-medium fs-13'>MAP</div>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between align-items-center mt-3 pt-2 ps-3 pe-3'>
                                    <div className='text-center' onClick={() => { handleFollow() }} role='button'>
                                        <img src={p_storeprofile_follow} alt="follow" width={'27px'} height={'32px'} />
                                        <div className='ff-poppins-medium fs-14'>{followShowMessage}</div>
                                    </div>
                                    <div className='text-center border-start ps-4 pe-4 border-end' role='button' onClick={() => { token ? navigate(`/history/${valueParam.vendor_id}`) : handleShow() }}>
                                        <img src={p_storeprofile_history} alt="History" height={'28px'} />
                                        <div className='ff-poppins-medium fs-14'>History</div>
                                    </div>
                                    <div className='text-center' onClick={() => { token ? copyToClipboard() : handleShow() }} role='button'>
                                        <img src={p_storeprofile_share} alt="follow" height={'28px'} />
                                        <div className='ff-poppins-medium fs-14'>Share</div>
                                    </div>
                                </div>
                            </div>

                            <div className='info-card-2 primary-bg'>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <div className='ff-poppins-semiBold fs-14 text-white'>Captain</div>
                                        <div className='ff-poppins-semiBold fs-16 text-white'>{profile?.vendor_profile?.contact_details?.contact_name}</div>
                                    </div>
                                    <img className='captainImage' src={profile?.vendor_profile?.contact_details?.contact_img} alt="image" width={'70px'} height={'70px'} />
                                </div>

                                <div className='mt-3 pt-2 d-flex'>
                                    <button className='bg-white border-0 w-50 d-flex justify-content-center align-items-center me-1'
                                        role='button' onClick={() => window.location.href = 'tel:' + profile?.vendor_profile?.contact_details?.contact_mbl}>
                                        <img src={p_dash_call} alt="image" width={'24px'} height={'24px'} />
                                        <div className='ff-poppins-semiBold fs-16 primary-text ms-3 pe-2'>Call</div>
                                    </button>
                                    <button className='bg-white border-0 w-50 d-flex justify-content-center align-items-center ms-1'
                                        role='button' onClick={() => navigate(`/chat/${profile?.vendor_id}`)}>
                                        <img src={p_dash_text} alt="image" width={'24px'} height={'24px'} />
                                        <div className='ff-poppins-semiBold fs-16 primary-text ms-3 pe-2'>Text</div>
                                    </button>
                                </div>
                            </div>

                            {
                                profile && Number(profile?.vendor_profile?.vendor_details?.vendor_status) == 1 && Number(profile?.vendor_profile?.vendor_details?.service_status) == 1 ?
                                    <div className='service-card bg-white'>
                                        <div className='mx-3 px-1 my-2'>
                                            <div className='ff-poppins-semiBold fs-24'>Book a Staff / Service</div>
                                            <div className='ff-poppins-regular fs-14'>
                                                If you want a particular choose select
                                                Staff,if you want to view all services
                                                Choose “Select Service”
                                            </div>
                                        </div>

                                        {
                                            token && profile?.vendor_profile?.vendor_details?.staff_app_status == '1'
                                                ?
                                                <div className="btn-group w-100" role="group" aria-label="Basic example">
                                                    <button type="button" className="btn primary-bg text-white ff-poppins-semiBold fs-14 d-flex justify-content-center align-items-center w-50"
                                                        onClick={() => { token ? setServiceDetails(1, profile?.vendor_profile?.vendor_details?.vendor_id, true) : handleShow() }}>
                                                        Select Staff
                                                        <img src={p_right_arrow} alt="image" width={'24px'} height={'24px'} />
                                                    </button>
                                                    <button type="button" className="btn primary-bg text-white ff-poppins-semiBold fs-14 d-flex justify-content-center align-items-center w-50"
                                                        onClick={() => setServiceDetails(1, profile?.vendor_profile?.vendor_details?.vendor_id, false)}>
                                                        Select Service
                                                        <img src={p_right_arrow} alt="image" width={'24px'} height={'24px'} />
                                                    </button>
                                                </div>
                                                :
                                                <div className='d-flex justify-content-end'>
                                                    <button type="button" className="btn primary-bg text-white ff-poppins-semiBold fs-14 d-flex justify-content-center align-items-center w-50"
                                                        onClick={() => setServiceDetails(1, profile?.vendor_profile?.vendor_details?.vendor_id, false)}>
                                                        Select Service
                                                        <img src={p_right_arrow} alt="image" width={'24px'} height={'24px'} />
                                                    </button>
                                                </div>
                                        }
                                    </div> : <></>
                            }


                            {
                                profile && Number(profile?.vendor_profile?.vendor_details?.vendor_status) == 1 && Number(profile?.vendor_profile?.vendor_details?.doorstep_status) == 1 && Number(profile?.vendor_profile?.vendor_details?.subscription_status) == 1 &&
                                <div className='service-card bg-white'>
                                    <div className='mx-3 px-1 my-2'>
                                        <div className='ff-poppins-semiBold fs-24'>Door step service</div>
                                        <div className='ff-poppins-regular fs-14'>
                                            If you want a particular choose select
                                            Staff,if you want to view all services
                                            Choose “Select Service”
                                        </div>
                                    </div>
                                    {
                                        token && profile?.vendor_profile?.vendor_details?.staff_app_status == '1' ?
                                            <div className="btn-group w-100" role="group" aria-label="Basic example">
                                                <button type="button" className="btn primary-bg text-white ff-poppins-semiBold fs-14 d-flex justify-content-center align-items-center w-50"
                                                    onClick={() => setServiceDetails(2, profile?.vendor_profile?.vendor_details?.vendor_id, true)}>
                                                    Select Staff
                                                    <img src={p_right_arrow} alt="image" width={'24px'} height={'24px'} />
                                                </button>
                                                <button type="button" className="btn primary-bg text-white ff-poppins-semiBold fs-14 d-flex justify-content-center align-items-center w-50"
                                                    onClick={() => setServiceDetails(2, profile?.vendor_profile?.vendor_details?.vendor_id, false)}>
                                                    Select Service
                                                    <img src={p_right_arrow} alt="image" width={'24px'} height={'24px'} />
                                                </button>
                                            </div>
                                            :
                                            <div className='d-flex justify-content-end'>
                                                <button type="button" className="btn primary-bg text-white ff-poppins-semiBold fs-14 d-flex justify-content-center align-items-center w-50"
                                                    onClick={() => setServiceDetails(2, profile?.vendor_profile?.vendor_details?.vendor_id, false)}>
                                                    Select Service
                                                    <img src={p_right_arrow} alt="image" width={'24px'} height={'24px'} />
                                                </button>
                                            </div>
                                    }
                                </div>
                            }

                            {
                                profile && Number(profile?.vendor_profile?.vendor_details?.vendor_status) == 1 && Number(profile?.vendor_profile?.vendor_details?.product_status) == 1 &&
                                <div className='service-card bg-white'>
                                    <div className='mx-3 px-1 my-2'>
                                        <div className='ff-poppins-semiBold fs-24'>Products</div>
                                        <div className='ff-poppins-regular fs-14'>
                                            Browse and book your products for store pick up or door delivery and pay them in a jiffy
                                        </div>
                                    </div>

                                    {
                                        Number(profile?.vendor_profile?.vendor_details?.product_delivery_status) == 1
                                            ?
                                            <div className="btn-group w-100" role="group" aria-label="Basic example">
                                                <button type="button" className="btn primary-bg text-white ff-poppins-semiBold fs-14 d-flex justify-content-center align-items-center w-50"
                                                    onClick={() => setProductDetails(1, profile?.vendor_profile?.vendor_details?.vendor_id)}>
                                                    Store Pickup
                                                    <img src={p_right_arrow} alt="image" width={'24px'} height={'24px'} />
                                                </button>
                                                <button type="button" className="btn primary-bg text-white ff-poppins-semiBold fs-14 d-flex justify-content-center align-items-center w-50"
                                                    onClick={() => setProductDetails(2, profile?.vendor_profile?.vendor_details?.vendor_id)}>
                                                    Doorstep
                                                    <img src={p_right_arrow} alt="image" width={'24px'} height={'24px'} />
                                                </button>
                                            </div>
                                            :
                                            <div className='d-flex justify-content-end'>
                                                <button type="button" className="btn primary-bg text-white ff-poppins-semiBold fs-14 d-flex justify-content-center align-items-center w-50"
                                                    onClick={() => setProductDetails(1, profile?.vendor_profile?.vendor_details?.vendor_id)}>
                                                    Store Pickup
                                                    <img src={p_right_arrow} alt="image" width={'24px'} height={'24px'} />
                                                </button>
                                            </div>

                                    }
                                </div>
                            }

                            {
                                profile?.vendor_profile?.vendor_details?.membership_status == '1' ?
                                    <div className='service-card bg-white'>
                                        <div className='mx-3 px-1 my-2'>
                                            <div className='ff-poppins-semiBold fs-24'>Membership Cards</div>
                                            <div className='ff-poppins-regular fs-14'>
                                                Buy memberships and get great benefits on your purchases.
                                            </div>
                                        </div>
                                        {
                                            token ?

                                                <div className="btn-group w-100" role="group" aria-label="Basic example">
                                                    <button onClick={() => navigate("/VendorMembership", { state: { vendorId: profile.vendor_id } })} type="button" className="btn primary-bg text-white ff-poppins-semiBold fs-14 d-flex justify-content-center align-items-center w-50">
                                                        My Cards
                                                        <img src={p_right_arrow} alt="image" width={'24px'} height={'24px'} />
                                                    </button>
                                                    <button onClick={() => navigate(`/MembershipBuyNow/${profile.vendor_id}`, { state: { vendorId: profile.vendor_id } })} type="button" className="btn primary-bg text-white ff-poppins-semiBold fs-14 d-flex justify-content-center align-items-center w-50" >
                                                        Buy Now
                                                        <img src={p_right_arrow} alt="image" width={'24px'} height={'24px'} />
                                                    </button>
                                                </div>
                                                :
                                                <div className="d-flex justify-content-end w-100" role="group" aria-label="Basic example">
                                                    <button onClick={() => navigate(`/MembershipBuyNow/${profile.vendor_id}`, { state: { vendorId: profile.vendor_id } })} type="button" className="btn primary-bg text-white ff-poppins-semiBold fs-14 d-flex justify-content-center align-items-center w-50" >
                                                        Buy Now
                                                        <img src={p_right_arrow} alt="image" width={'24px'} height={'24px'} />
                                                    </button>
                                                </div>
                                        }
                                    </div> :
                                    <>
                                    </>
                            }
                            {
                                profile?.vendor_profile?.vendor_details?.offers_status == '1' ?
                                    <div className='service-card bg-white'>
                                        <div className='mx-3 px-1 my-2'>
                                            <div className='ff-poppins-semiBold fs-24'>Offer</div>
                                            <div className='ff-poppins-regular fs-14'>
                                                Buy Offer and get great benefits on your purchases.
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end w-100" role="group" aria-label="Basic example">
                                            <button type="button" className="btn primary-bg text-white ff-poppins-semiBold fs-14 d-flex justify-content-center align-items-center w-50"
                                                onClick={() => navigate(`/Offerlist/${profile?.vendor_profile?.vendor_details?.vendor_id}`
                                                    , { state: { vendorId: profile?.vendor_profile?.vendor_details?.vendor_id, flowParent: 'cartlist' } })}>
                                                View offer
                                                <img src={p_right_arrow} alt="image" width={'24px'} height={'24px'} />
                                            </button>
                                        </div>
                                    </div>
                                    : <></>
                            }

                            <div className='loyaltyCard mt-2 white-bg pt-3 pb-3 ps-2 pe-2'>
                                <div className='banner d-flex justify-content-center align-items-center position-relative' style={{ backgroundImage: `url(${p_storeprofile_logo_background})` }}>
                                    <img src={p_storeprofile_logo} width={'95px'} height={'95px'} alt='Pingle Logo' />
                                    <div className='position-absolute text-center points'>
                                        <div className='ff-poppins-bold fs-48 primary-text'>{loyalty?.total_vendor_points} </div>
                                        <div className='ff-poppins-semiBold fs-14'>Loyalty Points</div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center mt-3'>
                                    <div className='text-center' onClick={() => navigate("/merchant-loyalty", { state: { vendorId: loyalty?.vendor_id } })}>
                                        <button className='button ff-poppins-semiBold fs-14 primary-bg text-light d-flex justify-content-center align-items-center rounded-pill border-0' >
                                            Redeem
                                            <img src={p_right_arrow} width={'24px'} height={'24px'} alt='Text' />
                                        </button>
                                        <div className='ff-poppins-medium fs-14 mt-3'>{loyalty?.vendor_eligble_offers_count} Offers Eligible</div>
                                    </div>
                                </div>
                            </div>

                            <div className='aboutCard mt-2 white-bg'>
                                <div className="ff-poppins-semiBold fs-24 primary-text">
                                    About
                                </div>

                                <div className="ff-poppins-regular fs-14 mb-2">
                                    {profile?.vendor_profile?.vendor_details?.vendor_about}
                                </div>

                                <div className="ff-poppins-semiBold fs-24 primary-text">
                                    Working hours
                                </div>

                                <table className='w-100 mt-2'>
                                    <tbody>
                                        {
                                            profile?.vendor_profile?.working_hours?.map(item => {
                                                if (item.day_status) {
                                                    return (
                                                        <tr key={item.day}>
                                                            <td className='ff-poppins-regular fs-14'>{item.day}</td>
                                                            <td className='ff-poppins-regular fs-13 text-end text-danger'>{item.day_status_msg}</td>
                                                        </tr>
                                                    )
                                                } else {
                                                    return (
                                                        <tr>
                                                            <td className='ff-poppins-regular fs-14'>{item.day}</td>
                                                            <td className='ff-poppins-regular fs-13 text-end'>{item.timing}</td>
                                                        </tr>
                                                    )
                                                }
                                            })
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div >

                        {showModal && (
                            <div className="modal-backdrop show"></div>
                        )
                        }

                        {
                            showModal && (
                                <div className="modal justify-content-center" tabIndex="-1" style={{ display: 'block' }}>
                                    <div className="modal-dialog-centered loginWarningDialog">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                <div className='ff-poppins-semiBold fs-1 primary-text text-center'>
                                                    Attention!
                                                </div>
                                                <div className='ff-poppins-regular fs-14 text-center mt-2'>
                                                    To proceed further you need to login/register and get access to all the features.
                                                </div>
                                                <div className='d-flex justify-content-center align-items-center mt-1 mb-1' role='button' onClick={() => navigate('/login')}>
                                                    <div className='loginBtn primary-bg text-center text-white p-1 ff-poppins-medium fs-16'>Login</div>
                                                </div>
                                                <div className='d-flex justify-content-center align-items-center' role='button' onClick={handleClose}>
                                                    <div className='loginBtn text-center text-secondary p-1 ff-poppins-medium fs-16 secondary-bg-1 mt-2'>Close</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </> :
                    <>

                    </>
            }

        </>
    )
}

export default VendorProfile