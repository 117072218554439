import React, { useEffect, useState } from "react";
import "../../Assets/Styles/offCanvas.css";
import { useNavigate } from "react-router-dom";

const ChatParticipantOffCanvas = ({
  isOpen,
  particpants,
  toggleOffCanvas
}) => {

  return (
    <div
      className={`offcanvas offcanvas-bottom chatParticpantModel ${isOpen ? "show" : ""
        }`}
      tabindex="-1"
      aria-labelledby="chatPArticpantModelLabel"
      style={{ position: "sticky" }}
    >
      <div className="offcanvas-body small">
        <div className="d-flex justify-content-between">
          <div className="ff-poppins-regular fs-20 mb-4">3 Particpants</div>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={toggleOffCanvas}
          ></button>
        </div>

        <div>
          {
            particpants && particpants.length > 0 &&

            particpants.map(item => (
              <div className='d-flex mb-3 w-100'>
                <img src={item?.image} width={'37px'} height={'37px'} className='me-1 rounded-2' />
                <div className='lh-sm ms-2'>
                  <div className='ff-balooPaaji2-bold fs-20'>{item.name}</div>
                  <div className='ff-poppins-regular fs-10'>{item.type}</div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default ChatParticipantOffCanvas;
