import React from "react";
import { FaHourglassHalf } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import p_history_icon from '../Assets/Images/booking/p_history_icon.svg';
export default function FollowingCards({
  vendor_id,
  vendor_name,
  vendor_address,
  vendor_logo,
}) {
  const valueParam = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  return (
    <>
      {/* <div className="p-2" style={{ padding: "16px" }}>
        <div className="border p-2" style={{ borderRadius: "7px", backgroundColor: "#fff" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <img
                src={vendor_logo}
                alt="Description"
                style={{ width: "100px", height: "100px", borderRadius: "10px" }}
              />
            </div>
            <div className="p-3 pe-4 py-3 justify-content-between w-100">
              <div className="ff-poppins-semiBold fs-14 ">{vendor_name}</div>
              <div className="ff-poppins-semiBold fs-14 d-flex">{vendor_id}</div>
              <div className="ff-poppins-semiBold fs-14">
                {vendor_address && vendor_address.length > 4
                  ? vendor_address.substring(0, 4) + "..."
                  : vendor_address}
              </div>
            </div>
            <Link to={'/history/:vendor_id'}>
              <div
                className="d-flex align-items-center justify-content-center ms-auto"
                style={{ width: "70px" }}
              >
                <div
                  className="align-items-center justify-content-center History-icon"
                  onClick={() => { token && navigate(`/history/${valueParam.vendor_id}`) }}

                >
                  <FaHourglassHalf color="#4e26a3" size={25} />
                  <div
                    className="ff-poppins-semiBold fs-12"
                    style={{ marginBottom: "5px" }}
                  >
                    History
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div> */}

      <div className="col-12 ps-1 pe-1 mt-4">
        <div className="store-card-1 d-flex">
          <img className="rounded-3" src={vendor_logo} width={'70px'} height={'70px'} />
          <div className="ms-2">
            <div className="ff-poppins-semiBold fs-16">{vendor_name}</div>
            <div className="ff-poppins-semiBold fs-12 text-secondary">&#x2022; MID {vendor_id} &#x2022; </div>
            <div className="ff-poppins-semiBold fs-12 text-secondary">&#x2022; {vendor_address && vendor_address.length > 4
                  ? vendor_address.substring(0, 4) + "..."
                  : vendor_address} </div>
          </div>
          <div className="ms-auto d-flex justify-content-center align-items-center history-btn">
            <Link to={`/history/${vendor_id}`} className="text-decoration-none">
              <div className="text-center history">
                <img src={p_history_icon} width={'36px'} height={'36px'} alt="History Icon" />
                <div className="ff-poppins-semiBold fs-13 primary-text">History</div>
              </div>
            </Link>
          </div>
        </div>
      </div>

    </>
  );
}
