import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { userRegister } from "../../Services/api";
import { toast } from 'react-toastify';
import p_reg_mobile from '../../Assets/Images/p_reg_mobile.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './customDatePicker.css';

const UserRegister = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedDate, setSelectedDate] = useState(null);
    const [isLoading, setLoadingStatus] = useState(false);
    const vendorUrl = localStorage.getItem("vendorUrl");
    const today = new Date();

    const [formData, setFormData] = useState({
        user_mbl: location.state?.mobileNumber,
        user_name: "",
        user_email: "",
        user_city: "",
        referral_id: "",
        user_gender: "",
        user_dob: "",
        device_token: "dPStCPWQTYC2tGWsl_s7OG:APA91bGoH5YHEsjWdAFoaSntwNckTcSnVgjLUsUZMz5KOJgVCTx3jeksh7sN4H_kq8BW9-AUG-ukDy4quQiiLwLHiLcRMJhkwgzdetQuRHHj3BTxBRhci136xbFGoHeWeLAp4bDJuLRg",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };
    const handleCalenderChange = (date) => {
        setSelectedDate(date);
         handleChange({ target: { name: 'user_dob', value: date } });
        // You can set the selected date to your form data here if needed
    };

    const dispErrorMsg = (msg) => {
        toast(msg, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: 'text-danger toast-style-1'
        });
    }

    const handleInputFocus = (e) => {
        e.target.blur(); // Blur the input field to dismiss the keyboard
      };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('formdata - ', formData)

        if (formData.user_name == '') {
            dispErrorMsg('Name field required')
            return
        }
        else if (formData.user_gender == '') {
            dispErrorMsg('Gender field required')
            return
        }
        else if (formData.user_dob == '') {
            dispErrorMsg('Date of Birth field required')
            return
        }
        else if (formData.user_city == '') {
            dispErrorMsg('City field required')
            return
        }
        else if (formData.user_email == '') {
            dispErrorMsg('Email field required')
            return
        }

        setLoadingStatus(true);

        userRegister(formData)
            .then((res) => {
                setLoadingStatus(false);
                if (res.data?.status === '1') {
                    navigate(vendorUrl ? vendorUrl : '/registration-success')
                }

            })


            .catch((error) => {
                setLoadingStatus(false)
                toast(error.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'text-danger toast-style-1'
                });
            });

    }

    return (
        <>
            <div className="userRegister scroller-style bg-white">
                <div className="banner primary-bg text-white d-flex justify-content-center align-items-center">
                    <div className="position-relative">
                        <div className='ff-poppins-bold fs-20'>Create your Pingle Account</div>
                        <div className='ff-poppins-semiBold fs-14 mt-2'>Mobile Verified!</div>
                        <div className='ff-poppins-semiBold fs-15'>+91 {location.state?.mobileNumber}</div>

                        <img className="position-absolute" src={p_reg_mobile} width={'62px'} height={'100px'} />
                    </div>
                </div>
                <form className="form bg-white" onSubmit={handleSubmit}>
                    <div className='ff-poppins-regular fs-12'>User Name <span className="text-danger">*</span></div>
                    <input type="text" className="w-100 ff-poppins-medium fs-20" name="user_name" value={formData.user_name} onChange={handleChange} />

                    <div className='ff-poppins-regular fs-12 mt-4 pt-2'>Gender <span className="text-danger">*</span></div>
                    <select className="form-select" aria-label="Default select example" name="user_gender" value={formData.user_gender} onChange={handleChange}>
                        <option value='' selected disabled>Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                    <div>
                        <div className='ff-poppins-regular fs-12 mt-4 pt-2 dobdatepicker'>D.O.B <span className="text-danger">*</span></div>
                        <DatePicker
                            className="w-100 ff-poppins-medium fs-20"
                            selected={selectedDate}
                            onChange={handleCalenderChange}
                            dateFormat="yyyy-MM-dd" 
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={100}
                            portalId="custom-calendar-portal"
                            onFocus={handleInputFocus} 
                            maxDate={today}
                        />
                    </div>

                    <div className='ff-poppins-regular fs-12 mt-4 pt-2'>City <span className="text-danger">*</span></div>
                    <select className="form-select" aria-label="Default select example" name="user_city" value={formData.user_city} onChange={handleChange}>
                        <option value='' selected disabled>Select City</option>
                        <option value="coimbatore">Coimbatore</option>
                        <option value="erode">Erode</option>
                        <option value="pollachi">Pollachi</option>
                        <option value="tirupur">Tirupur</option>
                    </select>

                    <div className='ff-poppins-regular fs-12 mt-4 pt-2'>Email <span className="text-danger">*</span></div>
                    <input type="text" className="w-100 ff-poppins-medium fs-20" name="user_email" value={formData.user_email} onChange={handleChange} />

                    <div className='ff-poppins-regular fs-12 mt-4 pt-2'>Referral Id</div>
                    <input type="text" className="w-100 ff-poppins-medium fs-20" name="referral_id" value={formData.referral_id} onChange={handleChange} />

                    <div className="d-flex justify-content-center">
                        <button type="submit" className={(isLoading ? "loading" : "") + " " + "w-100 border-0 primary-bg rounded-pill ff-poppins-semiBold fs-20 text-white mt-5 mb-5 d-flex justify-content-center align-items-center"}>
                            {
                                isLoading
                                    ?
                                    <span className="loader-style-1"></span>
                                    :
                                    <span>Continue</span>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
};

export default UserRegister;