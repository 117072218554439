import { Link, useNavigate } from "react-router-dom";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import { FaArrowRight } from "react-icons/fa6";
import { IoQrCodeOutline } from "react-icons/io5";
import { useState } from "react";
import axiosInstance from "../../Services/axios";
import { toast } from "react-toastify";

const SearchMerchant = () => {
  const navigate = useNavigate();
  const [vendorId, setVendorId] = useState("");
  console.log("vendorId", vendorId);
  const VendorProfile = () => {
    try {
      let reqData = {
        vendor_id: vendorId,
      };
      console.log("reqdata", reqData);
      axiosInstance
        .post("/user/vendor_profile_details", reqData)
        .then((res) => {
          console.log("resss", res.data);
          //   if (res.data.vendor_id == vendorId)
          navigate(`/vendor/${res.data.vendor_id}`);
        })
        .catch(
          toast("invalied id", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "text-danger toast-style-1",
          })
        );
    } catch {}
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
        <div className="d-flex justify-content-between align-items-center ms-2 me-2">
          <img
            src={p_nav_previous}
            width={"24px"}
            height={"16px"}
            alt="Previous Button"
            role="button"
            onClick={() => navigate(-1)}
          />
          <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
            Search Merchant
          </div>
        </div>
      </div>

      <div className="">
        <div className="d-flex justify-content-between align-items-center bg-white py-3 my-2">
          <div className="d-flex align-items-center ff-poppins-regular fs-5 ps-3">
            <IoQrCodeOutline className="fs-1" />
            <gaby className="ps-3">Scan QR Code</gaby>
          </div>
          <div>
            <Link to={"/QRCodeScan"} className="text-black">
              {" "}
              <FaArrowRight className="me-3 fs-5" />{" "}
            </Link>
          </div>
        </div>
      </div>

      <div className="p-1 d-flex flex-column justify-content-between bg-white scroller-style-y px-3">
        <div className="d-flex flex-column align-items-center">
          <div className="ff-poppins-semiBold fs-5 mt-5">Merchant ID</div>
          <div className="w-100 mt-4">
            <input
              type="text"
              className="border-bottom border-0 border-black w-100 inputOutlineDel text-center"
              value={vendorId}
              onChange={(e) => setVendorId(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="">
       
        <div
          className={`ff-poppins-semiBold fs-3 text-center ${
            vendorId === "" ? "passIdEmpty" : "passIdFind"
          }  mb-3 py-1 rounded fixed-bottom mx-3 submitMerFind`}
          role="button"s
          onClick={() => VendorProfile()}
        >
          Submit
        </div>
      </div>
    </>
  );
};

export default SearchMerchant;
