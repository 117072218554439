import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import p_map_marker from "../../Assets/Images/p_map_marker.svg";
import p_map_back from "../../Assets/Images/p_map_back.svg";
import p_map_location from "../../Assets/Images/p_map_location.svg";
import p_map_loc_marker from "../../Assets/Images/p_map_loc_marker.svg";
import { useNavigate, useLocation } from "react-router-dom";
import p_address_home from "../../Assets/Images/p_address_home.svg";
import p_address_others from "../../Assets/Images/p_address_others.svg";
import p_address_work from "../../Assets/Images/p_address_work.svg";
import p_search_location_arrow_secondary from '../../Assets/Images/p_search_location_arrow_secondary.svg';

import {
  addRecentLocation,
  getLocationTypes,
  saveMyLocation,
  save_my_location,
  updateMySavedLocation,
} from "../../Services/api";

const containerStyle = {
  width: "390px",
  height: "100dvh",
};

const center = { lat: 10.999297057160366, lng: 76.95885365803767 };

const mapOptions = {
  disableDefaultUI: true,
};

const AddDeliveryAddress = () => {
  const service = new window.google.maps.places.AutocompleteService();
  const geocoder = new window.google.maps.Geocoder();


  const navigate = useNavigate();
  const location = useLocation();
  const { fromPage } = location.state || {};
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [locationTypes, setLocationTypes] = useState([]);
  const [formData, setFormData] = useState({
    latlng: "",
    location_type: "",
    location_type_id: "",
    door_no: "",
    area: "",
    direction: "",
    latitude: "",
    longitude: "",
    location_id: ""
  });
  const [searchResults, setSearchResults] = useState([]);
  const [offcanvasHeight, setOffcanvasHeight] = useState('70vh');
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [showChangeOffCanvas, setShowChangeOffCanvas] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    checkFormValidity();
  };

  useEffect(() => {
    const adjustOffcanvasHeight = () => {
      const offcanvasContent = document.getElementById('offcanvasContent');
      if (offcanvasContent) {
        const contentHeight = offcanvasContent.scrollHeight;
        const windowHeight = window.innerHeight;
        const newHeight = contentHeight > windowHeight * 0.7 ? contentHeight : windowHeight * 0.7;
        setOffcanvasHeight(`${newHeight}px`);
        console.log('newHeight--> ', newHeight);
      }
    };

    window.addEventListener('resize', adjustOffcanvasHeight);
    adjustOffcanvasHeight(); // Initially adjust height on mount

    return () => {
      window.removeEventListener('resize', adjustOffcanvasHeight);
    };
  }, [formData, showOffCanvas]);


  const handleSubmit = (event) => {
    event.preventDefault();
    let locationType = locationTypes.find((x) => x.id == formData.location_type_id).name;
    formData.location_type = formData.location_type != "" ? formData.location_type : locationType;
    formData.latlng = latitude + "," + longitude;
    formData.latitude = latitude;
    formData.longitude = longitude;
    formData.select_address = address;
    formData.select_locality = city;

    setIsFormValid(false);
    // Save the address
    if (formData.location_id == '') {
      saveMyLocation(formData).then((res) => {
        // setIsFormValid(true); // Re-enable the button after the address is saved 
        if (fromPage == 'service') {
          let localServiceDetail = localStorage.getItem('serviceDetail') ? JSON.parse(localStorage.getItem('serviceDetail')) : '';
          let vendor_id = localServiceDetail.vendorId ? localServiceDetail.vendorId : '';
          navigate(`/review-and-pay/${vendor_id}`, { state: { fromPage: 'addAddress' } });
        } else if (location.state && (location.state.isFromFindLocation || location.state?.formData)) {
          navigate(-1);
        } else {
          navigate('/Reviewpay', { state: { fromPage: 'addAddress' } });
        }

      });
    } else {
      updateMySavedLocation(formData).then((res) => {

        if (res.data?.status == '1') {
          navigate(-1);
        }
      });
    }

  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC7cC8d67-AjIHnXizRFxTj1uFkOwnF2zo",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // get Place details on channge map
    window.google.maps.event.addListener(map, "center_changed", function () {
      console.log("event - ", map.center.lat());
      setLatitude(map.center.lat());
      setLongitude(map.center.lng());
      var geocoder = new window.google.maps.Geocoder();
      geocoder
        .geocode({ location: { lat: map.center.lat(), lng: map.center.lng() } })
        .then((response) => {
          response.results.map((item) => {
            if (item.types.includes("administrative_area_level_3")) {
              item.address_components.map((item1) => {
                if (item1.types.includes("administrative_area_level_3")) {
                  console.log("City - ", item1.long_name);
                  console.log("Address - ", item.formatted_address);
                  setCity(item1.long_name);
                  setAddress(item.formatted_address);
                }
              });
            }
            // if(item.types[0] == "street_address") {

            //     item.address_components.map(item1 => {

            //         if(item1.types.includes('political')){
            //             // console.log(' final 1 - ', item1)
            //         }

            //     })
            // }
          });
          // response.results.find(({ name }) => name === "cherries");
          setAddress(response.results[0].formatted_address);
        });
    });

    // To get current location on Button click
    document
      .getElementById("locationBtn")
      .addEventListener("click", function () {
        // console.log("clicked");
        getCurrentLocation();
      });

    const getCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            map.setCenter(pos);
          },
          () => {
            // Show default location if user Don't allow for access location
            let defaultLocation = new window.google.maps.LatLng(center);
            map.setCenter(defaultLocation);
          }
        );
      } else {
        alert(`Browser doesn't support Geolocation`);
      }
    };

    if (location.state?.mapLat) {
      const pos = {
        lat: location.state?.mapLat,
        lng: location.state?.mapLng,
      };
      map.setCenter(pos);
    } else if (location.state && !location.state?.isFromFindLocation && location.state?.formData?.latlng != '') {
      const pos = {
        lat: Number(location.state?.formData?.latlng.split(',')[0]),
        lng: Number(location.state?.formData?.latlng.split(',')[1])
      };
      map.setCenter(pos);
      setFormData(location.state?.formData);
      setFormData((prevFormData) => ({ ...prevFormData, location_id: location.state?.formData?.id }));

    } else {
      getCurrentLocation();
    }

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleConfirmLocation = () => {

    if (location.state && !location.state?.isFromFindLocation) {
      toggleOffCanvas();
    } else if (location.state?.mapLat) {
      let param = {
        "latlng": latitude + ',' + longitude,
        "select_address": address,
        "select_locality": city
      }
      addRecentLocation(param).then((res => {
        if (res.data?.status == '1') {
          navigate(`/search-store`);
        }
      }))
    } else if (latitude != '' && longitude != '') {
      let param = {
        "latlng": latitude + ',' + longitude,
        "select_address": address,
        "select_locality": city
      }
      addRecentLocation(param).then((res => {
        if (res.data?.status == '1') {
          navigate(`/search-store`);
        }
      }))
    }
  };

  function getLocationType() {
    getLocationTypes().then((res) => {
      res.data?.loctaions_types?.map((item) => {
        let icon = "";
        if (item.name == "Home") {
          icon = p_address_home;
        } else if (item.name == "Work") {
          icon = p_address_work;
        } else if (item.name == "Other") {
          icon = p_address_others;
        }
        item.icon = icon;
      });
      setLocationTypes(res.data?.loctaions_types);
    });
  }

  useEffect(() => {
    getLocationType();
  }, []);

  const checkFormValidity = () => {
    // Check if all required fields have values
    const isDoorNoValid = formData.door_no.trim() !== "";
    const isAreaValid = formData.area.trim() !== "";
    const isDirectionValid = formData.direction.trim() !== "";
    const isLocationTypeSelected = formData.location_type_id !== "";
    // Set the form validity state based on the above checks
    const isLocationType = formData.location_type_id == 3 ? formData.location_type !== "" : true;
    setIsFormValid((isDoorNoValid && isAreaValid && isDirectionValid && isLocationTypeSelected && isLocationType) ? true : false);
  };


  useEffect(() => {
    checkFormValidity();
  }, [formData]);



  const toggleOffCanvas = () => {
    setShowOffCanvas(!showOffCanvas);
  };


  const toggleChangeOffCanvas = () => {
    setShowChangeOffCanvas(!showChangeOffCanvas);
  };

  const displaySuggestions = function (predictions, status) {
    if (
      status != window.google.maps.places.PlacesServiceStatus.OK ||
      !predictions
    ) {
      alert(status);
      return;
    } else {
      setSearchResults(predictions);
    }
  }

  const handleChangelocation = (event) => {
    const { name, value } = event.target;
    if (event.target.value != "") {
      let addresses = service.getQueryPredictions(
        { input: event.target.value },
        displaySuggestions
      );
    } else {
      setSearchResults([]);
    }
  };

  const handlePlaceSelect = async (place) => {

    console.log(`place`, place);
    if (!place.place_id) {
      return;
    }

    geocoder
      .geocode({
        address: place.description
      })
      .then(({ results }) => {
        let location = results[0].geometry.location;

        const pos = {
          lat: location.lat(),
          lng: location.lng(),
        };
        map.setCenter(pos);
        toggleChangeOffCanvas()
      })
      .catch((e) => window.alert("Geocoder failed due to: " + e));
  };

  return isLoaded ? (
    <div className="bg-white  vh-100">
      <div className="position-relative">
        <GoogleMap
          mapContainerStyle={containerStyle}
          // center={center}
          zoom={12}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={mapOptions}
        >
          {/* Child components, such as markers, info windows, etc. */}
          <></>
        </GoogleMap>
        <img
          src={p_map_marker}
          width={"22px"}
          height={"37px"}
          className="marker position-absolute"
        />

        <button
          className="mapBackBtn position-absolute p-3 bg-white rounded-circle border-0"
          onClick={() => { navigate('/profile', { state: { fromPage: 'addAddress' } }) }}
        >
          <img src={p_map_back} width={"24px"} height={"24px"} />
        </button>

        <button
          className="mapLocationBtn position-absolute p-2 bg-white border-0 rounded-1"
          id="locationBtn">
          <img src={p_map_location} width={"24px"} height={"24px"} />
        </button>

        <div className="locationDetails" style={{
          padding: 20,
          position: 'absolute',
          bottom: 0,
          backgroundColor: '#fff',
          width: '100%',
        }}>
          <div className="ff-poppins-semiBold fs-12 secondary-text">
            SELECT LOCATION
          </div>
          <div className="d-flex">
            <div className="w-75">
              <div className="d-flex align-items-center">
                <img src={p_map_loc_marker} width={"16px"} height={"16px"} />
                <div className="ff-poppins-semiBold fs-20 text-truncate ms-1">
                  {city}
                </div>
              </div>
              <div className="ff-poppins-regular fs-14 clamped-text">
                {address}
              </div>
            </div>
            <div className="w-25">
              <button className="ctrlBtn ff-poppins-semiBold fs-14 primary-text border-0 mb-2" onClick={toggleOffCanvas}>
                Save
              </button>
              <button className="ctrlBtn ff-poppins-semiBold fs-14 primary-text border-0"
                // onClick={(toggleChangeOffCanvas)}
                onClick={() => navigate(`/find-location`)}
              >
                Change
              </button>
            </div>
          </div>

          <button className="confirmBtn w-100 ff-poppins-semiBold fs-16 text-white primary-bg border-0"
            // onClick={toggleOffCanvas}
            onClick={handleConfirmLocation}
          >
            CONFIRM LOCATION
          </button>
        </div>

        <div className={`saveProductAddress offcanvas offcanvas-bottom ${showOffCanvas ? 'show' : ''}`}
          tabIndex="-1"
          id="offCanvas"
          style={{ height: offcanvasHeight }}>
          <div className="offcanvas-header">
            <button
              type="button"
              className="btn-close text-reset"
              onClick={toggleOffCanvas}
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body" id="offcanvasContent">
            {/* Off-canvas content */}
            <div className="d-flex align-items-center">
              <img src={p_map_loc_marker} width={"16px"} height={"16px"} />
              <div className="ff-poppins-semiBold fs-20 text-truncate ms-1">
                {city}
              </div>
            </div>
            <div className="ff-poppins-regular fs-14">{address}</div>
            <form className="" onSubmit={handleSubmit} style={{ marginTop: 20 }}>
              <input
                type="text"
                className="w-100 ff-poppins-regular fs-16"
                placeholder="House / Flat / Block NO"
                name="door_no"
                value={formData.door_no}
                onChange={(e) => handleChange(e)}
              /><input
                type="text"
                className="w-100 ff-poppins-regular fs-16"
                placeholder="Appartment / Road / Area"
                name="area"
                value={formData.area}
                onChange={(e) => handleChange(e)}
              />

              <textarea
                placeholder="Direction To Reach"
                className="w-100 ff-poppins-regular fs-16 border"
                rows={3}
                name="direction"
                value={formData.direction}
                style={{ borderRadius: 5 }}
                onChange={(e) => handleChange(e)}
              ></textarea>

              <div className="ff-poppins-regular fs-16 addressTypeLabel">
                SAVE AS
              </div>

              <div
                className="btn-group addressType"
                role="group"
                aria-label="Basic radio toggle button group" >
                {locationTypes.map((item) => (
                  <>
                    <input
                      type="radio"
                      className="btn-check"
                      name="location_type_id"
                      id={item.id}
                      value={item.id}
                      autocomplete="off"
                      onChange={(e) => handleChange(e)}
                      disabled={formData.location_id == '' ? item.status : false}
                      checked={formData.location_type_id === item.id}
                    />
                    <label className="btn btn-outline-primary mb-2" for={item.id}>
                      <div className="d-flex ff-poppins-semiBold fs-16">
                        <img
                          src={item.icon}
                          width={"20px"}
                          height={"20px"}
                          alt="Address type - Home"
                          role="button"
                        />
                        <div className="ms-2 text-dark">{item.name}</div>
                      </div>
                    </label>
                  </>
                ))}
              </div>
              {
                formData.location_type_id == 3 ?
                  <input
                    type="text"
                    className="w-100 ff-poppins-regular fs-16 mt-4"
                    placeholder="Location name"
                    name="location_type"
                    value={formData.location_type}
                    onChange={(e) => handleChange(e)}
                  /> : <></>
              }


              <button type="submit" className={`text-center w-100 border-0 ff-poppins-semiBold fs-16 mt-3 ${isFormValid ? "primary-bg text-white" : "secondary-bg secondary-text-1 "}`}
                disabled={!isFormValid} // Disable button if form is not valid
                onClick={handleSubmit}>
                SAVE LOCATION
              </button>
            </form>
          </div>

        </div>

        <div className={`h-100 saveProductAddress offcanvas offcanvas-bottom ${showChangeOffCanvas ? 'show' : ''}`}
          tabIndex="-1"
          id="offCanvas" >
          <div className="offcanvas-header">
            <button
              type="button"
              className="btn-close text-reset"
              onClick={toggleChangeOffCanvas}
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            {/* Off-canvas content */}
            <div className="pt-3 pb-3 serachArea px-2">
              <input
                type="search"
                className="w-100 ff-poppins-semiBold fs-16"
                placeholder="Search address"
                onChange={handleChangelocation}

              />

              <div className="d-flex align-items-center mt-3 pt-1 pb-1">
                <img
                  src={p_map_loc_marker}
                  width={"20px"}
                  height={"20px"}
                  alt="Location Button"
                  role="button"
                />
                <div
                  className="ff-poppins-semiBold fs-13 primary-text ms-2 pe-1"
                  role="button"
                  onClick={() =>
                    toggleChangeOffCanvas()
                  }
                >
                  Use my current location
                </div>
              </div>
            </div>

            {searchResults.length > 0 && (
              <div className="searchResults">
                <div className="ff-poppins-light fs-13 heading">SEARCH RESULT</div>

                <ul className="m-0 p-0" style={{ listStyleType: 'none' }}>
                  {searchResults?.map((item) => (
                    <li className="mb-3" onClick={() => handlePlaceSelect(item)}>
                      <div className="d-flex">
                        <img
                          src={p_search_location_arrow_secondary}
                          width={"14px"}
                          height={"14px"}
                          alt="Location Button"
                          role="button"
                        />
                        <div className="ff-poppins-semiBold fs-12 title ms-2 ps-1 text">
                          {item?.structured_formatting.main_text}
                        </div>
                      </div>
                      <div className="ff-poppins-light fs-11 text">
                        {item?.description}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

        </div>
      </div>


      {/* <div
                className="saveProductAddress offcanvas offcanvas-bottom"
                tabIndex="-1"
                id="offcanvasBottom"
                aria-labelledby="offcanvasBottomLabel" >
                <div className="offcanvas-body small">
                    <div className="d-flex align-items-center">
                        <img src={p_map_loc_marker} width={"16px"} height={"16px"} />
                        <div className="ff-poppins-semiBold fs-20 text-truncate ms-1">
                            {city}
                        </div>
                    </div>
                    <div className="ff-poppins-regular fs-14">{address}</div>

                    
                </div>
            </div>   */}


    </div>
  ) : (
    <></>
  );
};

export default AddDeliveryAddress;
