import { useState } from 'react';
import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import { getChatList } from '../../Services/api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const MessengerList = () => {

    const navigate = useNavigate();
    const [chatList, setChatList] = useState([]);

    useEffect(() => {
        getChatList().then((res) => {
            setChatList(res.data?.chat_history) 
        });
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const monthNames = [
            "Jan", "Feb", "Mar",
            "Apr", "May", "Jun", "Jul",
            "Aug", "Sep", "Oct",
            "Nov", "Dec"
        ];

        const month = monthNames[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();



        return date.getFullYear() === new Date().getFullYear()
            ? `${month} ${day < 10 ? '0' + day : day}`
            : `${month} ${day < 10 ? '0' + day : day}, ${year}`
    };

    function isYesterday(date) {
        // Get today's date
        const today = new Date();
        date = new Date(date);

        // Set up a new Date object for yesterday
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        // Check if the given date is equal to yesterday's date
        return date.getDate() === yesterday.getDate() &&
            date.getMonth() === yesterday.getMonth() &&
            date.getFullYear() === yesterday.getFullYear();
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white shadow-none">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>Messages</div>
                </div>
            </div>

            <div className='messengerList scroller-style-y'>
                {
                    chatList.map(item => (
                        <div className='item d-flex bg-white w-100' role='button' onClick={() => navigate(`/chat/${item.sender_id}`, { state: { vendorImage: item.sender_image, vendorName: item.sender_name } })}>
                            <img src={item.sender_image} width={'47px'} height={'47px'} className='rounded-circle userImg' />
                            <div className='d-flex justify-content-between w-100'>
                                <div>
                                    <div className='senderName ff-poppins-medium fs-16 text-truncate'>{item.sender_name}</div>
                                    <div className='ff-poppins-light fs-13 message text-truncate'>
                                        {
                                            item.last_message.trim() != ''
                                                ?
                                                <span>{item.last_message}</span>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>

                                {/* Today  */}
                                {new Date().setHours(0, 0, 0, 0) === new Date(item.last_msg_date).setHours(0, 0, 0, 0) && (
                                    <div className='ff-poppins-light fs-11'>{item.last_msg_time}</div>
                                )}
                                {/* Yesterday  */}
                                {
                                    (new Date().setHours(0, 0, 0, 0) !== new Date(item.last_msg_date).setHours(0, 0, 0, 0))
                                    &&
                                    isYesterday(new Date(item.last_msg_date).setHours(0, 0, 0, 0))
                                    && (
                                        <div className='ff-poppins-light fs-12'>Yesterday {item.last_msg_time}</div>
                                    )
                                }
                                {/* Other Dates  */}
                                {
                                    (new Date().setHours(0, 0, 0, 0) !== new Date(item.last_msg_date).setHours(0, 0, 0, 0))
                                    &&
                                    !isYesterday(new Date(item.last_msg_date).setHours(0, 0, 0, 0))
                                    && (
                                        <div className='ff-poppins-light fs-12'>{formatDate(item.last_msg_date)}, {item.last_msg_time}</div>
                                    )
                                }



                            </div>
                        </div>
                    ))
                }

            </div>
        </>
    )
}

export default MessengerList