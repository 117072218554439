import React, { useEffect, useState } from "react";
import "../../Assets/Styles/offCanvas.css";
import { useNavigate } from "react-router-dom";
import { sendEnquiryQuestion } from "../../Services/api";

const ServiceEnquiryOffCanvas = ({
  isOpen,
  cartAppId,
  vendorId,
  serviceId,
  category,
  toggleOffCanvas
}) => {
  const navigate = useNavigate();
  const [enquiryQuestion, setEnquiryQuestion] = useState(null);


  useEffect(() => {
    if (isOpen) {
      console.log(cartAppId);
      console.log(vendorId);
      console.log(serviceId);
      console.log(category);
    }
  }, [isOpen]);

  function sendEnquiry() {

    let param = {
      "cart_app_id": cartAppId,
      "vendor_id": vendorId,
      "service_id": serviceId,
      "enquiry_question": enquiryQuestion
    }
    sendEnquiryQuestion(param).then((res) => {

      if (res.data?.status == '1') {
        setEnquiryQuestion('');
        toggleOffCanvas();
        navigate(`/chat/enquery/${res.data?.enquiry_id}`);
      }
    });
  }

  return (
    <div
      className={`offcanvas offcanvas-bottom serviceEnquiryModal ${isOpen ? "show" : ""
        }`}
      tabindex="-1"
      aria-labelledby="serviceEnquiryModalLabel"
      style={{ position: "sticky", zIndex: '10000' }}
    >
      <div className="offcanvas-body small">
        <div className="ff-poppins-semiBold fs-14">
          Service Enquiry
        </div>
        <div className="ff-poppins-regular fs-12">
          {category}
        </div>

        <textarea class="form-control ff-poppins-regular fs-14 mt-2 mb-2 enquiryTextarea" rows={5} placeholder='Please provide details of your service enquiry.' value={enquiryQuestion} onChange={ev => setEnquiryQuestion(ev.target.value)}></textarea>

        <button type='button'
          className='btn-type-1 ff-poppins-semiBold fs-14 w-100 primary-bg border-0 text-white  justify-content-center d-flex align-items-center'
          onClick={sendEnquiry}
        >
          Send Enquiry
        </button>
      </div>
    </div>
  );
};

export default ServiceEnquiryOffCanvas;
