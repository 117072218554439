import axiosInstance from "./axios";

export const userLogin = (body) => {
    return axiosInstance.post('User/user_login_details', body)
}

export const userResendOTP = (body) => {
    return axiosInstance.post('user/user_resend_otp', body)
}

export const userVerifyOTP = (body) => {
    return axiosInstance.post('user/user_verify_otp', body)
}

export const userEmailVerifyOTP = (body) => {
    return axiosInstance.post('user/user_email_verify_otp', body)
}

export const userRegister = (body) => {
    return axiosInstance.post('user/user_register_details', body)
}

export const uploadFamilyMemberImage = (body) => {
    return axiosInstance.post('user/upload_family_member_image', body)
}

export const sendAttachment = (body) => {
    return axiosInstance.post('user/send_attachment', body)
}

export const uploadMyProfileImage = (body) => {
    return axiosInstance.post('user/upload_myprofile_image', body)
}

export const uploadAadharImage = (body) => {
    return axiosInstance.post('user/upload_aadhar_image', body)
}

export const addUserFamilyMember = (body) => {
    return axiosInstance.post('user/add_user_family_member', body)
}

export const userUpdateSingleFamilyMemberDetail = (body) => {
    return axiosInstance.post('user/user_update_single_family_member_detail', body)
}

export const updateProfileDetails = (body) => {
    return axiosInstance.post('user/update_profile_details', body)
}

export const verifyEmailAddress = (body) => {
    return axiosInstance.post('user/verify_email_address', body)
}

export const userAllFamilyMembers = () => {
    return axiosInstance.post('user/user_all_family_members', {})
}

export const userSingleFamilyMemberDetail = (body) => {
    return axiosInstance.post('user/user_single_family_member_detail', body)
}

export const userSinglePetDetail = (body) => {
    return axiosInstance.post('user/user_single_pet_detail', body)
}

export const userSingleVehicleDetail = (body) => {
    return axiosInstance.post('user/user_single_vehicle_detail', body)
}

export const userUpdateSingleVehicleDetail = (body) => {
    return axiosInstance.post('user/user_update_single_vehicle_detail', body)
}

export const deleteFamilyMember = (body) => {
    return axiosInstance.post('user/delete_family_member', body)
}

export const deleteUserPet = (body) => {
    return axiosInstance.post('user/delete_pet', body)
}

export const deleteUserVehicle = (body) => {
    return axiosInstance.post('user/delete_vehicle', body)
}

export const getPetTypes = (body) => {
    return axiosInstance.post('user/pet_types', body)
}

export const uploadPetImage = (body) => {
    return axiosInstance.post('user/upload_pet_image', body)
}

export const addUserPet = (body) => {
    return axiosInstance.post('user/add_user_pet', body)
}

export const userUpdateSinglePetDetail = (body) => {
    return axiosInstance.post('user/user_update_single_pet_detail', body)
}

export const userAllPetsDetail = () => {
    return axiosInstance.post('user/user_all_pets_detail', {})
}

export const getUserAllVehicles = (body) => {
    return axiosInstance.post('user/user_all_vehicles', body)
}

export const uploadVehicleImage = (body) => {
    return axiosInstance.post('user/upload_vehicle_image', body)
}

export const getVehicleTypeAndCategory = (body) => {
    return axiosInstance.post('user/vehicle_type_and_category', body)
}

export const addUserVehicle = (body) => {
    return axiosInstance.post('user/add_user_vehicle', body)
}

export const getMyTransactions = () => {
    return axiosInstance.post('user/my_transactions', {})
}

export const getMyFollowingList = () => {
    return axiosInstance.post('user/my_following_list', {})
}

export const getBookingList = () => {
    return axiosInstance.post('user/index', {})
}

export const getBookingDetails = (body) => {
    return axiosInstance.post('user/get_booking_details', body)
}

export const getMyProfileDetails = () => {
    return axiosInstance.post('user/my_profile_details', {})
}

export const getBulletinDetails = () => {
    return axiosInstance.post('user/bulletin_details', {})
}

// Search Module Starts
export const getSearchFilter = () => {
    return axiosInstance.post('user/get_search_fillter', {})
}

export const getSearchHistory = () => {
    return axiosInstance.post('user/search_history', {})
}

export const addSearchHistory = (body) => {
    return axiosInstance.post('user/add_search_history', body)
}

export const clearSearchHistory = () => {
    return axiosInstance.post('user/clear_search_history', {})
}

export const getMyLocations = () => {
    return axiosInstance.post('user/my_locations', {})
}

export const getLocationTypes = () => {
    return axiosInstance.post('user/location_types', {})
}

export const getSearchVendor = (body) => {
    return axiosInstance.post('user/search_vendor', body)
}
// Search Module Ends

export const deleteMyAccount = () => {
    return axiosInstance.post('user/delete_my_account', {})
}

export const getVendorProfile = (body) => {
    return axiosInstance.post('user/vendor_profile_details', body)
}
export const getVendorLoyalty = (body) => {
    return axiosInstance.post('user/my_vendor_loyalty_points_detail', body)
}

export const getVendorProfileWithoutLogin = (body) => {
    return axiosInstance.post('user/vendor_profile_details_without_login', body)
}

export const getVendorServiceDetails = (body) => {
    return axiosInstance.post('user/vendor_service_detail_new_without_login', body)
}

export const cartSelectService = (body) => {
    return axiosInstance.post('user/cart_select_service_without_login', body)
}

export const checkValidCart = (body) => {
    return axiosInstance.post('user/check_valid_cart', body)
}

export const confirmBooking = (body) => {
    return axiosInstance.post('user/confirm_booking', body)
}

export const saveMyLocation = (body) => {
    return axiosInstance.post('user/save_my_location', body)
}

export const updateMySavedLocation = (body) => {
    return axiosInstance.post('user/update_my_saved_location', body)
}

export const removeMySavedLocation = (body) => {
    return axiosInstance.post('user/remove_my_saved_location', body)
}

export const clearRecentLocations = () => {
    return axiosInstance.post('user/clear_recent_locations', {})
}

export const addRecentLocation = (body) => {
    return axiosInstance.post('user/select_location', body)
}

export const getChatList = () => {
    return axiosInstance.post('user/user_chat_list_details_new', {})
}

export const createNewUserVendorChat = (body) => {
    return axiosInstance.post('user/create_new_user_vendor_chat', body)
}

export const userSingleChatDetailsNew = (body) => {
    return axiosInstance.post('user/user_single_chat_details_new', body)
}

export const userBookingChatDetails = (body) => {
    return axiosInstance.post('user/user_booking_chat_details', body)
}

export const userOrderChatDetails = (body) => {
    return axiosInstance.post('user/user_order_chat_details', body)
}

export const userServiceEnquiryChatDetails = (body) => {
    return axiosInstance.post('user/user_service_enquiry_chat_details', body)
}

export const sendChatMessage = (body) => {
    return axiosInstance.post('user/send_chat_message', body)
}

export const sendEnquiryQuestion = (body) => {
    return axiosInstance.post('user/enquiry_service', body)
}

export const userFamilyList = (body) => {
    return axiosInstance.post('user/user_family_list', body)
}

export const userPetsList = (body) => {
    return axiosInstance.post('user/user_pets_list', body)
}

export const userVehiclesList = (body) => {
    return axiosInstance.post('user/user_vehicles_list', body)
}

export const cartSelectPetOrPerson = (body) => {
    return axiosInstance.post('user/cart_select_pet_or_person', body)
}

export const vendorStaffList = (body) => {
    return axiosInstance.post('user/vendor_staff_list', body)
}

export const cartSelectStaff = (body) => {
    return axiosInstance.post('user/cart_select_staff', body)
}

export const serviceTimeSlotDetailsNew = (body) => {
    return axiosInstance.post('user/service_time_slot_details_new', body)
}

export const cartAddServiceTimeslot = (body) => {
    return axiosInstance.post('user/cart_add_service_timeslot', body)
}

export const singleCartDetails = (body) => {
    return axiosInstance.post('user/single_cart_details', body)
}

export const followUnfollowDetails = (body) => {
    return axiosInstance.post('user/follow_unfollow_details', body)
}
// notification count 

export const notificationCounts = () => {
    return axiosInstance.post('user/get_notifications_count')
}

// Product Module

// export const vendorProductList = (body) => {
//     return axiosInstance.post('user/vendor_products_list',body)
// }
