import { useNavigate } from 'react-router-dom';
import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import { useEffect, useState } from 'react';
import { getMyTransactions } from '../../Services/api';
import { IoIosArrowUp } from "react-icons/io";
import { IoChevronDown } from "react-icons/io5";
import { LuIndianRupee } from "react-icons/lu";
const Transactions = () => {

    const navigate = useNavigate();
    const [transactionsList, setTransactionsList] = useState([]);
    const [openIndices, setOpenIndices] = useState([]);
    const dateOptions = { year: "numeric", month: "short", day: "numeric" };
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    useEffect(() => {
        getMyTransactions().then((res) => {
            console.log('res', res.data.user_transactions);
            setTransactionsList(res.data.user_transactions);
        });
    }, []);
    const isItemOpen = (index) => openIndices.includes(index);
    const toggleAccordion2 = (index) => {
        if (openIndices.includes(index)) {
            setOpenIndices(openIndices.filter((i) => i !== index));
        } else {
            setOpenIndices([...openIndices, index]);
        }
    };
    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>Transactions</div>
                </div>
            </div>

            <div className='transactionsList scroller-style-y pt-1'>
                {
                    transactionsList.length > 0 ?
                        <div class="accordion" id="accordionExamples">
                            {
                                transactionsList.map((item, index) => {
                                    return (
                                        <div class="accordion-item">
                                            {/* <h2 class="accordion-header" id={"heading" + item.transaction_id}>
                                                <button class="accordion-button collapsed border-bottom" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + item.transaction_id} aria-expanded="true" aria-controls={"collapse" + item.transaction_id}>
                                                    <div className='d-flex justify-content-between w-100 me-3'>
                                                        <div>
                                                            <div className='ff-poppins-semiBold fs-18 mb-2'>
                                                                {item.vendor_name}
                                                            </div>
                                                            <div className='ff-poppins-regular fs-12 mt-1'>{item.transaction_date}</div>
                                                        </div>
                                                        <div className='text-end'>
                                                            <div className='ff-poppins-semiBold fs-16 mb-1'>({item.transaction_type_name})  ₹{item.transaction_amount}</div>
                                                            <div className='ff-poppins-semiBold fs-18 success-text mt-1'>Successfull</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </h2> */}
                                            <h2 className="accordion-header" id={"heading" + index}>
                                                <button className={`serviceMenu-but accordion-button collapsed ff-poppins-bold fs-16 pb-1 ${isItemOpen(index) ? "" : "collapsed"}`} type="button"
                                                    onClick={() => toggleAccordion2(index)} aria-expanded={isItemOpen(index) ? "true" : "false"}  >
                                                    <div>
                                                        <div className='ff-poppins-semiBold fs-18 mb-2 singleline-text'>  {item.vendor_name} </div>
                                                        <div className='ff-poppins-regular fs-12 mt-1'> {new Date(item.transaction_date).toLocaleDateString("en-US", dateOptions, timeOptions)} { }</div>
                                                    </div>

                                                    <div className='d-flex justify-content-around'>
                                                        <div className='me-2'>
                                                            <div className='ff-poppins-semiBold fs-16 mb-1'>({item.transaction_type_name})  ₹{item.transaction_amount}</div>
                                                            <div className='ff-poppins-semiBold fs-18 success-text mt-1'>Successfull</div>
                                                        </div>
                                                        <p>{isItemOpen(index) ? (<IoIosArrowUp style={{ fontSize: 20 }} />) : (<IoChevronDown style={{ fontSize: 20 }} />)}</p>
                                                        <style> {""}  {`button::after {  display: none; position: absolute;  content: '';   }`}   </style>
                                                    </div>
                                                </button>
                                            </h2>
                                            {/* <div id={"collapse" + item.transaction_id} class="accordion-collapse collapse" aria-labelledby={"heading" + item.transaction_id} data-bs-parent="#accordionExample">
                                                <div class="transaction-accordian-body"> */}
                                            <div id={"collapseOne" + index} className={`accordion-collapse collapse ${isItemOpen(index) ? "show" : ""}`} aria-labelledby={"heading" + index}>
                                                <div className="transaction-accordian-body secondary-bg-2">
                                                    <div className='d-flex justify-content-between mb-3'>
                                                        <span className='ff-poppins-regular fs-14'>Transaction Id</span>
                                                        <span className='ff-poppins-regular fs-15'>{item.transaction_id}</span>
                                                    </div>
                                                    <div className='d-flex justify-content-between mb-3'>
                                                        <span className='ff-poppins-regular fs-14'>Onine Full Pay</span>
                                                        <span className='ff-poppins-regular fs-15'><LuIndianRupee /> {item.transaction_amount}</span>
                                                    </div>
                                                    <div className='d-flex justify-content-between mb-3'>
                                                        {item.payment_for_name == "Product" ?
                                                            <span className='ff-poppins-regular fs-14'>order id</span> :
                                                            <span className='ff-poppins-regular fs-14'>Booking id</span>
                                                        }
                                                        <span className='ff-poppins-regular fs-15'>{item.booking_id}{item.order_id} </span>
                                                    </div>
                                                    <div className='d-flex justify-content-between mb-3'>
                                                        <span className='ff-poppins-regular fs-14'>Payment method</span>
                                                        {item.payment_for_name == "Product" ?
                                                            <span className='ff-poppins-regular fs-14'>{item.transaction_method_name}</span> :
                                                            <span className='ff-poppins-regular fs-14'>{item.payment_method_name} </span>
                                                        }
                                                    </div>
                                                    <div className='text-end'>
                                                        <button
                                                            className='ff-poppins-regular fs-15 border-0 text-white primary-bg viewBtn'
                                                            onClick={() => {
                                                                if (item.payment_for_name == "Service") {
                                                                    navigate(`/details/${item.booking_id}`)
                                                                }
                                                                else {
                                                                    navigate('/ProductPass', { state: { orderid: item.order_id } })
                                                                }
                                                            }}>
                                                            View Details
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        :
                        <div className='ff-poppins-semiBold fs-14 w-100 h-100 d-flex align-items-center justify-content-center'>
                            No Data Found
                        </div>
                }

            </div>
        </>
    )
}

export default Transactions