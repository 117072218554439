import React, { useEffect, useState } from "react";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SlArrowDown } from "react-icons/sl";
import { SlArrowUp } from "react-icons/sl";
import axiosInstance from "../../Services/axios";
import { RiDeleteBin5Line } from "react-icons/ri";
import OfferCard from "./offercard";
import ReactLoading from "react-loading";
import p_vendor_map from "../../Assets/Images/p_vendor_map.svg"
const Singleofferdetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { vendor_Id, offer_id } = location.state || {};
  const [offerdetails, setOfferdetails] = useState();
  const [showContentBI, setShowContentBI] = useState(true);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [offertime, setOffertime] = useState([]);
  const [showstore, setShowstore] = useState(true);
  const [showdoorstep, setDoorstep] = useState(true);
  const [showproduct, setShowproduct] = useState(true);
  const [storeservice, setStoreservice] = useState([]);
  const [doorstepservice, setDoorstepservice] = useState([]);
  const [product, setProduct] = useState([]);
  const [buyServices, setBuyServices] = useState([]);
  const [getServices, setGetServices] = useState([]);
  const [buydoorServices, setBuydoorServices] = useState([]);
  const [getdoorServices, setGetdoorServices] = useState([]);
  const [buyProduct, setBuyProduct] = useState([]);
  const [getProduct, setGetProduct] = useState([]);
  const [data, setData] = useState()
  const [vendor, setVendor] = useState()
  const dateOptions = { year: "numeric", month: "short", day: "numeric" };
  let { vendorId, offerid } = useParams();

  useEffect(() => {
    offerdetail();
    OfferProduct();

  }, []);

  function openMap(lat, lng) {
    const mapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
    window.open(mapsUrl, '_blank');
  }
  // const terms = termsAndConditions.split('•').filter(term => term.trim().length > 0);
  const offerdetail = () => {
    try {
      let reqData = {
        vendor_id: vendorId,
        offer_id: offerid,
      };

      axiosInstance
        .post("User_offer/get_vendor_offer_detail_without_login", reqData)
        .then((res) => {
          setVendor(res.data.vendor_details)
          setOfferdetails(res.data.offer_detail);
          setOffertime(res.data.offer_detail.offer_availability);
          setPageLoaded(true);
        });
    } catch (error) {
      console.log("error --->", error);
    }
  };
  const OfferProduct = () => {
    try {
      let reqData = {
        vendor_id: vendorId,
        offer_id: offerid,
      };
      axiosInstance.post("User_offer/get_vendor_offer_price_detail_without_login", reqData)
        .then((res) => { 
          let data = res.data;
          setData(data)
          setStoreservice(data.offer_Details.services_name);
          setDoorstepservice(data.offer_Details.doorstep_services_name);
          setProduct(data.offer_Details.products_name);
          setBuyServices(data.offer_Details.buy_services_name);
          setGetServices(data.offer_Details.get_services_name);
          setBuydoorServices(data.offer_Details.buy_doorstep_services_name);
          setGetdoorServices(data.offer_Details.get_doorstep_services_name);
          setBuyProduct(data.offer_Details.buy_product_name);
          setGetProduct(data.offer_Details.get_product_name);
        });
    } catch { }
  };

  const totalProductPrice = product.reduce((accumulator, product) => {
    return accumulator + parseFloat(product.product_price);
  }, 0);

  const totalServicePrice = storeservice.reduce((total, storeservice) => {
    return total + parseFloat(storeservice.service_price);
  }, 0);

  const totalBuyservicePrice = buyServices.reduce((cost, buyServices) => {
    return cost + parseFloat(buyServices.service_price);
  }, 0);

  const totalGetservicePrice = getServices.reduce((cost, getServices) => {
    return cost + parseFloat(getServices.service_price);
  }, 0);

  const totalBuydoorservicePrice = buydoorServices.reduce(
    (costs, buydoorServices) => {
      return costs + parseFloat(buydoorServices.doorstep_price);
    },
    0
  );
  const totalGetdoorservicePrice = getdoorServices.reduce((costs, index) => {
    return costs + parseFloat(index.doorstep_price);
  }, 0);
  const totalBuyProductPrice = buyProduct.reduce((costs, index) => {
    return costs + parseFloat(index.product_price) * index.count;
  }, 0);
  const totalGetProductPrice = getProduct.reduce((total, product) => {
    return total + parseFloat(product.product_price) * product.count;
  }, 0);

  const toggleContentBI = () => {
    setShowContentBI(!showContentBI);
  };
  const togglestore = () => {
    setShowstore(!showstore);
  };
  const toggledoorstep = () => {
    setDoorstep(!showdoorstep);
  };
  const toggleproduct = () => {
    setShowproduct(!showproduct);
  };



  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
        <div className="d-flex justify-content-between align-items-center ms-2 me-2">
          <img
            src={p_nav_previous}
            width={"24px"}
            height={"16px"}
            alt="Previous Button"
            role="button"
            onClick={() => navigate(`/Offerlist/${data?.vendor_id}`)}
          />

          <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
            Offer Details
          </div>
        </div>
      </div>

      {pageLoaded ? (
        <div>
          <div className="scroller-style-y">
            <div>{offerdetails ? <OfferCard data={offerdetails} /> : <></>}</div>
            <div>
              <hr></hr>
              <div className="p-3">
                <div className="m-0">
                  <h5 className="ff-poppins-semiBold">
                    {offerdetails.offer_name}{" "}
                  </h5>
                  <div className="ff-poppins-semiBold primary-text">
                    {offerdetails?.offer_type == "3" ? (
                      <h6>Buy Get Offer</h6>
                    ) : offerdetails?.offer_type == "2" ? (
                      <h6> Get Offer</h6>
                    ) : offerdetails?.offer_type == "1" ? (
                      <h6>
                        Get {offerdetails?.discount}
                        <span> {offerdetails?.discount_type == "1" ? ("₹") : offerdetails?.discount_type == "2" ? ("%") : (<></>)}
                        </span>
                        Offer
                      </h6>
                    ) : (
                      <></>
                    )}
                  </div>

                  <p>{offerdetails?.offer_description} </p>

                  <div
                    className="d-flex justify-content-between mt-4 "
                    onClick={toggleContentBI}
                    style={{ cursor: 'pointer' }}
                  >
                    <div>
                      <h6 className="primary-text fs-15 ff-poppins-bold">
                        <b>Offer Information</b>
                      </h6>
                    </div>
                    <div>
                      {showContentBI ? (
                        <SlArrowDown className="me-2" />
                      ) : (
                        <SlArrowUp className="me-2" />
                      )}
                    </div>
                  </div>
                  {showContentBI && (
                    <div>
                      {
                        // General Offer
                        offerdetails?.offer_type == "1" ? (
                          <div>
                            <div>
                              {((offerdetails?.applied_to === '1') || (offerdetails?.applied_to === '2,1') || (offerdetails?.applied_to === '1,2')) && (
                                <div>
                                  <div className="d-flex justify-content-between mt-4 " onClick={togglestore}>
                                    <div>
                                      <h6 className=" fs-15 ff-poppins-bold">
                                        <b>Store Services included</b>
                                      </h6>
                                    </div>
                                    <div>
                                      {showstore ? (
                                        <SlArrowDown className="me-2" />
                                      ) : (
                                        <SlArrowUp className="me-2" />
                                      )}
                                    </div>
                                  </div>
                                  {/* Store modle */}
                                  {showstore && (
                                    storeservice.map((service, index) => (
                                      <div className='d-flex justify-content-between fs-13' key={index}>
                                        <p className='col-8'>{service.service_name}</p>
                                        <p>{service.service_price}.00</p>
                                      </div>
                                    ))
                                  )}
                                  <div className="d-flex justify-content-between mt-4 " onClick={toggledoorstep}>
                                    <div>
                                      <h6 className=" fs-15 ff-poppins-bold">
                                        <b>Doorstep Services included</b>
                                      </h6>
                                    </div>
                                    <div>
                                      {showdoorstep ? (
                                        <SlArrowDown className="me-2" />
                                      ) : (
                                        <SlArrowUp className="me-2" />
                                      )}
                                    </div>
                                  </div>
                                  {showdoorstep && (
                                    doorstepservice.map((service, index) => (
                                      <div className='d-flex justify-content-between fs-13' key={index}>
                                        <p>{service.service_name}</p>
                                        <p>{service.doorstep_price}.00</p>
                                      </div>
                                    ))
                                  )}
                                </div>
                              )}
                              {(offerdetails?.applied_to === '2' || offerdetails?.applied_to === '2,1' || offerdetails?.applied_to === '1,2') && (
                                <div>
                                  <div className="d-flex justify-content-between mt-4 " onClick={toggleproduct}>
                                    <div>
                                      <h6 className=" fs-15 ff-poppins-bold">
                                        <b>Products</b>
                                      </h6>
                                    </div>
                                    <div>
                                      {showproduct ? (
                                        <SlArrowDown className="me-2" />
                                      ) : (
                                        <SlArrowUp className="me-2" />
                                      )}
                                    </div>
                                  </div>
                                  {showproduct && (
                                    product.map((item, index) => (
                                      <div className='d-flex justify-content-between fs-13' key={index}>
                                        <p className='col-8'>{item.product_name}</p>
                                        <p>{item.product_price}.00</p>
                                      </div>
                                    ))
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        ) : // Combo offer
                          offerdetails?.offer_type == "2" &&
                            offerdetails.applied_to == "2" ? (
                            <div>
                              <div
                                className="d-flex justify-content-between mt-4 "
                                onClick={toggleproduct}
                              >
                                <div>
                                  <h6 className=" fs-15 ff-poppins-bold">
                                    <b>Product</b>
                                  </h6>
                                </div>
                                <div>
                                  {showproduct ? (
                                    <SlArrowDown className="me-2" />
                                  ) : (
                                    <SlArrowUp className="me-2" />
                                  )}
                                </div>
                              </div>
                              {showproduct &&
                                product.map((item, index) => {
                                  return (
                                    <div className="d-flex justify-content-between fs-13">
                                      <p className="col-8">
                                        {/* <span><RiDeleteBin5Line className='text-danger' /></span> */}
                                        {item.product_name}{" "}
                                      </p>
                                      <div className="d-flex justify-content-around">
                                        {/* <p className=''>{}</p> */}
                                        <p>{item.product_price}.00 </p>
                                      </div>
                                    </div>
                                  );
                                })}
                              <div className="">
                                <div className=" fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                                  <p className="m-0">Subtotal</p>
                                  <p className="m-0">{totalProductPrice}.00 </p>
                                </div>
                                <div className="totalBar primary-text fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                                  <p className="m-0">
                                    Saved by offer(Rs{offerdetails.discount} )
                                  </p>
                                  <p className="m-0">
                                    Rs.{offerdetails.discount}.00
                                  </p>
                                </div>
                                <div className="savedBar fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                                  <p className="m-0">Total</p>
                                  <p className="m-0">
                                    Rs {totalProductPrice - offerdetails.discount}
                                    .00
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : offerdetails?.offer_type == "2" &&
                            offerdetails.applied_to == "1" ? (
                            <div>
                              <div
                                className="d-flex justify-content-between mt-4 "
                                onClick={togglestore}
                              >
                                <div>
                                  <h6 className=" fs-15 ff-poppins-bold">
                                    <b>Store Services included</b>
                                  </h6>
                                </div>
                                <div>
                                  {showstore ? (
                                    <SlArrowDown className="me-2" />
                                  ) : (
                                    <SlArrowUp className="me-2" />
                                  )}
                                </div>
                              </div>
                              {/* service modle */}
                              {showstore &&
                                storeservice.map((service, index) => {
                                  return (
                                    <div className="d-flex justify-content-between fs-13">
                                      <p className="col-8">
                                        {/* <span><RiDeleteBin5Line className='text-danger' /></span> */}
                                        {service.service_name}{" "}
                                      </p>
                                      <div className="d-flex justify-content-around">
                                        {/* <p className=''>{}</p> */}
                                        <p>{service.service_price}.00</p>
                                      </div>
                                    </div>
                                  );
                                })}
                              <div className="">
                                <div className=" fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                                  <p className="m-0">Subtotal</p>
                                  <p className="m-0">{totalServicePrice} </p>
                                </div>
                                <div className="totalBar primary-text fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                                  <p className="m-0">
                                    Saved by offer(Rs{offerdetails.discount}.00 )
                                  </p>
                                  <p className="m-0">
                                    Rs.{offerdetails.discount}.00
                                  </p>
                                </div>
                                <div className="savedBar fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                                  <p className="m-0">Total(Offer Price)</p>
                                  <p className="m-0">
                                    Rs.{totalServicePrice - offerdetails.discount}
                                    .00
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : // BuyGet Offer
                            offerdetails?.offer_type == "3" &&
                              offerdetails.applied_to == "1" ? (
                              <div>
                                <h5 className="fs-14 ff-poppins-semiBold">
                                  Buy Store Service
                                </h5>
                                {buyServices.map((buyser, index) => {
                                  return (
                                    <div className="d-flex justify-content-between fs-13">
                                      <p className="col-8">
                                        {/* <span><RiDeleteBin5Line className='text-danger' /></span> */}
                                        {buyser.service_name}{" "}
                                      </p>
                                      <div className="d-flex justify-content-around">
                                        {/* <p className=''>{}</p> */}
                                        <p>{buyser.service_price}.00</p>
                                      </div>
                                    </div>
                                  );
                                })}
                                <div className="totalBar fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                                  <p className="m-0">
                                    Total{" "}
                                    <span className="primary-text">
                                      (Offer Price)
                                    </span>{" "}
                                  </p>
                                  <p className="m-0">Rs.{totalBuyservicePrice}.00</p>
                                </div>

                                <h5 className="fs-14 ff-poppins-semiBold mt-3">
                                  Get Store Service
                                </h5>
                                {getServices.map((getser, index) => {
                                  return (
                                    <div className="d-flex justify-content-between fs-13">
                                      <p className="col-8">
                                        {/* <span><RiDeleteBin5Line className='text-danger' /></span> */}
                                        {getser.service_name}{" "}
                                      </p>
                                      <div className="d-flex justify-content-around">
                                        {/* <p className=''>{}</p> */}
                                        <p>{getser.service_price}.00</p>
                                      </div>
                                    </div>
                                  );
                                })}
                                <div className="totalBar fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                                  <p className="m-0">
                                    Total{" "}
                                    <span className="primary-text">
                                      (Offer Price)
                                    </span>{" "}
                                  </p>
                                  <p className="m-0">Rs.{totalGetservicePrice}.00</p>
                                </div>
                                {
                                  buydoorServices.length > 0 ?
                                    <div>
                                      <h5 className="fs-14 ff-poppins-semiBold mt-3">
                                        Buy Doorstep Service
                                      </h5>
                                      {buydoorServices.map((doorser, index) => {
                                        return (
                                          <div className="d-flex justify-content-between fs-13">
                                            <p className="col-8">
                                              {doorser.service_name}{" "}
                                            </p>
                                            <div className="d-flex justify-content-around">
                                              {/* <p className=''>{}</p> */}
                                              <p>
                                                {doorser.doorstep_price > 0
                                                  ? doorser.doorstep_price
                                                  : "0"}
                                                .00
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      })}
                                      <div className="totalBar fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                                        <p className="m-0">
                                          Total{" "}
                                          <span className="primary-text">
                                            (Offer Price)
                                          </span>{" "}
                                        </p>
                                        <p className="m-0">
                                          Rs
                                          {/* {Math.round(totalBuydoorservicePrice)}.00 */}
                                          {totalBuydoorservicePrice}
                                        </p>
                                      </div>
                                    </div> : <></>
                                }

                                {
                                  getdoorServices.length > 0 ?
                                    <div>
                                      <h5 className="fs-14 ff-poppins-semiBold mt-3">
                                        Get Doorstep Service
                                      </h5>
                                      {getdoorServices.map((getser, index) => {
                                        return (
                                          <div className="d-flex justify-content-between fs-13">
                                            <p className="col-8">
                                              {getser.service_name}{" "}
                                            </p>
                                            <div className="d-flex justify-content-around">
                                              {/* <p className=''>{}</p> */}
                                              <p>
                                                {getser.doorstep_price > 0
                                                  ? getser.doorstep_price
                                                  : "0"}
                                                .00
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      })}
                                      <div className="totalBar fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                                        <p className="m-0">
                                          Total{" "}
                                          <span className="primary-text">
                                            (Offer Price)
                                          </span>{" "}
                                        </p>
                                        <p className="m-0">
                                          Rs
                                          {/* {Math.round(totalBuydoorservicePrice)}.00 */}
                                          {totalGetdoorservicePrice}.00
                                        </p>
                                      </div>
                                    </div>
                                    : <></>
                                }

                              </div>
                            ) : offerdetails?.offer_type == "3" &&
                              offerdetails.applied_to == "2" ? (
                              <div>
                                <h5 className="fs-14 ff-poppins-semiBold">
                                  Buy product
                                </h5>
                                {buyProduct.map((buypro, index) => {
                                  return (
                                    <div className="d-flex fs-13">
                                      <p className="col-8">{buypro.product_name} </p>
                                      <p className="col-2">{buypro.count}</p>
                                      <p className="col-2">
                                        {buypro.product_price}.00
                                      </p>
                                    </div>
                                  );
                                })}
                                <div className="totalBar fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                                  <p className="m-0">
                                    Total{" "}
                                    <span className="primary-text">
                                      (Offer Price)
                                    </span>{" "}
                                  </p>
                                  <p className="m-0">Rs.{totalBuyProductPrice}.00</p>
                                </div>

                                <h5 className="fs-14 ff-poppins-semiBold mt-3">
                                  Get product
                                </h5>
                                {getProduct.map((getpro, index) => {
                                  return (
                                    <div className="d-flex justify-content-between fs-13">
                                      <p className="col-8">{getpro.product_name} </p>
                                      <p className="col-2">{getpro.count}</p>
                                      <p className="col-2">
                                        {getpro.product_price}.00
                                      </p>
                                    </div>
                                  );
                                })}
                                <div className="totalBar fs-13 m-0 d-flex justify-content-between p-1 mt-1">
                                  <p className="m-0">
                                    Total{" "}
                                    <span className="primary-text">
                                      (Offer Price)
                                    </span>{" "}
                                  </p>
                                  <p className="m-0">Rs.{totalGetProductPrice}.00</p>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )
                      }

                      <hr></hr>
                      <div className="mt-4 mb-4">
                        {offerdetails?.offer_type == "1" ||
                          offerdetails?.offer_type == "2" ? (
                          <h5 className="fs-14">
                            Discount -
                            {offerdetails?.offer_type == "1" ||
                              offerdetails?.offer_type == "2" ||
                              offerdetails?.offer_type == "3" ? (
                              <span className="primary-text">
                                {offerdetails?.discount}{" "}
                                <span>
                                  {" "}
                                  {offerdetails?.discount_type == "1" ? (
                                    "₹"
                                  ) : offerdetails?.discount_type == "2" ? (
                                    "%"
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </span>
                            ) : (
                              <></>
                            )}{" "}
                          </h5>
                        ) : (
                          <></>
                        )}

                        <h5 className="fs-14">
                          Expiry Date -{" "}
                          <span className="primary-text">
                            {new Date(offerdetails?.duration).toLocaleDateString(  "en-US",  dateOptions   )}
                          </span>{" "}
                        </h5>
                      </div>

                      <div className="col-12 ps-1 pe-1 mt-4 SPCard" onClick={() => navigate(`/vendor/${vendor.vendor_id}`)}>
                        <div className="d-flex justify-content-between ">
                          <div className="d-flex">
                            <img className="rounded-3 ms-1" src={vendor?.vendor_logo} width={'70px'} height={'70px'} />
                            <div className="ms-2">
                              <div className="ff-poppins-semiBold fs-14">{vendor?.vendor_company_name}</div>
                              <div className="ff-poppins-medium fs-12 text-secondary">&#x2022; MID {vendor?.vendor_id} </div>
                              <div className="ff-poppins-medium fs-12 text-secondary">&#x2022; {vendor?.vendor_type_name} </div>
                              <div className="ff-poppins-medium fs-12 text-secondary singleline-text">&#x2022; {vendor?.vendor_address} </div>
                            </div>
                          </div>

                          <div>
                            <div className="d-flex justify-content-center align-items-center ">
                              <div className='text-center mapIcons ps-4 me-3 mt-2' role='button' onClick={() => openMap(vendor?.vendor_lat, vendor?.vendor_long)}>
                                <img src={p_vendor_map} className="d-block mt-2" alt="map" width={'25px'} height={'30px'} />
                                <div className='ff-poppins-medium fs-13 primary-text mt-1'>MAP</div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className="mt-4">
                        <h5 className="fs-16 ff-poppins-semiBold">
                          Availability
                        </h5>
                        <p className="fs-13">
                          Select Days And Hours The offer Will Be Available
                        </p>
                      </div>
                      <div className="availbilitybox p-3">
                        <h5 className="fs-17">Available Hours</h5>
                        {offertime.map((time, subindex) => {
                          return (
                            <div className="d-flex justify-content-between p-2 fs-15">
                              <p className="primary-text m-0">{time.day} </p>
                              <p className="m-0">{time.timing} </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="p-2 ">
              <h6 className="fs-12 ms-1 ff-poppins-bold ">
                OFFER TERMS & CONDITION
              </h6>
              <div className="p-2 fs-13">{offerdetails.terms_and_conditions}</div>
            </div>
            <div className="p-2 booknowBtn" >
              <p className="w-100  text-center rounded-5 primary-bg text-light p-2"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  navigate("/offerBooknow", {
                    state: {
                      vendorId, applyid: offerdetails.applied_to, offerid, offertype: offerdetails?.offer_type,
                      categoryStatus: offerdetails?.applied_to_status
                    },
                  })}>
                Book Now
              </p>
            </div>
          </div>
        </div>

      ) : (
        <div className=" ReactLoading">
        <ReactLoading type={"spinningBubbles"} color={"#4E26A3"} />
      </div>
      )}
    </div>
  );
};
export default Singleofferdetails;
