import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import p_dash_user_active from '../../Assets/Images/p_dash_user_active.svg';
import p_dash_user_inactive from '../../Assets/Images/p_dash_user_inactive.svg';
import p_dash_history_active from '../../Assets/Images/p_dash_history_active.svg';
import p_dash_history_inactive from '../../Assets/Images/p_dash_history_inactive.svg';
import p_dash_qr_active from '../../Assets/Images/p_dash_qr_active.svg';
import p_dash_qr_inactive from '../../Assets/Images/p_dash_qr_inactive.svg';
import p_dash_settings from '../../Assets/Images/p_dash_settings.svg';
import p_dash_location from '../../Assets/Images/p_dash_location.svg';
import p_appointment_right_arrow from '../../Assets/Images/p_appointment_right_arrow.svg';
import p_appointment_inStore from "../../Assets/Images/p_appointment_inStore.svg";
import { FaStar } from "react-icons/fa";
import { useEffect, useState } from 'react';
import { getBookingList, getBulletinDetails, getMyProfileDetails } from '../../Services/api';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowRightLong } from "react-icons/fa6";
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import axiosInstance from '../../Services/axios';
import inStore from "../../Assets/Images/In-Store.png";
import AppointmentServiceCard from '../../Components/appointment_card/AppointmentServiceCard';
import AppointmentProductCard from '../../Components/appointment_card/AppointmentProductCard';
const UserDashboard = () => {

    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const [profile, setProfileData] = useState('');
    const [bulletinDetails, setBulletinDetails] = useState('');
    const [copied, setCopied] = useState(false);
    const [bookingDetails, setBookingDetails] = useState("");
    let [allAppointmentList, setAllAppointmentList] = useState([]);
    const [activeTab, setActiveTab] = useState("services");
    const [isOpen, setIsOpen] = useState(true);
    const [allBooking, setAllBooking] = useState([])
    const [allBookings, setAllBookings] = useState('')
    const [upcomingBookings, setUpcomingBookings] = useState('')
    const [completedBookings, setCompletedBookings] = useState('')
    const [expiredBookings, setExpiredBookings] = useState('')
    const [canelledBookings, setCancelledBookings] = useState('')
    const [bookingdetails, setbookingdetails] = useState([]);
    const [productRawData, setProductRawData] = useState([]);
    const [completedProduct, setCompletedProduct] = useState([])
    const [canelledProduct, setCancelledProduct] = useState([])
    const [allProduct, setAllProduct] = useState([])
    const [currentDate, setCurrentDate] = useState('');
    const [isLoading, setIsLoading] = useState(false); 
    useEffect(() => {
        const getCurrentDate = () => {
            const currentDateIST = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
            const dateObj = new Date(currentDateIST);
            const day = dateObj.getDate();
            const month = dateObj.toLocaleString('default', { month: 'short' });
            return `${day} ${month}`;
        };
        setCurrentDate(getCurrentDate());
    }, []);

    useEffect(() => {
        getBookingList().then((res) => {
            setBookingDetails(res.data);
            console.log('resres',res.data);
            setAllAppointmentList(
                allAppointmentList.concat(
                    res.data?.active_appointment_details,
                    res.data?.upcoming_appointment_details,
                    res.data?.missed_appointment_details,
                    res.data?.unconfirmed_appointment_details,
                    res.data?.all_cancel_book_details
                )
            );
        });

        getBookingDetails()

        getMyProfileDetails().then((res) => {
            setProfileData(res.data);
        });
        getBulletinDetails().then((res) => {
            setBulletinDetails(res.data?.bulletin_details);
        });

    }, [value]);

    function downloadImage() {
        const imageUrl = profile.user_details?.user_qr_code;
        saveAs(imageUrl, 'pingle_user_qr_code.png');
    }

    const copyToClipboard = () => {
        const textToCopy = profile.user_details?.user_referral_link;

        // Create a textarea element and set its value to the text to be copied
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
        document.body.appendChild(textarea);

        // Select the text in the textarea
        textarea.select();
        textarea.setSelectionRange(0, 99999); // For mobile devices

        // Copy the selected text to the clipboard
        document.execCommand('copy');

        // Remove the textarea from the DOM
        document.body.removeChild(textarea);

        // Update state to indicate that text has been copied
        setCopied(true);

        toast('Link copied!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: 'text-danger toast-style-1'
        });

        // Reset the copied state after 2 seconds
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    const getBookingDetails = async () => {
        setIsLoading(true);
        const reqData = {
            user_id: profile.user_id
        };
        axiosInstance.post('user/my_history', reqData)
            .then((res) => {
                if (res.status == 200) {
                    setbookingdetails(res?.data?.history_details[0]?.history);
                    setProductRawData(res?.data?.history_details[1]?.history)

                    let data = res.data
                    let allBookings = []
                    let upcomingBooking = []
                    let completedBookings = []
                    let expiredBookings = []
                    let cancelBookings = []

                    // Product
                    let allProducts = []
                    let completedProducts = []
                    let canelledProducts = []
                    
                    data.history_details.map((tabs) => {
                        if (tabs.tab == 'product') {
                            tabs.history.map((product) => {
                                if (product.order_status == "1") {
                                    allProducts.push(product)
                                }
                                else if (product.order_status == '2') {
                                    completedProducts.push(product)
                                }
                                else if (product.order_status == '3') {
                                    canelledProducts.push(product)
                                }
                            })
                        }
                    })
                    setCompletedProduct(completedProducts)
                    setCancelledProduct(canelledProducts)
                    setAllProduct([...allProducts,...completedProducts,...canelledProducts])

                    data.history_details.map((tabs) => {
                        if (tabs.tab == 'service') {
                            tabs.history.map((book) => {
                                if (book.book_status == '1') {

                                    allBookings.push(book)
                                    upcomingBooking.push(book)
                                } else if (book.book_status == '2') {

                                    allBookings.push(book)
                                    completedBookings.push(book)
                                }
                                else if (book.book_status == '6') {

                                    allBookings.push(book)
                                    expiredBookings.push(book)
                                }
                                else if (book.book_status == '4') {

                                    allBookings.push(book)
                                } else if (book.book_status == '3') {
                                    allBookings.push(book)
                                    cancelBookings.push(book)
                                }
                            })
                        }
                    })
                    setAllBookings(allBookings)
                    setCompletedBookings(completedBookings)
                    setUpcomingBookings(upcomingBooking)
                    setExpiredBookings(expiredBookings)
                    setCancelledBookings(cancelBookings)
                }
                setIsLoading(false);
            })

    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} role='button' onClick={() => navigate('/')} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>User Dashboard</div>
                </div>
            </div>

            <div className='userDashboard secondary-bg '>
                <div className='banner position-relative primary-bg'>
                    <img src={profile.user_details?.user_img} width={'100px'} height={'100px'} className='position-absolute userImg' />

                    <div className='position-absolute tabs'>
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item d-flex justify-content-center" role="presentation">
                                <div className='nav-link p-0 pills-home-image active' id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true"></div>
                            </li>
                            <li className="nav-item d-flex justify-content-center" role="presentation">
                                <div className='nav-link p-0 pills-history-image' id="pills-history-tab" data-bs-toggle="pill" data-bs-target="#pills-history" type="button" role="tab" aria-controls="pills-history" aria-selected="false"></div>
                            </li>
                            <li className="nav-item d-flex justify-content-center" role="presentation">
                                <div className='nav-link p-0 pills-qr-image' id="pills-qr-tab" data-bs-toggle="pill" data-bs-target="#pills-qr" type="button" role="tab" aria-controls="pills-qr" aria-selected="false"></div>
                            </li>
                            <li className="nav-item d-flex justify-content-center border-0" role="presentation">
                                <Link to={`/profile`}>
                                    <img src={p_dash_settings} width={'38px'} height={'38px'} className="nav-link p-0" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='userInfo'>
                    <div className='ff-balooPaaji2-semiBold fs-20'>{profile.user_details?.user_name}</div>
                    <div className='d-flex'>
                        <div className='ff-balooPaaji2-regular fs-14 me-2'>UID:{profile.user_details?.user_id}</div>
                        <div className='ff-balooPaaji2-regular fs-14 d-flex align-items-center'>
                            <img src={p_dash_location} width={'12px'} height={'12px'} className="nav-link p-0 me-1" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" />
                            {profile.user_details?.user_city}
                        </div>
                    </div>
                </div>
                <div className="tab-content " id="pills-tabContent">
                    <div className="tab-pane fade show active " id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className='dashboardOptions pb-5 scroller-style-y'>
                            <div className='white-bg bulletinBoard'>
                                <div className='p-3'>
                                    <div className='mt-2 ff-poppins-semiBold fs-20'>Bulletin Board</div>
                                    <div className='ff-poppins-semiBold fs-14'>{bulletinDetails[0]?.broadcast_title}</div>
                                    <div className='ff-poppins-regular fs-13'>{bulletinDetails[0]?.broadcast_msg}</div>
                                </div>

                                <Link to={`/bulletin-board`}>
                                    <div className='position-relative readMoreBox'>
                                        <div className='primary-bg text-white d-flex justify-content-center align-items-center ff-poppins-medium fs-12  position-absolute readMore'>Read More</div>
                                    </div>
                                </Link>
                            </div>

                            <div className='d-flex justify-content-between mt-3 pt-1 '>
                                <div className='white-bg p-3 support'>
                                    <div className='ff-poppins-regular fs-12 pb-2'>Support</div>
                                    <div className='ff-poppins-regular fs-12 pt-2'>24/7 Acess Call</div>
                                </div>
                                <Link className="text-decoration-none ms-3" to={`/`}>
                                    <div className='primary-bg text-white appointments'>
                                        <div className='ff-poppins-regular fs-14 pb-2'>Today - {currentDate}</div>
                                        <div className='ff-poppins-semiBold fs-12 pt-1'>{profile.count_details?.appointment_count}- Appointment</div>
                                        <div className='ff-poppins-regular fs-12'>{profile.count_details?.upcoming_count}- up coming</div>
                                    </div>
                                </Link>
                            </div>

                            <Link to={`/my-membership`} className="text-decoration-none">
                                <div className='shadow mt-3 mb-3 rounded-3 bg-white p-3 d-flex align-items-center'>
                                    <div>
                                        <div className='ff-poppins-medium fs-16 primary-text'>My Memberships</div>
                                        <div className='ff-poppins-regular fs-13 text-dark'>
                                            Manage and make bookings using your memberships
                                        </div>
                                    </div>
                                    <img src={p_appointment_right_arrow} width={'20px'} height={'20px'} alt='Text' />
                                </div>
                            </Link>

                            <div className='d-flex justify-content-between mt-3 pt-3 pb-5'>

                                <Link to={`/PinglePoints`} className="text-decoration-none">
                                    <div className='primary-bg d-flex justify-content-center align-items-center counts'>
                                        <div className='text-center text-white'>
                                            <div className='ff-poppins-regular fs-12'>Loyalty</div>
                                            <div className='ff-poppins-regular fs-12'>{profile.count_details?.points == '' ? '0' : profile.count_details?.points}</div>
                                        </div>
                                    </div>
                                </Link>

                                <Link to={`/UserFollowing`} className="text-decoration-none">
                                    <div className='primary-bg d-flex justify-content-center align-items-center counts'>
                                        <div className='text-center text-white'>
                                            <div className='ff-poppins-regular fs-12'>Following</div>
                                            <div className='ff-poppins-regular fs-12'>{profile.count_details?.following_count}</div>
                                        </div>
                                    </div>
                                </Link>

                                <Link to={`/transactions`} className="text-decoration-none">
                                    <div className='primary-bg d-flex justify-content-center align-items-center counts'>
                                        <div className='text-center text-white'>
                                            <div className='ff-poppins-regular fs-12'>Transactions</div>
                                            <div className='ff-poppins-regular fs-12'>{profile.count_details?.transaction_count}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>



                    {/* service / product  */}
                    <div className="tab-pane fade" id="pills-history" role="tabpanel" aria-labelledby="pills-history-tab">
                        <ul className="nav nav-pills d-flex justify-content-center mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link active rounded-start serviceProductTab serviceBtnBM ff-poppins-regular`}
                                    id="pills-services-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-services"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-services"
                                    aria-selected={activeTab === "services" ? "true" : "false"}
                                    onClick={() => handleTabClick("services")}
                                >
                                    Services
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link serviceProductTab rounded-end productBtnBM ff-poppins-regular`}
                                    id="pills-products-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-products"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-products"
                                    aria-selected={activeTab === "products" ? "true" : "false"}
                                    onClick={() => handleTabClick("products")}
                                >
                                    Products
                                </button>
                            </li>
                        </ul>

                        <div className="tab-content " id="pills-tabContent">

                            {
                                !isLoading
                                    ?
                                    <>
                                        {/* For service  */}
                                        <div
                                            className="tab-pane fade show active"
                                            id="pills-services"
                                            role="tabpanel"
                                            aria-labelledby="pills-services-tab"
                                            tabIndex="0"
                                        >
                                            <ul
                                                className="appointmentFilter scroller-style-x nav nav-pills pt-2 pb-3"
                                                id="pills-services-tab"
                                                role="tablist"
                                            >
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="pills-all-services-tab" data-bs-toggle="pill"
                                                        data-bs-target="#pills-all-services" type="button" role="tab" aria-controls="pills-all-services" aria-selected="true"  >
                                                        All
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-completed-services-tab" data-bs-toggle="pill" data-bs-target="#pills-completed-services"
                                                        type="button" role="tab" aria-controls="pills-completed-services" aria-selected="false"  >
                                                        Completed
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-cancelled-services-tab" data-bs-toggle="pill" data-bs-target="#pills-cancelled-services"
                                                        type="button" role="tab" aria-controls="pills-cancelled-services" aria-selected="false"  >
                                                        Cancelled
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-expired-services-tab" data-bs-toggle="pill" data-bs-target="#pills-expired-services"
                                                        type="button" role="tab" aria-controls="pills-expired-services" aria-selected="false" >
                                                        Expired
                                                    </button>
                                                </li>
                                            </ul>

                                            <div className='scroller-style-y-new pb-5 mb-2'>
                                                <div className={`tab-pane fade show active`} id="pills-all-services" role="tabpanel" aria-labelledby="pills-all-services-tab" >
                                                    {
                                                        allBookings.length > 0 ?
                                                            allBookings.map(item => {
                                                                return (
                                                                    <div onClick={() => navigate(`/details/${item.booking_id}`)}>
                                                                        <AppointmentServiceCard data={item} />
                                                                    </div>
                                                                )
                                                            }) :
                                                            <div className="text-center ff-poppins-regular fs-14 secondary-text">
                                                                No service appointments
                                                            </div>
                                                    }
                                                </div>
                                                <div className="tab-pane fade" id="pills-completed-services" role="tabpanel" aria-labelledby="pills-completed-services-tab" >
                                                    {
                                                        completedBookings.length > 0 ?
                                                            completedBookings.map(item => {
                                                                return (
                                                                    <div onClick={() => navigate(`/details/${item.booking_id}`)}>
                                                                        <AppointmentServiceCard data={item} />
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <div className="text-center ff-poppins-regular fs-14 secondary-text">
                                                                Today No service appointments
                                                            </div>
                                                    }
                                                </div>
                                                <div className="tab-pane fade " id="pills-cancelled-services" role="tabpanel" aria-labelledby="pills-cancelled-services-tab"  >
                                                    {
                                                        canelledBookings.length > 0 ?
                                                            canelledBookings.map(item => {

                                                                return (
                                                                    <div onClick={() => navigate(`/details/${item.booking_id}`)}>
                                                                        <AppointmentServiceCard data={item} />
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <div className="text-center ff-poppins-regular fs-14 secondary-text">
                                                                No unconfirmed service appointments
                                                            </div>
                                                    }
                                                </div>
                                                <div className="tab-pane fade w-100 " id="pills-expired-services" role="tabpanel" aria-labelledby="pills-expired-services-tab"  >
                                                    {
                                                        expiredBookings.length > 0 ?
                                                            expiredBookings.map(item => { 
                                                                return (
                                                                    <div   >
                                                                        <AppointmentServiceCard data={item} />
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <div className="text-center ff-poppins-regular fs-14 secondary-text">
                                                                No expired  appointments
                                                            </div>
                                                    }
                                                </div>
                                                <div className="tab-pane fade " id="pills-missed-services" role="tabpanel" aria-labelledby="pills-missed-services-tab"  >
                                                    {
                                                        bookingDetails?.missed_appointment_status  ? 
                                                            bookingDetails?.missed_appointment_details.map(item => {
                                                                return (
                                                                    <div onClick={() => navigate(`/details/${item.booking_id}`)}>
                                                                        <AppointmentServiceCard data={item} />
                                                                    </div>
                                                                )
                                                            })

                                                            :
                                                            <div className="text-center ff-poppins-regular fs-14 secondary-text">
                                                                No missed service appointments
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {/* For products  */}
                                        <div className="tab-pane fade" id="pills-products" role="tabpanel"
                                            aria-labelledby="pills-products-tab" tabIndex="0" >
                                            <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-homeBM"
                                                        type="button" role="tab" aria-controls="pills-homeBM" aria-selected="true" >
                                                        All
                                                    </button>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                                                        type="button" role="tab" aria-controls="pills-profile" aria-selected="false" >
                                                        Completed
                                                    </button>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
                                                        type="button" role="tab" aria-controls="pills-contact" aria-selected="false" >
                                                        Cancelled
                                                    </button>
                                                </li>
                                            </ul>

                                            <div class="tab-content p-0" id="pills-tabContent">
                                                <div className='scroller-style-y-new'>
                                                    <div class="tab-pane fade show active" id="pills-homeBM"
                                                        role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0" >
                                                        {allProduct.map((item, subindex) => {
                                                            return (
                                                                allProduct.length > 0 ?
                                                                    <div onClick={() => navigate('/ProductPass', { state: { orderid: item.order_id } })}>
                                                                        <AppointmentProductCard data={item} />
                                                                    </div>
                                                                    :
                                                                    <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                                        No product
                                                                    </div>
                                                            );
                                                        })}
                                                    </div>
                                                    {/* Complete product */}
                                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                                        {completedProduct.map((product, subindex) => {
                                                            return (
                                                                completedProduct.length > 0 ?

                                                                    <div onClick={() => navigate('/ProductPass', { state: { orderid: product.order_id } })}>
                                                                        <AppointmentProductCard data={product} />
                                                                    </div>

                                                                    :
                                                                    <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                                        No completed product
                                                                    </div>
                                                            );
                                                        })}
                                                    </div>
                                                    {/* Canceled product */}
                                                    <div class="tab-pane fade" id="pills-contact"
                                                        role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0" >
                                                        {canelledProduct.map((item, subindex) => {
                                                            return (
                                                                <div>
                                                                    {item.order_status == "3" ? (
                                                                        <div onClick={() => navigate('/ProductPass', { state: { orderid: item.order_id } })}>
                                                                            <AppointmentProductCard data={item} />
                                                                        </div>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className='d-flex justify-content-center align-items-center h-100'>
                                        <div class="spinner-border primary-text" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                            }

                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-qr" role="tabpanel" aria-labelledby="pills-qr-tab">
                        <div className='scroller-style-y dashboardOptions'>
                            <div className='qr-card bg-white p-4 scroller-style-y'>
                                <div className="text-center ff-poppins-regular fs-13 pb-2">Use QR Code To Refer Your <br /> Friends and Earn Pingle Points</div>
                                <div className="text-center">
                                    <img src={profile.user_details?.user_qr_code} width={'194px'} height={'194px'} />
                                    <div className='ff-poppins-semiBold fs-13'>Referral ID: {profile.user_details?.user_referral_id}</div>

                                    <div className='d-flex justify-content-center'>
                                        <button className='ff-poppins-semiBold fs-13 border-0 primary-bg text-white me-2' onClick={() => downloadImage()}>Download</button>
                                        <button className='ff-poppins-semiBold fs-13 border-0 primary-bg text-white' onClick={() => copyToClipboard()}>Share</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default UserDashboard