import React, { useState } from "react";
import p_appointment_right_arrow from "../../Assets/Images/p_appointment_right_arrow.svg"
import p_appointment_inStore from "../../Assets/Images/p_appointment_inStore.svg";
import { FaStar } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
const AppointmentServiceCard = (service) => {
    const [data, setData] = useState(service.data)

    return (
        <>
            <div className="appointmentCard position-relative" role="button">
                {data?.book_service_type == 1 ? (
                    <div className="service position-absolute">
                        <img src={p_appointment_inStore} width={'14px'} height={'14px'} />
                        <div className="ff-poppins-regular fs-12">In-Store</div>
                    </div>
                ) : (
                    <div className="doorstep position-absolute">
                        <img src={p_appointment_inStore} width={'14px'} height={'14px'} />
                        <div className="ff-poppins-regular fs-12">Doorstep</div>
                    </div>
                )}
                <div className="d-flex">
                    <div className="position-relative">
                        <img src={data?.vendor_logo} width={'79px'} height={'86px'} />
                        {data?.book_status == "1" ?
                            data?.book_que_status == "0" || data?.book_que_status == "4" ?
                                <div className="status   d-flex justify-content-center align-items-center text-white ff-poppins-semiBold fs-10 position-absolute" style={{ backgroundColor: "#68a801" }}>YET TO START</div>
                                : data?.book_que_status == "1" ?
                                    <div className="status d-flex justify-content-center align-items-center text-white ff-poppins-semiBold fs-10 position-absolute" style={{ backgroundColor: '#dfbf15' }}>INPROGRESS</div>
                                    : <></> : <></>
                        }
                        {/* Completed  Status*/}
                        {data?.book_status == "2" ?
                            <div className="status d-flex justify-content-center align-items-center text-white ff-poppins-semiBold fs-10 position-absolute" style={{ backgroundColor: '#727374' }}>COMPLETED</div> : <></>
                        }
                        {/* Cancelled  Status*/}
                        {data?.book_status == "3" ?
                            <div className="status d-flex justify-content-center align-items-center text-white ff-poppins-semiBold fs-10 position-absolute" style={{ backgroundColor: '#f43b51' }}>CANCELLED</div>
                            : <></>}

                        {/* Unconfirm Status */}
                        {data?.book_status == "5" ?
                            <div className="status d-flex justify-content-center align-items-center text-white ff-poppins-semiBold fs-10  position-absolute" style={{ backgroundColor: data?.doorstep_request_status == 1 ? 'red' : '#FFA500' }}>
                                {Number(data?.book_service_type) == 2 ?
                                    Number(data?.doorstep_request_status) == 1 ?
                                        'YET TO ACCEPT'
                                        :
                                        Number(data?.doorstep_request_status) == 2 ? 'YET TO PAY' : ''
                                    : <></>
                                }
                                {Number(data?.book_service_type) == 1 ? 'YET TO PAY' : ''}
                            </div>
                            : <></>
                        }
                        {/* Missed Status */}
                        {data?.book_status == "4" ?
                            <div className="status d-flex justify-content-center align-items-center text-white ff-poppins-semiBold fs-10 position-absolute" style={{ backgroundColor: '#0149a8' }}>MISSED</div>
                            : <></>}
                        {data?.book_status == "6" ?
                            <div className="status d-flex justify-content-center align-items-center text-white ff-poppins-semiBold fs-10 position-absolute" style={{ backgroundColor: 'red' }}>EXPIRED</div>
                            : <></>}

                    </div>
                    <div className="d-flex justify-content-between align-datas-center w-100 ms-3 ps-2">
                        <div>
                            <div className="ff-poppins-semiBold fs-16 singleline-text">{data?.vendor_company_name}</div>
                            <div className="ff-poppins-regular fs-11">MID-{data?.book_vendor_id}</div>
                            <div className="ff-poppins-semiBold fs-11">{data?.book_date}, {data?.book_time}</div>
                            <div className="ff-poppins-regular fs-11">{data?.service_gender} - {data?.book_service_id}</div>
                            {
                                data?.book_status == "2" && data?.rate_status == '0' ?
                                    <div className="primary-text fs-11 d-flex">
                                        <div > Feedback Pending </div> <div className="ms-1"><FaArrowRightLong /></div>
                                    </div> : <></>
                            }

                        </div>

                    </div>
                    <div className="d-flex h-100 flex-column justify-content-evenly">
                        <div className="d-flex h-100 flex-column justify-content-evenly mt-4">
                            <div className="d-flex justify-content-end">
                                <img src={p_appointment_right_arrow} width="16px" height="16px" />
                            </div>
                            <div >
                                {
                                    data?.rate_status == '1' ?
                                        <div className=' d-flex justify-content-end mt-3'>
                                            <div className='rating '>
                                                <div><span style={{ fontSize: '13px' }} className="d-flex flex-column align-items-center justify-content-center"> <FaStar className='star' /> </span></div>
                                                <div className="ff-poppins-semiBold"><span style={{ fontSize: '11px' }}> {data?.rate_count}</span></div>
                                            </div>
                                        </div>
                                        : <></>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div >

        </>
    )
}
export default AppointmentServiceCard