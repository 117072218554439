import { useEffect, useRef, useState } from 'react';
import p_nav_previous from '../../../Assets/Images/p_nav_previous.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { userEmailVerifyOTP } from '../../../Services/api';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

const EditProfileEmailVerifyOtp = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [otp, setOtp] = useState(['', '', '', '']);
    const [isLoading, setLoading] = useState(false);
    const inputs = useRef([]);
    const otpInputs = useRef([]);

    // const handleChange = (index, value) => {
    //     if (/^\d$/.test(value) || value === '') {
    //         const newOtp = [...otp];
    //         newOtp[index] = value;
    //         setOtp(newOtp);

    //         if (value === '' && index > 0) {
    //             otpInputs.current[index - 1].focus();
    //         } else if (value && index < otp.length - 1) {
    //             otpInputs.current[index + 1].focus();
    //         }
    //     }
    // };

    const handleChange = (index, value) => {
        const newOTP = [...otp];
        newOTP[index] = value;
        setOtp(newOTP);

        // Move to the next input
        if (value && index < otp.length - 1) {
            inputs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            // If backspace is pressed and the current input is empty, move to the previous input
            inputs.current[index - 1].focus();
            inputs.current[index - 1].select();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const enteredOtp = otp.join('');
        if (enteredOtp.length === 4) {
            setLoading(true);
            let param = {
                'user_email': location.state.userEmail,
                'otp_value': enteredOtp
            };

            userEmailVerifyOTP(param)
                .then((res) => {
                    setLoading(false);
                    navigate(-1);
                })
                .catch((error) => {
                    setLoading(false);
                    toast("Please Enter a Valid OTP", {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'text-danger toast-style-1'
                    });
                });
        } else {
            console.log('Invalid OTP');
        }
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>Email Verify</div>
                </div>
            </div>

            <div className='email-otp-verify-page'>
                <div className='ff-poppins-regular fs-24 mb-2'>Enter OTP</div>
                <div className='ff-poppins-light fs-14 mt-1 mb-4'>
                    Enter OTP We have sent you a mail with 4 digit verification code to the registered mail ID
                </div>

                <form className='mt-1 text-center' onSubmit={handleSubmit}>
                    {/* {otp.map((digit, index) => (
                        <input
                            key={index}
                            ref={(el) => (otpInputs.current[index] = el)}
                            type="text"
                            value={digit}
                            onChange={(e) => handleChange(index, e.target.value)}
                            maxLength={1}
                            className='otp-input bg-transparent me-2 text-center'
                        />
                    ))} */}
                    {otp.map((value, index) => (
                        <input
                            key={index}
                            type="text"
                            value={value}
                            onChange={(e) => handleChange(index, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            maxLength={1}
                            ref={(input) => (inputs.current[index] = input)}
                            className='otp-input bg-transparent me-3 text-center ff-balooPaaji2-regular fs-40 primary-text'
                        />
                    ))}
                    <button
                        className='submitBtn mt-4 primary-bg text-white ff-poppins-semiBold fs-20 d-flex justify-content-center align-items-center border-0 w-100'
                        type="submit"
                        disabled={isLoading}>
                        {isLoading ? <ReactLoading type="spinningBubbles" color="#fff" height={20} width={20} /> : 'Submit'}
                    </button>

                    <div className='ff-poppins-regular fs-16 mt-2'>
                        Didn’t receive code?
                        <span className='primary-text'> Click Here</span>
                    </div>
                </form>
            </div>
        </>
    )
}

export default EditProfileEmailVerifyOtp;
