import { useEffect, useState } from "react";
import p_nav_previous from "../../../Assets/Images/p_nav_previous.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { LuPlusCircle } from "react-icons/lu";
import { FiMinusCircle } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import axiosInstance from "../../../Services/axios";
import defaultimg from "../../../Assets/Images/default-placeholder.png";
const ProductBasket = () => {

  const [cartId, setCartId] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { merchantId } = location.state || {};
  const [cardDetails, setCardDetails] = useState([]);
  const [shipingtype, setShipingtype] = useState();
  const [deleteitem, setDeleteitem] = useState()

  // Offer or Membership 
  const [cardStatus, setCardStatus] = useState();
  const [cardType, setcardType] = useState();
  const [cardId, setcardId] = useState();

  console.log('cardDetails', cardDetails);

  // useEffect(() => {
  //   console.log('Effect ran');
  //   const localProductDetailCard = localStorage.getItem("cartid")
  //     ? JSON.parse(localStorage.getItem("cartid"))
  //     : "";
  //   console.log('Local Storage Data:', localProductDetailCard);
  //   setCartId(localProductDetailCard?.cart_id ? localProductDetailCard.cart_id : "");
  // }, []);
  console.log(cardDetails.length);
  useEffect(() => {
    let productCart = localStorage.getItem("productCart") ? JSON.parse(localStorage.getItem("productCart")) : "";
    setCartId(productCart?.cartId ? productCart?.cartId : "");
    setShipingtype(productCart?.shipping_type ? productCart?.shipping_type : "");

    let appliedCard = JSON.parse(localStorage.getItem("appliedCard"));
    setCardStatus(appliedCard?.cardStatus ? appliedCard?.cardStatus : "")
    setcardType(appliedCard?.cardType ? appliedCard?.cardType : "")
    setcardId(appliedCard?.cardId ? appliedCard?.cardId : "")
  }, []);

  useEffect(() => {
    if (cartId !== "") {
      console.log('Cart ID set, running getcardDetail and check:', cartId);
      getcardDetail();
    }

  }, [cartId, deleteitem]);

  const getcardDetail = () => {
    try {
      let reqData = {
        vendor_id: merchantId.vendor_id,
        cart_id: cartId,
      };

      axiosInstance.post("user/products/product_cart_without_login", reqData).then((res) => {
        console.log("res", res);
        setCardDetails(res.data.cart);
        if (res.data.cart_count == 0) {
          navigate(-1)
        }
      }).catch((err) => {
        if (err.response.status == 400) {
          alert(
            `Error: ${err.response.status} - ${err.response.data.message}`
          );
        }
        if (err.response.status == 500) {
          console.log("error2");
          alert(
            `Error: ${err.response.status} - ${err.response.data.message}`
          );
        }
      });
    } catch { }
  };
  const Add_Remove_productcount = (productId, Operation) => {
    try {
      let localServiceDetail = localStorage.getItem("shipping_type")
        ? JSON.parse(localStorage.getItem("shipping_type"))
        : "";
      let reqData = {
        vendor_id: merchantId.vendor_id,
        product_id: productId,
        shipping_type: shipingtype,
        quantity: 1,
        operation: Operation,
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId
      };
      console.log("req", reqData);
      axiosInstance
        .post("user/products/add_or_remove_product_cart_without_login", reqData)
        .then((res) => {
          if (res.status == 200) {
            getcardDetail();
          }
        });
    } catch (error) {
      alert("Tryagain later");
    }
  };
  const deleteProduct = () => {
    try {
      let localServiceDetail = localStorage.getItem("shipping_type")
        ? JSON.parse(localStorage.getItem("shipping_type"))
        : "";
      let reqData = {
        vendor_id: merchantId.vendor_id,
        product_id: deleteitem.product_id,
        shipping_type: shipingtype,
        quantity: deleteitem.added_count,
        operation: 'remove',
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId
      }

      axiosInstance.post('user/products/add_or_remove_product_cart_without_login', reqData)
        .then((res) => {
          getcardDetail();
          console.log("delete res", res.data);
        });
    } catch (error) {
      alert("Try Again later");
    }
  }
  const check = () => {
    const localProductDetail = localStorage.getItem("shipping_type")
      ? JSON.parse(localStorage.getItem("shipping_type"))
      : "";
    setShipingtype(
      localProductDetail?.shipping_type ? localProductDetail?.shipping_type : ""
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white ">
        <div className="d-flex justify-content-between align-items-center ms-2 me-2">
          <img
            src={p_nav_previous}
            width={"24px"}
            height={"16px"}
            alt="Previous Button"
            role="button"
            onClick={() => navigate(-1)}
          />
          <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
            Product Basket
          </div>
        </div>
      </div>
      {/* position-relative */}
      <div className="offcanvas-parentType3 position-relative scroller-style-y">
        <div className="doorStepService ">
          <div className="productsHeroDiv">
            <div className="offcanvas-parentType3 ">
              {/* position-relative */}
              <div className="doorStepService">
                <div className="productsHeroDiv scroller-style-y" style={{ overflowX: 'hidden' }}>
                  {cardDetails.map((item, index) => {
                    return (
                      <div className="productBasketDiv">
                        <div className="col-4 productImageBasketDiv">
                          <img
                            src={
                              item.cover_image.image == ""
                                ? defaultimg
                                : item.cover_image.image_url
                            }
                            className="productImage"
                          />
                        </div>

                        <div className="col-5">
                          <p className="productNameBasket ff-balooPaaji2">
                            {item.product_name}{" "}
                          </p>
                          <p className="productNameBasket ff-balooPaaji2">
                            ₹{item.price}{" "}
                          </p>
                          <p className="productWeightBasket primary-text ff-balooPaaji2">
                            {item.unit_quantity} {item.unit_type_name}
                          </p>

                          <div className="moreDetailsDiv">
                            <p
                              className="productWeightBasket primary-text ff-balooPaaji2"
                              onClick={() =>
                                navigate(`/singleProductDetails/${merchantId.vendor_id}/${item.product_id}`, {
                                  state: {
                                    productId: item.product_id,
                                    merchantId,
                                  },
                                })
                              }
                            >
                              More details
                            </p>
                            <IoIosArrowDown className="fw-bold productWeightBasket primary-text" />
                          </div>
                          {item.applied_card_status == "1" ? (
                            <></>
                          ) : (
                            <div className="row align-items-center w-50 gx-0">
                              <div className="row align-items-center w-100 gx-0">
                                <div className="col-4">
                                  <FiMinusCircle
                                    className="w-100 fs-24 primary-text"
                                    onClick={() =>
                                      Add_Remove_productcount(
                                        item.product_id,
                                        "remove"
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-4 d-flex justify-content-center fs-5 primary-text">
                                  {item.added_count}
                                </div>
                                <div className="col-4">
                                  <LuPlusCircle
                                    className="w-100 fs-24 primary-text"
                                    onClick={() =>
                                      Add_Remove_productcount(
                                        item.product_id,
                                        "add"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {item.applied_card_status == "1" ? (
                          <></>
                        ) : (
                          <div className="col-3 deleteIconProductBasket">
                            <MdOutlineDeleteOutline
                              className="ms-2"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              onClick={() => setDeleteitem(item)}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="reviewAndPayDiv-3" style={{ backgroundColor: "", position: 'sticky', width: 'unset' }}>
            <button
              className="reviewAndPayBtnProduct ff-balooPaaji2-bold"
              onClick={() =>
                navigate("/ReviewPay", {
                  state: { merchantId },
                })}  >
              Proceed to Buy
            </button>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mainModal text-dark ">
          <div class="modal-content p-3">
            <div class="modal-body text-center ff-balooPaaji2-bold mb-3">
              Are you sure want to delete the product?
            </div>
            <div class="modalFooter">
              <button
                type="button"
                class="btn btn-secondary ff-balooPaaji2-bold"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger ff-balooPaaji2-bold"
                onClick={() => deleteProduct()}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductBasket;
