import React, { useEffect, useState } from 'react';
import '../../Assets/Styles/offCanvas.css';
import { cartAddServiceTimeslot, serviceTimeSlotDetailsNew } from '../../Services/api';
import { useNavigate, useParams } from 'react-router-dom';
import ReactLoading from "react-loading";
import { toast } from 'react-toastify';
import { IoLogoHackernews } from 'react-icons/io';

const ReviewAndConfirmOffCanvas = ({ isOpen, date, day, time, section, slot, toggleOffCanvas,resetTimeslot }) => {

    const navigate = useNavigate();
    const valueParam = useParams(); // URL parameter hook
    const [reschedule, setReschedule] = useState(false);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        let localServiceDetail = localStorage.getItem('serviceDetail') ? JSON.parse(localStorage.getItem('serviceDetail')) : '';
        if (localServiceDetail && localServiceDetail?.reschedule && localServiceDetail?.reschedule == true) {
            setReschedule(true)
        }
    }, []);

    function addServiceTimeSlot() {
        setLoading(true)
        let localServiceDetail = localStorage.getItem('serviceDetail')
            ? JSON.parse(localStorage.getItem('serviceDetail'))
            : '';

        let param = {
            "vendor_id": valueParam.vendor_id,
            "cart_app_id": (localServiceDetail && localServiceDetail?.cart_app_id) ? localServiceDetail?.cart_app_id : '',
            "booking_type": (localServiceDetail && localServiceDetail?.reschedule && localServiceDetail?.reschedule == true) ? "2" : "1",
            "date": date,
            "time_section": section,
            "time_slot": slot,
            "service_type": (localServiceDetail && localServiceDetail?.serviceType) ? localServiceDetail?.serviceType : '',
        };

        cartAddServiceTimeslot(param).then((res) => { 
            let localServiceDetail = localStorage.getItem('serviceDetail') ? JSON.parse(localStorage.getItem('serviceDetail')) : '';
            if (localServiceDetail && localServiceDetail?.reschedule && localServiceDetail?.reschedule == true) {
                setLoading(false)
                navigate(-1)
            } else {
                setLoading(false)
                if (res.data?.status == '1') {
                    localServiceDetail.timslotStatus = true;
                    localStorage.setItem("serviceDetail", JSON.stringify(localServiceDetail));
                    navigate(`/review-and-pay/${valueParam.vendor_id}`)
                }
            }
        }).catch((error) =>{
            if (error.response.status == 400) {
                // Toast.show({
                //     type: 'error',
                //     text1: error.response.data.message,
                //     position: 'bottom',
                //     bottomOffset: 50,
                // }); 

                toast(error.response.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'text-danger toast-style-1'
                });
                resetTimeslot()
                setLoading(false)
            }
            if (error.response.status == 500) {
                toast('something went wrong please try again', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'text-danger toast-style-1'
                });
                resetTimeslot()
                setLoading(false)
            }
        })
    }

    return (
        <div className={`offcanvas reviewAndConfirmOffCanvas ${isOpen ? 'show' : ''}`} style={{ width: '-webkit-fill-available', marginBottom: 30, display: 'block', zIndex: 9999,position:'sticky',padding:10 }} tabindex="-1" aria-labelledby="selectCustomerModalLabel">
            <div className="small">
                <div className='d-flex justify-content-center'>
                    <div className='custom-pill'></div>
                </div>

                <div className='ff-poppins-semiBold fs-12 text-center m-3'>
                    <span className='primary-text'>{date} </span>
                    <span>{day} - {time}</span>
                </div>

                <button type='button'
                    className='btn-type-1 ff-poppins-semiBold fs-14 w-100 primary-bg border-0 text-white  justify-content-center d-flex align-items-center'
                    style={{
                        marginBottom: 45
                    }}  
                    onClick={() => { setLoading(true); addServiceTimeSlot()}}
                    disabled={loading}>
                    {loading ? <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20} /> : reschedule ? "Confirm" : "Review and Confirm"} 
                </button>
            </div>
        </div>
    )
};

export default ReviewAndConfirmOffCanvas;