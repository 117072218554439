import { useEffect, useState } from "react";

const CountdownTimer = ({
    trigger,
    triggerStart
}) => {
    const [isActive, setIsActive] = useState(true);
    const [seconds, setSeconds] = useState(30);

    useEffect(() => {
        let timer = null;
        if (isActive) {
            timer = seconds > 0 && setInterval(() => {
                setSeconds((seconds) => seconds - 1);
                if (seconds == 1) {
                    reset();
                }
            }, 1000);
        }
        return () => {
            clearInterval(timer);
        };
    });

    useEffect(() => {
        console.log('trigger - ', trigger)
    }, [trigger]);

    const reset = () => {
        setSeconds(30);
        setIsActive(!isActive);
        triggerStart();
    }

    return (
        <span>
            {seconds > 9 ? seconds : '0'+seconds}
        </span>
    );
}

export default CountdownTimer;