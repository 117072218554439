import React, { useEffect, useState } from 'react'
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../Services/axios';
import OfferCard from './offercard';
import { toast } from 'react-toastify';

const Offerslist = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { vendor_Id, flowParent } = location.state || {};
    const [data, setData] = useState('')
    const [offers, setOffers] = useState([])
    let { vendorId } = useParams();
    useEffect(() => {
        getofferlist()
    }, [])

    const getofferlist = () => {
        try {
            let reqData = {
                "vendor_id": vendorId
            }
            axiosInstance.post('User_offer/vendor_offer_list_without_login', reqData)
                .then((res) => {
                    setOffers(res.data.vendor_offer_list)
                    setData(res.data)
                })
        }
        catch (err) {
            alert('try again later')
        }
    }
    return (
        <div className=''>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={"24px"} height={"16px"} alt="Previous Button" role="button" onClick={() => navigate(`/vendor/${data.vendor_id}`)} />
                    <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
                        Offer
                    </div>
                </div>
            </div>
            <div className='scroller-style-y pb-4'>
                {
                    offers.length > 0 ?
                        offers.map((list, index) => {
                            return (
                                <div onClick={() => {
                                    if (list.usage_limit_status === "1" && list.usage_limit <= 0) {
                                        toast.error('Offer unavailable', {
                                            position: toast.POSITION.BOTTOM_CENTER
                                        });
                                    } else if (list.offer_used_count >= list.usage_limit_per_user) {
                                        toast.error('Offer limit reached per user', {
                                            position: toast.POSITION.BOTTOM_CENTER
                                        });
                                    } else {
                                        navigate(`/OfferDetails/${vendorId}/${list.offer_id}`, { state: { offerid: list.offer_id, vendorId, categoryStatus: list.applied_to_status } })
                                    }
                                }}>
                                    <OfferCard key={index} data={list} />
                                </div>
                            )
                        })
                        :
                        <p className=' text-center mt-5 ff-balooPaaji2-bold fs-20 '>No Offers Available</p>
                }
            </div>
        </div>
    )
}

export default Offerslist