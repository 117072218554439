import React, { useEffect, useState } from "react";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg"
import pingle_conin from "../../Assets/Images/PingleCoins-Business.png"
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import axiosInstance from "../../Services/axios";
const PinglePointsHistory = () => {
    const location = useLocation()
    const { vendorId } = location.state || {}
    const [loyalty, setLoyalty] = useState([])
    const [loyaltyhistory, setLoyaltyhistory] = useState([])
    useEffect(() => {
        getloyaltyhistory()
    }, [])
    const getloyaltyhistory = () => {
        try {
            let reqData = {
                vendor_id: vendorId
            }
            axiosInstance.post("user/my_vendor_loyalty_points_detail", reqData).then((res) => {
                let data = res.data;
                console.log("data====>>>>", data);
                setLoyalty(res.data)
                setLoyaltyhistory(res.data.vendor_loyalty_history)
            });
        } catch (error) {
            alert("Try again Later");
            console.log("catch====>>>>");
        }
    };

    const formatDate = (date) => {
        if (date && new Date(date).getTime() === new Date(date).getTime()) {
            const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
            return new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(date));
        } else {
            return "Invalid Date";
        }
    };
    const navigate = useNavigate()
    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img
                        src={p_nav_previous}
                        width={"24px"}
                        height={"16px"}
                        alt="Previous Button"
                        role="button"
                        onClick={() => navigate(-1)}
                    />
                    <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
                        Pingle Points History
                    </div>
                </div>
            </div>
            <div className="p-2 secondary-bg-2 ">
                <div className="loyaltyHistoryList scroller-style-y p-3 mb-2">
                    <div className="">
                        <div className="d-flex p-4 loyaltycoins rounded-4 mb-2">
                            <div className="col-6 " >
                                <img src={pingle_conin} style={{ width: 100, height: 100 }} />
                            </div>
                            <div className="col-6">
                                <div className="ff-poppins-regular fs-12">Pingle Coins</div>
                                <h1 className="ff-poppins-bold primary-text">{loyalty.total_vendor_points} </h1>
                                <p className="ff-poppins-regular fs-12">{loyalty.vendor_eligble_offers_count} offers are eligible</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center   text-light  ">
                        <p className="col-5 primary-bg p-2 Collectbtn text-center rounded-4 fs-12 ">Collect Offer <span className="ms-1"><FaArrowRightLong /></span> </p>
                    </div>

                    <div className="d-flex justify-content-between primary-text fs-12 ff-poppins-semiBold mt-5">
                        <p>TRANSACTION</p>
                        <p>POINTS</p>
                    </div>
                    {
                        loyalty?.vendor_loyalty_history?.map((list, index) => {
                            return (
                                <div>
                                    <div className="d-flex justify-content-between ff-balooPaaji2-semiBold">
                                        <div className="m-0">{list.vendor_name} </div>
                                        <div style={{ color: list.loyalty_method == "redeem" ? 'red' : "#4E26A3" }}>
                                            {list.loyalty_method == "redeem" ? "-" : "+"}  {list.loyalty_points} Coins
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between m-0 secondary-text-1 fs-13">
                                        <div>{list.loyalty_method == "booking" ? 'Booking Id' : list.loyalty_method == "billing" ? "Billing Id" : "Redeem Id"} : {list.loyalty_method_id}</div>
                                        <div >{formatDate(list.loyalty_created_at)} </div>
                                    </div>
                                    <hr></hr>
                                </div>
                            )
                        })
                    }

                </div>
            </div>

        </>
    )
}
export default PinglePointsHistory;