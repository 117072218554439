import { useEffect, useRef, useState } from "react";
import React from 'react';
import { CropperRef, Cropper } from 'react-mobile-cropper';
import cropper_check from '../../Assets/Images/cropper/cropper_check.svg';
import cropper_close from '../../Assets/Images/cropper/cropper_close.svg';
import 'react-mobile-cropper/dist/style.css';

const ImageCropperModal = ({ isOpen, inputImage, onSelect, onCancel, enableRatio, isBlolbOutput }) => {
    const [showModal, setShowModal] = useState(false);
    const cropperRef = useRef(null);

    const [croppedImage, setCroppedImage] = useState(false);

    const [isLoading, setLoading] = useState(false);
    const [ratioValue, setRatioValue] = useState(1 / 1);
    const [selectedRatioValue, setSelectedRatioValue] = useState(3);


    const onCrop = () => {
        const cropper = cropperRef.current;

        if (cropper) {
            const canvas = cropper.getCanvas();

            if (isBlolbOutput) {
                canvas.toBlob((blob) => {
                    if (blob) {
                        // Create a file from the Blob
                        const file = new File([blob], 'cropped-image.png', { type: 'image/png' });

                        // Do something with the file
                        console.log(file);
                        console.log(URL.createObjectURL(file));
                        onSelect(file);
                    }
                }, 'image/png');
            } else {
                onSelect(canvas.toDataURL());
            }
        }
    };

    useEffect(() => {
        setShowModal(isOpen);

        if (!isOpen) {
            setCroppedImage(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (croppedImage) {
            onCrop();
        }
    }, [croppedImage]);

    function selectImage() {
        const cropper = cropperRef.current;
        const canvas = cropper.getCanvas();

        if (canvas) {
            onSelect(canvas.toDataURL());
        }
        setCroppedImage(true);
    }

    function cancelImage() {
        setCroppedImage(false);
        onCancel();
    }

    const onChange = (cropper) => {
        console.log(cropper.getCoordinates(), cropper.getCanvas());
    };

    const handleRatioValue = (value) => {
        setRatioValue(value)
    };

    return (
        <>
            {showModal && (
                <div className="modal-backdrop show"></div>
            )}

            {showModal && (
                <div className="modal d-flex justify-content-center" tabIndex="-1" style={{ display: 'block' }}>
                    <div className="modal-dialog m-0 modal-dialog-centered imageCropperDialog">
                        <div className="modal-content">
                            <div className="modal-body p-0">
                                <div className="container-fluid bg-dark text-white">
                                    <div className="row p-2">
                                        <div className="col-1 p-0 text-center">
                                            <img src={cropper_close} width={'30px'} height={'30px'} alt='Previous Button' role='button' onClick={cancelImage} />
                                        </div>
                                        <div className="col-10 ff-poppins-medium fs-18">Edit Photo</div>
                                        <div className="col-1 p-0 text-center">
                                            <img src={cropper_check} width={'30px'} height={'30px'} alt='Previous Button' role='button' onClick={selectImage} />
                                        </div>
                                    </div>
                                </div>
                                <Cropper
                                    ref={cropperRef}
                                    src={inputImage}
                                    onChange={onChange}
                                    className={(enableRatio ? 'enableRatio' : '') + ' cropper'}
                                    stencilProps={{
                                        aspectRatio: ratioValue,
                                        movable: false,
                                        resizable: true
                                    }}
                                />

                                {
                                    enableRatio &&

                                    <div className="container-fluid bg-dark text-white">
                                        <div className="row pt-3 pb-3">
                                            <div className="col-2">
                                                <div className={(selectedRatioValue == 1 ? 'bg-primary' : '') + " text-center rounded-pill"} role="button" onClick={() => { handleRatioValue(1 / 1); setSelectedRatioValue(1); }}>
                                                    1:1
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className={(selectedRatioValue == 2 ? 'bg-primary' : '') + " text-center rounded-pill"} role="button" onClick={() => { handleRatioValue(3 / 4); setSelectedRatioValue(2); }}>
                                                    3:4
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className={(selectedRatioValue == 3 ? 'bg-primary' : '') + " text-center rounded-pill"} role="button" onClick={() => { handleRatioValue(1 / 1); setSelectedRatioValue(3); }}>
                                                    Original
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className={(selectedRatioValue == 4 ? 'bg-primary' : '') + " text-center rounded-pill"} role="button" onClick={() => { handleRatioValue(3 / 2); setSelectedRatioValue(4); }}>
                                                    3:2
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className={(selectedRatioValue == 5 ? 'bg-primary' : '') + " text-center rounded-pill"} role="button" onClick={() => { handleRatioValue(16 / 9); setSelectedRatioValue(5); }}>
                                                    16:9
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }



                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ImageCropperModal;
