import { useEffect, useRef, useState } from "react";
import React from "react";
import p_nav_previous from "../../../Assets/Images/p_nav_previous.svg";
import ion_cart_outline from "../../../Assets/Images/ion_cart-outline.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../Services/axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";

import defaultimg from "../../../Assets/Images/default-placeholder.png";

const SingleProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { productId, merchantId } = location.state || {};
  const [showAddToBasketBtn, setShowAddToBasketBtn] = useState(true);
  const [showBasketCount, setShowBasketCount] = useState(false);
  const [product, setProduct] = useState([]);
  const [cartId, setCartId] = useState("");
  const [shipingtype, setShipingtype] = useState("");
  const [cardStatus, setCardStatus] = useState();
  const [cardType, setcardType] = useState();
  const [cardId, setcardId] = useState();
  const [cartCount, setCartCount] = useState()
  let { vendorId, productid } = useParams();

  useEffect(() => {
    getproductdetails();
  }, [cartId]);

  useEffect(() => {
    let productCart = localStorage.getItem("productCart")
      ? JSON.parse(localStorage.getItem("productCart"))
      : "";
    setCartId(productCart?.cartId ? productCart?.cartId : "");
    setShipingtype(
      productCart?.shipping_type ? productCart?.shipping_type : ""
    );

    let appliedCard = JSON.parse(localStorage.getItem("appliedCard"));
    setCardStatus(appliedCard?.cardStatus ? appliedCard?.cardStatus : "");
    setcardType(appliedCard?.cardType ? appliedCard?.cardType : "");
    setcardId(appliedCard?.cardId ? appliedCard?.cardId : "");
  }, []);


  const getproductdetails = () => {
    try {
      let reqData = {
        vendor_id: vendorId,
        product_id: productid,
        cart_id: cartId
      };
      console.log('ReqDATA', reqData);
      axiosInstance
        .post("/user/products/single_product_detail_without_login", reqData)
        .then((res) => {
          console.log('product res---', res);
          setProduct(res.data.result);
          setCartCount(res.data.cart_count)
        })
        .catch((err) => {
          if (err.response.status == 400) {
            alert(`Error:${err.response.state}-${err.response.message}`);
          }
          if (err.response.status == 500) {
            alert(`Error:${err.response.status}-${err.response.message}`);
          }
        });
    } catch (error) {
      alert("Tryagin later");
    }
  };

  const Add_Remove_productcount = async (productId, Operation) => {
    try {
      let reqData = {
        vendor_id: vendorId,
        product_id: productId,
        shipping_type: shipingtype,
        quantity: 1,
        operation: Operation,
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
      };

      axiosInstance.post("user/products/add_or_remove_product_cart", reqData)
        .then((res) => {
          if (res.status == 200) {
            getproductdetails()
            const localcartid = res.data.cart_id;
            console.log('localcartid', res);
            let productCart = {
              shipping_type: shipingtype,
              cartId: res.data.cart_id,
              vendorId: vendorId,
            };

            localStorage.setItem("productCart", JSON.stringify(productCart));
            localStorage.setItem("cartid", JSON.stringify(res.data.cart_id));
            setCartId(res.data.cart_id);
            // getproductlist();
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            alert(
              `Error: ${err.response.status} - ${err.response.data.message}`
            );
          }
          if (err.response.status == 500) {
            alert(`Error:${err.response.status}-${err.response.message}`);
          }
        });
    } catch (error) {
      alert("try againe later");
    }
  };

  return (
    <>
      <div className="scroller-style-y">
        <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
          <div className="d-flex justify-content-between align-items-center ms-2 me-2">
            <img
              src={p_nav_previous}
              width={"24px"}
              height={"16px"}
              alt="Previous Button"
              role="button"
              onClick={() => navigate(-1)}
            />
            <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">Products</div>
          </div>

          <div
            className="batchContainer2"
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate("/productBasket", { state: { merchantId: { vendor_id: vendorId }, cartId } })
            }
          >
            <img src={ion_cart_outline} />

            { cartCount !== 0 ?
                < div className="basketCountSP primary-bg text-white">
                  {cartCount}
                </div> :
                <></>
            }
          </div>

          {product.added_count == 0 ? (
            <div className="addToBasketBtnDiv">
              <button
                className="my-2 py-3 addToBasketBtn primary-bg text-white ff-poppins-semiBold rounded-2 border-0 py-2"
                onClick={() => Add_Remove_productcount(productid, "add")} >
                Add to Basket
              </button>
            </div>
          ) : (
            <>
              <div className="addToBasketBtnDiv">
                <button
                  className="my-2 py-3 addToBasketBtn primary-bg text-white ff-poppins-semiBold rounded-2 border-0 py-2"
                    onClick={() => navigate("/productBasket", { state: { merchantId: { vendor_id: vendorId }, cartId } })}>
                  Go to Basket
                </button>
              </div>
            </>
          )}
        </div>

        {product?.imges?.length !== 0 && (
          <div
            id="carouselExampleInterval"
            className="carousel slide "
            data-bs-ride="carousel"
            data-bs-interval="2000"
          >
            <div className="carousel-inner">
              {product?.imges?.map((item, index) => (
                <div
                  key={index}
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                >
                  <img
                    src={item.image == "" ? defaultimg : item.image_url}
                    className="d-block w-100 curve"
                    alt={`slide-${index}`}
                  />
                </div>
              ))}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="prev"
            ></button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="next"
            ></button>
          </div>
        )}

        <div className="" style={{marginBottom:100}}>
          <div className="ff-balooPaaji2-semiBold fs-20 ms-2 ps-1 ">
            {product.product_name}
          </div>
          <div className="ff-balooPaaji2-semiBold fs-20 ms-2 ps-1">
            {product.price}{" "}
          </div>
          <div className="ff-balooPaaji2-semiBold primary-text fs-20 ms-2 ps-1">
            {product.unit_quantity}
            {product.unit_type_name}
            <p className="primary-text ">{product.stock_quantity}Left </p>
          </div>

          <div>
            <p className="ff-balooPaaji2 m-0 mx-2 my-4 ps-1">
              {product.description}
            </p>
          </div>
        </div>
      </div >
    </>
  );
};

export default SingleProduct;
