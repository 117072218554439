import { Link, useNavigate } from "react-router-dom";

import p_navbars from "../../Assets/Images/p_navbars.svg";
import p_nav_search from "../../Assets/Images/p_nav_search.svg";
import p_booking_appointments from "../../Assets/Images/p_booking_appointments.svg";
import p_booking_messenger from "../../Assets/Images/p_booking_messenger.svg";
import p_bookings_resech from "../../Assets/Images/p_bookings_resech.svg";
import p_booking_loyalty from "../../Assets/Images/p_booking_loyalty.svg";
import p_bookings_makeItEasy from "../../Assets/Images/p_bookings_makeItEasy.svg";
import p_bookings_connect from "../../Assets/Images/p_bookings_connect.svg";
import p_nav_location from "../../Assets/Images/p_nav_location.svg";
import p_nav_next_outline from "../../Assets/Images/p_nav_next_outline.svg";
import p_nav_book_outline from "../../Assets/Images/p_nav_book_outline.svg";
import p_nav_message_outline from "../../Assets/Images/p_nav_message_outline.svg";
import p_nav_notifications_outline from "../../Assets/Images/p_nav_notifications_outline.svg";
import p_nav_about_outline from "../../Assets/Images/p_nav_about_outline.svg";
import p_nav_setting_outline from "../../Assets/Images/p_nav_setting_outline.svg";
import p_appointment_right_arrow from "../../Assets/Images/p_appointment_right_arrow.svg";
import p_appointment_inStore from "../../Assets/Images/p_appointment_inStore.svg";
import inStore from "../../Assets/Images/In-Store.png";
import { FaExclamation } from "react-icons/fa";
import FooterButton from '../../Assets/Images/FooterButton.png';
import pingleLogoWhite from '../../Assets/Images/Pingle-Logo_White-62.png';

import { FaDoorOpen } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { getBookingList, getMyProfileDetails, notificationCounts } from "../../Services/api";
import axiosInstance from "../../Services/axios";
// import ProductCard from "../../Components/appointment_card/ProductCard";
import AppointmentCard from "../../Components/appointment_card/AppointmentCard";
import CameraScan from "../../Components/Camera/CameraScan";
import AppointmentProductCard from "../../Components/appointment_card/AppointmentProductCard";
import AppointmentServiceCard from "../../Components/appointment_card/AppointmentServiceCard";


const BookingsManager = () => {
    const yetTopayRef = useRef(null);
    const [isOpen, setIsOpen] = useState(true);
    const [value, setValue] = useState("");
    const [profile, setProfileData] = useState("");
    const [bookingDetails, setBookingDetails] = useState("");
    const [notificationcount, setNotificationcount] = useState("");
    const [activeTab, setActiveTab] = useState("services");
    const token = localStorage.getItem("token");
    const [vendor, setVendor] = useState();
    const [userDetail, setUserDetail] = useState("");
    const [userOrders, setUserOrders] = useState([]);
    const [ticket, setTicket] = useState([]);
    const [unconfirmed, setUnconfirmed] = useState([])
    const [todayproduct, setTodayproduct] = useState([])
    const [upcomingproduct, setUpcomingproduct] = useState([])

    const navigate = useNavigate();
    let [allAppointmentList, setAllAppointmentList] = useState([]);

    const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);

    const handlePaySuccessClose = () => setShowPaymentSuccessModal(false);
    const handlePaySuccessShow = () => setShowPaymentSuccessModal(true);

    useEffect(() => {
        if (!token) {
            navigate("/login");
            return;
        }

        getBookingList().then((res) => {

            setBookingDetails(res.data);

            setAllAppointmentList(
                allAppointmentList.concat(
                    res.data?.unconfirmed_appointment_details,
                    res.data?.active_appointment_details,
                    res.data?.upcoming_appointment_details,
                    res.data?.missed_appointment_details,
                    // res.data?.unconfirmed_appointment_details,
                    // res.data?.all_cancel_book_details

                )
            );
        });
        getMyProfileDetails().then((res) => {
            setProfileData(res.data?.user_details);
        });
        notificationCounts().then((res) => {

            setNotificationcount(res.data);
        });
        getIndex();
    }, [value]);

    function removeExtraFadeFromOffcanvas() {
        const myOffcanvas = document.getElementById('offcanvasTop');
        myOffcanvas.addEventListener('shown.bs.offcanvas', event => {
            let fade = document.getElementsByClassName('offcanvas-backdrop fade show')
            for (let i = 0; i < fade.length; i++) {
                while (fade.length > 1) {
                    fade[i].remove()
                }
            }
        });
    }

    const getIndex = () => {
        try {
            axiosInstance.post("/user/products/index").then((res) => {
                let data = res.data;

                setUserDetail(data.user_detail);
                let userOrders = data.user_orders
                userOrders?.map((order) => {
                    order.type = 'ORDER';
                });
                setUserOrders(userOrders);
                let unconfirmedOrders = data?.unconfirmed_orders
                data?.unconfirmed_orders?.map((order) => {
                    order.type = 'CART';
                });
                setTicket(data.user_orders.order_products);
                setUnconfirmed(unconfirmedOrders)
                let allOrders = unconfirmedOrders?.concat(userOrders);
                setUserOrders(allOrders);
                const todayProduct = [];
                const upcomingProduct = [];

                data.user_orders.forEach((tab) => {
                    if (tab.delivery_status === "2" || tab.delivery_status === "4") {
                        todayProduct.push(tab);
                    } else if (tab.delivery_status === "1") {
                        upcomingProduct.push(tab);
                    }
                });
                setTodayproduct(todayProduct);
                setUpcomingproduct(upcomingProduct);
            });
        } catch (error) {
            alert("Try Again later");
        }
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const openYettopayModal = () => {
        yetTopayRef.current.classList.add("show");
        yetTopayRef.current.style.display = "block";
    };
    const closeYettopayModal = () => {
        yetTopayRef.current.classList.remove("show");
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 white-bg p-navbar offcanvas-parentType1">
                <div className="d-flex justify-content-between align-items-center">
                    <img src={p_navbars} width={"24px"} height={"16px"} role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop" onClick={removeExtraFadeFromOffcanvas} />
                    <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1"> Bookings Manager </div>
                </div>
                <Link to={`/search-store`}>
                    <img src={p_nav_search} width={"20px"} height={"20px"} />
                </Link>
                <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel" >
                    <div className="offcanvas-body p-0">
                        <Link to={`/dashboard`} className="text-decoration-none">
                            <div className="profileDetails primary-bg p-3 text-white d-flex justify-content-between align-items-center">
                                <div className="d-flex">
                                    <img src={profile.user_img} width={"84px"} height={"81px"} />
                                    <div className="ms-2">
                                        <div className="ff-poppins-semiBold fs-16">
                                            {profile.user_name}
                                        </div>
                                        <div className="ff-balooPaaji2-regular fs-12">
                                            UID:{profile.user_id}
                                        </div>
                                        <div className="ff-balooPaaji2-regular fs-12">
                                            <img src={p_nav_location} width={"12px"} height={"12px"} className="me-1" />
                                            {profile.user_city}
                                        </div>
                                    </div>
                                </div>
                                <img src={p_nav_next_outline} width={"24px"} height={"24px"} />
                            </div>
                        </Link>

                        <div>
                            <div className="d-flex p-3" role="button" data-bs-dismiss="offcanvas" aria-label="Close"  >
                                <img src={p_nav_book_outline} width={"24px"} height={"24px"} />
                                <div className="ff-poppins-regular fs-16 ms-3">
                                    Bookings Manager
                                </div>
                            </div>

                            <Link
                                to={`/messenger-list`}
                                className="text-decoration-none text-dark"
                            >
                                <div className="d-flex p-3" role="button">
                                    <img src={p_nav_message_outline} width={"24px"} height={"24px"} />
                                    <div className="d-flex justify-content-between w-100">
                                        <div className="ff-poppins-regular fs-16 ms-3">Messenger</div>
                                        <p className="">
                                            {notificationcount.unread_message_count > 0 ?
                                                <span className="notification_count">
                                                    {notificationcount.unread_message_count >= 9 ? "9+" : notificationcount.unread_message_count}
                                                </span> : <></>
                                            }
                                        </p>
                                    </div>
                                </div>
                            </Link>

                            <div className="d-flex sidebar p-3 " role="button" onClick={() => navigate("/notifications", { state: { UserId: profile.user_id } })}>
                                <img src={p_nav_notifications_outline} width={"24px"} height={"24px"}
                                />
                                <div className="d-flex justify-content-between w-100">
                                    <div className="ff-poppins-regular fs-16 ms-3">
                                        Notification
                                    </div>
                                    <p className="">
                                        {notificationcount.unread_count > 0 ?
                                            <span className="notification_count">
                                                {notificationcount.unread_count >= 9 ? "9+" : notificationcount.unread_count}
                                            </span> : <></>
                                        }
                                    </p>
                                </div>
                            </div>

                            <Link to={`/aboutPingle`} className="text-decoration-none text-dark" >
                                <div className="d-flex p-3" role="button">
                                    <img src={p_nav_about_outline} width={"24px"} height={"24px"} />
                                    <div className="ff-poppins-regular fs-16 ms-3">
                                        About Pingle
                                    </div>
                                </div>
                            </Link>

                            <Link to={`/settings`} className="text-decoration-none text-dark">
                                <div className="d-flex p-3" role="button">
                                    <img
                                        src={p_nav_setting_outline}
                                        width={"24px"}
                                        height={"24px"}
                                    />
                                    <div className="ff-poppins-regular fs-16 ms-3">Settings</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bookingManager pb-5 scroller-style-y">
                <div className="header">
                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="ff-balooPaaji2-bold fs-20">Hello</div>
                            <div className="ff-balooPaaji2-bold fs-20">
                                {profile.user_name}
                            </div>
                        </div>
                    </div>

                    {/* <button data-bs-toggle="modal" data-bs-target="#exampleModal">Yet TO pay modal</button> */}


                    <div className="ff-poppins-regular fs-13 primary-text mt-2">
                        Your Appointments
                    </div>

                    {/* services and product  */}
                    <ul className="nav nav-pills py-4" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${activeTab === "services" ? "active" : ""} rounded-start serviceProductTab serviceBtnBM ff-poppins-regular`}
                                id="pills-services-tab" data-bs-toggle="pill" data-bs-target="#pills-services"
                                type="button" role="tab" aria-controls="pills-services"
                                aria-selected={activeTab === "services" ? "true" : "false"} onClick={() => handleTabClick("services")}  >
                                Services
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${activeTab === "products" ? "active" : ""} serviceProductTab rounded-end productBtnBM ff-poppins-regular`}
                                id="pills-products-tab" data-bs-toggle="pill" data-bs-target="#pills-products"
                                type="button" role="tab" aria-controls="pills-products"
                                aria-selected={activeTab === "products" ? "true" : "false"} onClick={() => handleTabClick("products")} >
                                Products
                            </button>
                        </li>
                    </ul>

                    {/* Seperate componant  */}
                    <div className="tab-content" id="pills-tabContent">
                        {/* For service  */}
                        <div className="tab-pane fade show active"
                            id="pills-services"
                            role="tabpanel"
                            aria-labelledby="pills-services-tab"
                            tabIndex="0"  >
                            <ul className="appointmentFilter scroller-style-x nav d-flex flex-nowrap nav-pills pt-2 pb-3"
                                id="pills-services-tab"
                                role="tablist"    >
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-all-services-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-all-services" type="button" role="tab" aria-controls="pills-all-services" aria-selected="true"   >
                                        All
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-today-services-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-today-services" type="button" role="tab" aria-controls="pills-today-services" aria-selected="false" >
                                        Today
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-unconfirmed-services-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-unconfirmed-services" type="button" role="tab" aria-controls="pills-unconfirmed-services" aria-selected="false"   >
                                        Unconfirmed
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link"
                                        id="pills-upcoming-services-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-upcoming-services"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-upcoming-services"
                                        aria-selected="false"
                                    >
                                        Upcoming
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className="nav-link"
                                        id="pills-missed-services-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-missed-services"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-missed-services"
                                        aria-selected="false"
                                    >
                                        Missed
                                    </button>
                                </li>
                            </ul>

                            <div className={`tab-pane fade show active`} id="pills-all-services" role="tabpanel" aria-labelledby="pills-all-services-tab" >
                                {
                                    allAppointmentList?.length > 0 ?
                                        allAppointmentList.map(item => {
                                            return (
                                                <div
                                                    onClick={() => {
                                                        if (item.book_status === '5') {
                                                            if (((Number(item.book_service_type) == 2 && Number(item.doorstep_request_status) == 2) || Number(item.book_service_type) == 1)) {
                                                                openYettopayModal()
                                                            } else {
                                                                let serviceDetail = {
                                                                    serviceType: item.book_service_type,
                                                                    vendorId: item.book_vendor_id,
                                                                    cart_app_id: item.cart_app_id
                                                                }
                                                                localStorage.setItem('serviceDetail', JSON.stringify(serviceDetail));
                                                                navigate(`/review-and-pay/${item.book_vendor_id}`)
                                                            }
                                                        }
                                                        else { navigate(`/details/${item.booking_id}`); }
                                                    }}>
                                                    <AppointmentServiceCard data={item} />
                                                    <div class="modal fade" id="exampleModal" tabindex="-1" ref={yetTopayRef} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div class="modal-dialog modal-dialog-centered modal-sm m-0">
                                                            <div class="modal-content mx-5">
                                                                <div class="modal-body px-3 pt-4 pb-4">
                                                                    <div className="fs-1 text-center"><FaExclamation className="exclamationIcon primary-text" /></div>
                                                                    <div className='text-center ff-poppins-semiBold fs-5 mb-3'>Confirm Booking</div>
                                                                    <div className='text-center ff-poppins-semiBold secondary-text fs-16' >Please confirm your booking by completing the payment by completing the payment process.</div>

                                                                    <div className='d-flex justify-content-center mt-3'>
                                                                        <button type="button" class="btn primary-bg text-white px-5 ff-poppins-semiBold rounded-pill" data-bs-dismiss="modal" onClick={() => {

                                                                            let serviceDetail = {
                                                                                serviceType: item.book_service_type,
                                                                                vendorId: item.book_vendor_id,
                                                                                cart_app_id: item.cart_app_id
                                                                            }
                                                                            localStorage.setItem('serviceDetail', JSON.stringify(serviceDetail));
                                                                            navigate(`/review-and-pay/${item.book_vendor_id}`)
                                                                        }}  >OK</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                            No service appointments
                                        </div>}
                            </div>
                            <div className="tab-pane fade" id="pills-today-services" role="tabpanel" aria-labelledby="pills-today-services-tab" >
                                {
                                    bookingDetails?.active_appointment_status ?
                                        bookingDetails?.active_appointment_details.map(item => {
                                            return (
                                                <div onClick={() => navigate(`/details/${item.booking_id}`)}>
                                                    <AppointmentServiceCard data={item} />
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="text-center ff-poppins-regular fs-14 secondary-text pt-4">
                                            Today No service appointments
                                        </div>
                                }
                            </div>

                            <div className="tab-pane fade" id="pills-unconfirmed-services" role="tabpanel" aria-labelledby="pills-unconfirmed-services-tab" >
                                {
                                    bookingDetails?.unconfirmed_appointment_details?.length > 0 ? bookingDetails?.unconfirmed_appointment_details.map(item => {
                                        return (
                                            <div onClick={() => openYettopayModal()}>
                                                <AppointmentServiceCard data={item} />
                                            </div>
                                        )
                                    })
                                        :
                                        <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                            No unconfirmed service appointments
                                        </div>
                                }
                            </div>

                            <div className="tab-pane fade w-100" id="pills-upcoming-services" role="tabpanel" aria-labelledby="pills-upcoming-services-tab"  >
                                {
                                    bookingDetails?.upcoming_appointment_status ?
                                        bookingDetails?.upcoming_appointment_details.map(item => {
                                            return (
                                                <div onClick={() => navigate(`/details/${item.booking_id}`)}>
                                                    <AppointmentServiceCard data={item} />
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                            No upcoming service appointments
                                        </div>}
                            </div>
                            <div className="tab-pane fade" id="pills-missed-services" role="tabpanel" aria-labelledby="pills-missed-services-tab" >
                                {bookingDetails?.missed_appointment_status ? (
                                    bookingDetails?.missed_appointment_details.map((item) => {
                                        return (
                                            <div onClick={() => navigate(`/details/${item.booking_id}`)}  >
                                                <AppointmentServiceCard data={item} />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="text-center ff-poppins-regular fs-14 secondary-text pt-4">
                                        No missed service appointments
                                    </div>
                                )}
                            </div>

                        </div>

                        {/* For products  */}
                        <div className="tab-pane fade" id="pills-products" role="tabpanel" aria-labelledby="pills-products-tab" tabIndex="0" >
                            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="pills-all-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true" >
                                        All
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-today-tab" data-bs-toggle="pill" data-bs-target="#pills-today"
                                        type="button" role="tab" aria-controls="pills-today" aria-selected="false" >
                                        Today
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-upcoming-tab" data-bs-toggle="pill" data-bs-target="#pills-upcoming"
                                        type="button" role="tab" aria-controls="pills-upcoming" aria-selected="false" >
                                        Upcoming
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-unconfirmed-tab" data-bs-toggle="pill" data-bs-target="#pills-unconfirmed"
                                        type="button" role="tab" aria-controls="pills-unconfirmed" aria-selected="false" >
                                        Unconfirmed
                                    </button>
                                </li>
                            </ul>

                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab" tabindex="0"  >
                                    {isOpen ? (
                                        <div className={`tab-pane fade ${activeTab === "products" ? "show active" : ""}`} >
                                            {userOrders?.length > 0 ? (
                                                userOrders.map((product, index) => (
                                                    <div className="tab-pane mt-2" id="pills-missed" role="tabpanel" aria-labelledby="pills-missed-tab" >
                                                        <div onClick={() => {
                                                            if (product.type === 'CART') {
                                                                navigate("/ReviewPay", { state: { merchantId: product.vendor_id, shipingtype: product.shipping_type }, })
                                                                localStorage.setItem("cartid", JSON.stringify(product.cart_id))
                                                            }
                                                            else {
                                                                navigate('/ProductPass', { state: { orderid: product.order_id } })
                                                            }
                                                        }}>
                                                            <AppointmentProductCard data={product} />
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                    No product
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div class="tab-pane fade" id="pills-today" role="tabpanel" aria-labelledby="pills-today-tab" tabindex="0">
                                    {
                                        todayproduct?.length > 0 ?
                                            todayproduct.map((product, subindex) => {
                                                return (
                                                    <div onClick={() => navigate('/ProductPass', { state: { orderid: product.order_id } })}>
                                                        <AppointmentProductCard data={product} />
                                                    </div>
                                                );
                                            }) :
                                            <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                No product
                                            </div>
                                    }
                                </div>
                                <div class="tab-pane fade" id="pills-upcoming" role="tabpanel" aria-labelledby="pills-upcoming-tab" tabindex="0" >
                                    {
                                        upcomingproduct?.length > 0 ?
                                            upcomingproduct.map((product, subindex) => {
                                                return (
                                                    <div onClick={() => navigate('/ProductPass', { state: { orderid: product.order_id } })}>
                                                        <AppointmentProductCard data={product} />
                                                    </div>
                                                )
                                            }) :
                                            <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                No upcoming product
                                            </div>}
                                </div>
                                <div class="tab-pane fade" id="pills-unconfirmed" role="tabpanel" aria-labelledby="pills-unconfirmed-tab" tabindex="0" >
                                    {
                                        unconfirmed?.length > 0 ?
                                            unconfirmed.map((product, subindex) => {
                                                return (
                                                    <div onClick={() => {
                                                        localStorage.setItem("cartid", JSON.stringify(product.cart_id))
                                                        navigate("/ReviewPay", { state: { merchantId: product.vendor_id, cartId: product.cart_id, shipingtype: product.shipping_type } })
                                                    }}>
                                                        <AppointmentProductCard data={product} />
                                                    </div>
                                                );
                                            }) :
                                            <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                No Unconfirmed paroduct
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Book Appointments  */}

                <div className="primary-bg text-white features" role="button" onClick={() => navigate('/search-store')}>
                    <div className="d-flex justify-content-between align-items-center">
                        <img src={p_booking_appointments} width={"75px"} height={"75px"} />
                        <div className="ff-poppins-semiBold fs-24 text-end">
                            Book <br /> Appointments
                        </div>
                    </div>
                    <div className="ff-poppins-regular fs-11 text-end mt-3">
                        Don’t waste your time in a queue or driving to your appointment.
                        Pingle will make it easy for you. Now you can check available
                        slots,customize service and book appointment. All at your
                        convenience!
                    </div>
                </div>

                <div className="pb-0 features">
                    <div className="ff-poppins-medium fs-21">Tutorial</div>
                    <div className="ff-poppins-regular fs-11">
                        This step-by-step Beginner-friendly tutoial will guide you on how to
                        use the app and make your first pingle booking.
                    </div>
                </div>

                <div className="tutorialSteps scroller-style-x">
                    <div className="bg-white tutorialCard">
                        <div className="ff-poppins-semiBold fs-34 primary-text">01</div>
                        <div className="ff-poppins-semiBold fs-10 mt-1">Step One</div>
                        <div className="ff-poppins-semiBold fs-16">Scan</div>
                        <div className="ff-poppins-regular fs-10 mt-2">
                            Pingle Scan Shop QR code.
                        </div>
                    </div>
                    <div className="bg-white tutorialCard">
                        <div className="ff-poppins-semiBold fs-34 primary-text">02</div>
                        <div className="ff-poppins-semiBold fs-10 mt-1">Step Two</div>
                        <div className="ff-poppins-semiBold fs-16">Select</div>
                        <div className="ff-poppins-regular fs-10 mt-2 text-wrap">
                            Click View Services, and Select the Preferred Service & Time Slot
                        </div>
                    </div>
                    <div className="bg-white tutorialCard">
                        <div className="ff-poppins-semiBold fs-34 primary-text">03</div>
                        <div className="ff-poppins-semiBold fs-10 mt-1">Step Three</div>
                        <div className="ff-poppins-semiBold fs-16">Book</div>
                        <div className="ff-poppins-regular fs-10 mt-2 text-wrap">
                            Book and Pay. You will receive a ticket, accessible from Bookings
                            Manager.
                        </div>
                    </div>
                    <div className="bg-white tutorialCard">
                        <div className="ff-poppins-semiBold fs-34 primary-text">04</div>
                        <div className="ff-poppins-semiBold fs-10 mt-1">Step Four</div>
                        <div className="ff-poppins-semiBold fs-16">Redeem</div>
                        <div className="ff-poppins-regular fs-10 mt-2 text-wrap">
                            Visit the Salon at the scheduled time and Redeem the Ticket.
                        </div>
                    </div>
                    <div className="bg-white tutorialCard">
                        <div className="ff-poppins-regular fs-14 mt-2 text-wrap">
                            If you are late by 20 minutes, the booking will be marked missed.
                            You need to reschedule your appointment at a different time.
                        </div>
                    </div>
                </div>

                <div className="primary-bg text-white features" role="button" onClick={() => navigate('/messenger-list')}>
                    <div className="d-flex justify-content-between align-items-center">
                        <img src={p_booking_messenger} width={"75px"} height={"75px"} />
                        <div className="ff-poppins-semiBold fs-24 text-end lh-sm">
                            Pingle <br /> Messenger
                        </div>
                    </div>
                    <div className="ff-poppins-regular fs-11 text-end mt-3">
                        Have a query? Don’t Worry. Our go-to assistant, Pingle messenger,
                        will assist you in real time and elevate your app experience. futher
                        - Talk to our bot like you do to a friend. and there is more. You
                        can also chat dircttly with the service provider to get absolute
                        clarity.
                    </div>
                </div>

                <div className="white-bg features">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="ff-poppins-semiBold fs-24 primary-text lh-sm">
                            Reschedule <br /> Appointments
                        </div>
                        <img src={p_bookings_resech} width={"75px"} height={"75px"} />
                    </div>
                    <div className="ff-poppins-regular fs-11 mt-3">
                        Feeling under the weather or like you need to switch up your plans?
                        We’ve all been there - life happens! But in Pingle, you can
                        reschedule any upcoming appointments with just one click. No more
                        waiting on the phone, trying to figure our a new time that works for
                        both parties. Click, select a new time, and reschedule.
                    </div>
                </div>

                <div className="primary-bg text-white features" role="button" onClick={() => navigate('/loyalty')}>
                    <div className="d-flex justify-content-between align-items-center">
                        <img src={p_booking_loyalty} width={"75px"} height={"75px"} />
                        <div className="ff-poppins-semiBold fs-24 text-end lh-sm">
                            Loyalty <br /> Points
                        </div>
                    </div>
                    <div className="ff-poppins-regular fs-11 text-end mt-3">
                        There is another“ing” we offer you.saving! For each referral and
                        booking, you earn Pingle Points that you can use to buy products
                        from our Reward Store! Happy user, Happy us.
                    </div>
                </div>

                <div className="white-bg features">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="ff-poppins-semiBold fs-24 primary-text lh-sm">
                            Make <br /> it easy
                        </div>
                        <img src={p_bookings_makeItEasy} width={"75px"} height={"75px"} />
                    </div>
                    <div className="ff-poppins-regular fs-11 mt-3">
                        With Pingle you can connect with your favorite outlets, book
                        appointments, pay for it, earn loyalty points, secure offers, give
                        feedback and also reschedule appointments. with pingle you can skip
                        the queue in this busy and unpack life, Life romba busy ah..pingle
                        pannu easy ah!!
                    </div>
                </div>

                <div className="primary-bg text-white features" role="button" onClick={() => navigate('/chat/PINGLE')}>
                    <div className="d-flex justify-content-between align-items-center">
                        <img src={p_bookings_connect} width={"75px"} height={"75px"} />
                        <div className="ff-poppins-semiBold fs-24 text-end lh-sm">
                            Connect <br /> Directly
                        </div>
                    </div>
                    <div className="ff-poppins-regular fs-11 text-end mt-3">
                        Need further assistance or want to share your Pingle experience with
                        us? Connect directly with our customer service center with your
                        booking number and share your query or grievance.Customer
                        satisfaction is our top priority.
                    </div>
                </div>


            </div>
            <div className="position-absolute " style={{ bottom: 0, width: 390, justifyContent: 'center' }}>
                <div className="">
                    <img src={FooterButton} width="100%" height={"90"} />
                </div>
                <div className="position-absolute " style={{ bottom: 0, justifyContent: 'center', left: 195, transform: 'translateX(-50px)', width: 100 }}>
                    <div className="d-flex justify-content-center ">
                        <Link to={'/QRCodeScan'}> <img src={pingleLogoWhite} height={50} width={50} className="primary-bg rounded-circle" /> </Link>
                    </div>
                    <div className="ff-balooPaaji2-semiBold primary-text fs-12 d-flex justify-content-center mb-2">
                        PINGLE SCAN
                    </div>
                </div>
            </div>
            {showPaymentSuccessModal && (
                <div className="modal-backdrop show"></div>
            )
            }

            {
                showPaymentSuccessModal && (
                    <div className="modal justify-content-center" tabIndex="-1" style={{ display: 'block' }}>
                        <div className="modal-dialog-centered paymentSuccessModal">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='ff-poppins-semiBold fs-1 primary-text text-center'>
                                        Attention!
                                    </div>
                                    <div className='ff-poppins-regular fs-14 text-center mt-2'>
                                        To proceed further you need to login/register and get access to all the features.
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center mt-1 mb-1' role='button' onClick={() => navigate('/login')}>
                                        <div className='loginBtn primary-bg text-center text-white p-1 ff-poppins-medium fs-16'>Login</div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center' role='button' onClick={handlePaySuccessClose}>
                                        <div className='loginBtn text-center text-secondary p-1 ff-poppins-medium fs-16 secondary-bg-1 mt-2'>Close</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }


        </>
    );
};

export default BookingsManager;
